import {computed} from "vue";

const stateColors = {
    'in-process': {
        itemClass: 'bg-yellow-600'
    },
    'sas-check': {
        itemClass: 'bg-yellow-600'
    },
    'user-check': {
        itemClass: 'bg-yellow-600'
    },
    'approved': {
        itemClass: 'bg-red-450'
    },
    'creative-waiting-for-approval': {
        itemClass: 'bg-orange-400'
    },
    'creative-returned': {
        itemClass: 'bg-orange-400'
    },
    'prepared': {
        itemClass: 'bg-green-600'
    },
    'online': {
        itemClass: 'bg-green-600'
    },
    'closed': {
        itemClass: 'bg-gray-300'
    },
    'paused': {
        itemClass: 'bg-blue-600'
    },
    'canceled': {
        itemClass: 'bg-red-450'
    },
    'reserved': {
        itemClass: 'bg-blue-600'
    },
}

export const getCampaignStateColor = (stateIdentifier) => {
    return stateColors[stateIdentifier].itemClass;
};

export default function (stateIdentifier) {
    const stateColor = computed(() => getCampaignStateColor(stateIdentifier.value));
    return {
        stateColor
    }
}
