<template>
    <p
        class="flex items-top relative group rounded-3xl px-3 py-1"
        :class="{
            'bg-red-50 text-red-500': type === 'error',
            'bg-green-50 text-green-500': type === 'success',
            'bg-yellow-50 text-orange-500': type === 'info',
        }"
        v-bind="$attrs"
    >
        <c-icon class="w-4 h-4 mr-2.5 mt-0.5 shrink-0" :icon="iconName"></c-icon>
        <slot></slot>
    </p>
</template>

<script>

import CIcon from "@/components/base/icons/CIcon.vue";

export const InfoMessageTypes = {
    INFO: 'info',
    SUCCESS: 'success',
    ERROR: 'error',
}

export default {
    name: "InfoMessage",
    components: {CIcon},
    props: {
        icon: {
            type: String,
            default: '',
        },
        type: {
            type: String,
            default: InfoMessageTypes.INFO,
        }
    },
    computed: {
        iconName() {
            if(this.icon) {
                return this.icon;
            }
            if(this.type === InfoMessageTypes.INFO) {
                return 'i-circle-full';
            } else if(this.type === InfoMessageTypes.SUCCESS) {
                return 'check-circle-full';
            } else if(this.type === InfoMessageTypes.ERROR) {
                return 'triangle-exclamation-full';
            }
            return 'triangle-exclamation-full';
        }
    }
}
</script>

<style scoped>

</style>
