import Vue from "vue";
import Vuex from "vuex";
import appSettings from "@/store/appSettings";
import account from "@/store/account";
import brands from "@/store/brands";
import products from "@/store/products";
import campaigns from "@/store/campaigns";
import campaignItemsList from "@/store/campaignItemsList";
import addedProduct from "@/store/addedProduct";
import targeting from "@/store/targeting";
import users from "@/store/users";
import sasInfo from "@/store/sasInfo";
import settings from "@/store/settings";
import images from "@/store/images";
import pages from "@/store/pages";
import creatives from "@/store/creatives";
import creativeFormats from "@/store/creativeFormats";
import landingPages from "@/store/landingPages";
import landingPagesList from "@/store/landingPagesList";
import dashboards from "@/store/dashboards";


Vue.use(Vuex);

const store = new Vuex.Store({
    state: {},
    getters: {},
    mutations: {},
    actions: {},
    modules: {
        appSettings,
        account,
        brands,
        products,
        campaigns,
        campaignItemsList,
        addedProduct,
        targeting,
        users,
        sasInfo,
        settings,
        images,
        pages,
        creatives,
        creativeFormats,
        landingPages,
        landingPagesList,
        dashboards,
    },
});
export default store;
export const useStore = () => store
