import axios from "@/plugins/axios";

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        async getCampaignSasInfo(context, {campaignId}) {
            const response = await axios.get('/sas-info/campaign/' + campaignId);
            return response.data;
        },
        async getCampaignItemSasInfo(context, {campaignItemId}) {
            const response = await axios.get('/sas-info/campaign-item/' + campaignItemId);
            return response.data;
        },
    }
};
