<template>
    <t-modal v-model="showDialog">
        <div class="md:p-16 py-8 px-4">
            <div class="mx-auto">
                <h1 class="text-black text-3xl mb-5 pr-5">
                    {{ $t('component_campaignCreateDialog_header') }}
                </h1>
				<form action class="mt-8 w-full" @submit.prevent="submit">
                    <div class="bg-gray-50 rounded-2xl flex flex-col space-y-4 p-4 pb-6">
                        <div
                            v-if="isRetailerOrAgency"
                        >
                            <label for="" class="text-gray-600 mb-3 block">
                                {{ $t('common_advertiserCompany') }}
                            </label>
                            <t-rich-select
                                v-model="companyId"
                                :options="companies"
                                value-attribute="id"
                                text-attribute="name"
                                required
                            >
                            </t-rich-select>
                        </div>
                        <div v-if="isRetailerOrAgency">
                            <label for="" class="text-gray-600 mb-3 block">
                                {{ $t('common_advertiserCompanyContact') }}
                            </label>
                            <t-rich-select
                                v-model="companyContactId"
                                :options="companyContacts"
                                value-attribute="id"
                                text-attribute="name"
                                :disabled="disableName"
                                clearable
                            >
                            </t-rich-select>
                        </div>
                        <div v-if="!disableSalesPersonSelect">
                            <label for="" class="text-gray-600 mb-3 block">
                                {{ $t('common_salesPerson') }}
                            </label>
                            <t-rich-select
                                v-model="salesPersonId"
                                :options="salesPersons"
                                value-attribute="id"
                                text-attribute="name"
                                :disabled="disableSalesPersonSelect"
                                clearable
                            >
                            </t-rich-select>
                        </div>
                        <div v-if="isRetailer">
                            <label for="" class="text-gray-600 mb-3 block">
                                {{ $t('common_agency') }}
                            </label>
                            <t-rich-select
                                v-model="agencyId"
                                :options="agencies"
                                value-attribute="id"
                                text-attribute="name"
                                clearable
                            >
                            </t-rich-select>
                        </div>
                        <div v-if="isRetailerOrAgency">
                            <label for="" class="text-gray-600 mb-3 block">
                                {{ $t('common_agencyContact') }}
                            </label>
                            <t-rich-select
                                v-model="agencyContactId"
                                :options="agencyContacts"
                                value-attribute="id"
                                text-attribute="name"
                                :disabled="disableAgencyContactSelect"
                                clearable
                            >
                            </t-rich-select>
                        </div>
                        <div>
                            <label for="" class="text-gray-600 mb-3 block">
                                {{ $t('component_campaignCreateDialog_inputCampaignName') }}
                            </label>
                            <t-input
                                variant=""
                                class=""
                                v-model="campaignName"
                                :disabled="disableName"
                                required
                            >
                            </t-input>
                        </div>
                    </div>
                    <div class="flex flex-wrap items-center justify-end mt-8 space-x-6">
                        <text-button class="text-gray-400" @click.prevent="showDialog = false">
                            {{ $t('common_cancel') }}
                        </text-button>
                        <c-button
                            color="green"
                            size="big"
                            :disabled="disableButton"
                            type="submit"
                            prepend-icon="plus"
                            :loading="submitting"
                        >
                            {{ $t('common_add') }}
                        </c-button>
                    </div>
                </form>

            </div>
        </div>
        <template v-slot:button>
            <div class="w-8 h-8 bg-blue-100 rounded-full flex">
                <svg-icon name="x" class="w-3 h-3 m-auto"></svg-icon>
            </div>
        </template>
    </t-modal>
</template>
<script>
import SvgIcon from "@/components/SvgIcon";
import {mapGetters} from "vuex";
import CButton from "@/components/base/buttons/CButton.vue";
import TextButton from "@/components/base/buttons/TextButton.vue";

export default {
    name: "CreateCampaignDialog",
    props: ["value"],
    components: { TextButton, CButton, SvgIcon },
    data: () => {
        return {
            campaignName: '',
            companyId: null,
            companyContactId: null,
            salesPersonId: null,
            agencyId: null,
            agencyContactId: null,

            agencies: [],
            agencyContacts: [],
            companies: [],
            companyContacts: [],
            salesPersons: [],

            submitting: false
        };
    },
    computed: {
        ...mapGetters('auth', ['isRetailer', 'isAgency', 'isVendor', 'isSalesPerson']),
        showDialog: {
            get() {
                return this.value;
            },
            set(value) {
                if (!value) {
                    this.campaignName = '';
                    this.companyId = null;
                    this.companyContactId = null;
                    this.salesPersonId = null;
                    this.agencyId = null;
                    this.agencyContactId = null;
                }
                this.$emit("input", value);
            },
        },
        disableName() {
            return this.isRetailerOrAgency && !this.companyId;
        },
        disableAgencyContactSelect() {
            return !this.agencyId;
        },
        disableButton() {
            return this.campaignName.length === 0 || this.submitting || (this.isRetailerOrAgency && !this.companyId);
        },
        isRetailerOrAgency() {
            return this.isRetailer || this.isAgency;
        },
        disableSalesPersonSelect() {
            return this.salesPersons.length <= 1;
        }
    },
    watch: {
        async showDialog(value) {
            if (!value) {
                return;
            }
            if (this.isRetailerOrAgency) {
                const userId = this.$store.getters['auth/userId'];
                this.companies = await this.$store.dispatch('users/getVendorCompanies', {userId: userId});
            }
            if (this.isRetailer) {
                this.agencies = await this.getAgencies();
                const allSalesPersons = await this.getAllSalesPersons();
                this.salesPersons = allSalesPersons.filter((item) => item.active === true);
                if (this.isSalesPerson) { // current user is sales person
                    this.salesPersonId = this.$store.getters['auth/userId'];
                }
            }
            if (this.isAgency) {
                this.agencyId = this.$store.getters['auth/agencyId'];
                this.agencyContactId = this.$store.getters['auth/agencyContactId'];
            }
            if (this.isVendor) {
                this.salesPersons = await this.getLoggedUserSalesPersons();
                if (this.salesPersons.length > 0) { // set first sales person as default
                    this.salesPersonId = this.salesPersons[0].id;
                }
            }
        },
        async companyId(value) {
            if (value) {
                this.companyContacts = await this.$store.dispatch('users/getCompanyContacts', {companyId: value});
                this.companyContactId = null;
            }
        },
        async companyContactId(value) {
            if (!value) { // value removed
                return;
            }
            if (this.isVendor) { // vendor can't set company contact
                return;
            }
            if (this.isRetailer && !this.isSalesPerson) {
                // set default sales person selected company contact
                const salesPersons = await this.getSelectedCompanyContactSalesPersons(value);
                if (salesPersons.length > 0) { // set first sales person as default
                    this.salesPersonId = salesPersons[0].id;
                }
            }
            if (this.isAgency) {
                const salesPersons = await this.getSelectedCompanyContactSalesPersons(value);
                this.salesPersons = salesPersons;
                if (salesPersons.length > 0) { // set first sales person as default
                    this.salesPersonId = salesPersons[0].id;
                }
            }
        },
        async agencyId(value, oldValue) {
            if (value) {
                this.agencyContacts = await this.$store.dispatch('users/getAgencyContacts', {agencyId: value});
            } else {
                this.agencyContacts = [];
            }
            if (oldValue) { // old value was not empty
                // reset agencyContactId
                this.agencyContactId = null;
            }
        }
    },
    methods: {
        async submit() {
            this.submitting = true;
            const campaign = await this.$store.dispatch("campaigns/createCampaign", {
                name: this.campaignName,
                companyId: this.companyId,
                contactId: this.companyContactId,
                salesPersonId: this.salesPersonId,
                agencyId: this.agencyId,
                agencyContactId: this.agencyContactId
            });
            this.submitting = false;
            this.campaignName = '';
            await this.$router.push({name: 'campaignDetail', params: {campaignId: campaign.id}});
        },
        async getAgencies() {
            return await this.$store.dispatch('users/getAgencies');
        },
        async getLoggedUserSalesPersons() {
            const user = await this.$store.dispatch('auth/getLoggedUser');
            return user.salesPersons;
        },
        async getAllSalesPersons() {
            return await this.$store.dispatch('users/getSalesPersonUsers');
        },
        async getSelectedCompanyContactSalesPersons(companyContactId) {
            const companyContact = this.companyContacts.find(companyContact => companyContact.id === companyContactId);
            if (!companyContact || !companyContact.userId) {
                return [];
            }
            const user = await this.$store.dispatch('users/getUser', {userId: companyContact.userId});
            return user.salesPersons;
        }
    },
    filters: {},
};
</script>
