<template>
    <router-link class="w-full py-0.5"
                 :class="filterActive ? 'ml-auto mr-0 xl:max-w-[calc(100%-360px)]' : ''"
                 :to="{
                name: 'campaignDetail',
                params: {campaignId: this.campaign.id},
            }"
    >
        <t-card variant="campaign" :class="[
					campaignStateIdentifier == 'closed' ? '' : '',
					campaignStateIdentifier == 'sas-check' ? 'bg-zinc-100 ' : '',
                    (campaignStateIdentifier !== 'closed' && campaignStateIdentifier !== 'sas-check')? '' : ''
				]"
        >
            <div class="grid lg:grid-cols-15 grid-cols-15 font-medium items-center gap-x-2">
                <div class="order-1 my-0 col-span-2 lg:col-span-2">
                    <h2 class="font-medium text-base leading-4">
                        {{ campaign.name }}
                    </h2>
                    <p class="text-xs font-normal leading-3 mt-1.5">
                        <span class="text-blue-800 opacity-30">{{ $t('common_contains') }}</span>
                        {{ $tc('component_campaignListItem_items', campaign.campaignItemsCount) }} <!-- DOTO: rozdělit do dvou textů -->
                    </p>
                </div>
                <div v-if="isRetailerOrAgency" class=" order-3 lg:order-2 leading-3 mt-3 lg:mt-0 col-start-5 col-end-7">
                    {{ campaign.company.name }}
                    <div v-if="campaign.companyContact" class="font-normal leading-3 mt-1.5 text-xs">
                        {{ campaign.companyContact.name }}
                    </div>
                </div>
                <div v-if="isRetailerOrAgency" class=" order-3 lg:order-2 leading-3 mt-3 lg:mt-0 pr-3 col-start-7 col-end-9">
                    <div v-if="campaign.salesPerson" class="font-normal leading-3 mt-1.5 text-xs">
                        {{ campaign.salesPerson.name }}
                    </div>
                </div>

                <div class="mt-3 lg:mt-0 items-start lg:items-center order-3 grid grid-cols-12 col-start-9 col-end-13">

                    <div class="col-span-6 flex lg:justify-start justify-end">
                        <campaign-state :campaignState="campaign.campaignState"></campaign-state>
                    </div>
                    <div class="col-span-6 order-2 flex justify-end lg:justify-center">
                        <div class="lg:flex inline-flex bg-gray-300 rounded-full px-3 py-1 lg:bg-transparent lg:justify-center">
                            <div v-if="startDateString" class="lg:text-right">{{ startDateString }}</div>
                            <div v-else class="lg:w-1/3 lg:text-right text-blue-800 opacity-30">/</div>
                            <span class="px-3 font-normal text-blue-800 opacity-30">-</span>
                            <div v-if="endDateString" class="lg:text-left">{{ (endDateString ? endDateString:'/') }}</div>
                            <div v-else class="lg:w-1/3 lg:text-left text-blue-800 opacity-30">/</div>
                        </div>
                    </div>
                </div>
                <div class="col-span-2 grid grid-cols-12 order-6 items-center w-full gap-x-2">
                    <div class="col-span-12 lg:col-span-12 text-right order-2 lg:order-1">
                        {{ campaign.totalPrice ? $n(campaign.totalPrice, "currency") : "" }}
                    </div>
                </div><!-- .col-span-3 -->
            </div>
        </t-card>
        <div v-if="campaign.displayPerformance" class="flex border-b-2 border-gray-50 mt-1 mb-3 px-4 py-3 bg-white first:rounded-l-xl last:rounded-r-xl group-hover:bg-blue-50 transition duration-100 w-[80%] ml-auto">
            <h2 class="text-gray-500 font-medium p-1 text-left">
                {{ $t('common_currentPerformance') }}:
            </h2>
            <activity-actual-performance :campaignItem="campaign"></activity-actual-performance>
        </div>
    </router-link>
</template>
<script>

import ActivityActualPerformance from '@/components/ActivityActualPerformance.vue';
import CampaignState from "@/components/CampaignState";
import stringFilters from "@/mixins/string-filters";
import {mapGetters} from "vuex";

export default {
    name: "CampaignListItem",
    mixins: [stringFilters],
    components: {CampaignState, ActivityActualPerformance},
    props: ["campaign", 'filterActive'],
    computed: {
        ...mapGetters('auth', ['isRetailer']),
        ...mapGetters('appSettings', [
            'displayActualImpressions',
            'displayActualClicks',
            'displayActualViews',
            'displayOfPerformanceMetricsGroup'
        ]),
        isRetailerOrAgency() {
            return this.$store.getters['auth/userRoles'].indexOf('Retailer') >= 0 || this.$store.getters['auth/userRoles'].indexOf('Agency') >= 0;
        },
        campaignStateIdentifier() {
            return this.campaign.campaignState.identifier;
        },
        startDateString: function () {
            return this.campaign.startDateString;
        },
        endDateString: function () {
            return this.campaign.endDateString;
        },
        actualImpressions() {
            return this.campaign.actualImpressions;
        },
        actualViews() {
            return this.campaign.actualViews;
        },
        actualClicks() {
            return this.campaign.actualClicks;
        },
    },
};
</script>
