<template>
    <sortable-multiple-select
        ref="select"
        class="w-full"
        v-model="sku"
        :label="label"
        :fetch-options="fetchTargetLinks"
        :fetch-key-option="getTargetLinkBySku"
        value-attribute="externalIdentifier"
        text-attribute="nameWithSku"
        :rules="rules"
        v-bind="$attrs"
    ></sortable-multiple-select>
</template>

<script>
import SortableMultipleSelect from "@/components/SortableMultipleSelect.vue";

export default {
    name: "SkuInputPicker",
    components: {
        SortableMultipleSelect
    },
    props: {
        value: {
            type: [Number, Array],
            default: null
        },
        label: {
            type: String,
            default: ''
        },
        targetId: {
            type: Number,
            nullable: true,
            default: null
        },
        multiple: {
            type: Boolean,
            default: false
        },
        rules: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            show: false,
            items: [],
            targetLink: null
        }
    },
    computed: {
        placeholder() {
            if (this.nativeFormat) {
                return this.$t('component_skuInputPicker_is_required')
            }
            return ""
        },
        error() {
            if (!this.targetLink && this.nativeFormat && this.value && this.value.length > 0) {
                return this.$t('component_skuInputPicker_product_name_is_required')
            }
            return '';
        },
        sku: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value)
            }
        }
    },
    watch: {
        error(value) {
            this.$emit('error', !!value);
        }
    },
    methods: {
        setSKU(value) {
            this.sku = value;
        },
        async fetchTargetLinks(query) {
            const items = await this.$store.dispatch('targeting/searchTargetProducts', {targetId: this.targetId, name: query});
            return ({results: items});
        },
        async getTargetLinkBySku(sku) {
            this.targetLink = await this.$store.dispatch('targeting/searchTargetProductsBySku', {targetId: this.targetId, sku: sku});
            return this.targetLink;
        },
    },

}
</script>

<style scoped>

</style>
