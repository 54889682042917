<template>
    <div class="w-full py-0.5">
        <div
            class="block rounded-lg transition duration-100 px-4 bg-white lg:py-2 py-5"
            :class="[
                itemStateIdentifier == 'online' ? ' border-zinc-300' : '',
                itemStateIdentifier == 'closed' ? 'bg-zinc-100 border-gray-100' : '',

            ]"
        >
            <div class="grid lg:grid-cols-12 grid-cols-2 font-medium items-center lg:gap-x-3 gap-x-2" :class="itemStateIdentifier == 'closed' ? 'opacity-40' : ''">

                <!-- Název -->
                <div class="col-span-3 md:pr-6 lg:order-1 order-3 my-3 lg:my-0">
                    <h2 class="h3">
                        {{ campaignItem.generatedName || campaignItem.product.name }}
                    </h2>
                    <h3 class="font-normal leading-3 text-xs mt-1" v-if="campaignItem.targetProduct">
                        {{ campaignItem.targetProduct.name }}
                    </h3>
                </div>

                <!-- OD -->
                <div class="lg:col-span-1 lg:order-2 order-1">
                    <div class="inline-flex lg:flex bg-gray-300 rounded-full px-3 py-2 lg:bg-transparent">
                        <div class="lg:w-1/4 lg:text-left">{{ startDateString }}</div>
                    </div>
                </div>

                <!-- DO -->
                <div class="lg:col-span-1 lg:order-3 order-1">
                    <div class="inline-flex lg:flex bg-gray-300 rounded-full px-3 py-2 lg:bg-transparent">
                        <div class="lg:w-1/4 lg:text-left">{{ endDateString }}</div>
                    </div>
                </div>

                <!-- objedáno -->
                <div class="col-span-2 flex items-center lg:order-5 order-4 lg:py-0 py-2">
                    <div>
                        <span class="font-normal text-sm"> {{ $n(campaignItem.unitPrice, 'currency') }} / {{ getRateTypeNameShort(campaignItem.product.rateType) }}</span><br>
                        <span class="font-normal text-sm">{{ $t('common_totalPrice') }}: {{ $n(campaignItem.price, 'currency') }}</span>
                    </div>
                </div>

                <!-- Stav kreativity -->
                <div class="col-span-2 flex items-center justify-start lg:order-6 order-6 lg:py-0 p-2 space-x-2 pl-0">
                    <campaign-item-state :campaignItemState="campaignItem.itemState"></campaign-item-state>
                    <circle-icon-button
                        v-if="campaignItem.product.nativeFormat === false && campaignItem.product.active"
                        :icon="creativeStatus.icon"
                        :title="creativeStatus.title"
                        :to="{name:'campaignItemCreative', params: { campaignId: campaign.id, campaignItemId: campaignItem.id}}"
                    />
                    <circle-icon-button
                        v-if="isRetailer && campaignItem.sasInfo && campaignItem.sasInfo.zipCreativeURL"
                        icon="zip"
                        :title="$t('common_download_zip')"
                        :href="campaignItem.sasInfo.zipCreativeURL"
                        target="_blank"
                    />
                </div>

                <!-- Tlačítka na úpravy -->
                <div class="flex text-blue-400 space-x-2 order-8 col-span-1 justify-end">
                    <circle-icon-button
                        v-if="['online', 'paused'].indexOf(stateIdentifier) >= 0 && isRetailerOrImpersonated"
                        :icon="stateIdentifier === 'online' ? 'pause' : 'arrow-full-right'"
                        :icon-color="stateIdentifier === 'online' ? 'red' : 'blue'"
                        @click="changeOnlinePause"
                        :title="stateIdentifier === 'online' ? $t('common_pause') : $t('common_run')"
                    />
                    <circle-icon-button
                        v-if="campaignItemEditable"
                        icon="edit-pencil"
                        :title="$t('common_edit')"
                        :to="{name: 'editCampaignActivity', params: {campaignId: campaign.id, campaignItemId: campaignItem.id}}"
                    />
                    <circle-icon-button
                        icon="eye"
                        :title="$t('common_show')"
                        :to="{name: 'campaignItemDetail', params: {campaignId: campaign.id, campaignItemId: campaignItem.id}}"
                    />
                    <circle-icon-button
                        v-if="campaignItemDeletable"
                        icon="x-fat"
                        icon-color="red"
                        :title="$t('common_delete')"
                        @click.stop="showDeleteDialog = true"
                    />
                </div>
            </div>
        </div>
        <div
            class="block rounded-lg transition duration-100 px-4 bg-white lg:py-2 py-5 xl:ml-80 lg:ml-48"
            :class="[
                itemStateIdentifier == 'online' ? ' border-zinc-300' : '',
                itemStateIdentifier == 'closed' ? 'bg-zinc-100 border-gray-100' : '',
            ]"
            v-if="displayCampaignItemPerformance"
        >
            <div class="font-medium" :class="itemStateIdentifier == 'closed' ? 'opacity-40' : ''">
                <div class="flex">
                    <h2 class="text-gray-500 font-medium p-1 text-left">{{ $t('common_currentPerformance') }}:</h2>
                    <activity-actual-performance :campaign-item="campaignItem"/>
                </div>
            </div>
        </div>

        <delete-campaign-item-dialog :campaignItem="campaignItem" v-model="showDeleteDialog"></delete-campaign-item-dialog>
        <campaign-item-detail-dialog v-model="showDetailDialog" :campaignItem="campaignItem"></campaign-item-detail-dialog>
    </div>
</template>
<script>

import CampaignItemState from '@/components/CampaignItemState';
import stringFilters from "@/mixins/string-filters";
import DeleteCampaignItemDialog from "@/components/DeleteCampaignItemDialog";
import CampaignItemDetailDialog from "@/components/CampaignItemDetailDialog";
import {mapGetters} from "vuex";
import {CreativeStates} from "@/utils/CreativeState";
import ActivityActualPerformance from "@/components/ActivityActualPerformance.vue";
import { getRateTypeNameShort } from "@/utils/RateType";
import CircleIconButton from "@/components/base/buttons/CircleIconButton.vue";

export default {
	name: "CampaignItem",
    components: {
        CircleIconButton,
        ActivityActualPerformance,
        DeleteCampaignItemDialog,
        CampaignItemDetailDialog,
        CampaignItemState,
    },
    mixins: [stringFilters],
    props: [
        "campaign",
        "campaignItem",
        "campaignEditable"
    ],
    data: () => {
        return {
            showDetailDialog: false,
            showMediaDetailDialog: false,
            showDeleteDialog: false,
            stateIdentifier: null
        };
    },
	computed: {
        ...mapGetters('auth', ['isImpersonate', 'isRetailer', 'isRetailerOrImpersonated']),
        ...mapGetters('appSettings', [
            'displayActualImpressions',
            'displayActualClicks',
            'displayActualViews',
            'displayVisibilityRatio',
            'displayOfPerformanceMetricsGroup',
            'displayOfPerformanceMetrics',
        ]),
        campaignItemEditable() {
            var editableAsRetailer = this.isRetailerOrImpersonated && ['approved'].indexOf(this.campaignItem.itemState.identifier) >= 0;
            return (this.campaignEditable && this.campaignItem.editable) || editableAsRetailer;
        },
        campaignItemDeletable() {
            return this.campaignEditable && (this.campaignItem.editable || this.campaignItem.product.nativeFormat);
        },
        displayCampaignItemPerformance(){
            return !!(
                (this.displayOfPerformanceMetrics) &&
                (this.campaignItem.actualClicks || this.campaignItem.actualImpressions || this.campaignItem.actualViews)
            );
        },
        itemStateIdentifier() {
            return this.campaignItem.itemState.identifier;
        },
        startDateString: function() {
            return this.campaignItem.startDateString;
        },
        endDateString: function() {
            return this.campaignItem.endDateString;
        },
        actualViews() {
            return this.campaignItem.actualViews;
        },
        actualClicks() {
            return this.campaignItem.actualClicks;
        },
        actualImpressions() {
            return this.campaignItem.actualImpressions;
        },
        visibilityRatio() {
            return this.campaignItem.visibilityRatio;
        },
        actualSoftConversions() {
            return this.campaignItem.actualSoftConversions;
        },
        showCreativeReviewButton() {
            return this.campaignItem.product.nativeFormat === false && this.isCreativeFilled;
        },
        isCreativeFilled() {
            if(!this.campaignItem) {
                return false;
            }
            return !!this.campaignItem.creative;
        },
        creativeStatus() {
            if (this.campaignItem.itemState.identifier === 'missing') {
                return {
                    'icon': 'plus',
                    'title': this.$t('common_add'),
                }
            } else if (this.campaignItem.creative.creativeState === CreativeStates.Approved) {

                return {
                    'icon': 'eye',
                    'title': this.$t('common_show'),
                }

            }
            return {
                'icon': 'edit-pencil',
                'title': this.$t('common_edit'),
            }
        }
    },
    methods: {
        getRateTypeNameShort,
        async changeOnlinePause() {
            try {
               let campaignItem =  await this.$store.dispatch("campaigns/changeOnlinePause", {campaignItemId: this.campaignItem.id});
               this.stateIdentifier = campaignItem.itemState.identifier;
            } catch (e) {
                //console.log(e);
            }
        },
    },
    created() {
        this.stateIdentifier = this.campaignItem.itemState.identifier;
    },
};
</script>
