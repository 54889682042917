<template>
    <t-modal v-model="showCard">
        <div class="md:p-20 p-8">
            <h1 class="h1 font-medium mb-5 pr-5">{{ productCategory ? productCategory.title : $t('component_ProductCategoryCard_newCategory') }}</h1>
            <loading-box theme="light" v-model="isWorking">
                <div class="flex items-center bg-gray-50 md:p-8 p-4 rounded-2xl flex-wrap space-y-4">
                    <div class="w-full">
                        <p class="mb-1 text-gray-600">{{ $t('component_ProductCategoryCard_nameFieldLabel')}}</p>
                        <t-input v-model="title"></t-input>
                    </div>
                    <div class="w-full">
                        <p class="mb-1 text-gray-600">{{ $t('component_ProductCategoryCard_shortDescriptionFieldLabel') }}</p>
                        <t-textarea v-model="shortDescription"></t-textarea>
                    </div>
                    <div class="w-full">
                        <p class="mb-1 text-gray-600">{{ $t('component_ProductCategoryCard_descriptionFieldLabel') }}</p>
                        <t-textarea v-model="description"></t-textarea>
                    </div>
                    <div class="w-full">
                        <p class="mb-1 text-gray-600">{{ $t('component_ProductCategoryCard_targetFieldLabel') }}</p>
                        <target-input-picker v-model="target"></target-input-picker>
                    </div>
                    <div class="w-full">
                        <p class="mb-1 text-gray-600">{{ $t('component_ProductCategoryCard_domainFieldLabel') }}</p>
                        <t-input v-model="domainUrl" type="url"/>
                        <div class="flex items-top relative group rounded-full px-3 py-1 bg-red-50 text-red-500 mt-1" v-if="!isValidDomainUrl">
                            <c-icon class="w-4 h-4 mr-2.5 mt-0.5 shrink-0" icon="triangle-exclamation-full"></c-icon>
                            URL must be valid
                        </div>
                    </div>

                    <div class="w-full flex space-x-4 items-center">
                        <div class="w-1/2 ">
                            <p class="mb-1 text-gray-600">{{ $t('component_ProductCategoryCard_minDaysBeforeFieldLabel') }}</p>
                            <t-input v-model="minDaysBeforeStartDate" type="number" min="0"/>
                        </div>
                        <div class="flex space-x-4 pt-5">
                            <div class="">
                                <label class="cursor-pointer inline-flex items-center w-full">
                                    <t-checkbox name="active" v-model="active"/>
                                    <checkbox-icon/>
                                    <span class="leading-tight">{{ $t('component_ProductCategoryCard_activeFieldLabel') }}</span>
                                </label>
                            </div>
                            <div class="">
                                <label class="cursor-pointer inline-flex items-center w-full">
                                    <t-checkbox name="internal" v-model="internal"/>
                                    <checkbox-icon/>
                                    <span class="leading-tight">{{ $t('view_productCategorySettings_internal') }}</span>
                                </label>
                            </div>
                            <div class="">
                                <label class="cursor-pointer inline-flex items-center w-full">
                                    <t-checkbox name="autoPauseFlight" v-model="autoPauseFlight"/>
                                    <checkbox-icon/>
                                    <span class="leading-tight">{{ $t('component_ProductCategoryCard_automaticPauseFieldLabel') }}</span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="w-full">
                        <p class="mb-1 text-gray-600">{{ $t('component_ProductCategoryCard_adsToolsFieldLabel') }}</p>
                    <t-rich-select
                        ref="select"
                        multiple
                        v-model="productIds"
                        :options="products"
                        text-attribute="name"
                        value-attribute="id"
                        :placeholder="$t('component_ProductCategoryCard_adsToolsSelection')"
                        :searchBoxPlaceholder="$t('component_ProductCategoryCard_adsToolsSelectionSearchPlaceholder')"
                        :noResultsText="$t('component_ProductCategoryCard_adsToolsSelectionNotFound')"
                        :searchingText="$t('common_searching')"
                    ></t-rich-select>
                    </div>
                </div>

                <p class="mb-3 text-red-400" v-if="errorMessage">{{ errorMessage }}</p>

                <div class="flex items-center justify-end mt-6">
                    <t-button
                        @click.prevent="showCard = false"
                        variant="link"
                        class="border-gray-400 text-gray-400 mr-4"
                    >
                        {{ $t('common_cancel') }}
                    </t-button>
                    <t-button
                        :disabled="isWorking || !isValid"
                        class="flex items-center relative group ml-4 my-4 pl-6"
                        :variant="isWorking || !isValid ? 'bigGray' : 'bigGreen'"
                        @click="saveProductCategory"
                    >
                       <div class="flex bg-green-200 rounded-full p-1.5 mr-3 text-black" :class="{'grayscale': isWorking || !isValid }">
                            <c-icon class="w-3.5 h-3.5" icon="check"></c-icon>
                        </div>
                        {{ $t('common_save') }}
                    </t-button>
                </div>
            </loading-box>
        </div>

        <template v-slot:button>
            <div class="w-8 h-8 bg-blue-100 rounded-full flex">
                <svg-icon name="x" class="w-3 h-3 m-auto"></svg-icon>
            </div>
        </template>
    </t-modal>
</template>
<script>

import SvgIcon from "@/components/SvgIcon";
import CIcon from "@/components/base/icons/CIcon.vue";
import LoadingBox from "@/components/LoadingBox";
import CheckboxIcon from "@/components/icons/CheckboxIcon";
import TargetInputPicker from "@/components/TargetInputPicker";

export default {
    name: "ProductCategoryCard",
    components: {
        LoadingBox,
        SvgIcon,
        CheckboxIcon,
        TargetInputPicker,
        CIcon
    },
    data() {
        return {
            productCategory: null,
            title: '',
            shortDescription: '',
            description: '',
            active: false,
            internal: false,
            autoPauseFlight: false,
            minDaysBeforeStartDate: 0,
            domainUrl: '',
            errorMessage: '',
            formErrors: {},
            isWorking: false,
            target: null,
            productIds: [],
            products: [],
            regex: /^(http|https):\/\/+[\www\d]+\.[\w]+(\/[\w\d]+)?/
        };
    },
    props: {
        productCategoryId: {
            default: null,
        },
    },
    computed: {
        isValid(){
            return !(!this.isValidDomainUrl || !this.title);
        },
        isValidDomainUrl() {
            return this.testDomainUrlRegex(this.domainUrl)
        },
        showCard: {
            get() {
                return !!this.productCategory || this.productCategoryId === 'new';
            },
            set(value) {
                if (this.showCard === value) {
                    return;
                }
                if (!value) {
                    this.$emit("close");
                }
            },
        }
    },
    watch: {
        async productCategoryId(newVal) {
            console.log(newVal);
            if (!newVal || newVal === 'new') {
                this.productCategory = null;
                this.active = false;
                this.internal = false;
                this.title = '';
                this.shortDescription = '';
                this.description = '';
                this.autoPauseFlight = false;
                this.minDaysBeforeStartDate = 0;
                this.domainUrl = '';
                this.target = null;
                this.productIds = [];
            } else {
                await this.getProductCategory();
                this.active = this.productCategory.active;
                this.internal = this.productCategory.internal;
                this.title = this.productCategory.title;
                this.shortDescription = this.productCategory.shortDescription;
                this.description = this.productCategory.description;
                this.autoPauseFlight = this.productCategory.autoPauseFlight;
                this.minDaysBeforeStartDate = this.productCategory.minDaysBeforeStartDate;
                this.domainUrl = this.productCategory.domainUrl;
                this.target = this.productCategory.target;
                this.productIds = this.productCategory.products.map((product) => product.id);

                this.testDomainUrlRegex(this.productCategory.domainUrl);
            }
        },
        async domainUrl(newVal) {
            this.testDomainUrlRegex(newVal);
        },
        async productIds(newVal) {
            console.log(newVal);
        }
    },
    methods: {
        async getProductCategory() {
            this.products = await this.$store.dispatch('products/getProducts');

            this.isWorking = true;
            if (this.productCategoryId) {
                this.productCategory = await this.$store.dispatch(
                    "products/getCategory",
                    {
                        productCategoryId: this.productCategoryId,
                    }
                );
            }
            this.isWorking = false;
        },
        async saveProductCategory() {
            this.isWorking = true;
            try {
                if (this.productCategoryId === 'new') {
                    await this.$store.dispatch("products/createCategory", {
                        active: this.active,
                        internal: this.internal,
                        title: this.title,
                        shortDescription: this.shortDescription,
                        description: this.description,
                        autoPauseFlight: this.autoPauseFlight,
                        minDaysBeforeStartDate: this.minDaysBeforeStartDate,
                        domainUrl: this.domainUrl,
                        target: this.target,
                        productIds: this.productIds
                    });
                } else {
                    await this.$store.dispatch("products/updateCategory", {
                        productCategoryId: this.productCategoryId,
                        active: this.active,
                        internal: this.internal,
                        title: this.title,
                        shortDescription: this.shortDescription,
                        description: this.description,
                        autoPauseFlight: this.autoPauseFlight,
                        minDaysBeforeStartDate: this.minDaysBeforeStartDate,
                        domainUrl: this.domainUrl,
                        target: this.target,
                        productIds: this.productIds
                    });
                }
                this.showCard = false;
            } catch (e) {
                this.errorMessage = e;
                if (e.response && e.response.data.message) {
                    this.errorMessage = e.response.data.message;
                }
                if (e.response.data.formErrors) { // there are some form errors
                    // DOTO: throw errors
                    this.errorMessage = Object.values(e.response.data.formErrors).join(' ');
                    this.formErrors = e.response.data.formErrors;
                }
            } finally {
                this.isWorking = false;
            }
        },
        testDomainUrlRegex: function (domainUrl) {
            if (domainUrl === '') {
                return true;
            }
            return this.regex.test(domainUrl);
        }
    },
    created() {
        this.getProductCategory();
    },
};
</script>
