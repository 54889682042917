import axios from "@/plugins/axios";

export default {
    namespaced: true,
    state: {
    },
    getters: {
    },
    mutations: {
    },
    actions: {
        async getLandingPageTypes() {
            const response = await axios.get('/landing-page-types');
            return response.data;
        },
        async getLandingPageType(context, {landingPageTypeId}) {
            const response = await axios.get(`/landing-page-types/${landingPageTypeId}`);
            return response.data;
        },
        async createLandingPageType(context, {name, active, creativeFormat, sizeExternalId, allowedTargets}) {
            const response = await axios.post(
                '/landing-page-types/create',
                {
                    name: name,
                    active: active,
                    creativeFormat: creativeFormat,
                    sizeExternalId: sizeExternalId,
                    allowedTargets: allowedTargets
                }
            );
            return response.data;
        },
        async updateLandingPageType(context,  {landingPageTypeId, name, active, creativeFormat, sizeExternalId, allowedTargets}) {
            const response = await axios.put(
                `/landing-page-types/${landingPageTypeId}`,
                {
                    name: name,
                    active: active,
                    creativeFormat: creativeFormat,
                    sizeExternalId: sizeExternalId,
                    allowedTargets: allowedTargets
                }
            );
            return response.data;
        },
        async getActiveLandingPageTypes() {
            const response = await axios.get('/landing-pages/active-types');
            return response.data;
        },
        async getLandingPages() {
            const response = await axios.get('/landing-pages');
            return response.data;
        },
        async createLandingPage(context, {landingPageTypeId, targetId, companyId, name}) {
            const response = await axios.post(
                '/landing-pages/create',
                {
                    landingPageTypeId: landingPageTypeId,
                    targetId: targetId,
                    companyId: companyId,
                    name: name,
                }
            );
            return response.data;
        },
        async getLandingPage(context, {landingPageId}) {
            const retailer = context.rootGetters['auth/isRetailer'];

            let lpPromise = axios.get(`/landing-pages/${landingPageId}`);
            let lp;
            if(retailer) {
                let sasInfoPromise = axios.get('/sas-info/landing-page/' + landingPageId);
                [lpPromise, sasInfoPromise] = await Promise.all([
                    lpPromise,
                    sasInfoPromise
                ]);
                lp = lpPromise.data;
                const sasInfo = sasInfoPromise.data;
                // connect sasInfo to campaign and campaign items
                lp.sasInfo = sasInfo;
            } else {
                [lpPromise] = await Promise.all([
                    lpPromise
                ]);
                lp = lpPromise.data;
            }
            return lp;
        },
        async updateLandingPage(context, landingPage) {
            const response = await axios.put(`/landing-pages/${landingPage.id}`, landingPage);
            return response.data;
        },
        async searchLandingPage(context, {targetId, companyId, name}) {
            const response = await axios.get(`/landing-pages/search`, {
                params: {
                    targetId: targetId,
                    companyId: companyId,
                    name: name,
                }
            });

            return response.data;
        },
        async requestLandingPageCheck(context, {landingPageId}) {
            const response = await axios.post(`/landing-pages/${landingPageId}/request-check`);
            return response.data;
        },
        async approveLandingPage(context, {landingPageId}) {
            const response = await axios.post(`/landing-pages/${landingPageId}/approve`);
            return response.data;
        },
        async rejectLandingPage(context, {landingPageId, note}) {
            const response = await axios.post(`/landing-pages/${landingPageId}/reject`, {note});
            return response.data;
        },
    }
};
