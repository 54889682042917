<template>
    <div v-if="creativeFormat" class="max-w-screen-2xl pt-1 pb-8 px-8 md:px-8 mx-auto">
        <simple-breadcrumb :items="breadcrumbs"></simple-breadcrumb>

        <h1 class="my-5 text-purple-100 h1"><span class="font-extralight">{{ $t('component_creativeFormatDetailView_title') }} - </span>{{ creativeFormat.name }}</h1>

        <div class="my-6 text-xl border-b-[1px] border-b-gray-200 pb-6">
            <p class="leading-6" >
                test {{ creativeFormat.name }}
            </p>
        </div>
        <div class="my-6 border-b-[1px] border-b-gray-200 pb-6" v-for="(field, fieldIndex) in creativeFormat.fields" :key="field.id">
            <creative-name-description-update
                    v-if="selectedItem === fieldIndex"
                    @displayNameUpdate="updateDisplayNameValue"
                    @displayDescriptionUpdate="updateDisplayDescriptionValue"
                    @displayValuesSubmit="isNameDescriptionEditable"
                    :placeholderName="formatName(field)"
                    :placeholderDescription="formatDescription(field)"
                    :fieldIndex="fieldIndex"
                />
            <div class="flex items-center" v-else>
                <div>
                    <h3 class="my-5 text-purple-100 h3">{{ formatName(field) }}</h3>
                    <p class="mb-1 text-gray-300">{{ formatDescription(field) }}</p>
                </div>
                <circle-icon-button
                class="ml-auto"
                icon="edit-pencil"
                @click="isNameDescriptionEditable(fieldIndex)"
                />
            </div>

            <c-select
                v-if="field.mediaType === 'text'"
                v-model="field.mediaTypeCruxo"
                :label="$t('view_creativeFormatDetailView_filed_mediaType')"
                :options="fieldTypes"
                label-attribute="label"
                value-attribute="value"
                @change="(newValue) => loadNewFieldType(newValue, fieldIndex, field.id)"
            />

            <div class="w-full" v-for="(restriction,index) in field.restrictions" :key="restriction.id">
                <CreativeFormatFieldRestrictions v-model="field.restrictions[index]"/>
            </div>

            <div class="w-full"
                 v-if="field.mediaType === 'file'">
                <c-select
                    :label="$t('component_creativeFormatDetailView_filed_convertFrom')"
                    v-model="field.convertFromId"
                    :options="getConvertFromFields(field.id)"
                    label-attribute="label"
                    value-attribute="value"
                />
            </div>

            <div class="w-full"
                 v-if="field.mediaType === 'file'">
                <c-select
                    :label="$t('component_creativeFormatDetailView_filed_convertToFormat')"
                    v-model="field.conversionFormat"
                    :options="convertToImageFormats"
                    label-attribute="label"
                    value-attribute="value"
                />
            </div>
        </div>

        <div class="flex justify-end">
            <c-button size="big" prepend-icon="check" @click="saveCreativeFormat" :loading="saving">
                {{ $t('common_save') }}
            </c-button>
        </div>
    </div>
</template>

<script>
import SimpleBreadcrumb from "@/components/SimpleBreadcrumb";
import CreativeFormatFieldRestrictions from "@/components/creative/CreativeFormatFieldRestrictions";
import Vue from "vue";
import CButton from "@/components/base/buttons/CButton.vue";
import CSelect from "@/components/base/form/CSelect.vue";
import CircleIconButton from "@/components/base/buttons/CircleIconButton.vue";
import CreativeNameDescriptionUpdate from "@/components/creative/CreativeNameDescriptionUpdate.vue";


export default {
    name: "CreativeFormatDetailView",
    metaTags() {
        return {
            title: this.$t('component_creativeFormatDetailView_title') + ' - ' + this.$t('common_meta_title'),
        }
    },
    components: {
        CSelect,
        CButton,
        CreativeFormatFieldRestrictions,
        SimpleBreadcrumb,
        CircleIconButton,
        CreativeNameDescriptionUpdate,
    },
    data: () => {
        return {
            creativeFormat: null,
            saving: false,
            editNameDescription: false,
            selectedItem: null,
        }
    },
    computed: {
        fieldTypes() {
            return [
                {value: 'text', label: this.$t('component_creativeFormatDetailView_filed_mediaType_text')},
                {value: 'number', label: this.$t('component_creativeFormatDetailView_filed_mediaType_number')},
                {value: 'sku', label: this.$t('component_creativeFormatDetailView_filed_mediaType_sku')},
                {value: 'product-shop-data', label: this.$t('component_creativeFormatDetailView_filed_mediaType_productShopData')},
            ]
        },
        breadcrumbs() {
            const that = this;

            return [
                {
                    title: that.$t('common_creative_format_settings'),
                    current: false,
                    to: {name: "creativeFormatList"}
                },
                {
                    title: this.creativeFormat.name,
                    current: 'page',
                },
            ];
        },
        convertToImageFormats() {
            return [
                {value: null, label: ''},
                {value: 'png', label: 'PNG'},
                {value: 'jpg', label: 'JPG'},
                {value: 'webp', label: 'WEBP'},
            ]
        }
    },
    methods: {
        async loadData() {
            const id = this.$route.params.creativeFormatId;
            this.creativeFormat = await this.$store.dispatch('creativeFormats/getCreativeFormat', {creativeFormatId: id});
        },
        async loadNewFieldType(value, fieldIndex, fieldId) {
            const restrictions = await this.$store.dispatch('creativeFormats/getCreativeFormatFieldRestrictions',
                {creativeFormatId: this.creativeFormat.id, creativeFormatFieldId: fieldId, type: value});
            Vue.set(this.creativeFormat.fields[fieldIndex], "restrictions", restrictions)
        },
        async saveCreativeFormat() {
            this.saving = true;
            try {
                await this.$store.dispatch("creativeFormats/updateCreativeFormat", {
                    creativeFormat: this.creativeFormat
                });
                this.saving = false;
                await this.$router.push({name: "creativeFormatList"})
            } catch (e) {
                this.errorMessage = e;
                this.saving = false;
                if (e.response && e.response.data.message) {
                    this.errorMessage = e.response.data.message;
                }
                if (e.response.data.formErrors) { // there are some form errors
                    // DOTO: throw errors
                    this.errorMessage = Object.values(e.response.data.formErrors).join(' ');
                    this.formErrors = e.response.data.formErrors;
                }
            }
        },
        getConvertFromFields(convertToFieldId) {
            const fields = this.creativeFormat.fields.filter(
                function(field) {
                    return field.id != convertToFieldId
                        && field.mediaType === 'file'
                }
            );
            let options = [{
                    value: null,
                    label: ''
                }];
            options = options.concat(
                fields.map(field => {
                    return {
                        value: field.id,
                        label: field.name
                    }
                })
            );
            return options;
        },
        isNameDescriptionEditable(fieldIndex) {
            this.selectedItem = fieldIndex;
        },
        updateDisplayNameValue(value) {
            Object.assign(this.creativeFormat.fields[0], { displayName: value });
        },
        updateDisplayDescriptionValue(value) {
            Object.assign(this.creativeFormat.fields[0], { displayDescription: value });
        },
        formatName(field) {
            return field.displayName ?? field.name;
        },
        formatDescription(field) {
            return field.displayDescription ?? field?.description;
        },
    },
    beforeRouteEnter(to, from, next) {
        next(async (vm) => {
            await vm.loadData();
        });
    },
    async beforeRouteUpdate() {
        await this.loadData();
    },
};
</script>
