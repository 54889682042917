<template>
    <t-card class="w-full">
        <base-dropzone @file="selected">
            <img
                alt="Dropzone"
                :src="imageSrc"
                v-if="imageSrc !== ''"
            />
        </base-dropzone>
        <div v-if="false">
            <div v-for="translation in translations" :key="translation.localeTag">
                <label>
                    Název ({{translation.localeTag}})
                    <t-input
                        label="Name"
                        v-model="translation.name"
                        required
                    />
                </label>
                <label>
                    Popis ({{translation.localeTag}})
                    <t-input
                        label="Description"
                        v-model="translation.description"
                        required
                    />
                </label>
            </div>
        </div>
        <div class="flex mt-4">
            <div class="flex-grow"></div>
            <t-button
                class="m-1 d-none d-sm-flex"
                @click="submit"
                :disabled="loading"
                variant="bigGreen"
            >
                Uložit
            </t-button>
        </div>
    </t-card>
</template>

<script>
import BaseDropzone from "@/components/base/BaseDrozone.vue";
import NoImage from "@/assets/images/noimage.jpg";

export default {
    name: "OneImageUpload",
    components: {BaseDropzone},
    props: {
        noimage: {
            default: NoImage,
            type: String,
        },
        defaultNames: {
            // default localized names for new image
            // format: {"cs-CZ": "Český název", "en-GB": ...}
            type: Object,
            default: () => {},
        }
    },
    data() {
        return {
            loading: false,
            file: null,
            imageSrc: "",
            translations: [],
            locales: []

        };
    },
    computed: {
    },
    methods: {
        selected(file) {
            const _this = this;
            // if (this.name === "") {
            //     this.name = file.name;
            // }
            this.file = file;
            const toBase64 = (file) =>
                new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => resolve(reader.result);
                    reader.onerror = (error) => reject(error);
                });

            toBase64(file).then((data) => {
                _this.imageSrc = data;
            });
        },
        async submit() {
            this.loading = true;
            const data = await this.$store.dispatch("images/uploadImage", {
                file: this.file,
            });
            await this.$store.dispatch("images/updateImageTranslations", {
                id: data.id,
                translations: this.translations,
            });
            this.translations = this.createTranslations(this.locales);
            this.file = null;
            this.imageSrc = this.noimage;
            this.$emit("image", data);
            this.loading = false;
        },
        createTranslations(localeTags) {
            return localeTags.map((locale) => {
                return {
                    localeTag: locale.value,
                    name: this.defaultNames[locale.value] || "",
                    description: "",
                };
            });
        },
        async getAvailableUILocales() {
            return await this.$store.dispatch("appSettings/getAvailableUILocales");
        },
    },
    async created() {
        this.locales = await this.getAvailableUILocales();
        this.imageSrc = this.noimage;
        this.translations = this.createTranslations(this.locales);
    },
}
</script>

<style scoped>

</style>
