
<template>
    <div class="bg-white rounded-2xl p-6" v-bind="$attrs">
        <div class="mb-3 font-semibold">
            {{ fieldValue.formatField.displayName ?? fieldValue.formatField.name }}
        </div>
        <div v-if="!onlyValues" class="text-gray-500 mb-2">
            {{ fieldValue.formatField.displayDescription ?? fieldValue.formatField.description }}
        </div>
        <div v-if="!onlyValues">{{ fieldValue.value }}</div>
        <div v-if="fieldValue.mediaType === 'text'">
            <div class="truncate" v-if="fieldValue.text">{{ fieldValue.text }}</div>
            <div v-else class="flex items-center justify-start">
                <c-icon class="w-6 h-6 text-red-450 mr-2" icon="x"></c-icon>
                <span>{{ $t('common_notFilled') }}</span>
            </div>
        </div>
        <div v-if="fieldValue.mediaType === 'file'">
                <div v-if="mediaLoading">
                    <loading-box class="h-32" v-model="mediaLoading" theme="light"></loading-box>
                </div>
                <div v-else-if="mediaInfo">
                    <div v-if="!onlyValues">
                        <div class="mb-3">{{ mediaInfo.name }}</div>

                        <table class="mb-3">
                            <tr><td class="pr-3 text-gray-500">{{ $t('common_size')}}</td><td>{{ imgFileSize }}</td></tr>
                            <tr><td class="pr-3 text-gray-500">{{ $t('common_mimeType')}}</td><td>{{ mediaInfo.contentType }}</td></tr>
                            <tr><td class="pr-3 text-gray-500">{{ $t('common_resolution')}}</td><td>{{ mediaInfo.width}}x{{ mediaInfo.height }}</td></tr>
                        </table>
                    </div>
                </div>
                <div v-else class="flex items-center justify-start">
                    <c-icon class="w-6 h-6 text-red-450 mr-2" icon="x"></c-icon>
                    <span>{{ $t('common_notFilled') }}</span>
                </div>
                <img v-if="imgSrc" :src="imgSrc"  :alt="mediaInfo.name">
        </div>
    </div>
</template>

<script>
import prettyBytes from 'pretty-bytes';
import LoadingBox from "@/components/LoadingBox.vue";
import CIcon from "@/components/base/icons/CIcon.vue";

export default {
    name: "CreativePreviewValue",
    components: {CIcon, LoadingBox},
    props: {
        fieldValue: {
            type: Object,
            required: true
        },
        onlyValues: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            mediaLoading: false,
            mediaInfo: null
        }
    },
    computed: {
        mediaExternalId() {
            return this.fieldValue.mediaExternalId;
        },
        imgSrc() {
            if(!this.mediaInfo) {
                return null;
            }
            return "data:" + this.mediaInfo.contentType + ";base64," + this.mediaInfo.content;
        },
        imgFileSize() {
            if(!this.mediaInfo) {
                return null;
            }
            return prettyBytes(this.mediaInfo.size);
        }
    },
    watch: {
        async mediaExternalId() {
            await this.getMediaInfo();
        }
    },
    methods: {
        async getMediaInfo() {
            if (this.fieldValue.mediaType !== 'file' || !this.fieldValue.mediaExternalId) {
                this.mediaInfo = null;
                return;
            }
            this.mediaLoading = true;
            this.mediaInfo =  await this.$store.dispatch('creatives/getMediaInfo', { mediaExternalId: this.fieldValue.mediaExternalId});
            this.mediaLoading = false;
        }
    },

    async created() {
        await this.getMediaInfo();
    }
}
</script>


<style scoped>

</style>
