<template>
    <div class="container">
        <div class="grid gap-4 grid-cols-3 grid-rows-3">
            <base-button
                v-for="image in images"
                :key="image.id"
                @click="select(image)"
            >
                <img
                    class="aspect-square object-cover rounded-2xl"
                    :src="imageSrc(image.id)"
                >
            </base-button>
        </div>
    </div>
</template>

<script>

import ImageService from "@/services/ImageService";
import BaseButton from "@/components/base/BaseButton.vue";

export default {
    name: "ImagesGrid",
    components: {BaseButton},
    props: {
        images: Array,
        cols: {
            type: Number,
            default: 2,
        },
        defaultImageSize: {
            type: Object,
            default: () => {
                return {
                    width: 300,
                    height: 300,
                };
            },
        }
    },
    methods: {
        select(image) {
            this.$emit("image", image);
        },
        imageSrc(id) {
            return ImageService.imageURL(id, this.defaultImageSize);
        },
    },
}
</script>

<style scoped>

</style>
