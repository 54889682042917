<template>
    <component
        v-bind="{
            ...$attrs,
            ...(href ? { href } : {}),
            ...(routerTo ? { to: routerTo } : {}),
            ...(routerLinkTag ? { tag: routerLinkTag } : {}),
        }"
        :class="{
            'cursor-pointer': !disabled,
        }"
        :is="tag"
        :disabled="disabled"
        @click="disabled || $emit('click', $event)"
    >
        <slot></slot>
    </component>
</template>

<script>
export default {
    name: "BaseButton",
    props: {
        tagName: {
            type: String,
            default: ''
        },
        to: {
            type: Object,
            default: null
        },
        href: {
            type: String,
            default: undefined
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        tag() {
            if(this.tagName) {
                return this.tagName;
            }
            if(this.to) {
                return 'router-link'
            }
            if(this.href) {
                return 'a'
            }
            return 'button';
        },
        routerTo() {
            if(this.tag === 'router-link') {
                return this.to;
            }
            return false;
        },
        routerLinkTag() {
            if(this.tag === 'router-link') {
                return 'a'
            }
            return false;
        }
    },
}
</script>

<style scoped>

</style>
