<template>
    <validation-provider
        ref="validationProvider"
        :rules="rules"
        :name="label"
        :detect-input="false"
        class="w-full"
        v-slot="{errors}"
    >
        <div class="relative">
            <c-input
                v-model="search"
                :label="label"
                @focus="focus"
                @blur="blur"
                :placeholder="$t('common_searching')"
            />
            <base-dropdown
                class="absolute table max-h-60 p-2 overflow-scroll z-50 w-full min-w-[340px] rounded-2xl bg-white border shadow-xl text-left top-16"
                v-model="opened"
                disable-click-outside
            >
                <base-button
                    v-for="option in filteredOptions"
                    :key="option.key"
                    @click.prevent.stop="toggle(option)"
                    class="text-left p-2 hover:bg-gray-300 w-full"
                    :class="{
                    'bg-green-400': option.selected
                }"
                >
                    {{ option.text }}
                </base-button>
            </base-dropdown>
            <info-message v-if="errors.length > 0" class="mt-1" type="error">
                {{ errors[0] }}
            </info-message>
        </div>

        <draggable
            class="py-2"
            v-model="values"
            @change="emitInput"
        >
            <div
                class="bg-green-400 px-2 py-2 my-2 rounded-full text-black flex justify-between cursor-move items-center"
                v-for="option in values"
                :key="option.key"
            >
                <div class="ml-2">{{ option.text }}</div>
                <base-button class="w-8 h-8 bg-blue-100 rounded-full flex hover:opacity-90"
                             @click.prevent.stop="remove(option)">
                    <c-icon class="w-3 h-3 m-2.5" icon="x"></c-icon>
                </base-button>
            </div>
        </draggable>
    </validation-provider>
</template>

<script>
import {ValidationProvider} from "vee-validate";
import draggable from "vuedraggable";
import BaseDropdown from "@/components/base/BaseDropdown.vue";
import CInput from "@/components/base/form/CInput.vue";
import BaseButton from "@/components/base/BaseButton.vue";
import CIcon from "@/components/base/icons/CIcon.vue";
import InfoMessage from "@/components/base/InfoMessage.vue";

export default {
    name: "SortableMultipleSelect",
    components: {
        InfoMessage,
        CIcon,
        BaseButton,
        CInput,
        BaseDropdown,
        draggable,
        ValidationProvider
    },
    props: {
        label: {
            type: String,
            default: ''
        },
        value: {
            type: Array
        },
        valueAttribute: {
            type: String
        },
        textAttribute: {
            type: String
        },
        options: {
            type: Array
        },
        fetchOptions: {
            type: Function,
            default: undefined,
        },
        fetchKeyOption: {
            type: Function,
            default: undefined,
        },
        rules: {
            type: String,
            default: ''
        },
        blurTimeout: {
            type: Number,
            default: 500
        }
    },
    data() {
        return {
            search: '',
            opened: false,
            foundOptions: [],
            values: [],
            blurTimeoutId: null,
        }
    },
    computed: {
        filteredOptions() {
            return this.normalizeOptions(this.foundOptions);
        },
        keyValues() {
            return this.values.map(option => option.key);
        }
    },
    watch: {
        async search(newVal) {
            const response = await this.fetchOptions(newVal);
            this.foundOptions = response.results;
        },
        async value(newVal) {
            if (JSON.stringify(newVal) === JSON.stringify(this.keyValues)) {
                return;
            }
            await this.pairData(newVal);
            await this.validate(this.keyValues);
        },
        async opened(newVal) {
            if (newVal) {
                return;
            }
            await this.validate(this.keyValues);
        }
    },
    methods: {
        toggle(option) {
            if (option.selected) {
                this.remove(option)
            } else {
                this.add(option)
            }
        },
        remove(option) {
            this.values.splice(this.value.indexOf(option.key), 1);
            this.emitInput();
        },
        add(option) {
            this.values.push(option);
            this.emitInput();
        },
        async emitInput() {
            await this.validate(this.keyValues);
            this.$emit('input', this.keyValues);
        },
        normalizeOptions(options) {
            return options.map(option => {
                return {
                    key: option[this.valueAttribute],
                    text: option[this.textAttribute],
                    selected: this.value.indexOf(option[this.valueAttribute]) >= 0
                }
            })
        },
        async pairData(data) {
            const _this = this;
            const items = [];
            for (const val of data) {
                let item = this.fetchKeyOption(val);
                items.push(item);
            }
            return Promise.all(
                items
            ).then(
                (values) => {
                    _this.values.splice(0, _this.values.length)
                    const normalized = _this.normalizeOptions(values);
                    normalized.forEach(value => _this.values.push(value));
                }
            )
        },
        async validate(value) {
            const _this = this;
            this.$refs.validationProvider.validate(value).then(
                (result) => {
                    _this.$refs.validationProvider.applyResult(result);
                }
            );
        },
        async focus() {
            if (this.blurTimeoutId) {
                clearTimeout(this.blurTimeoutId);
                this.blurTimeoutId = null;
            }
            this.foundOptions = (await this.fetchOptions(this.search)).results;
            this.opened = true;
        },
        async blur() {
            if (this.blurTimeoutId) {
                clearTimeout(this.blurTimeoutId);
            }
            this.blurTimeoutId = setTimeout(() => {
                this.opened = false
            }, this.blurTimeout)
            await this.validate(this.keyValues);
        }
    },
    async created() {
        await this.pairData(this.value);
    }
}
</script>

<style scoped>

</style>
