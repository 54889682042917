<template>
    <validation-provider
        tag="label"
        :rules="computedRules"
        :name="label"
        class="w-full mb-4 block"
        v-bind="$attrs"
        v-slot="{errors}"
    >
        <slot name="label" v-bind="{disabled, errors, label, readonly}">
            <CInputLabel :disabled="disabled" :errors="errors" :label="label" :readonly="readonly"/>
        </slot>
        <input
            class="
                    block w-full py-3.5 rounded-full px-5 bg-white border border-gray-500
                    transition duration-100 ease-in-out
                    disabled:opacity-40 disabled:cursor-not-allowed
                    readonly:opacity-40 readonly:cursor-not-allowed
                    required:bg-input-required required:bg-no-repeat
                    focus:outline focus:outline-2 focus:-outline-offset-1 focus:outline-blue-400
                "
            :class="{
                    'border-red-500': errors.length > 0,
                }"
            :type="type"
            :placeholder="placeholder"
            :value="value"
            :required="required"
            :readonly="readonly"
            :disabled="disabled"
            @input="$emit('input',  $event.target.value)"
            @focus="$emit('focus', $event)"
            @blur="$emit('blur', $event)"
            @change="$emit('change', $event)"
            @click="$emit('click', $event)"
            @keyup="$emit('keyup', $event)"
            @keydown="$emit('keydown', $event)"
            @keypress="$emit('keypress', $event)"
        >
        <info-message v-if="errors.length > 0" class="mt-1" type="error">
            {{ errors[0] }}
        </info-message>
    </validation-provider>
</template>


<script>
import {ValidationProvider} from "vee-validate";
import InfoMessage from "@/components/base/InfoMessage.vue";
import CInputLabel from "@/components/base/form/CInputLabel.vue";
import inputMixin from "@/components/base/form/inputMixin";

export default {
    name: "CInput",
    props: {
        type: {
            type: String,
            default: 'text'
        },
        placeholder: {
            type: String,
            default: ''
        },
        value: {
            type: String,
            default: ''
        },
    },
    components: {
        CInputLabel,
        InfoMessage,
        ValidationProvider
    },
    mixins: [inputMixin],
}
</script>

