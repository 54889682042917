<script>
import { ref, toRefs, computed } from 'vue';
import {onClickOutside} from "@vueuse/core";
import CIcon from "@/components/base/icons/CIcon.vue";
import BaseButton from "@/components/base/BaseButton.vue";

export default {
    components: {CIcon, BaseButton},
    props: {
        value: {
        },
        options: {
            type: Array,
            required: true,
        },
    },
    setup(props, { emit }) {
        const {value} = toRefs(props);
        const focus = ref(false);
        const target = ref(null)

        const currentValue = computed({
            get() {
                return value.value;
            },
            set(value) {
                emit('input', value);
            }
        });

        const setValue = (value) => {
            focus.value = false;
            currentValue.value = value;
        }

        onClickOutside(target, () => {
            focus.value = false;
        });

        return {
            focus,
            setValue,
            currentValue,
            target,
        }
    }
}
</script>


<template>
    <div>
        <div class="flex">
            <base-button
                class="rounded-2xl bg-white border border-gray-300 shadow-sm p-2 w-16 h-16 place-content-center"
                :class="{'rounded-tr-none' : currentValue}"
                @click="focus = !focus"
            >
                <c-icon
                    v-if="currentValue"
                    class="w-12 h-12"
                    :icon="currentValue"
                ></c-icon>
            </base-button>
            <base-button
                v-if="currentValue"
                class="w-8 h-8 rounded-r-2xl bg-white border border-l-0 border-gray-300 shadow-sm p-1 -ml-0.5 place-content-center"
                @click="currentValue = ''"
            >
                <c-icon class="w-4 h-4 text-red-500" icon="x"></c-icon>
            </base-button>
        </div>
        <div ref="target" class="absolute pt-2 z-50" :class="{'hidden':!focus}">

            <div class="z-10 bg-white rounded-2xl border border-gray-300 shadow-sm grid grid-cols-4 gap-2 p-2">
                <base-button
                    class="w-12 h-12 rounded-2xl bg-white border border-gray-300 shadow-sm p-2 hover:bg-gray-100 place-content-center"
                    v-for="option in options"
                    :key="option"
                    @click="setValue(option)"
                >
                    <c-icon
                        class="w-8 h-8"
                        :icon="option"
                    ></c-icon>
                </base-button>
            </div>

        </div>
    </div>

</template>


<style scoped>

</style>
