<template>
    <div>
        <main-header ref="menu"/>

        <router-view></router-view>
        <AddedProductDialog/>
        <portal-target name="main-overlay" multiple></portal-target>
        <main-footer/>
    </div>
</template>

<script>
import MainHeader from '../components/header/MainHeader.vue';
import AddedProductDialog from "@/components/AddedProductDialog";
import MainFooter from '../components/MainFooter.vue';


export default {
  components: {
      AddedProductDialog,
      MainHeader,
      MainFooter
    },
    name: "BaseLayout",
    computed: {
        username() {
            return this.$store.getters["auth/username"];
        },
    },
    methods: {
        async logout() {
            await this.$store.dispatch("auth/logout");
        },
    },
    beforeRouteEnter(to, from, next) {
        return next(vm => {
            vm.$refs.menu.hide();
        });
    },
    beforeRouteUpdate(to, from, next) {
        this.$refs.menu.hide();
        return next();
    },
    beforeRouteLeave(to, from, next) {
        this.$refs.menu.hide();
        return next();
    },
};
</script>

<style scoped></style>
