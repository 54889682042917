<template>
    <div v-if="user" class="max-w-screen-2xl pt-1 pb-8 px-4 md:px-8 mx-auto">
        <simple-breadcrumb :items="breadcrumbs"></simple-breadcrumb>

        <h1 class="my-5 text-purple-100 h1"><span class="font-extralight">{{ $t('component_userDetailView_title') }} - </span>{{ user.name }}</h1>
        <div class="my-6 text-xl border-b-[1px] border-b-gray-200 pb-6">
            <p v-if="user.companyName" class="mb-3">
                {{ user.companyName }}
            </p>
            <p class="leading-6">
                {{ user.email }}
            </p>
        </div>
        <div class="w-full mb-2">
            <label class="cursor-pointer inline-flex items-center w-full">
                <t-checkbox name="active" v-model="storeFrontActive"/>
                <checkbox-icon/>
                <span class="leading-tight">{{ $t('component_userDetailView_active') }}</span>
            </label>
        </div>
        <div class="flex flex-wrap -mx-1">
            <div class="md:w-1/2 lg:w-1/3 p-1">
                <user-sales-person-list :user="user" v-model="salesPersonIdes"></user-sales-person-list>
            </div>
            <div class="md:w-1/2 lg:w-1/3 p-1" v-if="isVendor">
                <user-agency-list :user="user" v-model="agencyIds"></user-agency-list>
            </div>
            <div class="md:w-1/2 lg:w-1/3 p-1" v-if="isAgency">
                <user-agency-companies-list :user="user" v-model="agencyVendorsIdes"></user-agency-companies-list>
            </div>
        </div>


        <div class="mt-4 lg:pr-6" v-if="!isAgency">
            <user-brands-list :user="user" v-model="allowedBrandsIdes"></user-brands-list>
        </div>
        <div class="mt-4 lg:pr-6">
            <span class="inline-block mb-2 font-medium">{{ $t('component_userDetailView_userCategories') }}</span>
            <checkbox-list
                :options="activeProductCategories"
                all="top"
                text-attribute="title"
                value-attribute="id"
                v-model="userAllowedCategoriesIdes"
                :all-label="$t('component_userDetailView_allUserCategories')"
            >
            </checkbox-list>
        </div>

        <div class="flex justify-end">

            <t-button
                @click="$router.push({name: 'userList'})"
                variant="link"
                class="border-gray-400 text-gray-400 mr-4"
            >
                {{ $t('common_back') }}
            </t-button>

            <t-button
                v-if="user"
                @click="saveUser"
                :disabled="saving"
                variant="bigGreen"
                class="flex items-center relative group ml-4 my-4 pl-6"
            >
                <loading-icon v-if="saving" class="inline-block"></loading-icon>
                <div class="flex bg-green-200 rounded-full p-1.5 mr-3 text-black">
                    <c-icon class="w-3.5 h-3.5" icon="check"></c-icon>
                </div>
                {{ $t('common_save') }}
            </t-button>
        </div>
    </div>
</template>

<script>
import CIcon from "@/components/base/icons/CIcon.vue";
import UserSalesPersonList from "@/components/UserSalesPersonList";
import UserAgencyList from "@/components/UserAgencyList";
import UserAgencyCompaniesList from "@/components/UserAgencyCompaniesList";
import UserBrandsList from "@/components/UserBrandsList";
import LoadingIcon from "@/components/LoadingIcon";
import CheckboxList from "@/components/CheckboxList";
import SimpleBreadcrumb from "@/components/SimpleBreadcrumb";
import CheckboxIcon from "@/components/icons/CheckboxIcon";


export default {
    name: "UserDetailView",
    metaTags() {
        return {
            title: this.$t('component_userDetailView_metaTitle') + ' - ' + this.$t('common_meta_title'),
        }
    },
    components: {
        CheckboxList,
        LoadingIcon,
        UserSalesPersonList,
        UserAgencyList,
        UserAgencyCompaniesList,
        UserBrandsList,
        SimpleBreadcrumb,
        CheckboxIcon,
        CIcon
    },
    data: () => {
        return {
            user: null,
            userEdit: null,
            saving: false,
            activeProductCategories: []
        }
    },
    computed: {
        breadcrumbs() {
            const that = this;

            return [
                {
                    title: that.$t('common_users_settings'),
                    current: false,
                    to: {name: "userList"}
                },
                {
                    title: this.user.name,
                    current: 'page',
                },
            ];
        },
        isAgency() {
            var roles = this.user.roles.map(function (role) {
                return role.name;
            });
            return roles.indexOf('Agency') >= 0;
        },
        isVendor() {
            var roles = this.user.roles.map(function (role) {
                return role.name;
            });
            return roles.indexOf('Vendor') >= 0;
        },
        selectedAllCategories: {
            get() {
                return this.userAllowedCategoriesIdes.length === 0;
            },
            set(newValue) {
                if (newValue) {
                    this.userAllowedCategoriesIdes = [];
                }
            }
        },
        storeFrontActive: {
            get() {
                if (this.userEdit) {
                    return this.userEdit.storefrontActive;
                }
                return false;
            },
            set(newValue) {
                this.userEdit.storefrontActive = newValue;
            }
        },
        userAllowedCategoriesIdes: {
            get() {
                if (this.userEdit) {
                    return this.userEdit.allowedProductCategories
                }
                return [];
            },
            async set(newValue) {
                this.selectedAllCategories = false;
                this.userEdit.allowedProductCategories = newValue;
            }
        },
        salesPersonIdes: {
            get() {
                if (this.userEdit) {
                    return this.userEdit.salesPersonIdes;
                }
                return [];
            },
            async set(newValue) {
                this.userEdit.salesPersonIdes = newValue;
            }
        },
        agencyIds: {
            get() {
                if (this.userEdit) {
                    return this.userEdit.agencyUsers
                }
                return [];
            },
            async set(newValue) {
                this.userEdit.agencyUsers = newValue;
            }
        },
        allowedBrandsIdes: {
            get() {
                if (this.userEdit) {
                    return this.userEdit.allowedBrands
                }
                return [];
            },
            async set(newValues) {
                this.userEdit.allowedBrands = newValues;
            }
        },
        agencyVendorsIdes: {
            get() {
                if (this.userEdit) {
                    return this.userEdit.agencyVendorCompanies
                }
                return [];
            },
            async set(newValues) {
                return this.userEdit.agencyVendorCompanies = newValues;
            }
        },
    },
    methods: {
        async saveUser() {
            this.saving = true;
            try {
                await this.$store.dispatch("users/updateUser", {
                    user: this.user,
                    userEdit: this.userEdit
                });
                this.saving = false;
                await this.$router.push({name: "userList"})
            } catch (e) {
                this.errorMessage = e;
                this.saving = false;
                if (e.response && e.response.data.message) {
                    this.errorMessage = e.response.data.message;
                }
                if (e.response.data.formErrors) { // there are some form errors
                    // DOTO: throw errors
                    this.errorMessage = Object.values(e.response.data.formErrors).join(' ');
                    this.formErrors = e.response.data.formErrors;
                }
            }

        },
        async loadData() {
            await this.loadActiveProductCategories();
            const id = this.$route.params.userId;
            this.user = await this.$store.dispatch('users/getUser', {userId: id});
            this.userEdit = await this.$store.dispatch('users/getUserEdit', {userId: id});

        },
        async loginAsVendor(id) {
            this.selectedVendorId = id || null;
            if (id) {
                await this.$store.dispatch('users/loginAsUser', {id});
                await this.$router.push({name: "campaignList"});
            } else {
                await this.$router.push({name: 'userList'})
            }
        },
        async loadActiveProductCategories() {
            this.activeProductCategories = await this.$store.dispatch('products/getActiveCategories')
        }
    },
    beforeRouteEnter(to, from, next) {
        next(async (vm) => {
            await vm.loadData();
        });
    },
    async beforeRouteUpdate() {
        await this.loadData();
    },
};
</script>
