<template>
    <div class="flex w-[100%] items-center">
        <label for="items-per-page" data-cy="items-per-page-label">{{
            $t("common_items_per_page")
        }}</label>
        <div class="w-20">
            <t-select
                id="items-per-page"
                :options="sizes"
                text-attribute="label"
                value-attribute="value"
                v-model="pageSize"
                data-cy="items-per-page-select"
                variant="selectButtonItemsPerPage"
            >
            </t-select>
        </div>
    </div>
</template>

<script>
export default {
    name: "PageItemsFilter",
    props: {
        sizes: {
            type: Array,
            default: () => [10, 20, 50],
        },
        value: {
            type: Number,
            default: 10,
        },
    },
    computed: {
        pageSize: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        },
    },
};
</script>
