import { render, staticRenderFns } from "./OneImageSelection.vue?vue&type=template&id=16ffc69e&scoped=true&"
import script from "./OneImageSelection.vue?vue&type=script&lang=js&"
export * from "./OneImageSelection.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16ffc69e",
  null
  
)

export default component.exports