<template>
    <th v-bind="$attrs"
        class="text-gray-500 font-medium"
        :class="classesHeader"
        :style="styleHeader"
        @click="sort"
    >
        <div :class="{ 'flex': true, 'justify-end': alignRight }">
        <slot>

        </slot>
        <c-icon v-if="stateIco" :icon="stateIco" class="ml-[2px] w-6 h-6 text-blue-100 group-hover:text-blue-400"></c-icon>
        </div>
    </th>
</template>

<script>
// DOTO: add support for sorting
import CIcon from "@/components/base/icons/CIcon.vue";

export default {
    name: "DynamicTableColumn",
    components: {CIcon},
    props: {
        identifier: {
            // identifier of the column
            type: String,
            required: true,
        },
        sorting: {
            // current sorting value
            type: Object,
            default: undefined,
        },
        classesHeader: {
            type: String,
            default: "p-4 text-left",
        },
        alignRight: {
            type: Boolean,
            default: false,
        },
        styleHeader: {
            type: String,
            default: "",
        },
        classesBody: {
            type: String,
            default: "p-2 text-left",
        },
    },
    computed: {
        stateIco() {
            if (this.sorting === undefined) {
                return null;
            }
            if (!this.sorting || this.sorting.name !== this.identifier) {
                return 'arrow-up-down';
            }
            if (this.sorting.direction === 'ASC') {
                return 'sorted-down';
            }
            if (this.sorting.direction === 'DESC') {
                return 'sorted-up';
            }
            return null;
        },
        newValue() {
            if (!this.sorting) {
                return {
                    name: this.identifier,
                    direction: 'ASC'
                };
            }
            if (this.sorting.name !== this.identifier) {
                return {
                    name: this.identifier,
                    direction: 'ASC'
                };
            }
            if (this.sorting.direction === 'ASC') {
                return {
                    name: this.identifier,
                    direction: 'DESC'
                };
            }
            return null;
        }
    },
    methods: {
        sort() {
            this.$emit('sort', this.newValue);
        }
    }
}
</script>

<style scoped>

</style>
