<template>
    <div class="">
        <label class="cursor-pointer flex items-center text-sm space-x-4 leading-none">
            <span class="text-right">{{ labelBefore }}</span>
            <toggle-switch v-model="valueChange"/>
            <span>{{ labelAfter }}</span>
        </label>
    </div>
</template>

<script>

import ToggleSwitch from "@/components/ToggleSwitch.vue";

export default {
    name: "ToggleSwitchLabeled",
    components: {
        ToggleSwitch
    },
    props: {
        value: {
            type: Boolean
        },
        labelBefore: {
            type: String
        },
        labelAfter: {
            type: String
        },
    },
    data() {
        return {
            error: ''
        }
    },
    computed: {
        valueChange: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            },
        },

    },
    watch: {},
    methods: {},
    async created() {

    }
}
</script>

<style scoped>

</style>
