<template>
    <div>
        <div class="w-full flex mb-3 space-x-4 items-center justify-between">
            <div class="relative flex-grow max-w-xl">
                <t-input v-model="search" :placeholder="$t('common_search')"></t-input>
                <svg-icon name="search" class="absolute right-6 top-3 pointer-events-none"></svg-icon>
            </div>
            <div class="flex justify-end my-4">
                <slot name="buttons"></slot>
            </div>
        </div>
        <dynamic-table :items="sortedCreatives" clickable @row-click="rowClick" :loading="loading">
            <dynamic-table-column identifier="sasCreated" :sorting="sorting" @sort="setSort">
                {{ $t('common_date') }}
            </dynamic-table-column>
            <dynamic-table-column identifier="name" :sorting="sorting" @sort="setSort">
                {{ $t('common_name') }}
            </dynamic-table-column>
            <dynamic-table-column identifier="creativeState" :sorting="sorting" @sort="setSort">
                {{ $t('common_state') }}
            </dynamic-table-column>
            <dynamic-table-column identifier="actions">
                {{ $t('common_actions') }}
            </dynamic-table-column>
            <template v-slot:sasCreated="item">
                {{ formatDate(item.sasCreated) }}
            </template>
            <template v-slot:creativeState="item">
                <creative-state :state="item.creativeState"></creative-state>
            </template>
            <template v-slot:actions="item">
                <t-button variant="editCircle" :title="$t('common_show')" @click="creative = item">
                    <c-icon class="w-3.5 h-3.5" icon="eye"></c-icon>
                </t-button>
            </template>
        </dynamic-table>

        <simple-dialog v-model="showPreview">
            <div class="h2 mb-4" v-if="creative">
                {{ creative.name }}
            </div>
            <div v-if="creative" class="grid grid-cols-2 gap-4 bg-gray-100 rounded-2xl p-4">
                <creative-preview-value
                    v-for="fieldValue in getFieldsToHandle(creative)"
                    :key="fieldValue.id"
                    :field-value="fieldValue"
                    only-values
                ></creative-preview-value>
            </div>
            <div class="w-full flex items-center justify-end mt-4 space-x-6">
                <t-button class="text-gray-400" variant="link" @click="showPreview = false">{{
                        $t('common_cancel')
                    }}
                </t-button>
                <c-button size="big" @click="selectCreative">{{ $t('common_select') }}</c-button>
            </div>
        </simple-dialog>
    </div>
</template>

<script>

import DynamicTable from "@/components/universal/dynamic-table/DynamicTable.vue";
import DynamicTableColumn from "@/components/universal/dynamic-table/DynamicTableColumn.vue";
import SvgIcon from "@/components/SvgIcon.vue";
import {format, parseISO} from "date-fns";
import SimpleDialog from "@/components/base/SimpleDialog.vue";
import CreativePreviewValue from "@/components/CreativePreviewValue.vue";
import CreativeState from "@/components/CreativeState.vue";
import CIcon from "@/components/base/icons/CIcon.vue";
import CButton from "@/components/base/buttons/CButton.vue";

export default {
    name: "CreativeSelection",
    components: {CIcon, CreativeState, CreativePreviewValue, SimpleDialog, SvgIcon, DynamicTableColumn, DynamicTable, CButton},
    props: {
        campaignItem: {
            type: Object,
            required: true
        },
        company: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            creative: null,
            search: '',
            creatives: [],
            loading: false,
            sorting: {
                name: "name",
                direction: 'ASC',
            }
        }
    },
    computed: {
        showPreview: {
            get() {
                return this.creative !== null;
            },
            set(val) {
                if (!val) {
                    this.creative = null;
                }
            }
        },
        sortedCreatives() {
            if(!this.sorting) {
                return this.creatives;
            }
            const creatives = [...this.creatives]
            return creatives.sort((a, b) => {
                if (this.sorting.direction === 'ASC') {
                    return a[this.sorting.name] > b[this.sorting.name] ? 1 : -1;
                } else {
                    return a[this.sorting.name] < b[this.sorting.name] ? 1 : -1;
                }
            });
        },
    },
    watch: {
        search: function (val) {
            this.fetchCreatives(val);
        }
    },
    methods: {
        format,
        parseISO,
        async fetchCreatives(query) {
            this.loading = true;
            this.creatives = await this.$store.dispatch(
                'creatives/searchCreatives',
                {
                    search: query,
                    productId: this.campaignItem.product.id,
                    companyId: this.company.id
                }
            );
            this.loading = false;
        },
        rowClick(creative) {
            this.creative = creative;
        },
        formatDate(date) {
            if (!date) {
                return '';
            }
            return this.$d(parseISO(date));
        },
        selectCreative() {
            this.$emit('select', this.creative);
            this.showPreview = false;
        },
        setSort(sorting) {
            this.sorting = sorting;
        },
        getFieldsToHandle(creative) {
            return creative.fieldValues.filter(fv => !fv.autoConverted);
        }
    },
    async created() {
        await this.fetchCreatives('');
    }
}
</script>

<style scoped>

</style>
