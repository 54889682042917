<template>
    <simple-dialog
        v-if="displayable"
        v-model="display"
        @close="onClose"
    >
        <h1 class="h1 mb-8">
            {{ $t('component_addedProductDialog_header', {name: campaign.name}) }}
        </h1>
        <p class="">
            {{ $t('component_addedProductDialog_description') }}
        </p>

        <div
            class="grid grid-cols-1 md:grid-cols-2 gap-4 overflow-hidden p-4 md:p-6 bg-gray-100 rounded-2xl mt-6"
        >

            <div class="md:col-span-1 md:text-left text-center">
                <h2 class="h3 mb-1 text-lg">{{ product.name }}</h2>
                <p>{{ product.rateType }} {{ $n(product.price, "currency") }}</p>
            </div>

            <div class="md:col-span-1 flex justify-end py-2" v-if="!isNativeProduct">
                <c-button
                    size="medium"
                    color="blue"
                    append-icon="upload"
                    append-icon-class="w-6 h-6"
                    @click="loadFiles"
                >
                    {{ $t('common_add_creative') }}

                </c-button>
            </div>
        </div>
        <div class="flex justify-end mt-8 flex-wrap">
            <c-button @click="toProducts" size="big" color="blue" class="mr-4">
                {{ $t('component_addedProductDialog_buttonBackToProducts') }}
            </c-button>
            <c-button @click="toCampaign" size="big" color="green">
                {{ $t('component_addedProductDialog_buttonToCampaignDetail') }}
            </c-button>
        </div>
    </simple-dialog>
</template>

<script>
import CButton from "@/components/base/buttons/CButton.vue";
import {mapGetters} from "vuex";
import SimpleDialog from "@/components/base/SimpleDialog.vue";

export default {
    name: "AddedProductDialog",
    components: {SimpleDialog, CButton},
    data() {
        return {
            closing: false,
        }
    },
    computed: {
        ...mapGetters("addedProduct", [
            "show",
            "product",
            "campaign",
            "campaignItem"
        ]),
        displayable() {
            return !!this.campaign && !!this.product
        },
        display: {
            get() {
                return this.show;
            },
            set(value) {
                if (!value) {
                    this.$store.dispatch('addedProduct/hide');
                }
            }
        },
        isNativeProduct() {
            if (!this.product) {
                return false;
            }
            return this.product.nativeFormat;
        },
    },
    methods: {
        loadFiles() {
            this.closing = true;
            this.display = false;
            this.$nextTick(() => {
                this.closing = false;
            })
            this.$router.push({
                name: 'campaignItemCreative',
                params: {
                    campaignId: this.campaign.id,
                    campaignItemId: this.campaignItem.id
                }
            })
        },
        toCampaign() {
            this.closing = true;
            this.display = false;
            this.$nextTick(() => {
                this.closing = false;
            })
            this.$router.push({
                name: "campaignDetail",
                params: {
                    campaignId: this.campaign.id
                }
            })
        },
        toProducts() {
            this.closing = true;
            this.display = false;
            this.$nextTick(() => {
                this.closing = false;
            })
            this.$router.push({
                name: "productList",
            })
        },
        onClose() {
            if (this.closing) {
                return;
            }
            this.$router.push({
                name: "campaignDetail",
                params: {
                    campaignId: this.campaign.id
                }
            })
        }
    }
}
</script>

<style scoped>

</style>
