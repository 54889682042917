import axios from "@/plugins/axios";

export default {
    namespaced: true,
    state: {
        imageListPageSize: 18,
        total: 0,
        images: [],
        page: 1,
        pages: 1,
        filterParams: {
            phrase: "",
        },
    },
    getters: {
        images: (state) => state.images,
        page: (state) => state.page,
        pages: (state) => state.pages,
        total: (state) => state.total,
        filterParams: (state) => state.filterParams
    },
    mutations: {
        SET_IMAGE_PAGE: (state, data) => {
            state.total = data.totalCount;
            state.pages = data.pageCount;
            state.page = data.currentPage;
            state.images = data.images;
        },
        SET_IMAGE_PAGE_FILTER_PARAMS: (state, params) => {
            state.filterParams = params;
        },
    },
    actions: {
        uploadImage: async function (context, payload) {
            const formData = new FormData();
            formData.append("file", payload.file);
            formData.append("translations", payload.translations);
            const response = await axios.post(
                `/images/upload`,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            );
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.data.error);
            }
            context.dispatch("listImages");
            return response.data;
        },
        updateImage: async function (context, payload) {
            const formData = new FormData();
            formData.append("file", payload.file);
            formData.append("translations", payload.translations);
            const response = await axios.post(
                `/images/update`,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            );
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.data.error);
            }
            return response.data;
        },
        async updateImageTranslations(context, image) {
            return await axios
                .post(`/images/update`, image)
                .then((response) => {
                    return response.data;
                })
                .catch((error) => {
                    context.dispatch(
                        "alertOverlay/handleAxiosJsonResponse",
                        error,
                        {root: true}
                    );
                });
        },
        listImages: async (context, payload = {}) => {
            const response = await axios.post("/images/list", {
                page: payload.page || context.page,
                filterParams: payload.filterParams || context.state.filterParams,
                pageSize: context.state.imageListPageSize
            });
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.data.error);
            }
            context.commit("SET_IMAGE_PAGE", response.data);
            if (payload.filterParams) {
                context.commit("SET_IMAGE_PAGE_FILTER_PARAMS", payload.filterParams);
            }
            return response.data;
        },
        removeImage: async (context, {id}) => {
            const response = await axios.delete(`/images/${id}`);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.data.error);
            }
            context.dispatch("listImages");
            return response.data;
        },
        getImgSizeText(context, payload) {
            let sizes = payload.sizes;
            if (sizes == null) {
                return "";
            }
            let width = Math.floor(sizes.width);
            let height = Math.floor(sizes.height);

            if (width && height) {
                return (
                    "Minimální doporučená velikost obrázku: " +
                    width +
                    " x " +
                    height +
                    " px"
                );
            } else if (width && !height) {
                return "Minimální doporučená šířka obrázku: " + width + " px";
            } else if (!width && height) {
                return "Minimální doporučená výška obrázku: " + height + " px";
            }

            return "";
        },
    },
};
