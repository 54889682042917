
<template>
    <simple-dialog v-model="show">
        <creative-selection :company="company" :campaign-item="campaignItem"></creative-selection>
    </simple-dialog>
</template>

<script>
import SimpleDialog from "@/components/base/SimpleDialog.vue";
import CreativeSelection from "@/components/CreativeSelection.vue";

export default {
    name: "CreativePopup",
    components: {CreativeSelection, SimpleDialog},
    props: {
        value: {
            type: Boolean,
            required: true
        },
        campaignItem: {
            type: Object,
            required: true
        },
        company: {
            type: Object,
            required: true
        }
    },
    computed:  {
        show: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        }
    }
}
</script>


<style scoped>

</style>
