<template>
    <div class="h-[50vh] flex items-center">
        <h2 class="text-3xl mx-auto">{{ $t(this.$props.title) }}</h2>
    </div>
</template>
<script>
export default {
    name: 'NotFoundSlate',
    props: {
        title: {
            type: String,
            default: 'component_campaign_notFound_description',
        },
    },
};
</script>
