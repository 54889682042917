export default {
    namespaced: true,
    state: {
        product: null,
        campaign: null,
        campaignItem: null,
        showtime: 30,
        show: false,
    },
    getters: {
        product: state => state.product,
        campaign: state => state.campaign,
        campaignItem: state => state.campaignItem,
        show: state => state.show,
    },
    mutations: {
        SET_DATA: (state, { product, campaign, campaignItem }) => {
            state.product = product;
            state.campaign = campaign;
            state.campaignItem = campaignItem;
        },
        SHOW: (state) => state.show = true,
        HIDE: (state) => state.show = false
    },
    actions: {
        showToast(context, { product, campaign, campaignItem }) {
            context.commit("SET_DATA", { product, campaign, campaignItem});
            context.commit("SHOW");
            //
            // setTimeout(() => {
            //     context.dispatch("hide");
            // }, context.state.showtime * 1000)
        },
        hide(context) {
            context.commit("HIDE");
        }
    }
};
