<template>
    <t-modal v-model="showCard">
        <div class="md:p-20 p-8">
            <h1 class="h1 font-medium mb-5 pr-5">{{ landingPageType ? landingPageType.name : $t('component_LandingPageTypeCard_newLandingPage') }}</h1>
            <loading-box theme="light" v-model="isWorking">
                <form @submit.prevent.stop="saveLandingPageType">
                <div class="flex items-center bg-gray-50 md:p-8 p-4 rounded-2xl flex-wrap space-y-4">
                    <div class="w-full">
                        <p class="mb-1 text-gray-600">{{ $t('common_title')}}</p>
                        <t-input v-model="name" required></t-input>
                    </div>
                    <div class="w-full">
                        <label class="cursor-pointer inline-flex items-center w-full">
                            <t-checkbox name="active" v-model="active"/>
                            <checkbox-icon/>
                            <span class="leading-tight">{{ $t('component_LandingPageTypeCard_activeFieldLabel') }}</span>
                        </label>
                    </div>
                    <div class="w-full">
                        <p class="mb-1 text-gray-600">{{ $t('common_creativeFormat') }}</p>
                        <t-rich-select
                            ref="select"
                            v-model="creativeFormatId"
                            :options="creativeFormats"
                            text-attribute="name"
                            value-attribute="id"
                            :searchBoxPlaceholder="$t('common_search')"
                            :noResultsText="$t('common_notFound')"
                            :searchingText="$t('common_searching')"
                            required
                        ></t-rich-select>
                    </div>
                    <div class="w-full">
                        <p class="mb-1 text-gray-600">{{ $t('common_sasSizeId')}}</p>
                        <t-input v-model="sizeExternalId" type="number" required></t-input>
                    </div>

                    <div class="w-full">
                        <c-select
                            v-model="allowedTargetsIdes"
                            :label="$t('component_LandingPageTypeCard_allowedTargets')"
                            :options="targetOptions"
                            valueAttribute="value"
                            labelAttribute="label"
                            multiple
                        />
                    </div>
                </div>

                <p class="mb-3 text-red-400" v-if="errorMessage">{{ errorMessage }}</p>

                <div class="flex items-center justify-end mt-6">
                    <t-button
                        @click.prevent.stop="showCard = false"
                        variant="link"
                        class="border-gray-400 text-gray-400 mr-4"
                    >
                        {{ $t('common_cancel') }}
                    </t-button>
                    <t-button
                        type="submit"
                        :disabled="isWorking || !isValid"
                        class="flex items-center relative group ml-4 my-4 pl-6"
                        :variant="isWorking || !isValid ? 'bigGray' : 'bigGreen'"
                    >
                       <div class="flex bg-green-200 rounded-full p-1.5 mr-3 text-black" :class="{'grayscale': isWorking || !isValid }">
                            <c-icon class="w-3.5 h-3.5" icon="check"></c-icon>
                        </div>
                        {{ $t('common_save') }}
                    </t-button>
                </div>
                </form>
            </loading-box>
        </div>

        <template v-slot:button>
            <div class="w-8 h-8 bg-blue-100 rounded-full flex">
                <svg-icon name="x" class="w-3 h-3 m-auto"></svg-icon>
            </div>
        </template>
    </t-modal>
</template>
<script>

import SvgIcon from "@/components/SvgIcon";
import CIcon from "@/components/base/icons/CIcon.vue";
import LoadingBox from "@/components/LoadingBox";
import CheckboxIcon from "@/components/icons/CheckboxIcon";
import CSelect from "@/components/base/form/CSelect.vue";

export default {
    name: "LandingPageTypeCard",
    components: {
        CSelect,
        LoadingBox,
        SvgIcon,
        CheckboxIcon,
        CIcon
    },
    data() {
        return {
            landingPageType: null,
            name: '',
            active: false,
            creativeFormatId: null,
            sizeExternalId: 0,
            allowedTargetsIdes: [],

            creativeFormats: [],
            targets: [],
            errorMessage: '',
            formErrors: {},
            isWorking: false,
        };
    },
    props: {
        landingPageTypeId: {
            default: null,
        },
    },
    computed: {
        isValid(){
            return !!this.name;
        },
        showCard: {
            get() {
                return !!this.landingPageType || this.landingPageTypeId === 'new';
            },
            set(value) {
                if (!value) {
                    this.$emit("close");
                }
            },
        },
        targetOptions() {
            return this.targets.map(target => {
                return {
                    value: target.id,
                    label: target.name,
                };
            });
        },
    },
    watch: {
        async landingPageTypeId(newVal) {
            if (!newVal || newVal === 'new') {
                this.landingPageType = null;
                this.active = false;
                this.name = '';
                this.sizeExternalId = 0;
                this.creativeFormatId = null;
                this.allowedTargetsIdes = [];
            } else {
                await this.getLandingPageType();
                this.active = this.landingPageType.active;
                this.name = this.landingPageType.name;
                this.sizeExternalId = this.landingPageType.sizeExternalId;
                this.creativeFormatId = this.landingPageType.creativeFormat;
                this.allowedTargetsIdes = this.landingPageType.allowedTargets;
            }
        },
    },
    methods: {
        async getLandingPageType() {
            [this.creativeFormats, this.targets] = await Promise.all([
                this.$store.dispatch('creatives/getCreativeFormats'),
                this.$store.dispatch('targeting/getTargets')
            ]);

            this.isWorking = true;
            if (this.landingPageTypeId) {
                this.landingPageType = await this.$store.dispatch(
                    "landingPages/getLandingPageType",
                    {
                        landingPageTypeId: this.landingPageTypeId,
                    }
                );
            }
            this.isWorking = false;
        },
        async saveLandingPageType() {
            this.isWorking = true;
            try {
                if (this.landingPageTypeId === 'new') {
                    await this.$store.dispatch("landingPages/createLandingPageType", {
                        name: this.name,
                        active: this.active,
                        sizeExternalId: this.sizeExternalId,
                        creativeFormat: this.creativeFormatId,
                        allowedTargets: this.allowedTargetsIdes,
                    });
                } else {
                    await this.$store.dispatch("landingPages/updateLandingPageType", {
                        landingPageTypeId: this.landingPageTypeId,
                        name: this.name,
                        active: this.active,
                        sizeExternalId: this.sizeExternalId,
                        creativeFormat: this.creativeFormatId,
                        allowedTargets: this.allowedTargetsIdes,
                    });
                }
                this.showCard = false;
            } catch (e) {
                this.errorMessage = e;
                if (e.response && e.response.data.message) {
                    this.errorMessage = e.response.data.message;
                }
                if (e.response.data.formErrors) { // there are some form errors
                    // DOTO: throw errors
                    this.errorMessage = Object.values(e.response.data.formErrors).join(' ');
                    this.formErrors = e.response.data.formErrors;
                }
            } finally {
                this.isWorking = false;
            }
        },
    },
    created() {
        this.getLandingPageType();
    },
};
</script>
