<template>
    <div class="lg:w-80 max-w-sm relative" :class="show ? 'z-20' : 'z-10'">
        <t-button variant="mediumCircleBlue" class="relative z-30 p-4"
                  @click="show = !show"
        >
            <c-icon class="w-4 h-4" icon="funnel"></c-icon>

            <div class="w-3 h-3 rounded-full bg-red-500 absolute bottom-1 -right-1" v-if="badges.length > 0"></div>

        </t-button>

        <base-dropdown v-model="show" :disable-click-outside="isDesktopWindowWidth" no-render class="absolute -top-4 lg:top-full pt-24 pb-5 lg:left-0 -left-4 px-4 lg:py-5 bg-white rounded-2xl w-80 lg:w-full z-20 space-y-4 lg:mt-8 shadow-lg">
            <div class="pl-2 mb-2 font-medium text-base leading-4">{{ $t('common_filter') }}</div>
            <div class="pl-2 flex flex-wrap" v-if="badges.length > 0">
                <h3 class="text-blue-400 text-lg mb-4 w-full">{{ $t('view_campaignList_filterBadgesHeading') }}</h3>
                <div class="-mx-1 -mt-1">
                    <t-button
                        v-for="badge in badges"
                        :key="badge.value"
                        variant="gray"
                        class="flex items-center m-1 pl-2 pr-4"
                        @click="removeBadge(badge.type, badge.value)"
                    >
                        {{ badge.text }}
                        <svg-icon name="x" class="ml-6 w-2 -mr-1"></svg-icon>
                    </t-button>
                </div>
                <div class="flex items-center my-4 cursor-pointer w-full" @click="clearFilter">
                    <svg-icon name="trash-2" class="mr-3 w-4 h-4"></svg-icon>
                    <span class="text-red-500">{{$t('view_campaignList_resetFilter')}}</span>
                </div>
                <hr class="w-full mb-4">
            </div>
            <div v-if="isSalesPerson || isVendor || isAgency" class="bg-gray-50 rounded-lg p-4">
                <label class="cursor-pointer inline-flex items-center w-full">
                    <t-checkbox v-model="filterMyCampaigns" />
                    <checkbox-icon />
                    <span class="leading-tight">{{ $t('common_myCampaigns') }}</span>
                </label>
            </div>
            <filter-dropdown :label="$t('common_state')">
                <checkbox-list
                    v-model="filterStates"
                    :options="campaignStates"
                    text-attribute="name"
                    value-attribute="id"
                    all="bottom"
                    :all-label="$t('view_campaignList_selectAll')"
                    all-checking
                ></checkbox-list>
            </filter-dropdown>
            <filter-dropdown :label="$t('view_campaignList_dateActive')">
                <template v-slot:right>
                    <t-button
                        v-if="dateBadge"
                        :key="dateBadge.value"
                        variant="gray"
                        class="flex items-center ml-2 pl-2 pr-4"
                        @click="removeBadge(dateBadge.type, dateBadge.value)"
                    >
                        {{ dateBadge.text }}
                        <svg-icon name="x" class="ml-3 w-2 -mr-1"></svg-icon>
                    </t-button>
                </template>
                <date-picker
                    class="my-3"
                    v-model="filterStartDate"
                    user-format="d.m."
                    :placeholder="$t('common_from')"
                    :disabled-dates="disableStartDates"
                ></date-picker>
                <date-picker
                    v-model="filterEndDate"
                    user-format="d.m."
                    :placeholder="$t('common_to')"
                    :disabled-dates="disableEndDates"
                ></date-picker>
            </filter-dropdown>
            <filter-dropdown :label="$t('common_campaigns')">
                <template v-slot:right>
                    <t-button
                        v-if="searchBadge"
                        :key="searchBadge.value"
                        variant="gray"
                        class="flex items-center ml-2"
                        @click="removeBadge(searchBadge.type, searchBadge.value)"
                    >
                        {{ searchBadge.text }}
                        <svg-icon name="x" class="ml-3 w-2 -mr-1"></svg-icon>
                    </t-button>
                </template>
                <div class="my-3 relative">
                    <t-input variant="" v-model="filterSearch" :placeholder="$t('view_campaignList_searchCampaign')">
                    </t-input>
                    <svg-icon name="search" class="absolute right-3 top-3 pointer-events-none"></svg-icon>
                </div>
            </filter-dropdown>
            <filter-dropdown
                v-if="isRetailerOrAgency"
                :label="$t('common_advertiserCompany')"
            >
                <t-rich-select
                    v-model="filterCompanies"
                    :options="companies"
                    text-attribute="name"
                    value-attribute="id"
                    multiple
                ></t-rich-select>
            </filter-dropdown>
            <filter-dropdown
                v-if="isRetailerOrAgency"
                :label="$t('common_salesPerson')"
            >
                <t-rich-select
                    v-model="filteredSalesPersonIdes"
                    :options="salesPersons"
                    text-attribute="name"
                    value-attribute="id"
                    multiple
                ></t-rich-select>
            </filter-dropdown>
        </base-dropdown>
    </div>
</template>
<script>
import {mapGetters} from "vuex";
import _ from 'lodash';
import DatePicker from "@/components/DatePicker.vue";
import SvgIcon from "@/components/SvgIcon.vue";
import CIcon from "@/components/base/icons/CIcon.vue";
import FilterDropdown from "@/components/FilterDropdown.vue";
import CheckboxList from "@/components/CheckboxList.vue";
import {parseISO} from "date-fns";
import CheckboxIcon from "@/components/icons/CheckboxIcon.vue";
import BaseDropdown from "@/components/base/BaseDropdown.vue";

export default {
	name: "CampaignsFilter",
    components: {
        BaseDropdown,
        CIcon,
        CheckboxIcon,
        CheckboxList,
        FilterDropdown,
        SvgIcon,
        DatePicker
    },
	props: {
        showFilter: {
            default: false,
        },
        value: { // selected filter values
            required: true,
        },
        filterData: { // data for filter as possible states
            required: true,
        },
    },
    data: () => {
        return {
            windowWidth: window.innerWidth,
        };
    },
	computed: {
        ...mapGetters('auth', ['isRetailer', 'isVendor', 'isAgency', 'isSalesPerson']),
        isDesktopWindowWidth() {
            return this.windowWidth >= 1280;
        },
		show: {
			get() {
				return this.showFilter;
			},
			set(value) {
                this.$emit("close", value);
			},
		},
        filter() {
            return this.value;
        },
        campaignStates() {
            if(!this.filterData) {
                return [];
            }
            return this.filterData.campaignStates;
        },
        companies() {
            if(!this.filterData) {
                return [];
            }
            return this.filterData.companies;
        },
        salesPersons() {
            if(!this.filterData) {
                return [];
            }
            return this.filterData.salesPersons;
        },
        isRetailerOrAgency() {
            return this.isRetailer || this.isAgency;
        },
        disableStartDates() {
            if(!this.filterEndDate) {
                return [];
            }
            const endDate = parseISO(this.filterEndDate);
            return [
                function(date) {
                    if (
                        endDate.getDate() === date.getDate()
                        && endDate.getMonth() === date.getMonth()
                        && endDate.getFullYear() === date.getFullYear()
                    ) {
                        return false;
                    }
                    return date >= endDate;
                }
            ];
        },
        disableEndDates() {
            if(!this.filterStartDate) {
                return [];
            }
            const startDate = parseISO(this.filterStartDate);
            return [
                function(date) {
                    if (
                        startDate.getDate() === date.getDate()
                        && startDate.getMonth() === date.getMonth()
                        && startDate.getFullYear() === date.getFullYear()
                    ) {
                        return false;
                    }
                    return date <= startDate;
                }
            ];
        },
        filterMyCampaigns: {
            get() {
                return this.filter.myCampaigns;
            },
            set(myCampaigns) {
                const filter = this.filter;
                filter.myCampaigns = myCampaigns;
                this.$emit("input", filter);
            }
        },
        filterStates: {
            get() {
                return this.filter.campaignStateIdes;
            },
            set(campaignStateIdes) {
                const filter = this.filter;
                filter.campaignStateIdes = campaignStateIdes;
                this.$emit("input", filter);
            }
        },
        filterStartDate: {
            get() {
                return this.filter.startDate;
            },
            set(startDate) {
                const filter = this.filter;
                filter.startDate = startDate;
                this.$emit("input", filter);
            }
        },
        filterEndDate: {
            get() {
                return this.filter.endDate;
            },
            set(endDate) {
                const filter = this.filter;
                filter.endDate = endDate;
                this.$emit("input", filter);
            }
        },
        filterSearch: {
            get() {
                return this.filter.search;
            },
            set: _.debounce(function(search) {
                const filter = this.filter;
                filter.search = search;
                this.$emit("input", filter);
            }, 500),
        },
        filterCompanies: {
            get() {
                return this.filter.companyIdes;
            },
            set(companyIdes) {
                const filter = this.filter;
                filter.companyIdes = companyIdes;
                this.$emit("input", filter);
            }
        },
        filteredSalesPersonIdes: {
            get() {
                return this.filter.salesPersonIdes;
            },
            set(salesPersonIdes) {
                const filter = this.filter;
                filter.salesPersonIdes = salesPersonIdes;
                this.$emit("input", filter);
            }
        },
        /** number of active filters */
        activeFiltersCount() {
            return this.badges.length;
        },
        filterStateBadges() {
            return this.filter.campaignStateIdes.reduce((stack, campaignStateId) => {
                const campaignState = this.campaignStates.find((state) => state.id === campaignStateId);
                if (campaignState === undefined) return stack;

                return [
                    ...stack,
                    { type: 'state', value: campaignStateId, text: campaignState.name, },
                ];
            }, []);
        },
        filterSalesPersonIdesBadges() {
            return this.filter.salesPersonIdes.reduce((stack, salesPersonId) => {
                const salesPerson = this.salesPersons.find((salesPerson) => salesPerson.id === salesPersonId);
                if (salesPerson === undefined) return stack;

                return [
                    ...stack, 
                    { type: 'salesPerson', value: salesPersonId, text: salesPerson.name, },
                ];
            }, []);
        },
        filterCompaniesBadges() {
            return this.filter.companyIdes.reduce((stack, companyId) => {
                const company = this.companies.find((company) => company.id === companyId);
                if (company === undefined) return stack;

                return [
                    ...stack,
                    { type: 'company', value: companyId, text: company.name, },
                ];
            }, []);
        },
        badges() {
            var filter = this.filter;

            var badges = [
                ...this.filterStateBadges,
                ...this.filterCompaniesBadges,
            ];
            if (filter.startDate || filter.endDate) {
                let text = '';
                if (filter.startDate === filter.endDate) {
                    text = this.$d(parseISO(filter.startDate))
                } else if (filter.startDate && filter.endDate) {
                    const startDate = parseISO(filter.startDate);
                    const endDate = parseISO(filter.endDate);
                    let format = "fullDate"
                    if(startDate.getFullYear() === endDate.getFullYear()) {
                        format = "dayMonth";
                        if(startDate.getMonth() === endDate.getMonth()) {
                            format = "day";
                        }
                    }
                    text = this.$d(startDate, format) + ' - ' + this.$d(endDate, "fullDate");
                } else if (filter.startDate) {
                    text = this.$t('common_from') + ' ' + this.$d(parseISO(filter.startDate));
                } else if (filter.endDate) {
                    text = this.$t('common_to') + ' ' + this.$d(parseISO(filter.endDate));
                }
                badges.push({
                    type: 'date',
                    value: 'date',
                    text: text
                })
            }
            if(filter.search) {
                badges.push({
                    type: 'search',
                    value: 'search',
                    text: filter.search
                })
            }
            if(filter.myCampaigns) {
                badges.push({
                    type: 'myCampaigns',
                    value: 'myCampaigns',
                    text: this.$t('common_myCampaigns')
                })
            }
            badges.push(...this.filterSalesPersonIdesBadges);
            return badges;
        },
        dateBadge() {
            return this.badges.find(item => item.type === "date");
        },
        searchBadge() {
            return this.badges.find(item => item.type === "search");
        },
	},
    watch: {
    },
	methods: {
        removeBadge(type, value) {
            if(type === "state") {
                this.filterStates = this.filterStates.filter(id => id !== value);
            } else if(type === "company") {
                this.filterCompanies = this.filterCompanies.filter(id => id !== value);
            } else if (type === "date") {
                this.filterStartDate = null;
                this.filterEndDate = null;
            } else if(type === "salesPerson") {
                this.filteredSalesPersonIdes = this.filteredSalesPersonIdes.filter(id => id !== value);
            } else if(type === "search") {
                this.filterSearch = '';
            } else if(type === "myCampaigns") {
                this.filterMyCampaigns = false;
            }
        },
        async clearFilter() {
            this.$emit("clearFilter");
        },
        onResize() {
            this.windowWidth = window.innerWidth;
        }
    },
    mounted() {
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
        })
    },

    beforeDestroy() {
        window.removeEventListener('resize', this.onResize);
    },
	filters: {

    },
};
</script>
