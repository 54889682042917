const location = window.location;
const baseURL = `${location.protocol}//${location.host}/api`;

export default {
    imageURL(id, thumb = undefined) {
        if (id === undefined || id === null) {
            return "";
        }
        let url = baseURL + "/images/" + id;
        if (thumb) {
            url += "?thumb=" + encodeURIComponent(JSON.stringify(thumb));
        }
        return url;
    },
};
