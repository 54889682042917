import axios from "@/plugins/axios";


export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        async getPage(context, {code}) {
            const response = await axios.get(`/pages/code/${code}`);
            return response.data;
        },
        async updatePage(context, {code, title, content, script}) {
            const response = await axios.put(`/pages/code/${code}`, {
                title,
                content,
                script,
            });
            return response.data;
        }
    }
}
