<template>
    <div class="divide-y md:-mx-8 -mx-4">
        <target-keywords-input-item
                v-for="item in valueChange"
                :key="item.id"
                :value="item"
                :index="item.id"
                @input="changeItem"
                @error="error = $event"
                @delete="deleteItem(item.id)"
        >

        </target-keywords-input-item>

<!--         <ul>-->
<!--             <li v-for="(item, index) in value" :key="item.id">-->
<!--                 {{ index }} - {{ item.name }}-->
<!--             </li>-->
<!--         </ul>-->

    </div>
</template>

<script>



import TargetKeywordsInputItem from "@/components/TargetKeywordsInputItem.vue";
import {v4 as uuidv4} from 'uuid';

export default {
    name: "TargetKeywordsInput",
    components: {
        TargetKeywordsInputItem

    },
    props: {
        value: {
            type: Array
        },
    },
    data() {
        return {
            error: ''
        }
    },
    computed: {
        valueChange: {
            get() {
                return  this.value;
            },
            set(value) {
                // change original value with new value
                this.$emit('input', { ...this.value, value });
            },
        },
    },
    watch: {

        value(value) {

            if(this.value.length === 0 || (this.value.slice(-1) && this.value.slice(-1)[0].value !== '')) {
                var unicateId = uuidv4();
                this.$emit('input', [...this.value, { id: unicateId, value: '', exactMatch: false }]);
            }

            this.validate(value);
        },
        error(value) {
            this.$emit('error', value.length > 0);
        }
    },
    methods: {
        async validate(value) {
            let error = '';
            if(value == null) {
                value = this.value;
            }
            this.error = error;
        },
        changeItem(changedItem) {
            this.$emit("input", this.value.map(originalItem => originalItem.id === changedItem.id ? changedItem : originalItem));
        },
        deleteItem(id) {
            this.$emit("input", this.value.filter(item => item.id !== id));
        }

    },
    async created() {
        if(this.value.length === 0 || (this.value.slice(-1) && this.value.slice(-1)[0].value !== '')) {
            var unicateId = uuidv4();
            this.$emit('input', [...this.value, { id: unicateId, value: '', exactMatch: false }]);
        }
    },
}
</script>

<style scoped>

</style>
