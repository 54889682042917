<template>
	<img
        :src="src"
        @click="click"
    />
</template>

<script>
const svgContext = require.context(
	"!@/assets/icons", // search this directory
	true, // search subdirectories
	/\w+\.svg$/i // only include SVG files
);
export default {
	name: "SvgIcon",
	props: {
		name: {
			type: String,
		},
	},
	data() {
		const icons = {};
		svgContext.keys().forEach((path) => {
			// get SVG file content
			const content = svgContext(path);
			// extract icon id from filename
			const id = path.replace(/^\.\/(.*)\.\w+$/, "$1");
			// replace svg tags with symbol tags and id attribute
			icons[id] = content;
		});
		return {
			icons: icons,
		};
	},
	computed: {
		src() {
			return this.icons[this.name];
		},
	},
    methods: {
        click() {
            return this.$emit('click', arguments);
        }
    }
};
</script>

<style scoped>
</style>
