<template>
<div :class="className">
    <a class="px-2 py-4 cursor-pointer transition duration-200 ease-out hover:text-blue-400"
       :class="[
            (currentPage === 1) ? 
            'pointer-events-none text-gray-200':
            'text-blue-100'
       ]"
       @click="currentPage--"
    >
        <c-icon class="w-2.5 h-2.5" icon="arrow-full-left"></c-icon>
    </a>
    <a
        class="p-4 cursor-pointer hover:text-blue-400"
        v-for="page in pages"
        :key="page.page"
        :class="{
            'text-blue-400 border-b-2 border-blue-400' : currentPage === page.page,
            'pointer-events-none text-gray-500': page.page === null
        }"
        @click="currentPage = page.page">
        {{ page.name }}
    </a>
    <a
        class="px-2 py-4 cursor-pointer text-blue-100 transition duration-200 ease-out hover:text-blue-400"
        :class="[
            (currentPage === maxPage) ? 
            'pointer-events-none text-gray-200':
            'text-blue-100'
        ]"
        @click="currentPage++"
    >  
        <c-icon class="w-2.5 h-2.5" icon="arrow-full-right"></c-icon>

    </a>
</div>
</template>

<script>
import CIcon from "@/components/base/icons/CIcon.vue";

export default {
    name: "PagePicker",
    components: {
        CIcon
    },
    props: {
        value: { // index of first item to be shown (FirstResult). min = 0, max = totalCount - pageSize
            type: Number
        },
        totalCount: { // total count of paged items
            type: Number
        },
        pageSize: { // number of items per page
            type: Number,
            default: 10
        },
        window: { // how many items to show arount current page
          type: Number,
          default: 3
        },
        className: String,
    },
    computed: {
        currentPage: {
            get() {
                return this.value / this.pageSize + 1;
            },
            set(value) {
                this.$emit('input', value * this.pageSize - this.pageSize);
            }
        },
        pages() {
            const pages = [
                {
                    page: 1,
                    name: 1,
                }
            ];
            let minSide = this.currentPage - this.sideSize;
            let maxSide = this.currentPage + this.sideSize;
            maxSide = maxSide + Math.max(2 - minSide, 0);
            minSide = minSide - Math.max(maxSide - this.maxPage + 1, 0);
            const firstEllipsis = minSide > 2;
            const lastEllipsis = maxSide < this.maxPage - 1;
            if(firstEllipsis) {
                pages.push({
                    page: null,
                    name: '...'
                });
            }
            for(let i = minSide; i <= maxSide; i++) {
                if(i > 1 && i < this.maxPage) {
                    pages.push({
                        page: Math.ceil(i),
                        name: Math.ceil(i)
                    });
                }
            }
            if(lastEllipsis) {
                pages.push({
                    page: null,
                    name: '...'
                });
            }
            if(this.maxPage > 1) {
                pages.push({
                    page: this.maxPage,
                    name: this.maxPage
                })
            }
            return pages;
        },
        maxPage() {
            let pages = Math.ceil(this.totalCount / this.pageSize);
            return pages > 0 ? pages : 1;
        },
        sideSize() {
            return Math.floor(this.window / 2);
        }
    },
};
</script>

<style scoped>

</style>
