<template>
    <div class="max-w-screen-2xl pt-1 pb-8 px-4 md:px-8 mx-auto">
        <header class="flex flex-wrap items-start justify-between mt-5 mb-3 space-y-2 lg:space-y-0">
            <div class="flex items-center mb-4 space-x-2 pr-3">
                <h1 class="text-purple-100 h1">
                    {{ $t('view_dashboards_title') }}
                </h1>
            </div>
        </header>

        <script type="module" v-if="workspaceHost && workspaceId">
            import { setContext } from '{{ workspaceHost }}/components/{{ workspaceId }}.js';
            import tigerFactory, { TigerJwtAuthProvider } from '{{ workspaceHost }}/components/tigerBackend.js';

            window.gooddataApi = {
                setContext,
                tigerFactory,
                TigerJwtAuthProvider,
            };
        </script>

        <loading-box v-model="loading" min-height="12rem" theme="light">
            <gd-dashboard
                ref="dashboard"
                :dashboard="dashboardId"
                :locale="locale"
                style="height: 700px"
                readonly
            ></gd-dashboard>
        </loading-box>
    </div>
</template>

<script>
import { ref } from 'vue';
import LoadingBox from '@/components/LoadingBox.vue';

const GD_LOADED = 'GDC.DASH/EVT.INITIALIZED';

export default {
    name: 'DashboardsView',
    components: {LoadingBox},
    props: {},
    data() {
        return {
            loading: true,
            workspaceHost: null,
            workspaceId: null,
            dashboardId: null,
            locale: 'en-US',
            token: null,
        };
    },
    computed: {
        breadcrumbs() {
            return [
                {
                    title: 'Dashboards',
                    current: true,
                },
            ];
        },
    },
    setup() {
        const dashboard = ref(null);
        return {
            dashboard,
        };
    },
    unmounted() {
        this.$refs.dashboard.removeEventListener(GD_LOADED, this.onDashboardInit);
    },
    mounted() {
        this.$refs.dashboard.addEventListener(GD_LOADED, this.onDashboardInit);
    },
    methods: {
        onDashboardInit() {
            this.loading = false;
        },
        async loadData() {
            this.loading = true;

            // load the data if not loaded previously
            if (!this.$store.getters['dashboards/loaded']) {
                await this.$store.dispatch('dashboards/loadData');
            }

            if (!this.$store.getters['dashboards/isEnabled']) {
                await this.$router.push({
                    name: 'campaignList',
                });
                return;
            }

            this.workspaceId = this.$store.getters['dashboards/workspaceId'];
            this.workspaceHost = this.$store.getters['dashboards/workspaceHost'];
            this.dashboardId = this.$store.getters['dashboards/dashboardId'];

            const gd = await this.waitForGoodDataApi();

            const getJwt = async () => {
                const token = this.$store.getters['dashboards/token'];
                if (token === null) {
                    await this.$store.dispatch('dashboards/retrieveToken');
                }

                return this.$store.getters['dashboards/token'];
            };
            const notAuthenticatedHandler = () => {
                // todo handle not authenticated requests
            };
            const jwtIsAboutToExpire = async (setJwt) => {
                const jwt = await getJwt();
                setJwt(jwt);
            };
            const secondsBeforeTokenExpirationToCallReminder = 60;
            const jwt = await getJwt();
            const jwtAuthProvider = new gd.TigerJwtAuthProvider(
                jwt,
                notAuthenticatedHandler,
                jwtIsAboutToExpire,
                secondsBeforeTokenExpirationToCallReminder,
            );

            const backend = gd.tigerFactory()
                .onHostname(this.workspaceHost)
                .withAuthentication(jwtAuthProvider);

            try {
                gd.setContext({
                    backend,
                    workspaceId: this.workspaceId,
                });
            } catch {
                // intentionally ignore setContext twice
            }
        },
        async waitForGoodDataApi() {
            return new Promise((resolve) => {
                if (window.gooddataApi) {
                    return resolve(window.gooddataApi);
                }
                const waitHandler = setInterval(() => {
                    if (window.gooddataApi) {
                        clearInterval(waitHandler);
                        resolve(window.gooddataApi);
                    }
                }, 100);
            });
        },
    },
    async beforeRouteEnter(to, from, next) {
        await next(async (vm) => {
            await vm.loadData(to);
        });
    },
    async beforeRouteUpdate(to) {
        await this.loadData(to);
    },
};
</script>

<style scoped>

</style>
