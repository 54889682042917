<template>
    <div class="shrink basis-full grow-0 max-w-[610px]">
        <t-rich-select
            ref="select"
            class="w-full"
            v-model="targetProduct"
            :fetch-options="fetchTargetProducts"
            value-attribute="value"
            text-attribute="nameWithSku"
        >
            <template v-slot:option="{ option }"> <!-- possible other params index, isHighlighted, isSelected, query -->
                <li class="cursor-pointer">
                    <div class="flex justify-between items-center px-3 py-2">
                        <span
                            class="truncate block"
                            :class="{'text-gray-400': !option.raw.salable}"
                        >
                            {{ option.text }}
                        </span>
                    </div>
                </li>
            </template>
        </t-rich-select>
    </div>
</template>

<script>

export default {
    name: "TargetProductInputPicker",
    props: {
        value: {
            type: Object,
            default: null
        },
        nativeFormat: {
            type: Boolean,
            default: false,
        },
        targetId: { // target web, where
            type: Number,
            nullable: true,
            default: null
        },
        companyRestrictions: {
            type: Object,
            default: () => ({})
        },
    },
    data() {
        return {
        }
    },
    computed: {
        placeholder() {
            if (this.nativeFormat) {
                return this.$t('component_urlInputPicker_is_required')
            }
            return ""
        },
        error() {
            if (!this.targetProduct && this.nativeFormat && this.value && this.value.length > 0) {
                return this.$t('component_urlInputPicker_product_name_is_required')
            }
            return '';
        },
        targetProduct: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value)
            }
        }
    },
    watch: {
        async value(newVal) {
            await this.fixData(newVal);
        },
        error(value) {
            this.$emit('error', !!value);
        },
        async companyRestrictions() {
            if(this.value) {
                const {valid} = await this.$store.dispatch('targeting/validateTargetProduct', {
                    targetProductId: this.value.id,
                    ...this.companyRestrictions
                });
                if(!valid) {
                    this.targetProduct = null;
                }
            }
            if(this.$refs.select) {
                this.$refs.select.filterOptions(this.$refs.select.query);
            }
        }
    },
    methods: {
        async fetchTargetProducts(query) {
            const items = await this.$store.dispatch(
                'targeting/searchTargetProducts',
                {targetId: this.targetId, name: query, ...this.companyRestrictions}
            );
            const _this = this;
            const options = items.map(function(item) { return _this.formatSelectOption(item) });
            return ({results: options});
        },
        formatSelectOption(item) {
            return {
                value: item,
                salable : item.salable,
                nameWithSku: item.nameWithSku + (!item.salable ? ' (' + this.$t('component_urlInputPicker_notSalable') + ')' : '')
            };
        },
        async getTargetProductByURL(url) {
            this.targetProduct = await this.$store.dispatch(
                'targeting/searchTargetProductByURL',
                {targetId: this.targetId, url: url}
            );
            return this.targetProduct;
        },
        fixData(value) {
            value = value || this.value;

            const _this = this;
            this.$nextTick(async () => {
                if (value) {
                    _this.$refs.select.selectedOptions = _this.$refs.select.normalizeOptions(
                        [_this.formatSelectOption(value)]
                    );
                }
            });
        }
    },
    async created() {
        this.fixData(this.value);
    },
}
</script>

<style scoped>

</style>
