<template>
    <div v-if="filterData" class="lg:w-80 max-w-sm relative mt-2" :class="show ? 'z-20' : 'z-10'">
        <t-button variant="mediumCircleBlue" class="relative z-30"
                  @click="show = !show"
        >
            <c-icon class="w-4 h-4" icon="funnel"></c-icon>

            <div class="w-3 h-3 rounded-full bg-red-500 absolute bottom-1 -right-1" v-if="badges.length > 0"></div>

        </t-button>

        <base-dropdown v-model="show" :disable-click-outside="isDesktopWindowWidth" no-render
             class="absolute -top-4 lg:top-full pt-24 pb-5 lg:left-0 -left-4 px-4 lg:py-5 bg-white rounded-2xl w-80 lg:w-full z-20 space-y-4 lg:mt-8 shadow-lg">
             <div class="pl-2 mb-2 font-medium text-base leading-4">{{ $t('common_filter') }}</div>
             <div class="pl-2 flex flex-wrap" v-if="badges.length > 0">
                <h3 class="text-blue-400 text-lg mb-4 w-full">{{ $t('common_filter_SelectedParameters') }}</h3>
                <div class="-mx-1 -mt-1">
                    <t-button
                        v-for="badge in badges"
                        :key="badge.value"
                        variant="gray"
                        class="flex items-center m-1 pl-2 pr-4"
                        @click="removeBadge(badge.type, badge.value)"
                    >
                        {{ badge.text }}
                        <svg-icon name="x" class="ml-6 w-2 -mr-1"></svg-icon>
                    </t-button>
                </div>
                <div class="flex items-center my-4 cursor-pointer w-full" @click="clearFilter">
                    <svg-icon name="trash-2" class="mr-3 w-4 h-4"></svg-icon>
                    <span class="text-red-500">{{ $t('common_filter_resetFilter') }}</span>
                </div>
                <hr class="w-full mb-4">
            </div>
            <div v-if="isSalesPerson || isVendor || isAgency" class="bg-gray-50 rounded-lg p-4">
                <label class="cursor-pointer inline-flex items-center w-full">
                    <t-checkbox v-model="filterMyCampaigns"/>
                    <checkbox-icon/>
                    <span class="leading-tight">{{ $t('common_myCampaigns') }}</span>
                </label>
            </div>
            <filter-dropdown :label="$t('common_state')">
                <checkbox-list
                    v-model="filterStates"
                    :options="campaignItemStates"
                    text-attribute="name"
                    value-attribute="id"
                    all="bottom"
                    :all-label="$t('view_campaignList_selectAll')"
                    all-checking
                ></checkbox-list>
            </filter-dropdown>
            <filter-dropdown :label="$t('view_campaignList_dateActive')">
                <template v-slot:right>
                    <t-button
                        v-if="dateBadge"
                        :key="dateBadge.value"
                        variant="gray"
                        class="flex items-center ml-2 pl-2 pr-4"
                        @click="removeBadge(dateBadge.type, dateBadge.value)"
                    >
                        {{ dateBadge.text }}
                        <svg-icon name="x" class="ml-3 w-2 -mr-1"></svg-icon>
                    </t-button>
                </template>
                <date-picker
                    class="my-3"
                    v-model="filterStartDate"
                    user-format="d.m."
                    :placeholder="$t('common_from')"
                    :disabled-dates="disableStartDates"
                ></date-picker>
                <date-picker
                    v-model="filterEndDate"
                    user-format="d.m."
                    :placeholder="$t('common_to')"
                    :disabled-dates="disableEndDates"
                ></date-picker>
            </filter-dropdown>
            <filter-dropdown
                v-if="isRetailerOrAgency"
                :label="$t('common_advertiserCompany')"
            >
                <t-rich-select
                    v-model="filterCompanies"
                    :options="companies"
                    text-attribute="name"
                    value-attribute="id"
                    multiple
                ></t-rich-select>
            </filter-dropdown>
            <filter-dropdown :label="$t('common_campaigns')">
                <template v-slot:right>
                    <t-button
                        v-if="searchBadge"
                        :key="searchBadge.value"
                        variant="gray"
                        class="flex items-center ml-2"
                        @click="removeBadge(searchBadge.type, searchBadge.value)"
                    >
                        {{ searchBadge.text }}
                        <svg-icon name="x" class="ml-3 w-2 -mr-1"></svg-icon>
                    </t-button>
                </template>
                <div class="my-3 relative">
                    <t-input variant="" v-model="filterSearch" :placeholder="$t('view_campaignList_searchCampaign')">
                    </t-input>
                    <svg-icon name="search" class="absolute right-6 top-3 pointer-events-none"></svg-icon>
                </div>
            </filter-dropdown>
            <filter-dropdown :label="$t('common_campaignState')">
                <checkbox-list
                    v-model="filterCampaignStates"
                    :options="campaignStates"
                    text-attribute="name"
                    value-attribute="id"
                    all="bottom"
                    :all-label="$t('view_campaignList_selectAll')"
                    all-checking
                ></checkbox-list>
            </filter-dropdown>
            <filter-dropdown
                v-if="isRetailerOrAgency"
                :label="$t('common_salesPerson')"
            >
                <t-rich-select
                    v-model="filteredSalesPersonIdes"
                    :options="salesPersons"
                    text-attribute="name"
                    value-attribute="id"
                    multiple
                    :searchBoxPlaceholder="$t('common_search')"
                ></t-rich-select>
            </filter-dropdown>
            <filter-dropdown :label="$t('common_product_category')">
                <checkbox-list
                    v-model="filterProductCategories"
                    :options="productCategories"
                    text-attribute="title"
                    value-attribute="id"
                    all="bottom"
                    :all-label="$t('view_campaignList_selectAll')"
                    all-checking
                ></checkbox-list>
            </filter-dropdown>
            <filter-dropdown
                :label="$t('common_product')"
            >
                <t-rich-select
                    v-model="filterProducts"
                    :options="products"
                    text-attribute="titleOrName"
                    value-attribute="id"
                    multiple
                    :searchBoxPlaceholder="$t('common_search')"
                ></t-rich-select>
            </filter-dropdown>
            <filter-dropdown
                :label="$t('common_brand')"
            >
                <t-rich-select
                    ref="brandsSelect"
                    v-model="filterBrands"
                    :fetch-options="fetchBrands"
                    text-attribute="name"
                    value-attribute="value"
                    multiple
                    :searchBoxPlaceholder="$t('common_search')"
                ></t-rich-select>
            </filter-dropdown>
            <filter-dropdown
                :label="$t('component_CampaignItemsFilter_rootTargetProductCategory')"
            >
                <t-rich-select
                    v-model="filterRootTargetProductCategories"
                    :options="rootTargetProductCategories"
                    value-attribute="id"
                    text-attribute="name"
                    multiple
                    :searchBoxPlaceholder="$t('common_search')"
                ></t-rich-select>
            </filter-dropdown>
            <filter-dropdown
                :label="$t('component_CampaignItemsFilter_targetProduct')"
            >
                <t-rich-select
                    ref="targetProductsSelect"
                    v-model="filterTargetProducts"
                    :fetch-options="fetchTargetProducts"
                    text-attribute="name"
                    value-attribute="value"
                    multiple
                ></t-rich-select>
            </filter-dropdown>
            <div class="bg-gray-50 rounded-lg p-4">
                <label class="cursor-pointer inline-flex items-center w-full">
                    <t-checkbox v-model="filterNoCatalogTargetUrl"/>
                    <checkbox-icon/>
                    <span class="leading-tight">{{ $t('component_CampaignItemsFilter_nonCatalogTargetLink') }}</span>
                </label>
            </div>
        </base-dropdown>
    </div>
</template>
<script>
import {mapGetters} from "vuex";
import _ from 'lodash';
import DatePicker from "@/components/DatePicker.vue";
import SvgIcon from "@/components/SvgIcon.vue";
import FilterDropdown from "@/components/FilterDropdown.vue";
import CheckboxList from "@/components/CheckboxList.vue";
import {parseISO} from "date-fns";
import CheckboxIcon from "@/components/icons/CheckboxIcon.vue";
import CIcon from "@/components/base/icons/CIcon.vue";
import BaseDropdown from "@/components/base/BaseDropdown.vue";

export default {
    name: "CampaignItemsFilter",
    components: {
        BaseDropdown,
        CIcon,
        CheckboxIcon,
        CheckboxList,
        FilterDropdown,
        SvgIcon,
        DatePicker
    },
    props: {
        showFilter: {
            default: false,
        },
        value: { // selected filter values
            required: true,
        },
    },
    data: () => {
        return {
            windowWidth: window.innerWidth,
        };
    },
    computed: {
        ...mapGetters('auth', ['isRetailer', 'isVendor', 'isAgency', 'isSalesPerson']),
        isDesktopWindowWidth() {
            return this.windowWidth >= 1280;
        },
        show: {
            get() {
                return this.showFilter;
            },
            set(value) {
                this.$emit("close", value);
            },
        },
        filterData() {
            return this.$store.getters['campaignItemsList/filterData'];
        },
        filter() {
            return this.value;
        },
        campaignItemStates() {
            return this.filterData.campaignItemStates;
        },
        productCategories() {
            return this.filterData.productCategories;
        },
        products() {
            return this.filterData.products;
        },
        companies() {
            return this.filterData.companies;
        },
        salesPersons() {
            return this.filterData.salesPersons;
        },
        rootTargetProductCategories() {
            const categories = [];
            const rootCategories = this.filterData.rootTargetProductCategories;
            for (const category of  rootCategories){
                const item = categories.find(item => item.externalIdentifier === category.externalIdentifier && item.name === category.name)
                if (!item) {
                    categories.push({
                        id: category.id,
                        externalIdentifier: category.externalIdentifier,
                        ids: [category.id],
                        name: category.name
                    });
                } else {
                    item.ids = [...item.ids, category.id];
                }
            }
            return categories;
        },
        campaignStates() {
            return this.filterData.campaignStates;
        },
        isRetailerOrAgency() {
            return this.isRetailer || this.isAgency;
        },
        disableStartDates() {
            if (!this.filterEndDate) {
                return [];
            }
            const endDate = parseISO(this.filterEndDate);
            return [
                function (date) {
                    if (
                        endDate.getDate() === date.getDate()
                        && endDate.getMonth() === date.getMonth()
                        && endDate.getFullYear() === date.getFullYear()
                    ) {
                        return false;
                    }
                    return date >= endDate;
                }
            ];
        },
        disableEndDates() {
            if (!this.filterStartDate) {
                return [];
            }
            const startDate = parseISO(this.filterStartDate);
            return [
                function (date) {
                    if (
                        startDate.getDate() === date.getDate()
                        && startDate.getMonth() === date.getMonth()
                        && startDate.getFullYear() === date.getFullYear()
                    ) {
                        return false;
                    }
                    return date <= startDate;
                }
            ];
        },
        filterMyCampaigns: {
            get() {
                return this.filter.myCampaigns;
            },
            set(myCampaigns) {
                const filter = this.filter;
                filter.myCampaigns = myCampaigns;
                this.$emit("input", filter);
            }
        },
        filterStates: {
            get() {
                return this.filter.campaignItemStateIdes;
            },
            set(campaignItemStateIdes) {
                const filter = this.filter;
                filter.campaignItemStateIdes = campaignItemStateIdes;
                this.$emit("input", filter);
            }
        },
        filterStartDate: {
            get() {
                return this.filter.startDate;
            },
            set(startDate) {
                const filter = this.filter;
                filter.startDate = startDate;
                this.$emit("input", filter);
            }
        },
        filterEndDate: {
            get() {
                return this.filter.endDate;
            },
            set(endDate) {
                const filter = this.filter;
                filter.endDate = endDate;
                this.$emit("input", filter);
            }
        },
        filterSearch: {
            get() {
                return this.filter.search;
            },
            set: _.debounce(function(search) {
                const filter = this.filter;
                filter.search = search;
                this.$emit("input", filter);
            }, 500),
        },
        filterProductCategories: {
            get() {
                return this.filter.productCategoryIdes;
            },
            set(productCategoryIdes) {
                const filter = this.filter;
                filter.productCategoryIdes = productCategoryIdes;
                this.$emit('input', filter);
            },
        },
        filterProducts: {
            get() {
                return this.filter.productIdes;
            },
            set(productIdes) {
                const filter = this.filter;
                filter.productIdes = productIdes;
                this.$emit("input", filter);
            }
        },
        filterCompanies: {
            get() {
                return this.filter.companyIdes;
            },
            set(companyIdes) {
                const filter = this.filter;
                filter.companyIdes = companyIdes;
                this.$emit("input", filter);
            }
        },
        filterCampaignStates: {
            get() {
                return this.filter.campaignStateIdes;
            },
            set(campaignStateIdes) {
                const filter = this.filter;
                filter.campaignStateIdes = campaignStateIdes;
                this.$emit("input", filter);
            }
        },
        filteredSalesPersonIdes: {
            get() {
                return this.filter.salesPersonIdes;
            },
            set(salesPersonIdes) {
                const filter = this.filter;
                filter.salesPersonIdes = salesPersonIdes;
                this.$emit("input", filter);
            }
        },
        filterBrands: {
            get() {
                return this.filter.brands;
            },
            set(brands) {
                const filter = this.filter;
                filter.brands = brands;
                this.$emit("input", filter);
            }
        },
        filterRootTargetProductCategories: {
            get() {
                return this.filter.rootTargetProductCategoryIdes.filter(id => this.rootTargetProductCategories.find(item => item.id === id));
            },
            set(rootTargetProductCategoryIdes) {
                const filter = this.filter;
                const currentIds = []
                for (const category of this.rootTargetProductCategories) {
                    if (rootTargetProductCategoryIdes.includes(category.id)) {
                        currentIds.push(...category.ids);
                    }
                }
                if(JSON.stringify(currentIds) === JSON.stringify(filter.rootTargetProductCategoryIdes)) {
                    return;
                }
                this.$emit("input", {...filter, rootTargetProductCategoryIdes: currentIds});
            }
        },
        filterTargetProducts: {
            get() {
                return this.filter.targetProducts;
            },
            set(targetProducts) {
                const filter = this.filter;
                filter.targetProducts = targetProducts;
                this.$emit("input", filter);
            }
        },
        filterNoCatalogTargetUrl: {
            get() {
                return this.filter.noCatalogTargetUrl;
            },
            set(noCatalogTargetUrl) {
                const filter = this.filter;
                filter.noCatalogTargetUrl = noCatalogTargetUrl;
                this.$emit("input", filter);
            }
        },
        /** number of active filters */
        activeFiltersCount() {
            return this.badges.length;
        },
        badges() {
            var _this = this;
            var filter = this.filter;
            var badges = [
                ...filter.campaignItemStateIdes.map((id) => {
                    return {
                        type: 'state',
                        value: id,
                        text: this.campaignItemStates.find((state) => state.id === id).name
                    }
                }),
                ...filter.campaignStateIdes.map((id) => {
                    return {
                        type: 'campaignState',
                        value: id,
                        text: this.campaignStates.find((state) => state.id === id).name
                    }
                }),
                ...filter.companyIdes.map((id) => {
                    const company = this.companies.find((company) => company.id === id);
                    return {
                        type: 'company',
                        value: id,
                        text: company ? company.name : ''
                    }
                })
            ];
            if (filter.startDate || filter.endDate) {
                let text = '';
                if (filter.startDate === filter.endDate) {
                    text = this.$d(parseISO(filter.startDate))
                } else if (filter.startDate && filter.endDate) {
                    const startDate = parseISO(filter.startDate);
                    const endDate = parseISO(filter.endDate);
                    let format = "fullDate"
                    if (startDate.getFullYear() === endDate.getFullYear()) {
                        format = "dayMonth";
                        if (startDate.getMonth() === endDate.getMonth()) {
                            format = "day";
                        }
                    }
                    text = this.$d(startDate, format) + ' - ' + this.$d(endDate, "fullDate");
                } else if (filter.startDate) {
                    text = this.$t('common_from') + ' ' + this.$d(parseISO(filter.startDate));
                } else if (filter.endDate) {
                    text = this.$t('common_to') + ' ' + this.$d(parseISO(filter.endDate));
                }
                badges.push({
                    type: 'date',
                    value: 'date',
                    text: text
                })
            }
            if (filter.search) {
                badges.push({
                    type: 'search',
                    value: 'search',
                    text: filter.search
                })
            }
            if (filter.myCampaigns) {
                badges.push({
                    type: 'myCampaigns',
                    value: 'myCampaigns',
                    text: this.$t('common_myCampaigns')
                })
            }
            filter.salesPersonIdes.forEach(function (salesPersonId) {
                const salesPerson = _this.salesPersons.find((salesPerson) => salesPerson.id === salesPersonId);
                badges.push({
                    type: 'salesPerson',
                    value: salesPersonId,
                    text: salesPerson.name
                })
            });
            filter.productCategoryIdes.forEach(productCategoryId => {
                const productCategory = _this.productCategories.find(productCategory => productCategory.id === productCategoryId);
                badges.push({
                    type: 'productCategory',
                    value: productCategoryId,
                    text: productCategory.title,
                });
            });
            filter.productIdes.forEach(function (productId) {
                const product = _this.products.find((product) => product.id === productId);
                badges.push({
                    type: 'product',
                    value: productId,
                    text: product.name
                })
            });
            filter.brands.forEach(function (brand) {
                badges.push({
                    type: 'brand',
                    value: brand.id,
                    text: brand.name
                })
            });
            filter.rootTargetProductCategoryIdes.forEach(function (categoryId) {
                const category = _this.rootTargetProductCategories.find((item) => item.id.toString() === categoryId.toString());
                if(!category) {
                    return;
                }
                badges.push({
                    type: 'rootTargetProductCategory',
                    value: categoryId,
                    text: category.name
                })
            });
            filter.targetProducts.forEach(function (targetProduct) {
                badges.push({
                    type: 'targetProduct',
                    value: targetProduct.externalIdentifier,
                    text: targetProduct.nameWithSku
                })
            });
            if (filter.noCatalogTargetUrl) {
                badges.push({
                    type: 'noCatalogTargetUrl',
                    value: 'noCatalogTargetUrl',
                    text: this.$t('component_CampaignItemsFilter_nonCatalogTargetLink')
                })
            }
            return badges;
        },
        dateBadge() {
            return this.badges.find(item => item.type === "date");
        },
        searchBadge() {
            return this.badges.find(item => item.type === "search");
        },
    },
    watch: {
        showFilter(newValue) {
            const _this = this;
            if (newValue) {
                // restore selected options for brands and select
                this.$nextTick(() => {
                    const brandOptions = _this.filterBrands.map(item => ({value: item, name: item.name}))
                    _this.$refs.brandsSelect.selectedOptions = _this.$refs.brandsSelect.normalizeOptions(brandOptions);
                    const targetProductOptions = _this.filterTargetProducts.map(item => ({
                        value: item,
                        name: item.name
                    }))
                    _this.$refs.targetProductsSelect.selectedOptions = _this.$refs.targetProductsSelect.normalizeOptions(targetProductOptions);
                });
            }
        },
    },
    methods: {
        removeBadge(type, value) {
            if (type === "state") {
                this.filterStates = this.filterStates.filter(id => id !== value);
            } else if (type === "campaignState") {
                this.filterCampaignStates = this.filterCampaignStates.filter(id => id !== value);
            } else if (type === "company") {
                this.filterCompanies = this.filterCompanies.filter(id => id !== value);
            } else if (type === "date") {
                this.filterStartDate = null;
                this.filterEndDate = null;
            } else if (type === "salesPerson") {
                this.filteredSalesPersonIdes = this.filteredSalesPersonIdes.filter(id => id !== value);
            } else if (type === "product") {
                this.filterProducts = this.filterProducts.filter(id => id !== value);
            } else if (type === 'productCategory') {
                this.filterProductCategory = this.filterProductsCategory.filter(id => id !== value);
            } else if (type === "search") {
                this.filterSearch = '';
            } else if (type === "myCampaigns") {
                this.filterMyCampaigns = false;
            } else if (type === "brand") {
                this.filterBrands = this.filterBrands.filter(item => item.id !== value);
            } else if (type === "rootTargetProductCategory") {
                this.filterRootTargetProductCategories = this.filterRootTargetProductCategories.filter(id => id !== value);
            } else if (type === "targetProduct") {
                this.filterTargetProducts = this.filterTargetProducts.filter(item => item.externalIdentifier !== value);
            } else if (type === "noCatalogTargetUrl") {
                this.filterNoCatalogTargetUrl = false;
            }
        },
        async clearFilter() {
            this.$emit("clearFilter");
        },
        async fetchBrands(name) {
            const results = await this.$store.dispatch('brands/searchBrands', {name});

            return {results: results.map(item => ({value: item, name: item.name}))};
        },
        async fetchTargetProducts(name) {
            const results = await this.$store.dispatch('targeting/searchTargetProducts', {name});
            return {results: results.map(item => ({value: item, name: item.nameWithSku}))};
        },
    },
    filters: {},
    async created() {
        await this.$store.dispatch('campaignItemsList/reloadFilterData');
    }
};
</script>
