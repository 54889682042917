var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.className},[_c('a',{staticClass:"px-2 py-4 cursor-pointer transition duration-200 ease-out hover:text-blue-400",class:[
            (_vm.currentPage === 1) ? 
            'pointer-events-none text-gray-200':
            'text-blue-100'
       ],on:{"click":function($event){_vm.currentPage--}}},[_c('c-icon',{staticClass:"w-2.5 h-2.5",attrs:{"icon":"arrow-full-left"}})],1),_vm._l((_vm.pages),function(page){return _c('a',{key:page.page,staticClass:"p-4 cursor-pointer hover:text-blue-400",class:{
            'text-blue-400 border-b-2 border-blue-400' : _vm.currentPage === page.page,
            'pointer-events-none text-gray-500': page.page === null
        },on:{"click":function($event){_vm.currentPage = page.page}}},[_vm._v(" "+_vm._s(page.name)+" ")])}),_c('a',{staticClass:"px-2 py-4 cursor-pointer text-blue-100 transition duration-200 ease-out hover:text-blue-400",class:[
            (_vm.currentPage === _vm.maxPage) ? 
            'pointer-events-none text-gray-200':
            'text-blue-100'
        ],on:{"click":function($event){_vm.currentPage++}}},[_c('c-icon',{staticClass:"w-2.5 h-2.5",attrs:{"icon":"arrow-full-right"}})],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }