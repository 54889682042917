<template>
    <div v-if="isDisplayed" class="space-y-4">
        <div class="sm:flex items-center justify-between bg-gray-50 rounded-xl p-6 -mx-6 space-x-2">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: "HorizontalTabContent",
    props: {
        tabName: {
            type: String
        },
        identifier: {
            type: String,
        },
        hidden: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            value: ''
        }
    },
    computed: {
        isDisplayed() {
            return !this.hidden && this.value === this.identifier;
        }
    }
}
</script>

<style scoped>

</style>
