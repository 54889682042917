<template>
    <div class="max-w-screen-2xl py-8 px:4 md:p-16 mx-auto space-y-6">
        <h1 class="h1 mb-8 pl-4">{{ $t('common_products') }}</h1>
        <div :key="productCategory.id" v-for="productCategory in productCategories"
             class="md:p-6 p-4 bg-white rounded-2xl">
            <div class="flex items-center mb-6">
                <h2 class="h2 ml-3">
                    {{productCategory.title }}
                </h2>
            </div>

            <show-more-text
                v-if="productCategory.description.trim() !== '' || productCategory.shortDescription.trim() !== ''"
                :perex="productCategory.shortDescription"
                :text="productCategory.description"
                :more-text="$t('common_showMore')"
                :less-text="$t('common_showLess')">
            </show-more-text>

            <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 mt-6">
                <product-list-item
                    v-for="product in productCategory.products.filter(c => c.active)"
                    :product="product"
                    :key="product.id"
                    :to="{name: 'productDetail', params: {selectedProductId: product.id}}"
                    >
                </product-list-item>
            </div>

        </div>

    </div>
</template>

<script>


import ProductListItem from "@/components/ProductListItem";
import ShowMoreText from "@/components/ShowMoreText";

export default {
    name: "ProductListView",
    metaTags() {
        return {
            title: this.$t('view_ProductListView_metaTitle') + ' - ' + this.$t('common_meta_title'),
        }
    },
    components: {
        ProductListItem,
        ShowMoreText,
    },
    data: () => {
        return {
            selectedProductId: null,
            productCategories: [],
            showAddToCampaign: false,
            addNewCampaign: false,
            showCreative: false,
            creativeAsZip: false,
            settings: {
                arrows: false,
                dots: false,
                responsive: [
                    {
                        breakpoint: 8000,
                        settings: "unslick",
                    },
                    {
                        breakpoint: 769,
                        settings: {
                            arrows: false,
                            dots: false,
                            responsive: [
                                {
                                    breakpoint: 8000,
                                    settings: "unslick",
                                },
                                {
                                    breakpoint: 769,
                                    settings: {
                                        slidesToShow: 2,
                                        slidesToScroll: 1,
                                        centerMode: true,
                                        rows: 1,
                                    },
                                },
                                {
                                    breakpoint: 620,
                                    settings: {
                                        centerMode: true,
                                        slidesToShow: 1,
                                        rows: 1,
                                    },
                                },
                            ],
                        },
                    },
                    {
                        breakpoint: 620,
                        settings: {
                            centerMode: true,
                            slidesToShow: 1,
                            rows: 1,
                        },
                    },
                ],
            },
        };
    },
    methods: {
        async loadData(to) {
            const id = to.params.selectedProductId;
            this.selectedProductId = id || null;
            this.productCategories = await this.$store.dispatch('products/getUserCategoriesWithProducts');
        }
    },
    async beforeRouteEnter(to, from, next) {
        await next(async (vm) => {
            await vm.loadData(to);
        });
    },
    async beforeRouteUpdate(to) {
        await this.loadData(to);
    },
};
</script>
