import axios from "@/plugins/axios";

const initialState = {
    loaded: false,
    enabled: false,
    token: null,
    workspaceHost: null,
    workspaceId: null,
    dashboardId: null,
};

export default {
    namespaced: true,
    state: initialState,
    getters: {
        loaded: (state) => state.loaded,
        isEnabled: (state) => state.enabled,
        token: (state) => state.token,
        workspaceHost: (state) => state.workspaceHost,
        workspaceId: (state) => state.workspaceId,
        dashboardId: (state) => state.dashboardId,
    },
    mutations: {
        ENABLE(state, { enabled, workspaceHost, workspaceId, dashboardId }) {
            state.loaded = true;
            state.enabled = enabled;
            state.workspaceHost = workspaceHost;
            state.workspaceId = workspaceId;
            state.dashboardId = dashboardId;
        },
        DISABLE(state) {
            state.enabled = false;
        },
        SET_TOKEN(state, token) {
            state.token = token;
        },
        RESET(state) {
            state.enabled = initialState.enabled;
            state.workspaceHost = initialState.workspaceHost;
            state.workspaceId = initialState.workspaceId;
            state.dashboardId = initialState.dashboardId;
        },
    },
    actions: {
        reset(context) {
            context.commit('RESET');
        },
        async loadData(context) {
            try {
                const response = await axios.get('/dashboards');
                if (!response.data) {
                    context.commit('DISABLE');
                } else {
                    context.commit('ENABLE', response.data);
                }
            } catch (err) {
                // intentionally
            }
        },
        async retrieveToken(context) {
            try {
                const response = await axios.post('/dashboards/token');
                context.commit('SET_TOKEN', response.data.token);
            } catch (err) {
                // intentionally
            }
        },
    }
};
