<template>
    <form
        action
        class="w-full"
        @submit.prevent.stop="submit"
        v-bind="$attrs"
    >
        <div class="flex flex-col flex-wrap justify-between space-x-2  max-w-4xl">
            <div class="flex-grow">
                <label for="" class="text-gray-600 mb-2 block">
                    {{ $t('common_note') }}
                </label>
                <t-input
                    variant=""
                    class=""
                    v-model="message"
                    :disabled="disableNote"
                    clearable
                >
                </t-input>
            </div>
            <div class="flex flex-wrap items-center justify-end space-x-2 mt-4">
                <c-button
                    size="medium"
                    type="submit"
                    color="green"
                    @click="message = ''"
                    :loading="submittingApproval"
                >
                    {{ $t('common_approve') }}
                </c-button>
                <c-button
                    size="medium"
                    color="red"
                    :disabled="disableDismissButton"
                    :loading="submittingDismiss"
                    type="submit"
                >
                    {{ $t('common_dismiss') }}
                </c-button>
            </div>
        </div>
    </form>
</template>

<script>

import {mapGetters} from "vuex";
import {CreativeStates} from "@/utils/CreativeState";
import CButton from "@/components/base/buttons/CButton.vue";

export default {
    name: "LandingPageApprovalForm",
    props: {
        landingPage: {
            type: Object,
            required: true,
        },
    },
    components: {CButton},
    data() {
        return {
            submitting: false,
            noteStorage: '',
            uploading: false,
            sending: false,
            message: '',
        }
    },
    computed: {
        ...mapGetters('auth', ['isRetailer']),
        disableDismissButton() {
            return !this.isRetailer || this.message.length === 0 || this.submitting;
            // return this.campaignName.length === 0 || this.submitting || (this.isRetailer && !this.companyId);
        },
        disableApproveButton() {
            return !this.isRetailer || this.message.length !== 0 || this.submitting;
            // return this.campaignName.length === 0 || this.submitting || (this.isRetailer && !this.companyId);
        },
        submittingApproval() {
            return this.submitting && this.message.length === 0;
        },
        submittingDismiss() {
            return this.submitting && this.message.length !== 0;
        },
        disableNote() {
            return !this.isRetailer || this.landingPage.creativeState === CreativeStates.Approved;
        },
        creativeMessage() {
            return this.landingPage.notes;
        },
    },
    watch: {
        creativeMessage() {
            this.loadData();
        },
    },
    methods: {
        async loadData() {
            this.message = this.creativeMessage;
        },
        // nefunguje submit z tlacitka
        async submit() {
            this.submitting = true;
            let landingPage = this.landingPage;
            if (this.message.length === 0) {
                landingPage = await this.$store.dispatch('landingPages/approveLandingPage', {
                    landingPageId: this.landingPage.id,
                });
            } else {
                landingPage = await this.$store.dispatch('landingPages/rejectLandingPage', {
                    landingPageId: this.landingPage.id,
                    note: this.message,
                });
            }
            this.submitting = false;
            this.$emit('submit', landingPage);
        },

    },
    created() {
        this.loadData();
    },

}
</script>

<style scoped>

</style>
