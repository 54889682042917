<template>
	<div class="max-w-screen-2xl pt-1 pb-8 px-4 md:px-8 mx-auto">
        <simple-breadcrumb v-if="breadcrumbs.length" :items="breadcrumbs"></simple-breadcrumb>
		<header class="flex flex-wrap items-center justify-between my-5">

            <campaign-items-filter
                :show-filter="showFilter"
                @close="newVal => showFilter = newVal"
                v-model="filter"
                @clearFilter="clearFilter"
                data-cy="campaign-items-filter"
            >
            </campaign-items-filter>

		</header>
        <t-button
            variant="mediumBlue"
            class="flex items-center relative group pl-4 ml-auto"
            @click="toggleDisplayPerformance"
            v-if="isPerformanceButtonVisible"
        >
            <c-icon class="w-4 h-4 mr-2" icon="graph-up"></c-icon>
            {{ performanceBttnText }}
        </t-button>
        <dynamic-table :items="extendedCampaignItems" :loading="loadingData" :classes="showFilter ? 'xl:max-w-[calc(100%-360px)] mr-0 ml-auto' : ''">
            <dynamic-table-column identifier="name">
                {{ $t('common_activity') }}
            </dynamic-table-column>
            <dynamic-table-column v-if="!isVendor" identifier="company" :sorting="filterSorting"  @sort="newValue => filterSorting = newValue">
                {{ $t('common_advertiserCompany') }}
            </dynamic-table-column>
            <dynamic-table-column identifier="campaignName">
                {{ $t('view_campaignItemsListView_campaignName') }}
            </dynamic-table-column>
            <dynamic-table-column identifier="campaignState">
                {{ $t('common_campaignState') }}
            </dynamic-table-column>
            <dynamic-table-column identifier="startDate" :sorting="filterSorting" @sort="newValue => filterSorting = newValue">
                {{ $t('common_from') }}
            </dynamic-table-column>
            <dynamic-table-column identifier="endDate" :sorting="filterSorting" @sort="newValue => filterSorting = newValue">
                {{ $t('common_to') }}
            </dynamic-table-column>
            <dynamic-table-column identifier="price" :sorting="filterSorting" @sort="newValue => filterSorting = newValue">
                {{ $t('common_ordered') }}
            </dynamic-table-column>
            <dynamic-table-column identifier="status">
                {{ $t('common_creativeState') }}
            </dynamic-table-column>
            <dynamic-table-column identifier="actions" :classes-header="'p-4 text-right'" :align-right="true">
                {{ $t('common_actions') }}
            </dynamic-table-column>

            <template v-slot:name="item">
                <h2 class="h3">
                    {{ item.name }}
                </h2>
                <h3 class="font-normal leading-3 text-xs" v-if="item.targetProductName">
                    {{ item.targetProductName }}
                </h3>
            </template>
            <template v-slot:company="item">
                    {{ item.companyName }}
            </template>
            <template v-slot:campaignName="item">
                <t-button variant="link" tag-name="a" :to="{name: 'campaignDetail', params: {campaignId: item.campaignId}}">
                    {{ item.campaignName }}
                </t-button>
            </template>
            <template v-slot:campaignState="item">
                <campaign-state :campaignState="item.campaignState"></campaign-state>
            </template>
            <template v-slot:startDate="item">
                {{ item.startDateString }}
            </template>
            <template v-slot:endDate="item">
                {{ item.endDateString }}
            </template>
            <template v-slot:performance="item">
                <div class="flex justify-start">
                    <h2 class="text-gray-500 font-medium p-1 text-left">
                        {{ $t('common_currentPerformance') }}:
                    </h2>
                    <activity-actual-performance :campaignItem="item"></activity-actual-performance>
                </div>
            </template>
            <template v-slot:price="item">
                <span class="font-normal text-sm" style="white-space: nowrap;">{{ $n(item.unitPrice, "currency") }}&nbsp;/&nbsp;{{ getRateTypeNameShort(item.productRateType) }}</span><br>
                <span class="font-normal text-sm">{{ $t('common_totalPrice') }}:</span>&nbsp;<span class="font-normal text-sm">{{ $n(item.price, "currency") }}</span>
            </template>
            <template v-slot:status="item">
                <div class="flex items-center justify-start">
                    <campaign-item-state :campaignItemState="item.itemState"></campaign-item-state>
                    <router-link

                        class="ml-2 underline hover:no-underline cursor-pointer"
                        :to="{name:'campaignItemCreative', params: { campaignId: item.campaignId, campaignItemId: item.id}}"
                    >
                                <span v-if="item.productNativeFormat === false && item.productActive">
                                    <t-button variant="editCircle" class="ml-2" :title="$t(item.title)">
                                        <c-icon class="w-3.5 h-3.5" :icon="item.creativeIcon"></c-icon>
                                    </t-button>
                                </span>
                    </router-link>
                    <a
                        v-if="isRetailer && item.sasInfo && item.sasInfo.zipCreativeURL"
                        class="cursor-pointer mr-4"
                        :href="item.sasInfo.zipCreativeURL"
                        target="_blank"
                    >
                        <t-button variant="editCircle" class="ml-2" :title="$t('common_download_zip')">
                            <c-icon class="w-3.5 h-3.5" icon="zip"></c-icon>
                        </t-button>
                    </a>
                </div>
            </template>
            <template v-slot:actions="item">
                <div class="flex justify-end space-x-2">
                    <circle-icon-button
                        v-if="['online', 'paused'].indexOf(item.itemState.identifier) >= 0 && isRetailer"
                        :icon="item.itemState.identifier === 'online' ? 'pause' : 'arrow-full-right'"
                        :icon-color="item.itemState.identifier === 'online' ? 'red' : 'blue'"
                        @click="changeOnlinePause(item.id)"
                        :title="item.itemState.identifier === 'online' ? $t('common_pause') : $t('common_run')"
                    />
                    <circle-icon-button
                        v-if="campaignItemEditable(item)"
                        icon="pen-full"
                        :title="$t('common_edit')"
                        :to="{name: 'editCampaignActivity', params: {campaignId: item.campaignId, campaignItemId: item.id}}"
                    />
                    <circle-icon-button
                        icon="eye"
                        :title="$t('common_show')"
                        :to="{name: 'campaignItemDetail', params: {campaignId: item.campaignId, campaignItemId: item.id}}"
                    />
                </div>
            </template>
        </dynamic-table>

        <div
            class="flex flex-col md:flex-row justify-between mt-5"
            data-cy="pagination"
        >
            <page-items-filter v-model="maxResults" />
            <page-picker
                v-model="firstResult"
                :total-count="totalCount"
                :page-size="maxResults"
                :className="customClass"
            ></page-picker>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import SimpleBreadcrumb from "@/components/SimpleBreadcrumb";
import CIcon from "@/components/base/icons/CIcon.vue";
import DynamicTable from "@/components/universal/dynamic-table/DynamicTable.vue";
import DynamicTableColumn from "@/components/universal/dynamic-table/DynamicTableColumn.vue";
import PagePicker from "@/components/PagePicker.vue";
import CampaignItemsFilter from "@/components/campaign-item/CampaignItemsFilter.vue";
import CampaignItemState from "@/components/CampaignItemState.vue";
import {CreativeStates} from "@/utils/CreativeState";
import ActivityActualPerformance from "@/components/ActivityActualPerformance.vue";
import CampaignState from "@/components/CampaignState.vue";
import PageItemsFilter from "@/components/PageItemsFilter.vue";
import {getRateTypeName, getRateTypeNameShort} from "../utils/RateType";
import CircleIconButton from "@/components/base/buttons/CircleIconButton.vue";
import {CanceledError} from "@/errors/api";
import {debounce} from "lodash";


export default {
    name: "CampaignItemsListView",
    metaTags() {
        return {
            title:  this.$t('view_campaignList_metaTitle')  + ' - ' + this.$t('common_meta_title'),
        }
    },
	components: {
        CircleIconButton,
        CampaignState,
        ActivityActualPerformance,
        CampaignItemState,
        CampaignItemsFilter,
        PagePicker,
        DynamicTableColumn,
        DynamicTable,
        CIcon,
        SimpleBreadcrumb,
        PageItemsFilter,
    },
    data: () => {
        return {
            loadingData: false,
            showFilter: false,
            showSortingFilter: false,
            customClass: 'main__pagePicker',
            displayPerformance: true,
        };
    },
    computed: {
        ...mapGetters('auth', ['locale', 'isRetailer', 'isVendor', 'isRetailerOrImpersonated']),
        ...mapGetters('appSettings', ['displayOfPerformanceMetrics']),
        breadcrumbs() {
            return [];
        },
        campaignItems() {
            return this.$store.getters['campaignItemsList/listData'].items;
        },
        totalCount() {
            return this.$store.getters['campaignItemsList/listData'].total;
        },
        extendedCampaignItems() {
            return this.campaignItems.map(item => {
                let creativeIcon = 'edit-pencil';
                let title = 'common_edit';
                if (item.itemState.identifier === 'missing' || !item.creative) {
                    creativeIcon = 'plus';
                    title = 'common_add';
                } else if (item.itemState.identifier === 'closed') {
                    creativeIcon = 'eye';
                    title = 'common_show';
                } else if (item.creativeState === CreativeStates.Approved) {
                    creativeIcon =  'eye';
                    title = 'common_show';
                }
                item.displayPerformance = this.displayOfPerformanceMetrics
                    && (item.actualClicks ?? item.actualImpressions ?? item.actualViews ?? false)
                    && this.displayPerformance;

                return {
                    ...item,
                    creativeIcon,
                    title,
                }
            })
        },
        firstResult: {
            get() {
                return this.$store.getters['campaignItemsList/filter'].firstResult;
            },
            set(value) {
                this.$store.dispatch('campaignItemsList/setFilterFirstResult', value);
            }
        },
        maxResultsStorageKey() {
            return `max-results-${this.$route.name}`;
        },
        maxResults: {
            get() {
                const initialMaxResults = this.$store.getters['campaignItemsList/filter'].maxResults;
                const storedMaxResultsValue = localStorage.getItem(this.maxResultsStorageKey);
                return storedMaxResultsValue
                    ? +storedMaxResultsValue
                    : initialMaxResults;
            },
            set(value) {
                localStorage.setItem(this.maxResultsStorageKey, value);
                this.$store.dispatch('campaignItemsList/setFilterMaxResults', value);
            },
        },
        filter: {
            get() {
                return this.$store.getters['campaignItemsList/filter'];
            },
            set(value) {
                this.$store.dispatch('campaignItemsList/setFilter', value);
            }
        },
        filterSorting: {
            get() {
                return this.$store.getters['campaignItemsList/filter'].sort;
            },
            set(sort) {
                this.$store.dispatch('campaignItemsList/setFilterSort', sort);
            }
        },
        performanceBttnText() {
            return this.displayPerformance 
                ? this.$t('common_hide_performance') 
                : this.$t('common_display_performance');
        },
        isPerformanceButtonVisible(){
            const campaignItemsWithPerformanceMetrics = this.extendedCampaignItems.filter(campaignItem => {
                return campaignItem.actualClicks 
                    ?? campaignItem.actualImpressions 
                    ?? campaignItem.actualViews 
                    ?? false;
            });
            
            return this.displayOfPerformanceMetrics && campaignItemsWithPerformanceMetrics.length > 0;
        },
    },
    watch: {
        filter: {
            handler: debounce(async function() {
                await this.loadData();
            }, 500),
            deep: true
        },
        async filterSort() {
            await this.loadData();
        },
        async locale() {
            await this.loadData();
        }
    },
    methods: {
        getRateTypeName,
        getRateTypeNameShort,
        async loadData() {
            this.loadingData = true;
            let filter = this.$store.getters['campaignItemsList/filter'];
            try {
                await this.$store.dispatch('campaignItemsList/filterItems', {
                    ...filter,
                    maxResults: this.maxResults,
                })
            } catch (e) {
                if(e instanceof CanceledError) {
                    return
                }
                throw e;
            }
            this.loadingData = false;
        },
        async clearFilter() {
            await this.$store.dispatch('campaignItemsList/clearFilter');
            await this.loadData();
        },
        async changeOnlinePause(id) {
            try {
                let campaignItem =  await this.$store.dispatch("campaigns/changeOnlinePause", {campaignItemId: id});
                this.stateIdentifier = campaignItem.itemState.identifier;
            } catch (e) {
                //console.log(e);
            }
        },
        campaignItemEditable(campaignItem) {
            var editableAsRetailer = this.isRetailerOrImpersonated && ['approved'].indexOf(campaignItem.itemState.identifier) >= 0;
            return (this.campaignEditable && campaignItem.editable) || editableAsRetailer;
        },
        toggleDisplayPerformance() {
            this.displayPerformance = !this.displayPerformance;
        },
	},
    beforeRouteEnter(from, to, next) {
        return next(async (vm) => {
            await vm.loadData();
        })
    },
    async beforeRouteUpdate() {
        await this.loadData();
    },
};
</script>
