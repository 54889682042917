<template>
    <div class="container p-3">
        <div ref="detail">
            <p v-html="perex">
            </p>

            <p v-if="isSetText && expanded"
                 v-html="text"
                class="mt-3">
            </p>
        </div>

        <button
            v-if="isSetText && !expanded"
            class="text-center text-blue-400 mt-6"
            @click="onClick"
        >
            {{ moreText }}
            <arrow-icon :isOpen="false" class="!mx-auto !bg-transparent -mt-1.5" />
        </button>
        <button
            v-if="isSetText && expanded"
            class="text-center text-blue-400 mt-6 mb-6"
            @click="onClick"
        >
            <arrow-icon :isOpen="true" class="!mx-auto !bg-transparent -mb-1.5" />
            {{ lessText }}
        </button>
    </div>
</template>

<script>
import ArrowIcon from "@/components/icons/ArrowIcon";

export default {
    name: 'ShowMoreText',
    props: {
        perex: {
            type: String,
            default: '',
        },
        text: {
            type: String,
            default: '',
        },
        moreText: {
            type: String,
            default: 'Show more',
        },
        lessText: {
            type: String,
            default: 'Show less',
        },
    },
    components: {
        ArrowIcon,
	},
    data() {
        return {
            expanded: false,
        }
    },
    computed: {
        isSetText: function () {
            return this.text.trim().length > 0;
        }
    },
    methods: {
        onClick() {
            this.expanded = !this.expanded
            this.$emit('click', this.expanded);
        },
    },
}
</script>
