<template>
	<div class="max-w-screen-2xl pt-1 pb-8 px-4 md:px-8 mx-auto">
        <simple-breadcrumb v-if="breadcrumbs.length" :items="breadcrumbs" data-cy="breadcrumb"></simple-breadcrumb>
		<header class="flex flex-wrap items-center justify-between my-5 ">

            <campaigns-filter
                :show-filter="showFilter"
                @close="newVal => showFilter = newVal"
                :filter-data="filterData"
                v-model="filter"
                @clearFilter="clearFilter"
                data-cy="campaigns-filter"
            >
            </campaigns-filter>

            <!-- Mobile table sorting -->
			<div class="relative lg:hidden order-2"
				:class="showFilter ? 'z-10' : 'z-20'">
				<button
                    class="flex items-center justify-between bg-blue-50 hover:bg-blue-100 hover:border-blue-100 text-white text-lg rounded-full p-4 relative z-30"
					@click="showSortingFilter = !showSortingFilter"
				>
					<!-- {{ $t('common_sort') }} -->
					<svg-icon name="ico-sort" class="w-4 h-4"></svg-icon>
				</button>

				<base-dropdown v-model="showSortingFilter" class="absolute -top-4 lg:top-full pt-24 pb-5 lg:right-0 -right-4 px-8 lg:py-5 bg-white shadow-lg rounded-b-xl w-72 lg:w-full rounded-xl lg:rounded-none z-20" data-cy="sorting-dropdown">
					<div class="space-y-2">
                        <table-sort-heading
                            :name="$t('view_campaignList_campaignName')"
                            property-name="name"
                            v-model="filterSort"
                            sort-type="mobile"
                        ></table-sort-heading>
                        <table-sort-heading
                            v-if="isRetailerOrAgency"
                            :name="$t('common_advertiserCompany')"
                            property-name="company"
                            v-model="filterSort"
                            sort-type="mobile"
                        ></table-sort-heading>
                        <table-sort-heading
                            v-if="isRetailerOrAgency"
                            :name="$t('common_salesPerson')"
                            property-name="salesPerson"
                            v-model="filterSort"
                            sort-type="mobile"
                        ></table-sort-heading>
                        <table-sort-heading
                            :name="$t('common_state')"
                            property-name="state"
                            v-model="filterSort"
                            sort-type="mobile"
                        ></table-sort-heading>
                        <table-sort-heading
                            :name="$t('common_beginning')"
                            property-name="startDate"
                            v-model="filterSort"
                            sort-type="mobile"
                        ></table-sort-heading>
                        <table-sort-heading
                            :name="$t('common_ending')"
                            property-name="endDate"
                            v-model="filterSort"
                            sort-type="mobile"
                        ></table-sort-heading>
                        <table-sort-heading
                            :name="$t('view_campaignList_campaignPrice')"
                            property-name="price"
                            v-model="filterSort"
                            sort-type="mobile"
                        ></table-sort-heading>
					</div>
				</base-dropdown>
			</div>

            <!-- New campaign button -->
            <div class="flex flex-wrap justify-end items-center lg:mb-0 lg:w-1/4" data-cy="new-campaign-button">
                <t-button @click="showCreateCampaignDialog = true" variant="bigGreen" class="flex items-center relative group pl-6">
                    <div class="flex bg-green-200 rounded-full p-1.5 mr-3 text-black">
                        <c-icon class="w-3.5 h-3.5" icon="plus"></c-icon>
                    </div>
                    <span>{{ $t('view_campaignList_buttonAddCampaign') }}</span>
                </t-button>
            </div>
		</header>
		<div class="px-4 pb-2 lg:grid grid-cols-15 hidden gap-x-2 text-gray-500" :class="showFilter ? 'xl:max-w-[calc(100%-360px)] mr-0 ml-auto' : ''"  data-cy="table-sort-headings">
            <!-- Název kampaně -->
            <table-sort-heading
                :name="$t('view_campaignList_campaignName')"
                property-name="name"
                v-model="filterSort"
                class="hover:text-blue-400 col-span-2"
            ></table-sort-heading>
            <!-- Vendor -->
            <table-sort-heading
                v-if="isRetailerOrAgency"
                :name="$t('common_advertiserCompany')"
                property-name="company"
                v-model="filterSort"
                class="hover:text-blue-400 col-start-5 col-end-7"
            ></table-sort-heading>

            <table-sort-heading
                v-if="isRetailerOrAgency"
                :name="$t('common_salesPerson')"
                property-name="salesPerson"
                v-model="filterSort"
                class="hover:text-blue-400 col-start-7 col-end-9"
            ></table-sort-heading>
            <!-- Stav -->

            <div class="col-start-9 col-end-13 grid grid-cols-12">
                <table-sort-heading
                    :name="$t('common_state')"
                    property-name="state"
                    v-model="filterSort"
                    class="col-span-6 hover:text-blue-400"
                    childClass="justify-start"
                ></table-sort-heading>
                <!-- Začátek -->
                <table-sort-heading
                    :name="$t('common_beginning')"
                    property-name="startDate"
                    v-model="filterSort"
                    class="hover:text-blue-400 col-span-3"
                    childClass="justify-end"
                ></table-sort-heading>
                <!-- Konec -->
                <table-sort-heading
                    :name="$t('common_ending')"
                    property-name="endDate"
                    v-model="filterSort"
                    class="hover:text-blue-400 col-span-3"
                ></table-sort-heading>
            </div>
            <div class="col-start-13 col-end-14 grid grid-cols-12 col-span-3">
                <!-- Cena Kampaně -->
                <table-sort-heading
                    :name="$t('view_campaignList_campaignPrice')"
                    property-name="price"
                    v-model="filterSort"
                    class="col-span-9 hover:text-blue-400"
                    childClass="justify-end col-start-11 col-end-14"
                ></table-sort-heading>
            </div>
		</div>

        <loading-box v-model="loadingData" min-height="12rem" theme="light" :class="{'xl:max-w-[calc(100%-360px)] ml-auto mr-0' : showFilter}" data-cy="loading-box">
            <campaign-list-item
                v-for="campaign in campaigns"
                :key="campaign.id"
                :campaign="campaign"
                :filter-active="showFilter"
            />

            <div
                v-if="campaigns.length == 0 && !loadingData" class="w-full py-1.5 text-xl"
                data-cy="no-matching-campaigns"
            >
                <t-card variant="campaign">
                    <div class="py-4 text-base text-center text-gray-400">{{ $t('view_campaignList_notMatchFilter') }}</div>
                </t-card>
            </div>

            <div
                v-if="campaigns.length > 0 && !loadingData"
                class="flex flex-col mt-4 py-2 w-full items-start lg:flex-nowrap flex-wrap space-y-4"
                data-cy="campaign-summary"
            >
                <div class="text-2xl lg:w-auto w-full">
                    {{ $t('view_campaignList_campaignTotalPrice') }}
                    <b>{{ $n(totalPrice, "currency") }}</b>
                </div>
            </div>
        </loading-box>

        <div
            class="flex flex-col md:flex-row justify-between mt-5"
            data-cy="pagination"
        >
            <page-items-filter v-model="maxResults" />
            <page-picker
                v-model="firstResult"
                :total-count="totalCount"
                :page-size="maxResults"
                :className="customClass"
            ></page-picker>
        </div>

        <create-campaign-dialog v-model="showCreateCampaignDialog" />
    </div>
</template>

<script>
import CIcon from "@/components/base/icons/CIcon.vue";
import SvgIcon from "@/components/SvgIcon";
import CreateCampaignDialog from "@/components/campaign/CreateCampaignDialog";
import CampaignListItem from "@/components/CampaignListItem";
import LoadingBox from "@/components/LoadingBox";
import TableSortHeading from "@/components/TableSortHeading";
import PagePicker from "@/components/PagePicker";
import {mapGetters} from "vuex";
import SimpleBreadcrumb from "@/components/SimpleBreadcrumb";
import CampaignsFilter from "@/components/campaign/CampaignsFilter.vue";
import BaseDropdown from "@/components/base/BaseDropdown.vue";
import PageItemsFilter from "@/components/PageItemsFilter.vue";

export default {
    name: "CampaignListView",
    metaTags() {
        return {
            title:  this.$t('view_campaignList_metaTitle')  + ' - ' + this.$t('common_meta_title'),
        };
    },
    components: {
        BaseDropdown,
        CampaignsFilter,
        PagePicker,
        TableSortHeading,
        LoadingBox,
        CampaignListItem,
        SvgIcon,
        CreateCampaignDialog,
        SimpleBreadcrumb,
        CIcon,
        PageItemsFilter,
    },
    data: () => {
        return {
            showFilter: false,
            showSortingFilter: false,
            showCreateCampaignDialog: false,
            loadingData: false,
			showAddedCreative: false,
            companies: [],
            filterRequestNumber: 0,
            customClass: "main__pagePicker",
        };
    },
    computed: {
        ...mapGetters('auth', ['locale', 'isRetailer']),
        breadcrumbs() {
            return [];
        },
        campaigns() {
            return this.$store.getters['campaigns/listData'].campaigns;
        },
        totalCount() {
            return this.$store.getters['campaigns/listData'].total;
        },
        totalPrice() {
            return this.$store.getters['campaigns/listData'].totalPrice;
        },
        isRetailerOrAgency() {
            return this.$store.getters['auth/userRoles'].indexOf('Retailer') >= 0 || this.$store.getters['auth/userRoles'].indexOf('Agency') >= 0;
        },
        filterStatesStorageKey() {
            return `filter-results-${this.$route.name}`;
        },
        filter: {
            get() {
                const storedFilterResultsValue = localStorage.getItem(this.filterStatesStorageKey) 
                    ? JSON.parse(localStorage.getItem(this.filterStatesStorageKey))
                    : null;

                if (storedFilterResultsValue) {
                    this.$store.dispatch('campaigns/setFilter', { filter: storedFilterResultsValue });
                }

                return this.$store.getters['campaigns/filter'];
            },
            set(filter) {
                localStorage.setItem(this.filterStatesStorageKey, JSON.stringify(filter));
                this.$store.dispatch('campaigns/setFilter', {filter: filter});
            }
        },
        filterSort: {
            get() {
                return this.$store.getters['campaigns/filter'].sort;
            },
            set(sort) {
                this.$store.dispatch('campaigns/setFilterSort', {sort: sort});
            }
        },
        filterData() {
            return this.$store.getters['campaigns/filterData'];
        },
        firstResult: {
            get() {
                return this.$store.getters['campaigns/filter'].offset;
            },
            set(value) {
                this.$store.dispatch('campaigns/setFilterFirstResult', {firstResult: value});
            }
        },
        maxResultsStorageKey() {
            return `max-results-${this.$route.name}`;
        },
        maxResults: {
            get() {
                const initialMaxResults = this.$store.getters['campaigns/filter'].limit;
                const storedMaxResultsValue = localStorage.getItem(this.maxResultsStorageKey);
                return storedMaxResultsValue
                    ? +storedMaxResultsValue
                    : initialMaxResults;
            },
            set(value) {
                localStorage.setItem(this.maxResultsStorageKey, value);
                this.$store.dispatch('campaigns/setFilterMaxResults', { maxResults: value });
            },
        },
    },
    watch: {
        filter:{
            handler() {
                this.loadData();
            },
            deep: true
        },
        filterSort() {
            this.loadData();
        },
        async locale() {
            await this.$store.dispatch('campaigns/getFilterData');
            await this.loadData();
        }
    },
    methods: {
        async loadData() {
            this.loadingData = true;
            const filter = this.filter;
            await this.$store.dispatch('campaigns/filterCampaigns', {
                ...filter,
                limit: this.maxResults,
            })
            this.loadingData = false;
        },
        async clearFilter() {
            localStorage.removeItem(this.filterStatesStorageKey);
            await this.$store.dispatch('campaigns/clearFilter');
            await this.loadData();
        }
    },
    filters: {
        truncate: function (text, length, suffix) {
            if (text.length > length) {
                return text.substring(0, length) + suffix;
            } else {
                return text;
            }
        },
    },
    beforeRouteEnter(from, to, next) {
        return next(async (vm) => {
            await vm.loadData();
            await vm.$store.dispatch('campaigns/getFilterData');
        })
    },
    async beforeRouteUpdate() {
        await this.loadData();
    },
};
</script>
