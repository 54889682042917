<template>
    <div class="flex justify-start items-center">
        <span class="w-4 h-4 rounded-full mr-2 flex-shrink-0" :class="stateColor"></span>
        <span class="uppercase whitespace-nowrap text-xs text-black">{{ stateName }}</span>
    </div>
</template>

<script>
import {getCreativeStateColor, getCreativeStateName} from "@/utils/CreativeState";

export default {
    name: "CreativeState",
    props: {
        state: {
            type: String,
            required: true
        }
    },
    computed: {
        stateName() {
            return getCreativeStateName(this.state);
        },
        stateColor() {
            return getCreativeStateColor(this.state);
        }
    }
}
</script>

<style scoped>

</style>
