<template>
	<t-card class="max-w-lg w-full m-auto">
		<div class="text-center">
			<h1 class="login__title">404</h1>
			<p>{{ $t('view_notFound_description') }}</p>
		</div>
	</t-card>
</template>

<script>
export default {
	name: "NotFoundView",
    metaTags() {
        return {
            title:  this.$t('view_notFound_description') + ' - ' + this.$t('common_meta_title'),
        }
    },
	data: () => {
		return {};
	},
};
</script>

<style scoped></style>
