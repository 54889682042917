<template>
    <div class="bg-gray-50 rounded-lg p-4">
        <div class="flex justify-between items-center cursor-pointer"
             @click="open = !open"
        >
            <p class="text-gray-500">{{ label }}</p>
            <slot name="right">
            </slot>
            <arrow-icon :isOpen="open" /> <!-- class="w-9 h-9" pro zvětšení ikony -->
        </div>

        <div :class="{'hidden': !open}" class="mt-4"> <!-- {'hidden': !open} needed for components in slot be referencable even if dropdown closed -->
            <slot></slot>
        </div>
    </div>
</template>

<script>
import ArrowIcon from "@/components/icons/ArrowIcon";
export default {
    name: "FilterDropdown",
    components: {ArrowIcon},
    props: {
        label: {
            type: String
        }
    },
    data() {
        return {
            open: false
        };
    },
}
</script>

<style scoped>

</style>
