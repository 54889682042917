<template>
    <div class="max-w-screen-2xl py-8 px:4 md:p-16 mx-auto space-y-6">
        <h1 class="h1 text-purple-100 mb-8">{{ $t('common_availability') }}</h1>
        <div :key="productAvailability.product.id" v-for="productAvailability in productsAvailability" class="md:py-6 py-4">
            <div class="flex items-center mb-6">
                <h2 class="h2 ml-3">{{ productAvailability.product.name }}</h2>
            </div>

            <product-availabilities-table
                :productAvailability="productAvailability"
            />
        </div>
    </div>
</template>

<script>
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import ProductAvailabilitiesTable from "../components/ProductAvailabilitiesTable";

export default {
    name: "ProductAvailabilityView",
    metaTags() {
        return {
            title:  this.$t('view_ProductAvailabilityView_metaTitle') + ' - ' + this.$t('common_meta_title'),
        }
    },
    components: {ProductAvailabilitiesTable},
    data: () => {
        return {
            productsAvailability: [],
            settings: {
                arrows: false,
                dots: false,
                responsive: [
                    {
                        breakpoint: 8000,
                        settings: "unslick",
                    },
                    {
                        breakpoint: 769,
                        settings: {
                            arrows: false,
                            dots: false,
                            responsive: [
                                {
                                    breakpoint: 8000,
                                    settings: "unslick",
                                },
                                {
                                    breakpoint: 769,
                                    settings: {
                                        slidesToShow: 2,
                                        slidesToScroll: 1,
                                        centerMode: true,
                                        rows: 1,
                                    },
                                },
                                {
                                    breakpoint: 620,
                                    settings: {
                                        centerMode: true,
                                        slidesToShow: 1,
                                        rows: 1,
                                    },
                                },
                            ],
                        },
                    },
                    {
                        breakpoint: 620,
                        settings: {
                            centerMode: true,
                            slidesToShow: 1,
                            rows: 1,
                        },
                    },
                ],
            },
        };
    },
    methods: {
        async loadData() {
            this.productsAvailability = await this.$store.dispatch('products/getFlatRateProductsWithAvailabilities');
        }
    },
    async beforeRouteEnter(to, from, next) {
        await next(async (vm) => {
            await vm.loadData();
        });
    },
    async beforeRouteUpdate() {
        await this.loadData();
    },
};
</script>
