<template>
    <t-modal v-model="show" v-if="campaignItem">
        <div class="md:p-20 p-8">
            <div class="max-w-2xl">
                <h1 class="text-blue-400 h1 mb-5 pr-5">
                    {{ campaignItem.generatedName || campaignItem.product.name }} - media
                </h1>

                <div class="flex flex-wrap space-y-2 -mx-8">
                    <div
                        v-for="(creativeTemplate, index) in campaignItem.itemMedia"
                        :key="creativeTemplate.id"
                        class="w-full bg-gray-50 rounded-2xl p-6"
                    >
                        <campaign-item-media-preview

                            :last="index === (campaignItem.itemMedia.length - 1)"
                            :creative-template="creativeTemplate"
                            :campaign-item="campaignItem"
                        ></campaign-item-media-preview>
                    </div>
                </div>

            </div>

            <form
                v-if="enableApprovalByRetailer"
                action
                class="mt-8 w-full"
                @submit.prevent="submit"
            >
                <div
                    class="p-8 bg-gray-50 rounded-2xl"
                    :class="{'pt-0' : isRetailer}"
                >
                    <label for="" class="text-gray-600 mb-3 block">
                        {{$t('common_note')}}
                    </label>
                    <t-input
                        variant=""
                        class=""
                        v-model="message"
                        :disabled="disableNote"
                        clearable
                    >
                    </t-input>
                </div>
                <div class="flex flex-wrap items-center justify-end space-x-2 mt-8">
                    <t-button class="border-gray-400 !text-gray-400 mr-6"
                              :disabled="disableDismissButton"
                              type="submit"
                              :variant="disableDismissButton ? 'link': 'link'"
                    >
                        {{ $t('common_dismiss') }}
                    </t-button>
                    <t-button class="sm:my-0 my-4 flex items-center"
                              :disabled="disableApproveButton"
                              type="submit"
                              :variant="disableApproveButton ? 'bigGray': 'bigGreen'"
                    >

                        <div v-if="!disableApproveButton" class="flex bg-green-200 rounded-full p-1.5 mr-3 text-black">
                            <c-icon class="w-3.5 h-3.5" icon="check"></c-icon>
                        </div>


                        {{ $t('common_approve') }}
                    </t-button>
                </div>
            </form>
            <div
                v-if="!isCreativeFilled"
            >
                {{ $t('component_campaignItemMediaDialog_creativeNotFilled') }}
            </div>
        </div>


        <template v-slot:button>
            <div class="w-8 h-8 bg-blue-100 rounded-full flex">
                <svg-icon name="x" class="w-3 h-3 m-auto"></svg-icon>
            </div>
        </template>
    </t-modal>
</template>

<script>
import SvgIcon from "@/components/SvgIcon";
import CIcon from "@/components/base/icons/CIcon.vue";
import CampaignItemMediaPreview from "@/components/CampaignItemMediaPreview.vue";
import {mapGetters} from "vuex";

export default {
    name: "CampaignItemMediaDetailDialog",
    components: {
        CampaignItemMediaPreview,
        SvgIcon,
        CIcon,
    },
    props: {
        value: {},
        campaignItem: {},
    },
    data() {
        return {
            submitting: false,
            noteStorage: '',
            uploading: false,
            sending: false,
        }
    },
    computed: {
        ...mapGetters('auth', ['isRetailer']),
        message: {
            get() {
                return this.noteStorage;
            },
            set(value) {
                this.noteStorage = value;
            }
        },
        enableApprovalByRetailer() {
            // retailer or admin logged as retailer
            return this.isCreativeFilled && this.isRetailer;
        },
        isCreativeFilled() {
            return !!this.campaignItem.creative;
        },
        show: {
            get() {
                return this.value;
            },
            set(value) {
                if(this.value !== value) {
                    this.$emit('input', value);
                }
            }
        },
        disableDismissButton() {
            return !this.isRetailer || this.noteStorage.length === 0 || this.submitting;
            // return this.campaignName.length === 0 || this.submitting || (this.isRetailer && !this.companyId);
        },
        disableApproveButton() {
            return !this.isRetailer || this.noteStorage.length !== 0 || this.submitting;
            // return this.campaignName.length === 0 || this.submitting || (this.isRetailer && !this.companyId);
        },
        disableNote() {
            return !this.isRetailer || this.campaignItem.state === null ;
        },
    },
    watch: {
        show(newParameter) {
            if(newParameter) {
                this.loadData();
            }
        }
    },
    methods: {
        async loadData() {
            this.noteStorage = this.campaignItem.message;
        },
        // nefunguje submit z tlacitka
        async submit() {
            this.submitting = true;
            await this.$store.dispatch("campaigns/updateCampaignItemMessage", {
                campaignItemId: this.campaignItem.id,
                message: this.message
            });
            this.submitting = false;
            this.show = false;
            // await this.$router.push({name: 'campaignDetail', params: {campaignId: this.campaignItem.campaignId}});

        },

        changed() {
            this.$emit('change');
        },
        created() {
            this.loadData();
        },

    }
}
</script>

<style scoped>

</style>
