<template>
    <svg class="icon" v-bind="$attrs">
        <use :xlink:href="`#${icon}`" />
    </svg>
</template>

<script>
export default {
    name: 'CIcon',
    props: {
        icon: {
            type: String,
            required: true,
        },
    },
}
</script>

<style>
svg.icon {
    fill: currentColor;
}
svg.icon use {
    fill: currentColor;
}
</style>
