<template>
    <div>
        <span class="inline-block mb-2 font-medium">
            {{ $t('component_UserAgencyVendorsList_selected_users') }}
        </span>
        <t-rich-select
            ref="select"
            multiple
            v-model="valueChange"
            :fetch-options="fetchOptions"
            text-attribute="name"
            value-attribute="id"
            :placeholder="$t('component_UserAgencyVendorsList_placeholder')"
            :searchBoxPlaceholder="$t('component_UserAgencyVendorsList_search_placeholder')"
            :noResultsText="$t('component_userSalesPersonList_not_found')"
            :searchingText="$t('common_searching')"
        ></t-rich-select>
    </div>
</template>

<script>


import axios from "@/plugins/axios";

export default {
    name: "UserAgencyCompaniesList",
    components: {},
    props: {
        "value" : Array,
        "user": Object
    },
    data: () => {
        return {
            agencyVendorCompanies: []
        };
    },
    computed: {
        valueChange: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value)

            }
        }
    },
    watch: {
        user(newValue) {
           this.setAgencyVendors(newValue);
        }
    },
    methods: {
        async fetchOptions(name) {
            const response = await axios.get('/companies/search', {params: {name}});
            return ({results: response.data});
        },
        setAgencyVendors(user) {
            const _this = this;
            this.$nextTick(() => {
                if (user.agencyVendorCompanies.length > 0) {
                    const items = user.agencyVendorCompanies || [];

                    _this.agencyVendorCompanies = items.map(user => user.id);
                    _this.$refs.select.selectedOptions = _this.$refs.select.normalizeOptions(items);
                }
            });
        }
    },
    created() {
        this.setAgencyVendors(this.user);
    },
};
</script>
