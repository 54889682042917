<template>
    <div class="shrink basis-full grow-0 max-w-[610px]">
        <t-rich-select
            ref="select"
            class="w-full"
            v-model="landingPage"
            :fetch-options="fetchLandingPages"
            value-attribute="value"
            text-attribute="name"
        >
        </t-rich-select>
    </div>
</template>

<script>

export default {
    name: "TargetLandingPageInputPicker",
    props: {
        value: {
            type: Object,
            default: null
        },
        targetId: { // target web, where
            type: Number,
            required: true,
        },
        companyId: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
        }
    },
    computed: {
        placeholder() {
            // if (this.nativeFormat) {
            //     return this.$t('component_urlInputPicker_is_required')
            // }
            return ""
        },
        error() {
            // if (!this.targetProduct && this.nativeFormat && this.value && this.value.length > 0) {
            //     return this.$t('component_urlInputPicker_product_name_is_required')
            // }
            return '';
        },
        landingPage: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value)
            }
        }
    },
    watch: {
        error(value) {
            this.$emit('error', !!value);
        },
    },
    methods: {
        async fetchLandingPages(query) {
            const items = await this.$store.dispatch(
                'landingPages/searchLandingPage',
                {targetId: this.targetId, companyId: this.companyId, search: query}
            );
            const _this = this;
            const options = items.map(function(item) { return _this.formatSelectOption(item) });
            return ({results: options});
        },
        formatSelectOption(item) {
            return {
                value: item,
                active : item.active,
                name: item.name
            };
        },
        fixData(value) {
            value = value || this.value;

            const _this = this;
            this.$nextTick(async () => {
                if (value) {
                    _this.$refs.select.selectedOptions = _this.$refs.select.normalizeOptions(
                        [_this.formatSelectOption(value)]
                    );
                }
            });
        }
    },
    async created() {
        this.fixData(this.value);
    },
}
</script>

<style scoped>

</style>
