import axios from "@/plugins/axios";


export default {
    namespaced: true,
    state: {
        listFilter: { // filter for creative format list view
            maxResults: 20,
            firstResult: 0,
            search: '',
        },
    },
    getters: {
        listFilter(state) {
            return state.listFilter;
        }
    },
    mutations: {
        CLEAR_LIST_FILTER(state) {
            state.filter = {
                maxResults: 10,
                firstResult: 0,
                search: '',
            };
        },
        SET_LIST_FILTER(state, filter) {
            state.filter = filter;
        },
    },
    actions: {
        async filterCreativeFormats(context, {maxResults, firstResult, search}) {
            const filterData = new URLSearchParams();
            filterData.append('maxResults', maxResults);
            filterData.append('firstResult', firstResult);
            if (search) {
                filterData.append('search', search);
            }
            const response = await axios.get('/creatives/formats/filter', { params: filterData });
            return response.data;
        },
        setFilterFirstResult(context, firstResult) {
            let filter = context.state.listFilter;
            filter.firstResult = firstResult;
            context.commit('SET_LIST_FILTER', filter);
        },
        setFilterSearch(context, search) {
            let filter = context.state.listFilter;
            filter.search = search;
            context.commit('SET_LIST_FILTER', filter);
        },
        clearFilter(context, {firstResult}) {
            let filter = context.state.listFilter;
            filter.firstResult = firstResult;
            context.commit('CLEAR_LIST_FILTER', filter);
        },
        async getCreativeFormat(context, {creativeFormatId}) {
            const response = await axios.get('/creatives/formats/'+ creativeFormatId);
            return response.data;
        },
        async updateCreativeFormat(context, { creativeFormat }) {
            await axios.put(`/creatives/formats/${creativeFormat.id}`, creativeFormat);
        },
        async getCreativeFormatFieldRestrictions(context, {creativeFormatId, creativeFormatFieldId, type}) {
            const url = '/creatives/formats/'+ creativeFormatId + '/fields/' + creativeFormatFieldId + '/restrictions'
            const queryData = new URLSearchParams();
            if (type){
                queryData.append('type', type)
            }
            const response = await axios.get(url, {params: queryData});
            return response.data;
        },
    }
}
