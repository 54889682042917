import axios from "@/plugins/axios";

const pathPrefix = '/settings';

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        async getAllVariableGroups() {
            const response = await axios.get(pathPrefix +'/variable-groups');
            return response.data;
        },
        async updateVariable(context, {id, value}) {
            const response = await axios.put(
                pathPrefix +'/variables/' + id,
                {
                    id: id,
                    value: value
                }
            );
            await  context.dispatch('appSettings/initAppSettings', null, {root: true});
            return response.data;
        },
    }
};
