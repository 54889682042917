import axios from "@/plugins/axios";

function createDefaultFilter() {
    return {
        sort: null,

        firstResult: 0,
        maxResults: 10
    }
}

export default {
    namespaced: true,
    state: {
        filterData: null,
        filter: createDefaultFilter(),
    },
    getters: {
        filterData: function (state) {
            if (state.filterData) {
                return state.filterData;
            }
            return null;
        },
        filter: function (state) {
            return state.filter;
        },
    },
    mutations: {
        SET_FILTER_DATA(state, filterData) {
            state.filterData = filterData;
        },
        CLEAR_FILTER(state) {
            state.filter = createDefaultFilter();
        },
        SET_FILTER(state, filter) {
            state.filter = filter;
        },
    },
    actions: {
        async filter(context, filter) {
            const data = {
                firstResult: filter.firstResult,
                lastResult: filter.lastResult,
                maxResults: filter.maxResults,
            };

            if (filter.sort) {
                data.order = [filter.sort]
            }
            const response = await axios.post('/landing-pages/filter', data);
            return response.data;
        },
        async reloadFilterData({commit}) {
            const response = await axios.get("/landing-pages/filter-data");
            if (response) {
                commit("SET_FILTER_DATA", response.data);
                return response.data;
            }
        },
        setFilter(context, filter) {
            context.commit('SET_FILTER', filter);
        },
        async setFilterSort(context, sort) {
            let filter = context.state.filter;
            filter.sort = sort;
            context.commit('SET_FILTER', filter);
        },
        setFilterFirstResult(context, firstResult) {
            let filter = context.state.filter;
            filter.firstResult = firstResult;
            context.commit('SET_FILTER', filter);
        },
        setFilterMaxResults(context, maxResults) {
            let filter = context.state.filter;
            filter.maxResults = maxResults;
            filter.firstResult = 0;
            context.commit('SET_FILTER', filter);
        },
        clearFilter(context) {
            context.commit('CLEAR_FILTER');
        },
    }
};
