export default {
    Approved: "approved",
    Canceled: "canceled",
    Closed: "closed",
    CreativeReturned: "creative-returned",
    WaitingForApproval: "creative-waiting-for-approval",
    InProcess: "in-process",
    Online: "online",
    Paused: "paused",
    Prepared: "prepared",
    SasCheck: "sas-check",
    UserCheck: "user-check",
    Reserved: "reserved",
}

export const CampaignStateIDEnum = {
    InProcess: 1,
    SasCheck: 2,
    UserCheck: 3,
    Approved: 4,
    WaitingForApproval: 5,
    CreativeReturned: 6,
    Prepared: 7,
    Online: 8,
    Closed: 9,
    Paused: 10,
    Canceled: 11
}

