<script>
import {computed, ref, toRefs, watch} from "vue";
import campaignStateColor from "@/mixins/campaign-state-color";
import CIcon from "@/components/base/icons/CIcon.vue";
import BaseButton from "@/components/base/BaseButton.vue";
import {useStore} from "@/store";
import CampaignState from "@/components/CampaignState.vue";
import BaseDropdown from "@/components/base/BaseDropdown.vue";
import CButton from "@/components/base/buttons/CButton.vue";

export default {
    name: "CampaignStateEdit",
    components: {CButton, BaseDropdown, CampaignState, BaseButton, CIcon},
    props: {
        campaign: {
            type: Object,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, {emit}) {
        const $store = useStore();
        const {campaign, disabled} = toRefs(props);
        const open = ref(false);
        const possibleStates = ref([]);
        const changedState = ref(null);
        const saving = ref(false);

        const campaignState = computed(() => {
            return campaign.value.campaignState
        });

        const currentState = computed(() => {
            if(changedState.value) {
                return changedState.value;
            }
            return campaignState.value;
        });
        const stateName = computed(() => {
            return currentState.value.name;
        });
        const campaignStateIdentifier = computed(() => {
            return currentState.value.identifier;
        });

        const campaignStateId = computed(() => {
            return campaignState.value.id;
        });

        const fetchStates = async () => {
            possibleStates.value = await $store.dispatch('campaigns/possibleStates', {campaignId: campaign.value.id});
        }

        fetchStates();
        watch(campaignStateId, () => {
            fetchStates();
        });

        const {stateColor} = campaignStateColor(campaignStateIdentifier);
        const doOpen = () => {
            if(disabled.value) {
                return;
            }
            open.value = true;
            emit('open');
        }
        const doClose = () => {
            open.value = false;
        }

        const doCancel = () => {
            changedState.value = null;
            open.value = false;
        }

        const doSave = () => {
            saving.value = true;
            if(changedState.value) {
                emit('update', changedState.value);
            }
            open.value = false;
            changedState.value = null;
            saving.value = false;
        }

        const selectState = (state) => {
            if (state.id === campaignState.value.id) {
                doCancel();
            } else {
                changedState.value = state;
                open.value = false;
            }
        }

        return {
            open,
            stateName,
            stateColor,
            doOpen,
            doClose,
            possibleStates,
            changedState,
            selectState,
            doCancel,
            doSave,
        }
    }
};
</script>

<template>
    <div>
        <div class="flex align-center justify-center items-center">
            <base-button
                class="flex align-center justify-center w-auto rounded-full py-2 px-3 text-center font-medium border border-gray-500 focus:outline focus:outline-2 focus:-outline-offset-1 focus:outline-blue-500 bg-white"
                @click="doOpen"
            >
                <span class="w-4 h-4 rounded-full mr-4 flex-shrink-0" :class="stateColor"></span>
                <span class="uppercase whitespace-nowrap text-xs text-black">{{ stateName }}</span>
                <c-icon v-if="!disabled" class="my-auto ml-2.5 w-2 h-2 text-gray-300" icon="arrow-full-down"></c-icon>
            </base-button>
            <c-button
                v-if="changedState"
                color="green"
                size="small"
                prepend-icon="check"
                class="ml-2"
                :loading="saving"
                @click="doSave"
            >
                {{ $t('common_save') }}
            </c-button>
            <c-button
                v-if="changedState"
                color="blue"
                size="small"
                class="ml-2"
                prepend-icon="x"
                @click="doCancel"
            >
                {{ $t('common_cancel') }}
            </c-button>
        </div>
        <base-dropdown v-model="open" class="absolute z-10 pt-2">
            <div class="bg-white rounded-2xl border border-gray-300 shadow-xl px-0 py-1 flex flex-col overflow-hidden">
                <base-button class="py-1 px-2 hover:bg-blue-100" v-for="state in possibleStates" :key="state.id" @click="selectState(state)">
                    <campaign-state   :campaign-state="state"></campaign-state>
                </base-button>
            </div>
        </base-dropdown>
    </div>

</template>

<style scoped>

</style>
