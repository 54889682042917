<template>
    <div class="max-w-screen-2xl p-8 md:p-16 mx-auto">


        <h1 class="h1 text-purple-100">Styleguide - prostě prvky</h1>


        <h2 class="h2 mt-6">Ikony - https://www.streamlinehq.com/icons/streamline-mini-line</h2>
        <div class="rounded-xl bg-white p-4">
            Ikony priárně vybíráme z balíčku <a class="underline" href="https://www.streamlinehq.com/icons/streamline-mini-line" target="_blank">https://www.streamlinehq.com/icons/streamline-mini-line</a>. Jsou výjimky, kdy je potřeba použít ikonu jinou, pak jen vybíráme tak, aby seděla stylem mezi ostatní ikony.
            Při vkládání ikon - je potřeba smazat <b>width</b> a <b>height</b>.<br>
            Jsou výjimky - vícebarevné ikony - ty pak nemůžou měnit své barvy - zatím je známa jediná - použitá v TableSortHeading<br>
            A je potřeba přepsat barvu (stroke/fill) na <b>currentColor</b>.<br>
            Ikony nesou jméno toho, co zobrazují, ne toho, co zastupují v aplikaci. Např. máme ikonu <c-icon class="inline mt-[-4px] w-3.5 h-3.5" icon="mouse"></c-icon> na počet kliknutí. Tato ikona má jménou mouse, ne click.
        </div>


        <h2 class="h2 mt-6">Tlačítka - varianty</h2>

        <div class="grid grid-cols-4 gap-2">
            <div class="rounded-xl bg-white p-4">
                bigGreen - s ikonou
                <t-button @click="showCreateCampaignDialog = true" variant="bigGreen" class="flex items-center relative group pl-6">
                    <div class="flex bg-green-200 rounded-full p-1.5 mr-3 text-black">
                        <c-icon class="w-3.5 h-3.5" icon="plus"></c-icon>
                    </div>
                    <span>{{ $t('view_campaignList_buttonAddCampaign') }}</span>
                </t-button>
                <c-button size="big" color="red" prepend-icon="plus">
                    {{ $t('view_campaignList_buttonAddCampaign') }}
                </c-button>
                <i>Hlavní funkce - Continue, Save, Approve, Send, ...</i>
            </div>
            <div class="rounded-xl bg-white p-4">
                mediumGreen
                <t-button @click="showCreateCampaignDialog = true" variant="mediumGreen" class="mr-2 mb-2">
                    <span>{{ $t('view_campaignList_buttonAddCampaign') }}</span>
                </t-button>
                <t-button @click="showCreateCampaignDialog = true" variant="mediumGreen" class="flex items-center relative group pl-4">
                    <div class="flex bg-green-200 rounded-full p-1 mr-2 text-black">
                        <c-icon class="w-3 h-3" icon="plus"></c-icon>
                    </div>
                    <span>{{ $t('view_campaignList_buttonAddCampaign') }}</span>
                </t-button>
                <i>Standardní tlačítko, možno s ikonou</i><br>
                <i>Můžeme-li akci zobrazit ikonou a nejedná se o akci "otevřít odkaz mimo", tak ikonu dáme do kolečka.</i>
            </div>
            <div class="rounded-xl bg-white p-4">
                smallGreen
                <t-button @click="showCreateCampaignDialog = true" variant="smallGreen">
                    <span>{{ $t('view_campaignList_buttonAddCampaign') }}</span>
                </t-button>
                <i>Minimální využití, spíš tam, kde není místo, nebo kde je potřeba odlišit, ale je v zálavě modrých tlačítek</i>
            </div>
            <div class="rounded-xl bg-white p-4">
                editCircle
                <t-button variant="editCircle">
                    <c-icon class="w-3.5 h-3.5" icon="edit-pencil"></c-icon>
                </t-button>
                <i>Pro editaci - jiná barva, plná ikona pro lepší "čtení"</i>
            </div>
            <div class="rounded-xl bg-white p-4">
                smallBlue
                <t-button @click="showCreateCampaignDialog = true" variant="smallBlue" class="flex items-center relative group pr-2.5 mb-2">
                    Stránka produktu
                    <c-icon class="ml-1.5 w-3 h-3" icon="external-link"></c-icon>
                </t-button>
                <t-button
                    variant="smallBlue"
                    class="flex items-center relative group pr-2.5"
                    target="_blank"
                >
                    SAS Campaign
                    <c-icon class="ml-1 w-3 h-3" icon="paperclip"></c-icon>
                </t-button>
                <c-button size="small" color="blue" append-icon="external-link" loading>
                    SAS Campaign
                </c-button>
                <i>Typicky odkaz do SAS, vedlejší funkce, směr na jinou stránku - pryč z flow</i>
                <i>U odkazu u kampaně nebop aktivity do SAS - použita ikona kancelářské sponky, protože jde o připnuté odkazy.</i>
            </div>
            <div class="rounded-xl bg-white p-4">
                mediumCircleBlue - s ikonou
                <t-button variant="mediumCircleBlue"
                    @click="showFilter = !showFilter"
                >
                    <c-icon class="w-4 h-4" icon="funnel"></c-icon>
                </t-button>
                <i>Funkce - když je ikona klikací, je v kolečku. Editace, náhled, play, pause, ... až na výjimky jsou funkční ikony vždy blue</i>
            </div>
            <div class="rounded-xl bg-white p-4">
                mediumBlue
                <t-button @click="showCreateCampaignDialog = true" variant="mediumBlue" class="flex items-center relative group pl-4">
                    <c-icon class="w-4 h-4 mr-2" icon="graph-up"></c-icon>
                    <span>Performance</span>
                </t-button>
                <c-button size="medium" color="blue" prepend-icon="graph-up">
                    Performance
                </c-button>
                <i>Standardní tlačítko vedoucí napříč funkcemi</i><br>
                <i>Funkce je znázorněna ikonou - ikona není v kolečku, protože pouze znázorňuje co se zobrazí, zobrazuje KAM TLAČÍTKO VEDE - oproti zelenému tlačítku s ikonou, kde ikona značí CO SE STANE</i>
            </div>
            <div class="rounded-xl bg-white p-4">
                Specifické použití buttonu - detail aktivity - tlačítko pro zobrazení Performance<br>
                <i>Tlačítko po rozkliknutí</i>
                <t-button
                    variant="mediumBlue"
                    class="flex items-center relative group pl-4 pr-3 rounded-b-none rounded-t-[18px]"
                    @click="showActualPerformance = !showActualPerformance"
                >
                    <c-icon class="w-4 h-4 mr-2" icon="graph-up"></c-icon>
                    <!--{{ $t("common_currentPerformance") }}--> Performance
                    <div class="flex bg-blue-40 rounded-full p-1.5 ml-2.5 text-black">
                        <c-icon class="w-2.5 h-2.5" icon="x"></c-icon>
                    </div>
                </t-button>
                <i>Tlačítko je po rozkliknutí nalepené na tabulku s performance daty a tak je zaoblené pouze nahoře. Zároveň má křížek v kolečku jako indikaci co se stane po kliknutí.</i>
            </div>
        </div>

        <div v-for="buttons in buttonList" :key="buttons.name" class="grid w-full grid-cols-4 gap-4 my-6">
            <div class="rounded-3xl bg-white p-4">
                {{ buttons.name}}
            </div>
            <div v-for="button in buttons.buttons" :key="button.size" class="rounded-3xl bg-white p-4">
                <c-button
                    :color="button.color"
                    :size="button.size"
                    :prepend-icon="button.prependIcon"
                    :append-icon="button.appendIcon"
                    :loading="button.loading"
                >
                    {{ button.text }}
                </c-button>
                <pre class="block text-[12px] mt-2" v-text="JSON.stringify(button, null, 2)">
                </pre>
            </div>
        </div>
        <!-- / Tlačítka - varianty -->

        <h2 class="h2 mt-6">Formuláře</h2>
        <div class="grid grid-cols-2 gap-2">
            <div class="rounded-xl bg-white p-4">
                <form ref="form" class="lg:px-14">
                    <div class="mt-9 mb-5">
                        <t-input placeholder="$t('common_email')" required
                            class="mb-4 rounded-full py-5 text-purple">
                        </t-input>
                        <t-input
                            :placeholder="$t('common_password')"
                            type="password"
                            required
                            class="mb-4 rounded-full py-5 bg-gray-100"
                        ></t-input>
                        <info-message type="error">
                            Zadali jste nesprávné heslo
                        </info-message>
                    </div>
                    <div class="relative text-center">
                        <t-button type="submit" variant="bigGreen" class="flex items-center relative group pr-6 mx-auto mb-3">
                            {{ $t('view_login_buttonLogin') }}
                            <div class="flex bg-green-200 rounded-full p-1.5 ml-3 text-black">
                                <c-icon class="w-3.5 h-3.5" icon="arrow-right"></c-icon>
                            </div>
                        </t-button>
                        <t-button :to="{ name: 'forgottenPassword' }" variant="link" class="mx-auto" tag-name="a">
                            {{ $t('view_login_buttonForgottenPassword') }}
                        </t-button>
                    </div>
                </form>
            </div>
            <div class="rounded-xl bg-white p-4">
                Ve formulářích, ale také jinde můžeme použít hlášky:<br>
                <i>Hlášky by měly být krátké - ideálně na 1 řádek, výstižné, neukecané. Pokud je potřeba - sdělit uživateli co má v danou chvíli dělat</i>
                <br> <br>
                <info-message type="error">
                    Chybová hláška - nutné, aby ji uživatel věnoval pozornost
                </info-message>
                <br>
                <info-message type="info">
                    Info hláška - Doporučuje ověření - například systém odhadne chybu v syntaxi, ale je umožněno i přes to takto zadat
                </info-message>
                <br>
                <info-message type="success">
                    Success hláška - Parciální oznámení o správnosti - po ukončení větší akce (form send) by se tato hláška neměla používat
                </info-message>
            </div>
        </div>
        <div class="grid grid-cols-4 gap-2 pt-2">
            <div class="rounded-xl bg-white p-4">
                Input required
                <br> <br>
                <label for="" class="text-gray-600 mb-1 block">
                    {{$t('component_campaignCreateDialog_inputCampaignName')}}
                </label>
                <t-input
                    variant=""
                    class=""
                    required
                >
                </t-input>
            </div>
            <div class="rounded-xl bg-white p-4">
                Input s rozšířenou informací
                <br> <br>
                <label for="" class="text-gray-600 mb-1 block">
                    {{$t('component_campaignCreateDialog_inputCampaignName')}}
                </label>
                <t-input
                    variant=""
                    class=""
                >
                </t-input>
                <p class="px-3 pb-1 text-gray-600 text-xs">
                    Nepovolené znaky jsou: ", ', \, /, ...
                </p>
            </div>
            <div class="rounded-xl bg-white p-4">
                Neaktivní - Disabled input
                <br> <br>
                <label for="" class="text-gray-300 mb-1 block">
                    {{$t('component_campaignCreateDialog_inputCampaignName')}}
                </label>
                <t-input
                    variant=""
                    class=""
                    :disabled="true"
                >
                </t-input>
            </div>
            <div class="rounded-xl bg-white p-4">
                Input s nevalidními daty
                <br> <br>
                <label for="" class="text-red-300 mb-1 block">
                    {{$t('component_campaignCreateDialog_inputCampaignName')}}
                </label>
                <t-input
                    variant="error"
                    class=""
                >
                </t-input>
                <info-message class="mt-1" type="error">
                    Odůvodnění chyby
                </info-message>
            </div>
        </div>
        <div class="rounded-xl bg-white p-4">
            <form>
                <c-input type="date" v-model="textValue" label="Text" required></c-input>
                <c-select v-model="multipleSelect" label="Select" :options="['PNG', 'JPG', 'GIF']" multiple required searchable></c-select>
                <c-button type="submit">Submit</c-button>
            </form>
        </div>
        <h2 class="h2 mt-6">Ikony</h2>
        <div class="grid grid-cols-8 gap-2">
            <div v-for="icon in iconNames" :key="icon" class="rounded-xl bg-white p-4 flex items-center justify-center flex-wrap space-y-2">
                    <c-icon class="w-8 h-8" :icon="icon"></c-icon>
                    <p class="text-xs text-gray-600 mt-1 w-full text-center">
                        {{icon}}
                    </p>
            </div>
        </div>

    </div>
</template>

<script>
import CIcon from "@/components/base/icons/CIcon.vue";
import InfoMessage from "@/components/base/InfoMessage.vue";
import CButton from "@/components/base/buttons/CButton.vue";
import CInput from "@/components/base/form/CInput.vue";
import CSelect from "@/components/base/form/CSelect.vue";

const svgContext = require.context(
    '!!raw-loader!@/assets/svg-icons', // search this directory
    true, // search subdirectories
    /\w+\.svg$/i // only include SVG files
);

const iconNames = svgContext.keys()
    .map(path => path.replace(/^\.\/(.*)\.\w+$/, '$1'));

const buttonSizes = ['big', 'medium', 'small'];
export default {
    name: "StyleguideView",
    components: {CSelect, CInput, CButton, InfoMessage, CIcon},
    data() {
        return {
            iconNames,
            textValue: '',
            multipleSelect: [],
            select: '',
        }
    },
    computed: {
        buttonList() {
            return [
                {
                    name: "Green",
                    buttons: buttonSizes.map(size => ({
                        size: size,
                        color: 'green',
                        text: 'Tlačítko',
                        prependIcon: '',
                        appendIcon: '',
                        loading: false,
                    }))
                },
                {
                    name: "Green icon prepend",
                    buttons: buttonSizes.map(size => ({
                        size: size,
                        color: 'green',
                        text: 'Tlačítko',
                        prependIcon: 'online',
                        appendIcon: '',
                        loading: false,
                    }))
                },
                {
                    name: "Green icon append",
                    buttons: buttonSizes.map(size => ({
                        size: size,
                        color: 'green',
                        text: 'Tlačítko',
                        prependIcon: '',
                        appendIcon: 'graph-up',
                        loading: false,
                    }))
                },
                {
                    name: "Green icon prepend loading",
                    buttons: buttonSizes.map(size => ({
                        size: size,
                        color: 'green',
                        text: 'Tlačítko',
                        prependIcon: 'graph-up',
                        appendIcon: '',
                        loading: true,
                    }))
                },
                {
                    name: "Green icon append loading",
                    buttons: buttonSizes.map(size => ({
                        size: size,
                        color: 'green',
                        text: 'Tlačítko',
                        prependIcon: '',
                        appendIcon: 'graph-up',
                        loading: true,
                    }))
                },
                {
                    name: "Blue",
                    buttons: buttonSizes.map(size => ({
                        size: size,
                        color: 'blue',
                        text: 'Tlačítko',
                        prependIcon: '',
                        appendIcon: '',
                        loading: false,
                    }))
                },
                {
                    name: "Blue icon prepend",
                    buttons: buttonSizes.map(size => ({
                        size: size,
                        color: 'blue',
                        text: 'Tlačítko',
                        prependIcon: 'graph-up',
                        appendIcon: '',
                        loading: false,
                    }))
                },
                {
                    name: "Blue icon append",
                    buttons: buttonSizes.map(size => ({
                        size: size,
                        color: 'blue',
                        text: 'Tlačítko',
                        prependIcon: '',
                        appendIcon: 'graph-up',
                        loading: false,
                    }))
                },
                {
                    name: "Blue icon prepend loading",
                    buttons: buttonSizes.map(size => ({
                        size: size,
                        color: 'blue',
                        text: 'Tlačítko',
                        prependIcon: 'graph-up',
                        appendIcon: '',
                        loading: true,
                    }))
                },
                {
                    name: "Blue icon append loading",
                    buttons: buttonSizes.map(size => ({
                        size: size,
                        color: 'blue',
                        text: 'Tlačítko',
                        prependIcon: '',
                        appendIcon: 'graph-up',
                        loading: true,
                    }))
                },
                {
                    name: "Red",
                    buttons: buttonSizes.map(size => ({
                        size: size,
                        color: 'red',
                        text: 'Tlačítko',
                        prependIcon: '',
                        appendIcon: '',
                        loading: false,
                    }))
                },
                {
                    name: "Red icon prepend",
                    buttons: buttonSizes.map(size => ({
                        size: size,
                        color: 'red',
                        text: 'Tlačítko',
                        prependIcon: 'graph-up',
                        appendIcon: '',
                        loading: false,
                    }))
                },
                {
                    name: "Red icon append",
                    buttons: buttonSizes.map(size => ({
                        size: size,
                        color: 'red',
                        text: 'Tlačítko',
                        prependIcon: '',
                        appendIcon: 'graph-up',
                        loading: false,
                    }))
                },
                {
                    name: "Red icon prepend loading",
                    buttons: buttonSizes.map(size => ({
                        size: size,
                        color: 'red',
                        text: 'Tlačítko',
                        prependIcon: 'graph-up',
                        appendIcon: '',
                        loading: true,
                    }))
                },
                {
                    name: "Red icon append loading",
                    buttons: buttonSizes.map(size => ({
                        size: size,
                        color: 'red',
                        text: 'Tlačítko',
                        prependIcon: '',
                        appendIcon: 'graph-up',
                        loading: true,
                    }))
                }
            ]
        }
    },
}
</script>

<style scoped>

</style>
