<template>
    <div class="flex flex-wrap space-y-3 justify-between py-5 md:px-8 px-4">

        <div class="sm:w-80 w-full sm:pr-4">
            <t-input
                v-model="valueValue"
                maxlength="255"
                class="text-center w-full border-gray-400"
                type="text"
                :placeholder="$t('component_targetKeywordsItemInput_placeholder')"
            ></t-input>
        </div>

        <div class="w-60">
                <toggle-switch-labeled
                    v-model="valueExactMatch"
                    :label-before="$t('component_targetKeywordsItemInput_extendedMatch')"
                    :label-after="$t('component_targetKeywordsItemInput_exactMatch')"
                >
                </toggle-switch-labeled>
        </div>

        <div class="w-10">
            <button class="cursor-pointer" @click="deleteItem">
                <remove-icon />
            </button>
        </div>
    </div>
</template>

<script>

import ToggleSwitchLabeled from "@/components/ToggleSwitchLabeled.vue";
import RemoveIcon from "@/components/icons/RemoveIcon.vue";

export default {
    name: "TargetKeywordsInputItem",
    components: {
        ToggleSwitchLabeled,
        RemoveIcon
    },
    props: {
        value: {
            type: Object
        }
    },
    data() {
        return {
            error: ''
        }
    },
    computed: {
        valueValue: {
            get() {
                return  this.value.value === null ? "" : this.value.value;
            },
            set(value) {
                this.$emit('input', { ...this.value, value: value });
            },
        },
        valueExactMatch: {
            get() {
                return  this.value.exactMatch === null ? false : this.value.exactMatch;
            },
            set(value) {
                this.$emit('input', { ...this.value, exactMatch: value });
            },
        },
    },
    watch: {

        value(value) {
            this.validate(value);
        },
        error(value) {
            this.$emit('error', value.length > 0);
        }
    },
    methods: {
        async validate(value) {
            let error = '';
            if(value == null) {
                value = this.value;
            }
            this.error = error;
        },
        deleteItem() {
            this.$emit('delete');
        }

    },
    async created() {

    }
}
</script>

<style scoped>

</style>
