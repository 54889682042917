<template>
    <div class="flex justify-start items-center">
        <span class="w-4 h-4 rounded-full mr-4 flex-shrink-0" :class="{[this.states[this.itemType].itemClass]: true}"></span>
        <span class="uppercase text-xs">{{ stateName }}</span>
    </div>
</template>

<script>

export default {
    name: "CampaignItemState",
    components: {
    },
    props: {
        campaignItemState: {},
    },
    data: () => {
        return {
            states: {
                'missing': {
                    itemClass: 'bg-red-450',
                },
                'in-process': {
                    itemClass: 'bg-yellow-600',
                },
                'returned': {
                    itemClass: 'bg-orange-400',
                },
                'waiting-for-approval': {
                    itemClass: 'bg-orange-400',
                },
                'approved': {
                    itemClass: 'bg-green-600',
                },
                'online': {
                    itemClass: 'bg-green-600',
                },
                'closed': {
                    itemClass: 'bg-gray-300',
                },
                'paused': {
                    itemClass: 'bg-blue-600',
                },
            },
        };
    },
    computed: {
        iconName() {
            if (!this.states[this.itemType]) {
                return '';
            }
            return this.states[this.itemType].iconName;
        },
        textClass() {
            if (!this.states[this.itemType]) {
                return '';
            }
            return this.states[this.itemType].textClass;
        },
        stateName() {
            return this.campaignItemState.name;
        },
        itemType() {
            return this.campaignItemState.identifier;
        }
    }
}
</script>
