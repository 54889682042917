<template>
	<div class="max-w-screen-2xl pt-1 pb-8 px-8 md:px-8 mx-auto">
        <simple-breadcrumb v-if="breadcrumbs.length" :items="breadcrumbs" data-cy="breadcrumb"></simple-breadcrumb>
		<header class="flex flex-wrap items-center justify-between my-5 space-y-2 lg:space-y-0 justify-end" data-cy="header">
                <t-button
                    @click="showCreateLandingPageDialog = true"
                    class="flex items-center relative group ml-auto mt-2 pl-6"
                    variant="bigGreen"
                    data-cy="add-landing-page-button">
                    <div class="flex bg-green-200 rounded-full p-1.5 mr-3 text-black">
                        <c-icon class="w-3.5 h-3.5" icon="plus"></c-icon>
                    </div>
                    {{ $t('view_landingPagesListView_buttonAdd') }}
                </t-button>
		</header>

        <dynamic-table :items="items" :loading="loadingData" :classes="showFilter ? 'xl:max-w-[calc(100%-360px)] mr-0 ml-auto' : ''" data-cy="landing-pages-table">
            <dynamic-table-column identifier="name">
                {{ $t('common_name') }}
            </dynamic-table-column>
            <dynamic-table-column identifier="target">
                {{ $t('common_target_web') }}
            </dynamic-table-column>
            <dynamic-table-column identifier="company" v-if="isRetailerOrAgency">
                {{ $t('common_advertiserCompany') }}
            </dynamic-table-column>
            <dynamic-table-column identifier="active" class="text-center">
                {{ $t('view_activityCreativeView_active') }}
            </dynamic-table-column>
            <dynamic-table-column identifier="actions" style-header="width: 12rem" class="text-right">
                {{ $t('common_actions') }}
            </dynamic-table-column>

            <template v-slot:target="item">
                {{ item.target.name }}
            </template>
            <template v-slot:company="item">
                {{ item.company.name }}
            </template>
            <template v-slot:active="item">
                <boolean-column :value="item.active"></boolean-column>
            </template>
            <template v-slot:actions="item">
                <div class="flex justify-end">
                    <router-link class="cursor-pointer" :to="{name: 'landingPageDetail', params: {landingPageId: item.id}}" data-cy="show-landing-page-link">
                        <t-button variant="editCircle" :title="$t('common_show')" data-cy="show-landing-page-button">
                            <c-icon class="w-3.5 h-3.5" icon="eye"></c-icon>
                        </t-button>
                    </router-link>
                </div>
            </template>
        </dynamic-table>

        <div class="flex flex-col md:flex-row justify-between mt-5">
            <page-items-filter v-model="maxResults" />
            <page-picker
                v-model="firstResult"
                :total-count="totalCount"
                :page-size="maxResults"
                :className="customClass"
                data-cy="page-picker"
            ></page-picker>
        </div>

        <create-landing-page-dialog
            :open="showCreateLandingPageDialog"
            @close="closeLandingPageDialog"
            @created="onLandingPageCreated"
            data-cy="create-landing-page-dialog"
        ></create-landing-page-dialog>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import SimpleBreadcrumb from "@/components/SimpleBreadcrumb";
import CIcon from "@/components/base/icons/CIcon.vue";
import DynamicTable from "@/components/universal/dynamic-table/DynamicTable.vue";
import DynamicTableColumn from "@/components/universal/dynamic-table/DynamicTableColumn.vue";
import PagePicker from "@/components/PagePicker.vue";
import CreateLandingPageDialog from "@/components/landing-page/CreateLandingPageDialog.vue";
import BooleanColumn from "@/components/universal/dynamic-table/BooleanColumn.vue";
import PageItemsFilter from "@/components/PageItemsFilter.vue";

export default {
    name: "LandingPagesListView",
    metaTags() {
        return {
            title:  this.$t('view_landingPagesListView_metaTitle')  + ' - ' + this.$t('common_meta_title'),
        };
    },
    components: {
        BooleanColumn,
        PagePicker,
        DynamicTableColumn,
        DynamicTable,
        CIcon,
        SimpleBreadcrumb,
        CreateLandingPageDialog,
        PageItemsFilter,
    },
    data: () => {
        return {
            loadingData: false,
            items: [],
            totalCount: 0,
            showFilter: false,
            showSortingFilter: false,
            showCreateLandingPageDialog: false,
            customClass: "main__pagePicker",
        };
    },
    computed: {
        ...mapGetters('auth', ['locale', 'isRetailer', 'isAgency']),
        breadcrumbs() {
            // const that = this;
            // return [
            //     {
            //         title: that.$t('common_campaignsManagement'),
            //         current: 'page',
            //     },
            // ];
            return [];
        },
        isRetailerOrAgency() {
            return this.isRetailer || this.isAgency;
        },
        firstResult: {
            get() {
                return this.$store.getters['landingPagesList/filter'].firstResult;
            },
            set(value) {
                this.$store.dispatch('landingPagesList/setFilterFirstResult', value);
            }
        },
        maxResultsStorageKey() {
            return `max-results-${this.$route.name}`;
        },
        maxResults: {
            get() {
                const initialMaxResults = this.$store.getters['landingPagesList/filter'].maxResults;
                const storedMaxResultsValue = localStorage.getItem(this.maxResultsStorageKey);
                return storedMaxResultsValue
                    ? +storedMaxResultsValue
                    : initialMaxResults;
            },
            set(value) {
                localStorage.setItem(this.maxResultsStorageKey, value);
                this.$store.dispatch('landingPagesList/setFilterMaxResults', value);
            },
        },
        filter: {
            get() {
                return this.$store.getters['landingPagesList/filter'];
            },
            set(value) {
                this.$store.dispatch('landingPagesList/setFilter', value);
            }
        },
        filterSorting: {
            get() {
                return this.$store.getters['landingPagesList/filter'].sort;
            },
            set(sort) {
                this.$store.dispatch('landingPagesList/setFilterSort', sort);
            }
        },
    },
    watch: {
        filter: {
            handler() {
                this.loadData();
            },
            deep: true
        },
        filterSort() {
            this.loadData();
        },
        async locale() {
            await this.loadData();
        }
    },
    methods: {
        async loadData() {
            this.loadingData = true;
            let filter = this.$store.getters['campaignItemsList/filter'];
            const result = await this.$store.dispatch('landingPagesList/filter', {
                ...filter,
                maxResults: this.maxResults,
            });
            this.items = result.items;
            this.totalCount = result.total;
            this.loadingData = false;
        },
        async clearFilter() {
            await this.$store.dispatch('landingPagesList/clearFilter');
            await this.loadData();
        },
        closeLandingPageDialog() {
            this.showCreateLandingPageDialog = false;
        },
        onLandingPageCreated(newLandingPage) {
            console.log(newLandingPage);
            this.showCreateLandingPageDialog = false;
            this.$router.push({name: 'landingPageDetail', params: {landingPageId: newLandingPage.id, viewState: 'edit'}});
        },
    },
    filters: {},
    beforeRouteEnter(from, to, next) {
        return next(async (vm) => {
            await vm.loadData();
        })
    },
    async beforeRouteUpdate() {
        await this.loadData();
    },
};
</script>
