import axios from "@/plugins/axios";
import Vue from "vue";
import {CanceledError} from "@/errors/api";

function createDefaultFilter() {
    return {
        myCampaigns: false,
        campaignItemStateIdes: [],
        companyIdes: [],
        companyContactIdes: [],
        startDate: null,
        endDate: null,
        search: '',
        salesPersonIdes: [],
        productCategoryIdes: [],
        productIdes: [],
        brands: [],
        rootTargetProductCategoryIdes: [],
        targetProducts: [],
        campaignStateIdes: [],
        noCatalogTargetUrl: false,

        sort: null,

        firstResult: 0,
        maxResults: 10
    }
}

let listController = new AbortController();
export default {
    namespaced: true,
    state: {
        filterData: null,
        filter: createDefaultFilter(),
        listData: {
            items: [],
            total: 0,
            totalPrice: 0,
        },
        listRequestCount: 0,
    },
    getters: {
        filterData: function (state) {
            if (state.filterData) {
                return state.filterData;
            }
            return null;
        },
        listData: function (state) {
            return state.listData;
        },
        filter: function (state) {
            return state.filter;
        },
    },
    mutations: {
        SET_FILTER_DATA(state, filterData) {
            state.filterData = filterData;
        },
        CLEAR_FILTER(state) {
            state.filter = createDefaultFilter();
        },
        SET_FILTER(state, filter) {
            state.filter = filter;
        },
        INCREMENT_LIST_REQUEST_COUNT(state) {
            state.listRequestCount++;
        },
        SET_LIST_DATA(state, listData) {
            Vue.set(state, 'listData', listData);
        }
    },
    actions: {
        async filterItems(context, filter) {
            const data = {
                firstResult: filter.firstResult,
                lastResult: filter.lastResult,
                maxResults: filter.maxResults,
            };

            if (filter.sort) {
                data.order = [filter.sort]
            }
            if (filter.myCampaigns) {
                data.myCampaigns = filter.myCampaigns;
            }
            if (filter.startDate) {
                data.startDate = filter.startDate;
            }
            if (filter.endDate) {
                data.endDate = filter.endDate;
            }
            if (filter.search) {
                data.search = filter.search;
            }
            if (filter.campaignItemStateIdes && filter.campaignItemStateIdes.length > 0) {
                data.campaignItemStateIdes = filter.campaignItemStateIdes;
            }
            if (filter.campaignStateIdes && filter.campaignStateIdes.length > 0) {
                data.campaignStateIdes = filter.campaignStateIdes;
            }
            if (filter.companyIdes && filter.companyIdes.length > 0) {
                data.companyIdes = filter.companyIdes;
            }
            if (filter.salesPersonIdes && filter.salesPersonIdes.length > 0) {
                data.salesPersonIdes = filter.salesPersonIdes;
            }
            if (filter.productCategoryIdes && filter.productCategoryIdes.length > 0) {
                data.productCategoryIdes = filter.productCategoryIdes;
            }
            if (filter.productIdes && filter.productIdes.length > 0) {
                data.productIdes = filter.productIdes;
            }
            if (filter.brands && filter.brands.length > 0) {
                data.brandIdes = filter.brands.map(brand => brand.id)
            }
            if (filter.rootTargetProductCategoryIdes && filter.rootTargetProductCategoryIdes.length > 0) {
                data.rootTargetProductCategoryIdes = filter.rootTargetProductCategoryIdes;
            }
            if (filter.targetProducts && filter.targetProducts.length > 0) {
                data.targetProductExternalIdes = filter.targetProducts.map(brand => brand.externalIdentifier)
            }
            if (filter.noCatalogTargetUrl) {
                data.noCatalogTargetUrl = filter.noCatalogTargetUrl;
            }

            context.commit('INCREMENT_LIST_REQUEST_COUNT');
            const listRequestCount = context.state.listRequestCount;

            listController.abort();
            listController = new AbortController();

            return axios.post('/campaign-items/filter', data, {signal: listController.signal})
                .then(function (response) {
                    const data = response.data;
                    if (listRequestCount !== context.state.listRequestCount) {
                        return data;
                    }
                    context.commit('SET_LIST_DATA', data);
                    return data;
                })
                .catch((error) => {
                    if (error.message === 'canceled') {
                        throw new CanceledError();
                    }
                    throw error;
                });
        },
        async reloadFilterData({commit}) {
            const response = await axios.get("/campaign-items/filter-data");
            if (response) {
                commit("SET_FILTER_DATA", response.data);
                return response.data;
            }
        },
        setFilter(context, filter) {
            context.commit('SET_FILTER', filter);
        },
        async setFilterSort(context, sort) {
            let filter = context.state.filter;
            filter.sort = sort;
            context.commit('SET_FILTER', filter);
        },
        setFilterFirstResult(context, firstResult) {
            let filter = context.state.filter;
            filter.firstResult = firstResult;
            context.commit('SET_FILTER', filter);
        },
        setFilterMaxResults(context, maxResults) {
            let filter = context.state.filter;
            filter.maxResults = maxResults;
            filter.firstResult = 0;
            context.commit('SET_FILTER', filter);
        },
        clearFilter(context) {
            context.commit('CLEAR_FILTER');
        },
    }
};
