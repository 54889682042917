<template>
    <simple-dialog v-model="show">
        <h2 class="text-black text-3xl mb-5 pr-5">
            {{ $t('component_campaignDeleteDialog_header') }}
        </h2>

        <h3 class="h1 text-blue-400">
            {{ campaignName }}
        </h3>

        <div class="flex mx-auto w-1/2 justify-center mt-7 flex-col">
            <info-message type="error" class="mt-2" v-if="errorMessage">
                {{ errorMessage }}
            </info-message>
        </div>
        <div class="flex mt-8 flex-wrap justify-end">
            <text-button class="text-gray-400 md:mr-6 my-1" @click="show = false">
                {{ $t('common_back') }}
            </text-button>
            <c-button
                color="red"
                size="medium"
                @click="deleteCampaign"
                :loading="deleteInProgress"
            >
                {{ $t('common_delete') }}
            </c-button>
        </div>
    </simple-dialog>
</template>
<script>
import SimpleDialog from "@/components/base/SimpleDialog.vue";
import InfoMessage from "@/components/base/InfoMessage.vue";
import CButton from "@/components/base/buttons/CButton.vue";
import TextButton from "@/components/base/buttons/TextButton.vue";

export default {
    name: 'DeleteCampaignDialog',
    components: {
        SimpleDialog,
        InfoMessage,
        CButton,
        TextButton
    },
    props: {
        showDialog: {
            type: Boolean,
            default: true,
        },
        campaign: {
            type: [Object, null],
        },
    },
    data: () => {
        return {
            deleteInProgress: false,
            errorMessage: '',
        };
    },
    computed: {
        show: {
            get() {
                return this.showDialog;
            },
            set(value) {
                if (!value) {
                    this.$emit('close', value);
                }
            },
        },
        campaignName() {
            if (!this.campaign) {
                return '';
            }
            return this.campaign.name;
        },
    },
    methods: {
        async deleteCampaign() {
            this.deleteInProgress = true;
            const campaignId = this.campaign?.id ?? this.$route.params.campaignId;

            try {
                await this.$store.dispatch('campaigns/deleteCampaign', {
                    campaignId,
                });
            } catch (e) {
                this.errorMessage = this.$t('common_unexpectedError');
            }

            await this.$router.push({ name: 'campaignList' });
            this.deleteInProgress = false;
        },
    },
    filters: {},
};
</script>
