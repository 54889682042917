<template>
    <div class="bg-white border-2 rounded-md border-gray-300 w-5 h-5 flex flex-shrink-0 justify-center items-center mr-2
        focus-within:border-zinc-300 checkbox cursor-pointer">
        <svg class="text-blue-400 w-4 h-4 pointer-events-none" version="1.1" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <polyline points="4 11 8 15 16 6" fill="currentColor"></polyline>
        </svg>
    </div>
</template>

<script>
export default {
    name: "CheckboxIcon"
}
</script>
