<template>
    <base-button
        class="
            flex justify-center items-center text-sm  border-none tracking-wide p-1 rounded-full
            w-[30px] h-[30px] shrink-0 bg-lime-100 hover:bg-lime-120
            active:focus:outline active:focus:outline-2 active:focus:outline-offset-1 active:focus:outline-blue-500
            disabled:bg-gray-300 disabled:text-gray-500 disabled:cursor-not-allowed
        "
        :class="{
            'text-blue-400': iconColor === 'blue',
            'text-red-400': iconColor === 'red',
        }"
        v-bind="$attrs"
        :tag-name="tagName"
        :to="to"
        :href="href"
        :disabled="disabled"
        @click="$emit('click', $event)"
    >
       <c-icon class="w-3.5 h-3.5" :icon="icon"></c-icon>
    </base-button>
</template>

<script>

import BaseButton from "@/components/base/BaseButton.vue";
import CIcon from "@/components/base/icons/CIcon.vue";

export default {
    name: "CircleIconButton",
    components: {CIcon, BaseButton},
    props: {
        tagName: {
            type: String,
            default: ''
        },
        to: {
            type: Object,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        },
        href: {
            type: String,
            default: undefined
        },
        icon: {
            type: String,
            default: '',
            required: true
        },
        iconColor: {
            type: String,
            default: 'blue'
        },
    }
}

</script>

<style scoped>

</style>
