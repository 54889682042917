<template>
    <div class="max-w-screen-2xl pt-1 pb-8 px-4 md:px-8 mx-auto">
        <simple-breadcrumb v-if="!loadingCampaign && campaignExists && campaignItem" :items="breadcrumbs"></simple-breadcrumb>

        <not-found-slate v-if="!loadingCampaign && (!campaignExists || !campaignItem)" />

        <loading-box v-model="loadingCampaign" min-height="12rem" theme="light">
            <div v-if="!loadingCampaign && campaignExists && campaignItem">
                <div class="flex space-x-2 justify-end mb-4 lg:mb-4">
                    <c-button
                        v-if="!campaignItem.product.nativeFormat"
                        color="blue"
                        size="medium"
                        prepend-icon="eye"
                        :to="{name: 'campaignItemCreative' , params: {campaignItemId: campaignItem.id, campaignId: campaign.id}}"
                    >
                        {{ $t('view_campaignItemDetail_buttonShowCreativeInputPreview') }}
                    </c-button>
                    <c-button
                        color="blue"
                        size="medium"
                        :href="campaignItem.targetURL"
                        target="_blank"
                    >
                        {{ $t('view_campaignItemDetail_clickTargetUrl') }}
                    </c-button>
                </div>

                <div class="">
                    <h1 class="text-blue-400 h1 mb-5 pr-5">
                        {{ campaignItem.generatedName || campaignItem.product.name }}
                    </h1>
                    <p>
                        {{ campaignItem.product.description }}
                    </p>
                    <div class="grid xl:grid-cols-6 md:grid-cols-4 grid-cols-2 mt-8 gap-4">
                        <div class="whiteBlock">
                            <div class="text-gray-500 mb-3">
                                {{ $t('component_campaignItemDialog_itemState') }}
                            </div>
                            <campaign-item-state :campaignItemState="campaignItem.itemState"></campaign-item-state>
                        </div>
                        <div class="whiteBlock">
                            <div class="text-gray-500 mb-3">{{ $t('component_campaignItemDialog_fromTo') }}</div>
                            <span class="inline-block font-bold">{{ startDate }} - {{ endDate }}</span>
                        </div>
                        <div class="whiteBlock">
                            <div class="text-gray-500 mb-3">{{ $t('common_price') }}</div>
                            <span class="inline-block font-bold">
                                {{ $n(campaignItem.unitPrice, "currency") }} / {{ rateTypeName }}<br>
                                {{ $t('common_totalPrice') }}: {{ $n(campaignItem.price, "currency") }} <br>
                                <span v-if="!isFlatRate">
                                    {{ $t('component_campaignItemDialog_priceUsage') }}: {{ $n( (campaignItem.progress/100) * campaignItem.price, "currency") }}<br>
                                </span>
                            </span>
                        </div>
                        <div class="whiteBlock" v-if="campaignItem.variableValues && campaignItem.variableValues.length">
                            <div class="text-gray-500 mb-3">
                                {{ $t('common_tags') }}
                            </div>
                            <span class="font-bold" v-for="(value, index) in campaignItem.variableValues" :key="index">
                                {{ value.name }}
                            </span>
                        </div>

                        <div class="whiteBlock col-span-2" v-if="campaignItem.targetURL">
                            <div class="text-gray-500 mb-3">
                                {{ $t('component_campaignItemDialog_clickUrl') }}
                            </div>
                            <a :href="campaignItem.targetURL" target="_blank"
                               class="underline hover:no-underline break-words">{{ campaignItem.targetURL }}</a>
                        </div>
                        <div class="whiteBlock col-span-2" v-if="sasLineItemURL">
                            <div class="text-gray-500 mb-3">
                                {{ $t('component_campaignItemDialog_sasLineItemUrl') }}
                            </div>
                            <a :href="sasLineItemURL" target="_blank" class="underline hover:no-underline break-words">{{
                                    sasLineItemURL
                                }}</a>
                        </div>
                        <div class="whiteBlock col-span-2" v-if="sasFlightURL">
                            <div class="text-gray-500 mb-3">
                                {{ $t('component_campaignItemDialog_sasFlightUrl') }}:
                            </div>
                            <a :href="sasFlightURL" target="_blank"
                               class="underline hover:no-underline break-words">{{ sasFlightURL }}</a>
                        </div>
                        <div class="whiteBlock col-span-2" v-if="sasCreativeURL">
                            <div class="text-gray-500 mb-3">
                                {{ $t('component_campaignItemDialog_sasCreativeUrl') }}:
                            </div>
                            <a :href="sasCreativeURL" target="_blank" class="underline hover:no-underline break-words">
                                {{ sasCreativeURL }}
                            </a>
                        </div>
                        <div class="whiteBlock" v-if="isRetailer && sasZipCreativeURL">
                            <div class="text-gray-500 mb-3">
                                {{ $t('common_zip') }}:
                            </div>
                            <a :href="sasZipCreativeURL" target="_blank" class="underline hover:no-underline break-words">
                                {{ sasZipCreativeURL }}
                            </a>
                        </div>
                        <div v-if="displayOfPerformanceMetrics" class="whiteBlock col-span-4">
                            <div class="text-gray-500 mb-3">
                                {{ $t('common_currentPerformance') }}
                            </div>
                            <table class="text-center -mx-3 w-[calc(100%+1.5rem)]">
                                <tr class="border-b borex-gray-100">
                                    <th v-if="displayOfPerformanceMetricsGroup.displayOspRatio" class="p-2">
                                        {{ $t("common_ospRatio") }}
                                    </th>
                                    <th v-if="displayOfPerformanceMetricsGroup.displayImpressions" class="px-2 pb-1">
                                        {{ $t("common_impressions") }}
                                    </th>
                                    <th v-if="displayOfPerformanceMetricsGroup.displayViews" class="px-2 pb-1">
                                        {{ $t("common_views") }}
                                    </th>
                                    <th v-if="displayOfPerformanceMetricsGroup.displayVisibilityRatio" class="px-2 pb-1">
                                        {{ $t("common_visibilityRatio") }}
                                    </th>
                                    <th v-if="displayOfPerformanceMetricsGroup.displayVisibilityClicks" class="px-2 pb-1">
                                        {{ $t("common_clicks") }}
                                    </th>
                                    <th v-if="displayOfPerformanceMetricsGroup.displayCtrRatio" class="px-2 pb-1">
                                        {{ $t("common_ctrRatio") }}
                                    </th>
                                    <th v-if="displayOfPerformanceMetricsGroup.displayHardConversions" class="px-2 pb-1">
                                        {{ $t("common_hard_conversions") }}
                                    </th>
                                </tr>
                                <tr>
                                    <td v-if="displayOfPerformanceMetricsGroup.displayOspRatio" class="p-2">
                                        {{ $n(campaignItem.ospRatio, { style: 'percent' }) }}
                                    </td>
                                    <td v-if="displayOfPerformanceMetricsGroup.displayImpressions" class="px-2 py-3">
                                        {{ $n(campaignItem.actualImpressions) }}
                                    </td>
                                    <td v-if="displayOfPerformanceMetricsGroup.displayViews" class="px-2 py-3">
                                        {{ $n(campaignItem.actualViews) }}
                                    </td>
                                    <td v-if="displayOfPerformanceMetricsGroup.displayVisibilityRatio" class="px-2 py-3">
                                        {{ $n(campaignItem.visibilityRatio / 100, { style: 'percent' }) }}
                                    </td>
                                    <td v-if="displayOfPerformanceMetricsGroup.displayVisibilityClicks" class="px-2 py-3">
                                        {{ $n(campaignItem.actualClicks) }}
                                    </td>
                                    <td v-if="displayOfPerformanceMetricsGroup.displayCtrRatio" class="px-2 py-3">
                                        {{ $n(campaignItem.ctrRatio / 100, { style: 'percent', minimumFractionDigits: 2, maximumSignificantDigits: 2 }) }}
                                    </td>
                                    <td v-if="displayOfPerformanceMetricsGroup.displayHardConversions" class="px-2 py-3">
                                        {{ $n(campaignItem.actualHardConversions) }}
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div class="whiteBlock"
                             v-if="campaignItem.orderedClicksCount || campaignItem.orderedImpressionsCount">
                            <div class="text-gray-500 mb-3">
                                {{ $t('component_campaignItemDialog_ordered') }}
                            </div>
                            <div class="flex items-center my-1 mx-2" v-if="campaignItem.orderedClicksCount">
                                <svg-icon name="ico-click" class="mr-2"></svg-icon>
                                <span>
                                    {{
                                        $tc(
                                            'common_pluralize_clicks',
                                            campaignItem.orderedClicksCount,
                                            {clicks: $n(campaignItem.orderedClicksCount)}
                                        )
                                    }}
                                </span>
                            </div>
                            <div class="flex items-center my-1 mx-2" v-if="campaignItem.orderedImpressionsCount">
                                <svg-icon name="square-rounded" class="mr-2 h-5"></svg-icon>
                                <span class="font-bold">
                                    {{
                                        $tc(
                                            'common_pluralize_impressions',
                                            campaignItem.orderedImpressionsCount,
                                            {impressions: $n(campaignItem.orderedImpressionsCount)}
                                        )
                                    }}
                                </span>
                            </div>
                        </div>
                        <div class="whiteBlock" v-if="campaignItem.message">
                            <div class="text-gray-500 mb-3">
                                {{ $t('component_campaignItemDialog_message') }}
                            </div>
                            {{ campaignItem.message }}
                        </div>
                        <div class="whiteBlock" v-if="campaignItem.actualSoftConversions!==null">
                            <div class="text-gray-500 mb-3">
                                {{ $t('common_soft_conversions') }}
                            </div>
                            {{ campaignItem.actualSoftConversions }}
                        </div>
                        <div class="whiteBlock" v-if="campaignItem.note">
                            <div class="text-gray-500 mb-3">
                                {{ $t('component_campaignItemDialog_note') }}
                            </div>
                            {{ campaignItem.note }}
                        </div>
                        <div class="whiteBlock" v-if="campaignItem.progress">
                            <div class="text-gray-500 mb-3">{{ $t('common_progress') }}</div>
                            <ProgressBar :progress="progress" maxProgress="100" text="%"/>
                        </div>
                    </div>
                </div>
                <div class="flex w-full space-y-6 mt-6 items-center flex-col  lg:w-auto lg:space-x-6 lg:flex-row lg:space-y-0 lg:justify-end">
                    <text-button class="text-gray-400" :to="{name: 'campaignDetail', params: {campaignId: campaign.id}}">
                        {{ $t('common_back_to_campaign') }}
                    </text-button>
                </div>
            </div>
        </loading-box>
    </div>
</template>

<script>
import SvgIcon from "@/components/SvgIcon.vue";
import CampaignItemState from "@/components/CampaignItemState.vue";
import ProgressBar from "@/components/ProgressBar.vue";
import {parseISO} from "date-fns";
import {mapGetters} from "vuex";
import SimpleBreadcrumb from "@/components/SimpleBreadcrumb";
import TextButton from "@/components/base/buttons/TextButton.vue";
import CButton from "@/components/base/buttons/CButton.vue";
import {getRateTypeName} from "@/utils/RateType";
import NotFoundSlate from "@/components/NotFoundSlate.vue";
import LoadingBox from "@/components/LoadingBox.vue";

export default {
    name: "CampaignItemDetailView",
    metaTags() {
        return {
            title: this.$t('view_campaignItemDetail_metaTitle') + ' - ' + this.$t('common_meta_title'),
        }
    },
    components: {
        LoadingBox,
        NotFoundSlate,
        CButton,
        TextButton,
        CampaignItemState,
        SvgIcon,
        ProgressBar,
        SimpleBreadcrumb,
    },
    props: {
        value: {},
    },
    data() {
        return {
            loadingCampaign: false,
            campaignExists: null,
        };
    },
    computed: {
        ...mapGetters("auth", ["isRetailer", 'isVendor', 'isAgency']),
        breadcrumbs() {
            const that = this;

            const breadcrumbs = [
                {
                    title: that.$t('common_campaignsManagement'),
                    current: false,
                    onClick: () => {
                        that.$router.push({name: "campaignList"})
                    },
                },
            ];

            if (that.campaignItem !== null) {
                breadcrumbs.push({
                    title: this.campaignBreadcrumbName,
                    current: false,
                    onClick: () => {
                        that.closeDetail();
                    },
                });
                breadcrumbs.push({
                    title: that.campaignItem.generatedName,
                    current: 'page',
                });
            }

            return breadcrumbs;
        },
        campaign() {
            return this.$store.getters['campaigns/currentCampaign'];
        },
        campaignBreadcrumbName() {
            if (!this.campaign) {
                return null;
            }

            let name = this.campaign.name;
            if ((this.isRetailer || this.isAgency) && this.campaign && this.campaign.company) {
                name = name + ' (' + this.campaign.company.name + ')';
            }

            return name;
        },
        campaignItem() {
            if (!this.campaign) {
                return null;
            }
            const campaignItemId = this.$route.params.campaignItemId;
            return this.campaign.campaignItems.find((campaignItem) => campaignItem.id.toString() === campaignItemId.toString());
        },
        product() {
            if (!this.campaignItem) {
                return null;
            }
            return this.campaignItem.product;
        },
        rateTypeName() {
            if (!this.product) {
                return null;
            }
            return getRateTypeName(this.product.rateType);
        },
        isFlatRate() {
            if(!this.product){
                return null;
            }
            return (this.product.rateType === "Flat Rate");
        },
        progress() {
            if (!this.campaignItem) {
                return 0;
            }
            return Math.round(this.campaignItem.progress);
        },
        startDate() {
            if (!this.campaign || !this.campaignItem) {
                return null;
            }
            if (!this.campaignItem.startDate) {
                return '';
            }
            return this.$d(parseISO(this.campaignItem.startDate));
        },
        endDate() {
            if (!this.campaign || !this.campaignItem) {
                return null;
            }
            if (!this.campaignItem.endDate) {
                return '';
            }
            return this.$d(parseISO(this.campaignItem.endDate));
        },
        sasLineItemURL() {
            if (!this.campaign || !this.campaignItem) {
                return null;
            }
            if (this.campaignItem.sasInfo) {
                return this.campaignItem.sasInfo.lineItemURL;
            }
            return null;
        },
        sasFlightURL() {
            if (!this.campaign || !this.campaignItem) {
                return null;
            }

            // 74394 - only for retailer
            if (!this.isRetailer) {
                return null;
            }
            if (this.campaignItem.sasInfo) {
                return this.campaignItem.sasInfo.flightURL;
            }
            return null;
        },
        sasCreativeURL() {
            if (!this.campaignItem || !this.campaignItem.sasInfo) {
                return null;
            }
            return this.campaignItem.sasInfo.creativeURL;
        },
        sasZipCreativeURL() {
            if (!this.campaignItem || !this.campaignItem.sasInfo) {
                return null;
            }
            return this.campaignItem.sasInfo.zipCreativeURL;
        },
        displayOfPerformanceMetrics() { // dispay of performance metrics is allowed
            return this.$store.getters['appSettings/displayOfPerformanceMetrics'];
        },
        displayOfPerformanceMetricsGroup() { // dispay of performance metrics is allowed
            return this.$store.getters['appSettings/displayOfPerformanceMetricsGroup'];
        },
    },
    methods: {
        creativeItemPreview() {
            this.$router.push({name: 'campaignItemCreativePreview', params: {id: this.$route.params.campaignItemId}});
        },
        closeDetail() {
            this.$router.push({name: 'campaignDetail', params: {id: this.$route.params.campaignId}});
        },
        async loadData() {
            const campaignId = this.$route.params.campaignId;
            try {
                this.loadingCampaign = true;
                await this.$store.dispatch('campaigns/setCurrentCampaign', {campaignId: campaignId});
                this.$metaTags({title: this.$t('view_campaignItemDetail_metaTitle') + ' „' + this.campaignItem.generatedName + '“ - ' + this.$t('common_meta_title'),});
                this.campaignExists = true;
            } catch (error) {
                this.campaignExists = false;
                console.log(this);
            } finally {
                this.loadingCampaign = false;
            }
        },
    },
    async beforeRouteEnter(to, from, next) {
        await next(async (vm) => {
            await vm.loadData();
        });
    },
    async beforeRouteUpdate(to, from, next) {
        await next(async (vm) => {
            await vm.loadData();
        });
    }
}
</script>

<style scoped>

</style>
