<template>
    <base-button
        class="transition duration-200 ease-out rounded-full border-none flex items-center disabled:cursor-not-allowed disabled:text-gray-500 disabled:bg-gray-300"
        :class="classes"
        v-bind="$attrs"
        :tag-name="tagName"
        :to="to"
        :href="href"
        :disabled="isDisabled"
        @click="$emit('click', $event)"
    >
        <slot name="prepend">
            <span v-if="prependIcon" :class="prependIconWrapperClasses" >
                <c-icon v-if="!loading" :icon="prependIcon" :class="prependIconClasses"></c-icon>
                <icon-spinner v-if="loading" :class="appendIconClasses"></icon-spinner>
            </span>
        </slot>
        <slot></slot>
        <slot name="append">
            <span v-if="appendIcon" :class="appendIconWrapperClasses">
                <c-icon v-if="!loading" :icon="appendIcon" :class="appendIconClasses"></c-icon>
                <icon-spinner v-if="loading" :class="appendIconClasses"></icon-spinner>
            </span>
        </slot>
    </base-button>
</template>


<script>
import BaseButton from "@/components/base/BaseButton.vue";
import CIcon from "@/components/base/icons/CIcon.vue";
import IconSpinner from "@/components/base/icons/IconSpinner.vue";

const buttonSizes = {
    small: 'text-[12px] px-4 py-1',
    medium: 'text-sm px-6 py-2',
    big: 'text-base px-8 py-3',
};

const buttonPaddingPrepend = {
    small: 'pl-2',
    medium: 'pl-4',
    big: 'pl-6',
};

const buttonPaddingAppend = {
    small: 'pr-2',
    medium: 'pr-4',
    big: 'pr-6',
};

const buttonColors = {
    green: 'text-blue-700 bg-green-400 hover:bg-blue-400 hover:text-green-400 active:focus:outline active:focus:outline-2 active:focus:outline-offset-1 active:focus:outline-blue-500',
    blue: 'text-blue-700 bg-blue-50 hover:bg-blue-100 hover:border-blue-100 active:focus:outline active:focus:outline-2 active:focus:outline-offset-1 active:focus:outline-blue-500',
    red: 'text-white border-red-400 bg-red-500 hover:bg-red-600 hover:text-white hover:border-red-500 hover:bg-red-60'
}

const iconSizes = {
    small: 'w-3 h-3',
    medium: 'w-3 h-3',
    big: 'w-3.5 h-3.5',
}

const prependIconMargin = {
    small: 'mr-1.5',
    medium: 'mr-2',
    big: 'mr-3'
};

const appendIconMargin = {
    small: 'ml-1.5',
    medium: 'ml-2',
    big: 'ml-3',
};

const iconWrapper = {
    small: 'flex rounded-full p-1',
    medium: 'flex rounded-full p-1',
    big: 'flex rounded-full p-1.5',
};

const iconWrapperColor = {
    green: 'bg-green-200 text-black',
    blue: 'bg-none',
};


export default {
    name: "CButton",
    components: {IconSpinner, CIcon, BaseButton},
    props: {
        size: {
            type: String,
            default: 'medium'
        },
        color: {
            type: String,
            default: 'green'
        },
        prependIcon: {
            type: String,
            default: ''
        },
        prependIconClass: {
            type: String,
            default: ''
        },
        appendIcon: {
            type: String,
            default: ''
        },
        appendIconClass: {
            type: String,
            default: ''
        },
        loading: {
            type: Boolean,
            default: false
        },
        tagName: {
            type: String,
            default: ''
        },
        to: {
            type: Object,
            default: null
        },
        href: {
            type: String,
            default: undefined
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        classes() {
            const classes = [
                buttonSizes[this.size],
                buttonColors[this.color],
            ];
            if (this.prependIcon) {
                classes.push(buttonPaddingPrepend[this.size]);
            }
            if (this.appendIcon) {
                classes.push(buttonPaddingAppend[this.size]);
            }
            return classes;
        },
        prependIconClasses() {
            return [
                iconSizes[this.size],
                this.prependIconClass,
            ];
        },
        appendIconClasses() {
            return [
                iconSizes[this.size],
                this.appendIconClass,
            ];
        },
        prependIconWrapperClasses() {
            const classes = [
                !this.disabled ? iconWrapperColor[this.color] :  'bg-gray-300 text-gray-500',
                prependIconMargin[this.size],
            ];
            if (this.color === 'green') {
                classes.push(iconWrapper[this.size]);
            }
            return classes;
        },
        appendIconWrapperClasses() {
            const classes = [
                iconWrapperColor[this.color],
                appendIconMargin[this.size],
            ];
            if (this.color === 'green') {
                classes.push(iconWrapper[this.size]);
            }
            return classes;
        },
        isDisabled() {
            return this.disabled || this.loading;
        }
    }
};
</script>

<style scoped>

</style>
