import axios from "@/plugins/axios";
import Vue from "vue";
import {NotFoundError} from "@/errors/api";

function createDefaultFilter() {
    return {
        myCampaigns: false,
        campaignStateIdes: [],
        companyIdes: [],
        companyContactIdes: [],
        startDate: null,
        endDate: null,
        search: '',
        salesPersonIdes: [],
        sort: null,
        offset: 0,
        limit: 10
    }
}

function defaultListData() {
    return {
        campaigns: [],
        total: 0,
        totalPrice: 0,
    };
}

let listController = new AbortController();

export default {
    namespaced: true,
    state: {
        filterData: null,
        listData: defaultListData(),
        listCounter: 0,
        filter: createDefaultFilter(),
        currentCampaign: null,
    },
    getters: {
        listData: function (state) {
            return state.listData;
        },
        filterData: function (state) {
            if (state.filterData) {
                return state.filterData;
            }
            return null;
        },
        filter: function (state) {
            return state.filter;
        },
        currentCampaign: (state) => state.currentCampaign
    },
    mutations: {
        setFilterData(state, filterData) {
            state.filterData = filterData;
        },
        SET_CURRENT_CAMPAIGN(state, campaign) {
            state.currentCampaign = campaign;
        },
        SET_MEDIA(state, media) {
            state.currentCampaign.campaignItems.forEach((item) => {
                const currentMediaIndex = item.itemMedia.findIndex(med => med.id === media.id);
                if (currentMediaIndex < 0) {
                    return;
                }
                Vue.set(item.itemMedia, currentMediaIndex, media);
            })
        },
        CLEAR_FILTER(state) {
            state.filter = createDefaultFilter();
        },
        SET_FILTER(state, filter) {
            state.filter = filter;
        },
        SET_ITEM(state, campaignItem) {
            const index = state.currentCampaign.campaignItems.findIndex((item) => {
                return item.id === campaignItem.id;
            })
            Vue.set(state.currentCampaign.campaignItems, index, campaignItem);
        },
        REMOVE_CAMPAIGN_ITEM(state, campaignItemId) {
            const index = state.currentCampaign.campaignItems.findIndex((item) => {
                return item.id === campaignItemId;
            })
            Vue.delete(state.currentCampaign.campaignItems, index);
        },

        REMOVE_CAMPAIGN(state, campaignId) {
            Vue.delete(state.currentCampaign, campaignId);
        },

        SET_LIST_DATA(state, listData) {
            if(!listData) {
                listData = defaultListData();
            }
            listData = {...defaultListData(), ...listData};
            Vue.set(state, 'listData', listData);
        },
        INCREMENT_LIST_COUNTER(state) {
            state.listCounter++;
        }
    },
    actions: {
        async getCampaigns() {
            const response = await axios.get('/campaigns');
            return response.data;
        },
        async filterCampaigns(context, {
            sort,
            myCampaigns,
            campaignStateIdes,
            startDate,
            endDate,
            search,
            limit,
            offset,
            companyIdes,
            companyContactIdes,
            salesPersonIdes
        }) {
            const data = {limit, offset};
            if (sort) {
                data.order = [sort]
            }
            if (myCampaigns) {
                data.myCampaigns = myCampaigns;
            }
            if (startDate) {
                data.startDate = startDate;
            }
            if (endDate) {
                data.endDate = endDate;
            }
            if (search) {
                data.search = search;
            }
            if (campaignStateIdes && campaignStateIdes.length > 0) {
                data.campaignStateIdes = campaignStateIdes;
            }
            if (companyIdes && companyIdes.length > 0) {
                data.companyIdes = companyIdes;
            }
            if (companyContactIdes && companyContactIdes.length > 0) {
                data.companyContactIdes = companyContactIdes;
            }
            if (salesPersonIdes && salesPersonIdes.length > 0) {
                data.salesPersonIdes = salesPersonIdes;
            }
            context.commit('INCREMENT_LIST_COUNTER');
            const listCounter = context.state.listCounter;
            listController.abort();
            listController = new AbortController();
            return axios.post('/campaigns/filter', data, {
                signal: listController.signal
            }).then(
                (response) => {
                    if (listCounter !== context.state.listCounter) {
                        return;
                    }
                    context.commit('SET_LIST_DATA', response.data)
                }
            ).catch((error) => {
                if (error.message === 'canceled') {
                    return;
                }
                throw error;
            });
        },
        async getCampaign(context, {campaignId}) {
            const retailer = context.rootGetters['auth/isRetailer'];

            let campaignPromise = axios.get('/campaigns/' + campaignId);
            let campaign;
            try {
            if(retailer) {
                let sasInfoPromise = axios.get('/sas-info/campaign/' + campaignId);
                [campaignPromise, sasInfoPromise] = await Promise.all([
                    campaignPromise,
                    sasInfoPromise
                ]);
                campaign = campaignPromise.data;
                const sasInfo = sasInfoPromise.data;
                // connect sasInfo to campaign and campaign items
                campaign.sasInfo = sasInfo;
                campaign.campaignItems.forEach((item) => {
                    const infos = sasInfo.campaignItemInfos.filter((info) => {
                        return item.id == info.campaignItemId;
                    });
                    if(infos.length > 0) {
                        item.sasInfo = infos[0];
                    }
                });
            } else {
                [campaignPromise] = await Promise.all([
                    campaignPromise
                ]);
                campaign = campaignPromise.data;
            }
            } catch (error) {
                if(error.response && error.response.status === 404) {
                    throw new NotFoundError(error.response.data.message);
                }
                throw error;
            }

            return campaign;
        },
        async setCurrentCampaign(context, {campaignId}) {
            context.commit('SET_CURRENT_CAMPAIGN', null);
            const campaign = await context.dispatch("getCampaign", {campaignId});
            context.commit('SET_CURRENT_CAMPAIGN', campaign);
            return campaign;
        },
        async setCurrentCampaignByCampaign(context, {campaign}) {
            context.commit('SET_CURRENT_CAMPAIGN', null);
            const retailer = context.rootGetters['auth/isRetailer'];
            if (retailer) {
                let sasInfoPromise = axios.get('/sas-info/campaign/' + campaign.id);
                [sasInfoPromise] = await Promise.all([
                    sasInfoPromise
                ]);
                const sasInfo = sasInfoPromise.data;
                // connect sasInfo to campaign and campaign items
                campaign.sasInfo = sasInfo;
                campaign.campaignItems.forEach((item) => {
                    const infos = sasInfo.campaignItemInfos.filter((info) => {
                        return item.id == info.campaignItemId;
                    });
                    if (infos.length > 0) {
                        item.sasInfo = infos[0];
                    }
                });
            }
            context.commit('SET_CURRENT_CAMPAIGN', campaign);
            return campaign;
        },
        async createCampaign(context, {name, companyId, contactId, salesPersonId, agencyId, agencyContactId}) {
            const data = {
                name
            };
            if (companyId) {
                data.companyId = companyId;
            }
            if (contactId) {
                data.companyContactId = contactId;
            }
            if (salesPersonId) {
                data.salesPersonId = salesPersonId;
            }
            if (agencyId) {
                data.agencyId = agencyId;
            }
            if (agencyContactId) {
                data.agencyContactId = agencyContactId;
            }
            const response = await axios.post('/campaigns/create', data);
            return response.data;
        },
        async updateCampaign(context, {campaignId, salesPersonId , name}) {
            try {
                const response = await axios.put(`/campaigns/${campaignId}`, {salesPersonId, name});
                return await context.dispatch('setCurrentCampaignByCampaign', {campaign: response.data});
            } catch (error) {
                throw Error(error.response.data.message)
            }
        },
        async addCampaignItem(
            context,
            {
                id,
                productId,
                note,
                startDate,
                endDate,
                orderedAmount,
                variableValueIdes,
                customProductPrice,
                targetURL,
                targetProductId,
                targetLandingPageId,
                targetKeywords
            }
        ) {
            const response = await axios.post(`/campaigns/${id}/add-item`, {
                productId,
                note: note || '',
                startDate: startDate || null,
                endDate: endDate || null,
                orderedAmount: orderedAmount || null,
                variableValueIdes: variableValueIdes,
                customProductPrice: customProductPrice || null,
                targetURL: targetURL || null,
                targetProductId: targetProductId || null,
                targetLandingPageId: targetLandingPageId || null,
                targetKeywords: targetKeywords || []
            });
            return response.data;
        },
        async updateCampaignItem(context, {
            campaignItem,
            startDate,
            endDate,
            note,
            orderedAmount,
            variableValueIdes,
            customProductPrice,
            targetURL,
            targetProductId,
            targetLandingPageId,
            targetKeywords
        }) {
            const data = {
                startDate,
                endDate,
                note,
                orderedAmount,
                variableValueIdes,
                customProductPrice,
                targetURL,
                targetProductId,
                targetLandingPageId,
                targetKeywords
            };

            await axios.put(`/campaign-item/${campaignItem.id}`, data);

            if (context.state.currentCampaign) {
                await context.dispatch('setCurrentCampaign', {campaignId: context.state.currentCampaign.id});
            }
        },
        async deleteCampaign(context, { campaignId }) {
            await axios.delete(`/campaigns/${campaignId}`);
            context.commit('REMOVE_CAMPAIGN', campaignId);
        },
        async deleteCampaignItem(context, {id}) {
            await axios.delete(`/campaign-item/${id}`);
            context.commit('REMOVE_CAMPAIGN_ITEM', id);
        },
        async unsetCurrentCampaign(context) {
            context.commit('SET_CURRENT_CAMPAIGN', null);
        },
        async checkCampaignItemAvailability(context, {productId, startDate, endDate, orderedAmount, variableValueIdes}) {
            const response = await axios.put(`/campaign-item/check-availability`, {
                productId,
                startDate: startDate,
                endDate: endDate,
                orderedAmount: orderedAmount || null,
                variableValueIdes: variableValueIdes,
            });
            return response.data;
        },
        async updateCampaignItemMessage(context, {campaignItemId, message}) {
            try {
                await axios.put(`/campaign-item/${campaignItemId}/message`, {message});
                if (context.state.currentCampaign) {
                    await context.dispatch('setCurrentCampaign', {campaignId: context.state.currentCampaign.id});
                }
            } catch (error) {
                throw Error(error.response.data.message)
            }
        },
        async getFilterData({commit}) {
            const response = await axios.get("/campaigns/filterData");
            if (response) {
                commit("setFilterData", response.data);
                return response.data;
            }
        },
        setFilter(context, {filter}) {
            context.commit('SET_FILTER', filter);
        },
        async setFilterSort(context, {sort}) {
            let filter = context.state.filter;
            filter.sort = sort;
            context.commit('SET_FILTER', filter);
        },
        setFilterFirstResult(context, {firstResult}) {
            let filter = context.state.filter;
            filter.offset = firstResult;
            context.commit('SET_FILTER', filter);
        },
        setFilterMaxResults(context, { maxResults }) {
            let filter = context.state.filter;
            filter.limit = maxResults;
            filter.offset = 0;
            context.commit('SET_FILTER', filter);
        },
        clearFilter(context) {
            context.commit('CLEAR_FILTER');
        },
        async fileInfo(context, {creativeTemplateId}) {
            const response = await axios.post('/campaigns/media/' + creativeTemplateId + '/file-info', {
            });
            return response.data;
        },
        async filePreview(context, {creativeTemplateId}) {
            const response = await axios.post('/campaigns/media/' + creativeTemplateId + '/file-preview', {
            });
            return response.data;
        },
        uploadFile(context, {file, progressFunc, id}) {
            return new Promise((resolve, reject) => {
                let data = new FormData();
                data.append("file", file);
                axios
                    .post(`/campaigns/media/${id}/file`, data, {
                        onUploadProgress: progressFunc,
                    })
                    .then((response) => {
                        context.commit('SET_MEDIA', response.data)
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        uploadZipFile(context, {file, progressFunc, id}) {
            return new Promise((resolve, reject) => {
                let data = new FormData();
                data.append("file", file);
                axios.post(`/campaign-item/${id}/creative-zip`, data, {
                    onUploadProgress: progressFunc,
                })
                    .then((response) => {
                        context.commit('SET_ITEM', response.data)
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        async updateMedia(context, media) {
            const response = await axios.put('/campaigns/media/' + media.id, media);
            context.commit('SET_MEDIA', response.data)
            return response.data;
        },
        async requestCampaignCheck(context, {campaignId}) {
            const response = await axios.post('/campaigns/' + campaignId + '/request-check');
            return await context.dispatch('setCurrentCampaignByCampaign', {campaign: response.data});
        },
        async possibleStates(context, {campaignId}) {
            const response = await axios.get('/campaigns/' + campaignId + '/possible-states');
            return response.data;
        },
        async changeState(context, {campaignId, stateId}) {
            const response = await axios.post('/campaigns/' + campaignId + '/change-state', {
                stateId
            });
            return await context.dispatch('setCurrentCampaignByCampaign', {campaign: response.data});
        },
        async approve(context, {campaignId}) {
            const response = await axios.post('/campaigns/' + campaignId + '/approve');
            return await context.dispatch('setCurrentCampaignByCampaign', {campaign: response.data});
        },
        async rejectToVendor(context, {campaignId, reason}) {
            const response = await axios.post('/campaigns/' + campaignId + '/reject-to-vendor', {
                reason
            });
            return await context.dispatch('setCurrentCampaignByCampaign', {campaign: response.data});
        },
        async approveByRetailer(context, {campaignId}) {
            try {
                const response = await axios.post('/campaigns/' + campaignId + '/approve-by-retailer');
                return await context.dispatch('setCurrentCampaignByCampaign', {campaign: response.data});
            } catch (error) {
                throw Error(error.response.data.message);
            }
        },
        async campaignItemApprove(context, {campaignItemId}) {
            const response = await axios.post('/campaign-item/' + campaignItemId + '/approve');
            context.commit('SET_ITEM', response.data);
            return response.data;
        },
        async requestCampaignItemCheck(context, {campaignItemId}) {
            const response = await axios.post(`/campaign-item/${campaignItemId}/request-check`);
            context.commit('SET_ITEM', response.data)
            return response.data;
        },
        async changeOnlinePause(context, { campaignItemId }) {
            const response = await axios.post(`/campaign-item/${campaignItemId}/change-online-pause`);
            context.commit('SET_ITEM', response.data)
            return response.data;
        },
        async setCreativeToCampaignItem(context, {campaignItemId, creativeId}) {
            const response = await axios.post(`/campaign-item/${campaignItemId}/creative`, {
                creativeId
            });
            context.commit('SET_ITEM', response.data)
            return response.data;
        }
    }
};
