<template>
    <validation-provider
        ref="validationProvider"
        tag="label"
        :rules="computedRules"
        :name="label"
        class="relative w-full block mb-4"
        v-bind="$attrs"
        v-slot="{errors}"
        :detect-input="false"
    >
        <slot name="label" v-bind="{disabled, errors, label, readonly}">
            <CInputLabel :disabled="disabled" :errors="errors" :label="label" :readonly="readonly"/>
        </slot>
        <base-button
            class="
                block w-full py-3.5 rounded-3xl px-5 bg-white border border-gray-500
                flex justify-between items-center
                transition duration-100 ease-in-out
                disabled:opacity-40 disabled:cursor-not-allowed
                readonly:opacity-40 readonly:cursor-not-allowed
            "
            :disabled="disabled || readonly"
            :class="{
                    'border-red-500': errors.length > 0,
                    'bg-input-required bg-no-repeat': required,
                }"
            type="button"
            @click.prevent.stop="open = true"
        >
            <div v-if="selectedOptions.length > 0 && multiple" class="flex items-center justify-start">
                <base-button
                    v-for="option in selectedOptions"
                    :key="option.value"
                    type="button"
                    @click.prevent.stop="toggle(option)"
                >
                    <slot name="selectedOption" v-bind="{option}">
                        <span class="rounded-full bg-green-400 text-white px-2 py-1 text-sm mr-1 flex items-center">
                            <span>{{ option.label }}</span>
                            <c-icon icon="x" class="w-3 h-3 ml-1"></c-icon>
                        </span>
                    </slot>
                </base-button>
            </div>
            <div v-else-if="selectedOptions.length > 0">
                {{ selectedOptions[0].label }}
            </div>
            <div v-else>
                <span class="text-gray-400">{{ placeholder }}</span>
            </div>
            <c-icon icon="arrow-down" class="w-4 h-4 ml-2.5 text-gray-600"></c-icon>
        </base-button>
        <base-dropdown
            v-model="open"
            class="absolute w-full mt-1 border rounded-lg shadow-sm z-40 bg-white border-gray-300"
        >
            <div v-if="searchable" class="p-1">
                <c-input v-model="search" :placeholder="searchPlaceholderText"></c-input>
            </div>
            <div class="flex flex-col p-1">
                <base-button
                    v-for="option in displayedOptions"
                    :key="option.value"
                    type="button"
                    @click.prevent.stop="toggle(option)"
                >
                    <slot name="option" v-bind="{option}">
                        <div
                            class="flex justify-between items-center px-3 py-2 hover:bg-blue-100"
                            :class="{ 'bg-green-100': option.selected}"
                        >
                            {{ option.label }}
                        </div>
                    </slot>
                </base-button>
            </div>
        </base-dropdown>
        <info-message v-if="errors.length > 0" class="mt-1" type="error">
            {{ errors[0] }}
        </info-message>
    </validation-provider>
</template>

<script>
import BaseDropdown from "@/components/base/BaseDropdown.vue";
import {ValidationProvider} from "vee-validate";
import CInputLabel from "@/components/base/form/CInputLabel.vue";
import BaseButton from "@/components/base/BaseButton.vue";
import InfoMessage from "@/components/base/InfoMessage.vue";
import CIcon from "@/components/base/icons/CIcon.vue";
import CInput from "@/components/base/form/CInput.vue";
import inputMixin from "@/components/base/form/inputMixin";

export default {
    name: "CSelect",
    components: {CInput, CIcon, InfoMessage, BaseButton, CInputLabel, BaseDropdown, ValidationProvider},
    mixins: [inputMixin],
    props: {
        value: {
            type: [String, Number, Array, Boolean],
            default: () => {
                return this.multiple ? [] : '';
            },
        },
        placeholder: {
            type: String,
            default: ''
        },
        options: {
            type: Array,
            default: () => {
                return null;
            },
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        searchable: {
            type: Boolean,
            default: false,
        },
        searchPlaceholder: {
            type: String,
            default: null,
        },
        valueAttribute: {
            type: String,
            default: null,
        },
        labelAttribute: {
            type: String,
            default: null,
        },
        searchProvider: {
            type: Function,
            default: null,
        },
        getProvider: {
            type: Function,
            default: null,
        },
    },
    data() {
        return {
            open: false,
            search: '',
            availableOptions: [],
            selectedOptions: [],
            searchNumber: 0,
        };
    },
    computed: {
        isAjax() {
            return this.searchProvider !== null || this.getProvider !== null;
        },
        displayedOptions() {
            return this.availableOptions.map(option => ({
                ...option,
                selected: this.multiple ? this.value.includes(option.value) : this.value === option.value
            }));
        },
        searchPlaceholderText() {
            return this.searchPlaceholder ? this.searchPlaceholder : this.$t('common_search');
        },
    },
    watch: {
        async open() {
            if (this.open) {
                if(this.isAjax) {
                    await this.ajaxSearch();
                }
                this.$emit('focus');
            } else {
                this.validate(this.value);
                this.$emit('blur');
            }
        },
        async search() {
            if(!this.isAjax) {
                this.availableOptions = this.options.map(this.normalizeOption).filter(option => {
                    return option.label.toLowerCase().includes(this.search.toLowerCase());
                });
            } else {
                await this.ajaxSearch();
            }
        },
        async value() {
            await this.loadSelectedOptions();
        },
        options: {
            handler() {
                if(this.isAjax) {
                    return;
                }
                this.availableOptions = this.options.map(this.normalizeOption);
            },
            deep: true,
        }
    },
    methods: {
        normalizeOption(option) {
            return {
                value: this.valueAttribute ? option[this.valueAttribute] : option,
                label: this.labelAttribute ? option[this.labelAttribute] : option,
            };
        },
        toggle(option) {
            let newValue = option.value;
            const oldValue = this.multiple ? [...this.value] : this.value;
            if (this.multiple) {
                if (this.value.includes(option.value)) {
                    this.selectedOptions.splice(
                        this.selectedOptions.findIndex((selectedOption) => selectedOption.value === option.value),
                        1
                    );
                } else {
                    this.selectedOptions.push(option);
                }
                newValue = this.selectedOptions.map(option => option.value);
            } else {
                if(this.selectedOptions.length > 0) {
                    this.selectedOptions.splice(0, 1)
                }
                this.selectedOptions.push(option);
            }

            this.validate(newValue);
            if (!this.multiple){
                this.open = false;
            }
            this.$emit('input', newValue);
            this.$emit('change', newValue, oldValue);

        },
        async ajaxSearch() {
            const current = this.searchNumber++;
            const searched = await this.searchProvider(this.search);
            if(current !== this.searchNumber) {
                return;
            }
            this.availableOptions = searched.map(this.normalizeOption);
        },
        async validate(value) {
            const _this = this;
            this.$refs.validationProvider.validate(value).then(
                (result) => {
                    _this.$refs.validationProvider.applyResult(result);
                }
            );
        },
        async getSelectOption(value) {
            const selected = this.selectedOptions.find(option => option.value === value);
            if (selected) {
                return selected;
            }
            if (!this.isAjax) {
                return this.options.map(this.normalizeOption).find(option => option.value === value);
            } else {
                const option = await this.getProvider(value);
                return this.normalizeOption(option);
            }
        },
        async loadSelectedOptions() {
            const values = this.multiple ? this.value : [this.value];
            this.selectedOptions = (await Promise.all(
                values.map(value => this.getSelectOption(value))
            )).filter(option => option !== undefined);
        }
    },
    async created() {
        if(!this.isAjax) {
            this.availableOptions = this.options.map(this.normalizeOption);
        }
        await this.loadSelectedOptions();
    }
}
</script>

<style scoped>

</style>
