<template>
<div class="max-w-2xl m-auto px-3">
    <div class="max-w-lg loginModal">
        <loading-box v-model="submitting" rounded>
            <div class="text-center mb-12">
                <h1 class="login__title">
                    {{$t('view_forgottenPassword_title')}}
                </h1>
                <p v-if="notValidRecovery">
                    {{ $t('view_forgottenPassword_descriptionNotValidHash')}}
                </p>
                <p v-else>

                </p>
            </div>
            <form v-if="!recovery" ref="form" @submit.prevent="submit" :class="submitting ? 'is-loading' : ''">
                <div class="mt-9 mb-5">
                    <t-input v-model="login" :placeholder="$t('common_email')" required type="email"
                    class="rounded-full mb-4"></t-input>
                    <p v-if="error.length" class="rounded-full px-3 py-1 bg-red-50 text-red-500">{{ error }}</p>
                </div>

                <div class="flex w-full space-y-6 mt-6 items-center flex-col  lg:w-auto lg:space-x-6 lg:flex-row lg:space-y-0 lg:justify-end">
                    <t-button :to="{ name: 'login' }" tagName="a" variant="link" class="text-gray-400">
                        {{ $t('view_forgottenPassword_buttonBackToLogin') }}
                    </t-button>
                    <c-button
                        size="big"
                        class=""
                        :disabled="submitting"
                        :class="submitting? 'flex items-center': ''"
                        type="submit"
                        :loading="submitting"
                    >
                        {{ $t('common_send') }}
                    </c-button>
                </div>
            </form>
            <div v-else class="text-center -mt-8"> <!-- Hlášky po odeslání emailu s linkem -->
                <p class="text-base">
                    {{ $t('view_forgottenPassword_successMessage', { email: recovery.username, validity: validity})}}
                </p>
                <c-button :to="{ name: 'login' }" size="big" class="mx-auto mt-4 inline-flex">
                    {{ $t('view_forgottenPassword_buttonBackToLogin') }}
                </c-button>
            </div>
        </loading-box>
    </div>
</div>

</template>

<script>
import LoadingBox from '../components/LoadingBox.vue';
import {mapActions, mapGetters} from "vuex";
import {parseISO} from "date-fns";
import {DeactivatedUserError} from "@/errors/login";
import CButton from "@/components/base/buttons/CButton.vue";


export default {
    name: "ForgottenPasswordView",
    metaTags() {
        return {
            title:  this.$t('view_forgottenPassword_metaTitle') + ' - ' + this.$t('common_meta_title'),
        }
    },
    components: {
        CButton,
        LoadingBox
    },
    data: () => {
        return {
            login: "",
            error: "",
            recovery: null,
            submitting: false,
        };
    },
    computed: {
        validity() {
            if (!this.recovery) {
                return '';
            }
            const date = parseISO(this.recovery.validityTo);
            if (!(date instanceof Date)) {
                return this.recovery.validityTo;
            }
            return this.$d(date, "long");
        },
        ...mapGetters("account", ["notValidRecovery"])
    },
    methods: {
        async submit() {
            this.submitting = true;
            this.error = "";
            try {
                const recovery = await this.$store.dispatch('account/createPasswordReset', {login: this.login});
                if (recovery) {
                    this.recovery = recovery;
                } else {
                    this.error = this.$t('view_forgottenPassword_errorMessage', {email: this.login});
                }
            } catch (err) {
                if (err instanceof DeactivatedUserError) {
                    this.error = this.$t('view_login_error_deactivated');
                }
            }
            this.submitting = false;
        },
        ...mapActions("account", ["handleNotValidRecovery"]),
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.error = "";
        });
    },
    beforeRouteLeave(to, from, next) {
        if (this.notValidRecovery) {
            this.handleNotValidRecovery();
        }
        next();
    }
};
</script>

<style scoped></style>
