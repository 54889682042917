<template>
    <div>
        <horizontal-tabs v-model="currentTab">
            <horizontal-tab-content
                identifier="native"
                :tab-name="$t('component_productcard_tab_targetLink')"
                :hidden="!allowCatalogURL"
            >
                <p class="font-bold">{{ $t('component_productcard_url') }}</p>
                <div class="basis-full shrink">
                    <target-product-input-picker
                        ref="targetPicker"
                        v-model="targetProduct"
                        :nativeFormat="isNativeProduct"
                        :targetId="targetId"
                        :companyRestrictions="companyRestrictions"
                    >
                    </target-product-input-picker>
                    <p v-if="error" class="px-3 pb-1 text-gray-600 text-xs">
                        {{error}}
                    </p>
                </div>
                <t-button
                    v-if="displayLinkButton"
                    class="shrink-0"
                    variant="mediumCircleBlue"
                    @click="showFilter = !showFilter"
                    :href="targetURL"
                    target="_blank"
                >
                    <c-icon class="w-4 h-4" icon="eye"></c-icon>
                </t-button>
            </horizontal-tab-content>

            <horizontal-tab-content
                identifier="landingPage"
                :tab-name="this.$t('component_targetTabImput_targetLandingPage')"
                :hidden="!allowLandingPage"
            >
                <p class="font-bold">{{ $t('component_productcard_url') }}</p>
                <div class="basis-full shrink">
                    <target-landing-page-input-picker
                        ref="targetLandingPagePicker"
                        v-model="targetLandingPage"
                        :target-id="targetId"
                        :company-id="companyId"
                    >
                    </target-landing-page-input-picker>
                    <p v-if="error" class="px-3 pb-1 text-gray-600 text-xs">
                        {{error}}
                    </p>
                </div>
                <t-button
                    v-if="displayLinkButton"
                    variant="mediumCircleBlue"
                    class="shrink-0"
                    @click="showFilter = !showFilter"
                    :href="targetURL"
                    target="_blank"
                >
                    <c-icon class="w-4 h-4" icon="eye"></c-icon>
                </t-button>
            </horizontal-tab-content>

            <horizontal-tab-content
                identifier="custom"
                :tab-name="$t('component_productcard_tab_ownLink')"
                :hidden="!allowCustomURL"
            >
                <p class="font-bold">{{ $t('component_productcard_url') }}</p>
                <div class="basis-full shrink">
                    <t-input v-model="targetURL" @change="updateTargetProductByURL(targetURL)"></t-input>
                    <p v-if="error" class="px-3 pb-1 text-gray-600 text-xs">
                        {{error}}
                    </p>
                </div>
                <t-button
                    v-if="displayLinkButton"
                    variant="mediumCircleBlue"
                    class="shrink-0"
                    @click="showFilter = !showFilter"
                    :href="targetURL"
                    target="_blank"
                >
                    <c-icon class="w-4 h-4" icon="eye"></c-icon>
                </t-button>
            </horizontal-tab-content>

            <horizontal-tab-content
                identifier="new"
                :tab-name="$t('component_productcard_tab_newPage')"
                :hidden="!allowNewPageRequest"
            >
                <div class="sm:flex items-center justify-between bg-gray-50 rounded-xl py-4 px-6 -mx-6">
                    <p class="font-bold">{{ $t('component_productcard_tab_newPage_description') }}</p>
                </div>
            </horizontal-tab-content>
        </horizontal-tabs>
    </div>
</template>

<script>
import HorizontalTabs from "@/components/HorizontalTabs";
import HorizontalTabContent from "@/components/HorizontalTabContent";
import TargetProductInputPicker from "@/components/TargetProductInputPicker";
import TargetLandingPageInputPicker from "@/components/landing-page/TargetLandingPageInputPicker";
import CIcon from "@/components/base/icons/CIcon.vue";

const NEW_PAGE = 'New page';

export default {
    name: "TargetTabInput",
    components: {
        CIcon,
        TargetProductInputPicker,
        HorizontalTabContent,
        HorizontalTabs,
        TargetLandingPageInputPicker
    },
    props: {
        value: {
            type: Object
        },
        product: {
            type: Object
        },
        companyRestrictions: {
            type: Object,
            default: () => ({})
        },
    },
    data() {
        return {
            currentTab: '',
            error: ''
        }
    },
    computed: {
        targetURL: {
            get() {
                return this.value.targetURL != null ? this.value.targetURL : "";
            },
            async set(targetURL) {
                if(targetURL == '') {
                    targetURL = null;
                }
                let returnValue = {
                    targetURL: targetURL,
                    targetProduct: null,
                    targetLandingPage: null,
                };
                this.$emit('input', returnValue);
                const _this = this;
                this.$nextTick(_this.validate);
            }
        },
        targetProduct: {
            get() {
                return this.value.targetProduct;
            },
            async set(targetProduct) {
                let returnValue = {
                    targetURL: targetProduct === null ? null : targetProduct.url,
                    targetProduct: targetProduct,
                    targetLandingPage: null,
                };
                this.$emit('input', returnValue);
            }
        },
        targetLandingPage: {
            get() {
                return this.value.targetLandingPage;
            },
            async set(targetLandingPage) {
                let path = '';
                if (targetLandingPage !== null) {
                    path = this.$router.resolve({
                        name: 'landingPageDetail',
                        params: {landingPageId: targetLandingPage.id}
                    }).href;
                }
                let returnValue = {
                    targetURL: targetLandingPage === null ? null : `${window.location.protocol}//${window.location.host}${path}`,
                    targetProduct: null,
                    targetLandingPage: targetLandingPage,
                };
                this.$emit('input', returnValue);
            }
        },
        companyId() {
            return this.companyRestrictions.advertiserCompanyId;
        },
        isNativeProduct() {
            return this.product.nativeFormat;
        },
        displayLinkButton() {
            return this.targetURL && this.targetURL !== NEW_PAGE;
        },
        targetId() {
            if(!this.product.productCategory.target) {
                return null;
            }
            return this.product.productCategory.target.id;
        },
        allowCatalogURL() {
            const campaignItemsTargetURL = this.$store.getters['appSettings/campaignItemsTargetURL'];
            return this.product.nativeFormat // native product always have catalog url
                || campaignItemsTargetURL.allowCatalogURL;
        },
        allowLandingPage() {
            const allowDynamicLandingPages = this.$store.getters['appSettings/allowDynamicLandingPages'];
            return !this.product.nativeFormat
                && allowDynamicLandingPages
        },
        allowCustomURL() {
            return !this.product.nativeFormat
        },
        allowNewPageRequest() {
            const campaignItemsTargetURL = this.$store.getters['appSettings/campaignItemsTargetURL'];
            return campaignItemsTargetURL.allowNewPageRequest
                && !this.product.nativeFormat
        },
    },
    watch: {
        currentTab(value) {
            if (value === 'new') {
                let returnValue = {
                    targetURL: NEW_PAGE,
                    targetProduct: null
                };
                this.$emit('input', returnValue);
            }
            const _this = this;
            this.$nextTick(() => _this.validate())
        },
        value: {
            handler(value) {
                this.validate(value);
            },
            deep: true
        },
        error(value) {
            this.$emit('error', value.length > 0);
        }
    },
    methods: {
        async validate(targetObject) {
            targetObject = targetObject || this.value;
            const targetURL = targetObject.targetURL;
            const targetProduct = targetObject.targetProduct;
            const targetLandingPage = targetObject.targetLandingPage;

            if (targetURL === NEW_PAGE) {
                this.error = '';
                return;
            }

            let error = '';
            if (this.currentTab === 'custom' && !targetURL) {
                error = this.$t('component_UrlTabInput_isRequired');
            } else if (this.currentTab === 'custom') {
                try {
                    new URL(targetURL);
                } catch (err) {
                    error = this.$t('component_UrlTabInput_isNotValid', {domainUrl: this.product.productCategory.domainUrl})
                }
            } else if (this.currentTab === 'native') {
                if (!targetProduct) {
                    error = this.$t('component_UrlTabInput_notSelectedTargetProduct');
                }
            } else if (this.currentTab === 'landingPage') {
                if (!targetLandingPage) {
                    error = this.$t('component_UrlTabInput_notSelectedTargetLandingPage');
                } else {
                    this.error = '';
                    return;
                }
            }

            if (error === '' && !targetURL.startsWith(this.product.productCategory.domainUrl)) {
                error = this.$t('component_UrlTabInput_isNotAllowedDomain', {domainUrl: this.product.productCategory.domainUrl})
            }

            this.error = error;
        },
        async selectTab() {
            if (this.value.targetURL === NEW_PAGE) {
                this.currentTab = 'new';
            } else if (this.value.targetProduct) {
                this.currentTab = 'native';
            } else if (this.value.targetLandingPage) {
                this.currentTab = 'landingPage';
            } else {
                this.currentTab = 'custom';
            }
            if(this.isNativeProduct && this.currentTab !== 'native') {
                this.currentTab = 'native';
            }
            await this.validate(this.value)
        },
        /**
         * tries to update target product according to filled url
         * @param targetURL
         */
        async updateTargetProductByURL(targetURL) {
            let targetProduct = await this.$store.dispatch(
                'targeting/searchTargetProductByURL',
                {targetId: this.targetId, url: targetURL}
            );
            let returnValue = {
                targetURL: targetURL,
                targetProduct: targetProduct
            };
            this.$emit('input', returnValue);
        }
    },
    async created() {
        await this.selectTab();
    }
}
</script>

<style scoped>

</style>
