<template>
    <div>
        <label v-if="all === 'top'"
            class="cursor-pointer inline-flex items-center w-full mb-2"
        >
            <t-checkbox v-model="allVal"/>
            <checkbox-icon />
            <span class="leading-tight">{{allLabelText}}</span>
        </label>
        <hr v-if="all === 'top'">

        <div
            class="space-y-4"
            :class="{
                'mt-3':all === 'top',
                'mb-2':all === 'bottom',
            }"
        >
            <label v-for="option in options" :key="getValue(option)" class="cursor-pointer inline-flex items-center w-full">
                <t-checkbox v-model="val" :value="getValue(option)"/>
                <checkbox-icon />
                <span class="leading-tight">{{ getText(option) }}</span>
            </label>
        </div>

        <hr v-if="all === 'bottom'">
        <label v-if="all === 'bottom'"
               class="cursor-pointer inline-flex items-center w-full mt-3"
        >
            <t-checkbox v-model="allVal"/>
            <checkbox-icon />
            <span class="leading-tight">{{allLabelText}}</span>
        </label>
    </div>
</template>

<script>
import CheckboxIcon from "@/components/icons/CheckboxIcon";
export default {
    name: "CheckboxList",
    components: {CheckboxIcon},
    props: {
        value: {
            type: Array
        },
        options: {
            type: Array,
        },
        textAttribute: {
            type: String,
            default: null
        },
        valueAttribute: {
            type: String,
            default: null
        },
        all: {
            type: String,
            default: null
        },
        allLabel: {
            type: String,
            default: null
        },
        allChecking: {
            type: Boolean,
            default: false,
        }
    },
    computed: {
        val: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value)
            }
        },
        allVal: {
            get() {
                if(this.allChecking) {
                    return this.value.length === this.options.length;
                } else {
                    return this.value.length === 0;
                }
            },
            set(value) {
                if(this.allChecking && value) {
                    this.$emit('input', this.options.map((option) => option[this.valueAttribute]));
                } else {
                    this.$emit('input', [])
                }
            }
        },
        allLabelText() {
            return this.allLabel || this.$t('common_all')
        }
    },
    methods: {
        getValue(option) {
            if(this.valueAttribute) {
                return option[this.valueAttribute];
            }
            return option
        },
        getText(option) {
            if(this.textAttribute) {
                return option[this.textAttribute];
            }
            return option
        }
    }
}
</script>

<style scoped>

</style>
