import i18n from "@/plugins/i18n";

const RateType = {
    FlatRate: "Flat Rate",
    CPC: "CPC",
    CPM: "CPM"
};
export default RateType;

export const getRateTypeNameShort = (rateType) => {
    switch(rateType) {
        case RateType.FlatRate:
            return i18n.t('common_rateType_flatRateShort');
        case RateType.CPC:
            return i18n.t('common_rateType_CPC');
        case RateType.CPM:
            return i18n.t('common_rateType_CPM');
        default:
            return '';
    }
};

export const getRateTypeName = (rateType) => {
    switch(rateType) {
        case RateType.FlatRate:
            return i18n.t('common_rateType_flatRate');
        case RateType.CPC:
            return i18n.t('common_rateType_CPC');
        case RateType.CPM:
            return i18n.t('common_rateType_CPM');
    }
}
