<template>
    <div>
        <label class="relative inline-flex items-center cursor-pointer">
            <input type="checkbox" v-model="valueChange" class="sr-only peer">
            <div class="w-14 h-8 bg-gray-300 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300  rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[4px] after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all peer-checked:bg-blue-400"></div>
        </label>
    </div>

</template>

<script>

export default {
    name: "ToggleSwitch",
    components: {

    },
    props: {
        value: {
            type: Boolean
        },
    },
    data() {
        return {
            error: ''
        }
    },
    computed: {
        valueChange: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            },
        },

    },
    watch: {},
    methods: {},
    async created() {

    }
}
</script>

