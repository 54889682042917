var render = function render(){var _vm=this,_c=_vm._self._c;return _c(_vm.tag,_vm._b({tag:"component",class:{
        'cursor-pointer': !_vm.disabled,
    },attrs:{"disabled":_vm.disabled},on:{"click":function($event){_vm.disabled || _vm.$emit('click', $event)}}},'component',{
        ..._vm.$attrs,
        ...(_vm.href ? { href: _vm.href } : {}),
        ...(_vm.routerTo ? { to: _vm.routerTo } : {}),
        ...(_vm.routerLinkTag ? { tag: _vm.routerLinkTag } : {}),
    },false),[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }