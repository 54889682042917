<template>
	<div class="max-w-screen-2xl pt-1 pb-8 px-4 md:px-8 mx-auto">
        <simple-breadcrumb :items="breadcrumbs"></simple-breadcrumb>

        <div class="w-full">
            <h1 class="text-purple-100 h1 mt-5 mb-4">{{ $t('component_SettingsVariablesView_title') }}</h1>

            <loading-box theme="light" v-model="loading">
                <div class="mt-4 lg:pr-6">
                    <div class="mb-3" v-for="settingsVariableGroup in settingsVariableGroups" :key="settingsVariableGroup.id">
                        <h2 class="h2 font-medium">{{ settingsVariableGroup.name }}</h2>

                        <dynamic-table :items="settingsVariableGroup.variables">
                            <dynamic-table-column identifier="name" class="w-full">
                                {{ $t('common_name') }}
                            </dynamic-table-column>
                            <dynamic-table-column identifier="value" class="text-center">
                                {{ $t('common_settings') }}
                            </dynamic-table-column>
                            <dynamic-table-column identifier="actions" class="text-right">
                                {{ $t('common_actions') }}
                            </dynamic-table-column>
                            <template v-slot:value="item">
                                <div class="text-center">
                                    <boolean-column v-if="item.type === 'bool'" :value="item.value"></boolean-column>
                                    <div v-else>{{item.value}}</div>
                                </div>
                            </template>
                            <template v-slot:actions="item">
                                <div class="text-center">
                                    <t-button
                                        class="ml-auto"
                                        variant="editCircle"
                                        :title="$t('common_edit')"
                                        @click.prevent="editVariable(item)"
                                    >
                                        <c-icon class="w-3.5 h-3.5" icon="edit-pencil"/>
                                    </t-button>
                                </div>
                            </template>
                        </dynamic-table>
                    </div>
                </div>

                <settings-variable-edit-dialog
                    v-if="variableToEdit"
                    :settings-variable="variableToEdit"
                    @close="variableToEdit = null"
                    @change="handleChange($event)"
                />
            </loading-box>
        </div>
    </div>
</template>

<script>
import SimpleBreadcrumb from "@/components/SimpleBreadcrumb";
import LoadingBox from "@/components/LoadingBox.vue";
import SettingsVariableEditDialog from "@/components/SettingsVariableEditDialog.vue";
import DynamicTable from "@/components/universal/dynamic-table/DynamicTable.vue";
import DynamicTableColumn from "@/components/universal/dynamic-table/DynamicTableColumn.vue";
import BooleanColumn from "@/components/universal/dynamic-table/BooleanColumn.vue";
import CIcon from "@/components/base/icons/CIcon.vue";

export default {
    name: "SettingsVariablesView",
    metaTags() {
        return {
            title:  this.$t('component_SettingsVariablesView_title') + ' - ' + this.$t('common_meta_title'),
        }
    },
    components: {
        CIcon,
        BooleanColumn,
        DynamicTableColumn,
        DynamicTable,
        LoadingBox,
        SettingsVariableEditDialog,
        SimpleBreadcrumb
    },
    data() {
        return {
            settingsVariableGroups: null,
            variableToEdit: null
        }
    },
    computed: {
        loading() {
            return this.settingsVariableGroups == null;
        },
        breadcrumbs() {
            const that = this;

            return [
                {
                    title: that.$t('common_settings'),
                    current: false,
                },
                {
                    title: this.campaignBreadcrumbName,
                    current: 'page',
                },
            ];
        },
    },
    methods: {
        async loadData() {
            try {
                this.settingsVariableGroups =  await this.$store.dispatch('settings/getAllVariableGroups');
            } catch {
                // intentionally
            }
        },
        editVariable(variable) {
            this.variableToEdit = variable;
        },
        async saveVariable(variable) {
            console.log(variable);
        },
        handleChange(changedValue) {
            this.settingsVariableGroups.forEach(group => {
                const variable = group.variables.find(variable => variable.id === changedValue.id)
                if (variable) {
                    variable.value = changedValue.value;
                }
            });
            this.variableToEdit = null;
        }
    },
    beforeRouteEnter(to, from, next) {
        next(async (vm) => {
            await vm.loadData();
        });
    },
    async beforeRouteUpdate() {
        await this.loadData();
    },
};
</script>
