<template>
    <div class="flex w-full items-center">
        <div>
            <c-input
                :placeholder="placeholderName"
                type="text"
                v-model="displayName"
            />
            <c-input
                :placeholder="placeholderDescription"
                type="text"
                v-model="displayDescription"
            />
        </div>
        <t-button
            @click.prevent="submitChanges"
            variant="editCircle"
            class="ml-auto"
        >
            <c-icon class="w-3.5 h-3.5" icon="check"></c-icon>
        </t-button>
    </div>
</template>

<script>
import CIcon from '@/components/base/icons/CIcon.vue';
import CInput from '@/components/base/form/CInput.vue';

export default {
    name: 'CreativeNameDescriptionUpdate',
    components: {
        CIcon,
        CInput,
    },
    props: {
        placeholderName: {
            type: String,
            default: '',
        },
        placeholderDescription: {
            type: String,
            default: '',
        },
        fieldIndex: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            name: '',
            description: '',
        };
    },
    methods: {
        submitChanges() {
            this.$emit('displayValuesSubmit');
        },
    },
    computed: {
        displayName: {
            get() {
                return this.name ?? '';
            },
            set(value) {
                this.name = value;
                this.$emit('displayNameUpdate', value);
            },
        },
        displayDescription: {
            get() {
                return this.description ?? '';
            },
            set(value) {
                this.description = value;
                this.$emit('displayDescriptionUpdate', value);
            },
        },
    },
};
</script>
