import {configure, extend} from 'vee-validate';
import {max, max_value, min_value, required} from "vee-validate/dist/rules";
import i18n from "@/plugins/i18n";

configure({
    // this will be used to generate messages.
    defaultMessage: (field, values) => {
        values._field_ = field;
        return i18n.t(`validations_messages_${values._rule_}`, values);
    }
});

extend('required', {
    ...required
});

extend('max', max);
extend('min_value', min_value);
extend('max_value', max_value);
extend('sku_count', {
    validate(value, args) {
        return value.length >= args.length;
    },
    params: ['length']
});
extend('sku_count_max', {
    validate(value, args) {
        return value.length <= args.length;
    },
    params: ['length']
});

