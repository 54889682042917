<template>
    <div v-bind="$attrs">
        <creative-input
            v-for="(fieldValue, index) in fieldsToHandle"
            :key="fieldValue.id"
            class="whiteBlock bg-white mx-1"
            :last="index == value.length - 1"
            :value="fieldsToHandle[index]"
            :target-id="targetId"
            :company-id="companyId"
            @input="changed"
        ></creative-input>
    </div>
</template>

<script>
import CreativeInput from "@/components/creative/CreativeInput.vue";

export default {
    name: "CreativeFieldsInput",
    components: {CreativeInput},
    props: {
        value: {
            type: Array,
            required: true
        },
        targetId: {
            type: Number,
            required: true
        },
        companyId: {
            type: Number,
            required: true
        }
    },
    computed: {
        fieldsToHandle() {
            return this.value
                .filter(fv => !fv.autoConverted)
                .filter(fv => fv.formatField.mediaTypeCruxo !== 'product-shop-data')
        }
    },
    methods: {
        changed(fieldValue) {
            const fieldValues = [...this.value];
            const index = fieldValues.findIndex(fv => fv.formatField.id === fieldValue.formatField.id);
            fieldValues[index] = fieldValue;
            this.$emit('input', fieldValues);
        }
    }

}
</script>

<style scoped>

</style>
