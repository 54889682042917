<template>
    <div class="w-full bg-gray-500 rounded-md text-center relative overflow-hidden">
        <div class="absolute inset-0 bg-green-600 origin-left" :style="{ transform: 'scaleX('+ progressPercent+')'}"></div>
        <div class="text-sm py-1 font-semibold relative z-5 tracking-wider text-white">{{ progress }} {{ text }}</div>
    </div>
</template>

<script>

export default {
    name: "ProgressBar",

    props: {
        maxProgress: {
            default: 0
        },
        progress: {
            default: 0
        },
        text: {
            type: String,
            default: ''
        }
    },
    computed: {
        progressPercent() {
            if (this.progress > this.maxProgress) {
                return 1;
            }

            return this.progress / this.maxProgress;
        }
    }
}
</script>
