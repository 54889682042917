<template>
    <div>
        <c-button
            :append-icon="icon"
            :color="color"
            size="medium"
            append-icon-class="w-5 h-5"
            :loading="loading"
            @click.prevent.stop="click"
        >
            {{ text }}
        </c-button>
        <input type="file" class="hidden" ref="input" @change="selectFile" :accept="accepted">
    </div>
</template>

<script>

import CButton from "@/components/base/buttons/CButton.vue";

export default {
    name: "FileStateButton",
    components: {
        CButton
    },
    props: {
        itemType: {
            type: String,
            default: 'default'
        },
        percents: {
            type: Number
        },
        accept: {
            type: Array
        }
    },
    data: () => {
        return {
            val: null
        };
    },
    computed: {
        accepted() {
            if (!Array.isArray(this.accept) || this.accept.length === 0) {
                return undefined;
            }
            return this.accept.join(', ');
        },
        color() {
            if (this.itemType === 'wrong') {
                return 'red';
            }
            return 'blue';
        },
        icon() {
            if (this.itemType === 'approved') {
                return 'online';
            } else if (this.itemType === 'wrong') {
                return 'x';
            }
            return 'upload';
        },
        text() {
            if (this.itemType === 'loading') {
                return '' + this.percents + ' %';
            } else if (this.itemType === 'approved') {
                return this.$t('component_fileStateButton_uploaded');
            } else if (this.itemType === 'wrong') {
                return this.$t('component_fileStateButton_wrongFormat');
            }
            return this.val ? this.val.name : this.$t('common_uploadFile');
        },
        loading() {
            return this.itemType === 'loading';
        }
    },
    methods: {
        click() {
            this.$refs.input.click();
        },
        selectFile(event) {
            if (event.target.files.length > 0) {
                this.val = event.target.files[0];
                this.$emit('file', this.val);
            }
        },
    }
}
</script>
