<template>
	<span class="w-8 h-8 rounded-full bg-cyan-100 flex border border-cyan-600 hover:bg-cyan-600">
		<svg width="20" height="20" viewBox="0 0 20 20" fill="none" class="m-auto text-cyan-700 group-hover:text-cyan-900 transition-colors duration-200" xmlns="http://www.w3.org/2000/svg">
			<path d="M10.7906 5.19702L14.6353 9.04178M10.7906 5.19702L12.9876 3L16.8323 6.84479L14.6353 9.04178L10.7906 5.19702ZM10.7906 5.19702L3.32846 12.6591C3.18279 12.8047 3.10095 13.0024 3.10095 13.2083V16.7314H6.62399C6.83001 16.7314 7.02758 16.6496 7.17325 16.5039L14.6353 9.04178L10.7906 5.19702Z" stroke="currentColor" stroke-width="1.39818" stroke-linecap="round" stroke-linejoin="round"/>
		</svg>
	</span>
</template>

<script>

export default {
	name: "EditIcon",
};
</script>
