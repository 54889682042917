<template>
	<span class="w-6 h-6 rounded-full bg-blue-100 flex ml-3">
		<svg-icon name="arrow" class="w-3 m-auto transition-transform duration-200" :class="isOpen ? '' : 'rotate-180'"></svg-icon>
	</span>
</template>

<script>
import SvgIcon from "@/components/SvgIcon";

export default {
	name: "ArrowIcon",
    props: ['isOpen'],
	components: {
        SvgIcon,
	}
}
</script>
