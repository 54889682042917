<template>
    <div class="relative">
        <t-datepicker
            class="text-center border-gray-500 mt-2 mb-6 md:my-0"
            v-model="model"
            ref="input"
            :lang="lang"
            :placeholder="placeholder"
            :disabled="disabled"
            :disabled-dates="disabledDates"
            :weekStart="1"
            :clearable="false"
            :required="required"
            :user-format="userFormat"
        ></t-datepicker>
        <svg-icon
            name="calendar"
            class="absolute right-3 top-3"
            @click="iconClick"
        ></svg-icon>
    </div>
</template>
<script>
import SvgIcon from "@/components/SvgIcon"

export default {
    name: 'date-picker',
    components: {SvgIcon},
    props: {
        placeholder: {
            type: String,
        },
        required: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        disabledDates: {},
        userFormat: {
            type: String
        },
        value: {},
    },
    computed: {
        model: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        },
        lang() {
            const localeParts = this.$i18n.locale.split('-');
            return localeParts[0];
        }
    },
    methods: {
        iconClick() {
            this.$refs.input.focus();
        }
    }
}
</script>
