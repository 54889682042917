import axios from "@/plugins/axios";

export default  {
        namespaced: true,
        state: {
            creatives: null,
            campaignItemsTargetURL: null,
            displayOfPerformanceMetrics: null,
            defaultSalesPerson: null,
            dynamicLandingPages: null,
            menu: null,
        },
        getters: {
            allowCreativeZips: state => state.creatives.allowCreativeZips,
            campaignItemsTargetURLGroup: state => state.campaignItemsTargetURL,
            displayOfPerformanceMetricsGroup: state => state.displayOfPerformanceMetrics,
            displayOfPerformanceMetrics(state) {
                if(state.displayOfPerformanceMetrics === null) {
                    return false;
                }
                let displayOfPerformanceMetrics = state.displayOfPerformanceMetrics;
                return displayOfPerformanceMetrics.displayImpressions
                    || displayOfPerformanceMetrics.displayViews
                    || displayOfPerformanceMetrics.displayVisibilityRatio
                    || displayOfPerformanceMetrics.displayVisibilityClicks
                    || displayOfPerformanceMetrics.displayCtrRatio
                    || displayOfPerformanceMetrics.displayOspRatio
                    || displayOfPerformanceMetrics.displayHardConversions;
            },
            displayActualImpressions(state) {
                if(state.displayOfPerformanceMetrics === null) {
                    return false;
                }
                return state.displayOfPerformanceMetrics.displayImpressions;
            },
            displayActualViews(state) {
                if(state.displayOfPerformanceMetrics === null) {
                    return false;
                }
                return state.displayOfPerformanceMetrics.displayViews;
            },
            displayActualClicks(state) {
                if(state.displayOfPerformanceMetrics === null) {
                    return false;
                }
                return state.displayOfPerformanceMetrics.displayVisibilityClicks;
            },
            displayActualCtrRatio(state) {
                if(state.displayOfPerformanceMetrics === null) {
                    return false;
                }
                return state.displayOfPerformanceMetrics.displayCtrRatio;
            },
            displayVisibilityRatio(state) {
                if(state.displayOfPerformanceMetrics === null) {
                    return false;
                }
                return state.displayOfPerformanceMetrics.displayVisibilityRatio;
            },
            displayActualOspRatio(state) {
                if(state.displayOfPerformanceMetrics === null) {
                    return false;
                }
                return state.displayOfPerformanceMetrics.displayOspRatio;
            },
            displayHardConversions(state) {
                if(state.displayOfPerformanceMetrics === null) {
                    return false;
                }
                return state.displayOfPerformanceMetrics.displayHardConversions;
            },
            campaignItemsTargetURL: state => state.campaignItemsTargetURL,
            defaultSalesPerson: state => state.defaultSalesPerson,
            allowDynamicLandingPages(state) {
                if(state.dynamicLandingPages === null) {
                    return false;
                }
                return state.dynamicLandingPages.allowDynamicLandingPages;
            },
            displayAvailabilitiesMenuItem(state) {
                if(state.menu === null) {
                    return false;
                }
                return state.menu.displayAvailabilities;
            },
        },
        mutations: {
            SET_CREATIVES_SETTINGS: (state, creatives) => {
                state.creatives = creatives;
            },
            SET_CAMPAIGN_ITEMS_TARGET_URL_SETTINGS: (state, campaignItemsTargetURL) => {
                state.campaignItemsTargetURL = campaignItemsTargetURL;
            },
            SET_DISPLAY_OF_PERFORMANCE_METRICS: (state, displayOfPerformanceMetrics) => {
                state.displayOfPerformanceMetrics = displayOfPerformanceMetrics;
            },
            SET_DYNAMIC_LANDING_PAGES: (state, dynamicLandingPages) => {
                state.dynamicLandingPages = dynamicLandingPages;
            },
            SET_MENU_SETTINGS: (state, menu) => {
                state.menu = menu;
            },
        },
        actions: {
            async getAvailableUILocales() {
                let response = await axios.get("/app-settings/available-ui-locales");
                return response.data;
            },
            async reloadCreativesSettings(context) {
                let response = await axios.get("/app-settings/creatives");
                context.commit("SET_CREATIVES_SETTINGS", response.data);
            },
            async reloadCampaignItemsTargetURLSettings(context) {
                let response = await axios.get("/app-settings/campaign-items-target-url");
                context.commit("SET_CAMPAIGN_ITEMS_TARGET_URL_SETTINGS", response.data);
            },
            async reloadDisplayOfPerformanceMetricsSettings(context) {
                let response = await axios.get("/app-settings/display-of-performance-metrics");
                context.commit("SET_DISPLAY_OF_PERFORMANCE_METRICS", response.data);
            },
            async reloadDynamicLandingPages(context) {
                let response = await axios.get("/app-settings/dynamic-landing-pages");
                context.commit("SET_DYNAMIC_LANDING_PAGES", response.data);
            },
            async reloadMenuSettings(context) {
                let response = await axios.get("/app-settings/menu");
                context.commit("SET_MENU_SETTINGS", response.data);
            },
            async initAppSettings(context) {
                let promises = [];
                if(!this.creatives) {
                    promises.push(context.dispatch("reloadCreativesSettings"));
                }
                if(!this.campaignItemsTargetURL) {
                    promises.push(context.dispatch("reloadCampaignItemsTargetURLSettings"));
                }
                if(!this.displayOfPerformanceMetrics) {
                    promises.push(context.dispatch("reloadDisplayOfPerformanceMetricsSettings"));
                }
                if(!this.dynamicLandingPages) {
                    promises.push(context.dispatch("reloadDynamicLandingPages"));
                }
                if(!this.menu) {
                    promises.push(context.dispatch("reloadMenuSettings"));
                }
                await Promise.all(promises);
            }
        },
    };
