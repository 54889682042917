<template>
    <span
        v-if="label"
        v-bind="$attrs"
        class="text-gray-600 mb-1 block"
        :class="{
            'text-red-500': errors.length > 0,
            'text-gray-300': disabled || readonly
        }"
    >
        {{ label }}
    </span>
</template>
<script>
export default {
    name: 'CInputLabel',
    props: {
        disabled: {
            type: Boolean,
            default: false
        },
        errors: {
            type: Array,
            default: () => []
        },
        label: {
            type: String,
            default: ''
        },
        readonly: {
            type: Boolean,
            default: false
        }
    }
}
</script>
