<template>
    <div v-if="loaded" class="max-w-screen-2xl pt-1 pb-8 px-4 md:px-8 mx-auto">
        <simple-breadcrumb :items="breadcrumbs"></simple-breadcrumb>

        <div class="flex space-x-2 justify-end mb-4 lg:mb-4">
            <c-button
                v-if="displayCreativeSasLink"
                color="blue"
                size="medium"
                prepend-icon="eye"
                :href="campaignItem.sasInfo.creativeURL"
                target="_blank"
            >
                {{ $t('view_activityCreativeView_sasCreative') }}
            </c-button>
        </div>

        <header class="flex flex-wrap items-start justify-between mt-5 mb-3 space-y-2 lg:space-y-0">
            <div class="flex items-center mb-4 space-x-2 pr-3">
                <h1 class="text-purple-100 h1">{{ campaignItem.generatedName }}</h1>
            </div>
        </header>

        <creative-selection
            v-if="viewState === 'search'"
            :company="campaign.company"
            :campaign-item="campaignItem"
            @select="setCreativeToCampaignItem"
        >
            <template v-slot:buttons>
                <c-button color="green" size="big" @click="viewState = 'new'" prepend-icon="plus">
                    {{ $t('common_create_new_creative') }}
                </c-button>
            </template>
        </creative-selection>

        <div v-if="(viewState === 'edit' || viewState === 'new') && creativeEdit">
            <form class="bg-white rounded-2xl p-6" @submit.prevent.stop="saveCreative">
                <creative-edit-form
                    v-model="creativeEdit"
                    :campaign="campaign"
                    :campaign-item="campaignItem"
                    :allow-creative-zips="allowCreativeZips"
                ></creative-edit-form>

                <div class="w-full flex justify-end">
                    <c-button type="submit" color="green" size="big" prepend-icon="check" :loading="saving">
                        {{ $t('common_save') }}
                    </c-button>
                </div>

            </form>
        </div>
        <div v-if="viewState === 'preview'">

            <creative-preview v-if="creative" :creative="creative"></creative-preview>

            <div class="grid xl:grid-cols-3 md:grid-cols-3 grid-cols-2 gap-4">
                <creative-approval-form
                    v-if="isApprovable"
                    class="rounded-2xl bg-white p-6 mt-4 col-span-2"
                    :creative="creative"
                    @submit="submitApprove"
                >
                </creative-approval-form>
            </div>

            <div class="flex w-full space-y-6 mt-6 items-center flex-col  lg:w-auto lg:space-x-6 lg:flex-row lg:space-y-0 lg:justify-end">

                <text-button class="text-gray-400" :to="{name: 'campaignDetail', params: {campaignId: campaign.id}}">
                    {{ $t('common_back_to_campaign') }}
                </text-button>

                <c-button color="blue" size="big" @click="viewState = 'search'">
                    {{ $t('common_change') }}
                </c-button>
                <c-button v-if="canEditCreative" color="green" size="big" @click="viewState = 'edit'" prepend-icon="edit-pencil">
                    {{ $t('common_edit') }}
                </c-button>
                <c-button
                    v-if="displaySendForCheck"
                    color="green"
                    size="big"
                    prepend-icon="check"
                    :disabled="!enableSendForCheck"
                    :loading="requestingCreativeCheck"
                    @click="requestCreativeCheck"
                >
                    {{ $t('component_campaignItemDialog_buttonSendForApproval') }}
                </c-button>
            </div>


        </div>
    </div>
</template>

<script>

import SimpleBreadcrumb from "@/components/SimpleBreadcrumb.vue";
import CreativeSelection from "@/components/CreativeSelection.vue";
import CreativeEditForm from "@/components/creative/CreativeEditForm.vue";
import {isEqual} from "lodash";
import CreativePreview from "@/components/CreativePreview.vue";
import {mapGetters} from "vuex";
import {CreativeStates} from "@/utils/CreativeState";
import CreativeApprovalForm from "@/components/creative/CreativeApprovalForm.vue";

import Vue from "vue";
import TextButton from "@/components/base/buttons/TextButton.vue";
import CButton from "@/components/base/buttons/CButton.vue";

export default {
    name: "ActivityCreativeView",
    components: {
        CButton,
        TextButton,
        CreativeApprovalForm,
        CreativePreview, CreativeEditForm, CreativeSelection, SimpleBreadcrumb},
    data() {
        return {
            campaign: null,
            campaignItemId: null,
            viewState: 'search',
            creativeEdit: null,

            saving: false,
            requestingCreativeCheck: false,
            approvingInProgress: false,
        };
    },
    computed: {
        ...mapGetters('auth',['isRetailer']),
        ...mapGetters('appSettings', ['allowCreativeZips']),
        loaded() {
            return this.campaign;
        },
        campaignItem() {
            return this.campaign.campaignItems.find(item => item.id.toString() === this.campaignItemId.toString())
        },
        breadcrumbs() {
            if (!this.campaign || !this.campaignItem) {
                return [];
            }
            return [
                {
                    title: this.$t('common_campaignsManagement'),
                    current: false,
                    to: {name: "campaignList"},
                },
                {
                    title: this.campaignBreadcrumbName,
                    current: false,
                    to: {name: 'campaignDetail', params: {campaignId: this.campaign.id}},
                },
                {
                    title: this.campaignItem.generatedName,
                    current: false,
                    to: {
                        name: 'campaignItemDetail',
                        params: {campaignId: this.campaign.id, campaignItemId: this.campaignItem.id}
                    },
                },
                {
                    title: this.$t('common_creative'),
                    current: 'page'
                }
            ];
        },
        campaignBreadcrumbName() {
            let name = this.campaign.name;

            if ((this.isRetailer || this.isAgency) && this.campaign && this.campaign.company) {
                name = name + ' (' + this.campaign.company.name + ')';
            }

            return name;
        },
        creative() {
            if (!this.campaignItem) {
                return null;
            }
            return this.campaignItem.creative;
        },
        canEditCreative() {
            return (
                this.creative &&
                this.creative.creativeState !== CreativeStates.Approved
                && this.creative.creativeState !== CreativeStates.WaitingForApproval
            ) || this.isRetailer;
        },
        isEditedCreative() {
            return this.creative && this.creativeEdit && !isEqual(this.creative, this.creativeEdit);
        },
        enableSendForCheck() {
            return !this.sending && this.isCreativeFilled;
        },
        displaySendForCheck() {
            return !this.isRetailer
                && this.creative
                && this.creative.creativeState !== CreativeStates.Approved
                && this.creative.creativeState !== CreativeStates.WaitingForApproval;
        },
        enableApprovalByRetailer() {
            return this.isCreativeFilled
                && this.isRetailer
                && this.creative
                && this.creative.creativeState !== CreativeStates.Approved;
        },
        isCreativeFilled() {
            return this.creative
            && (
                this.creative.zipMediaExternalId
                || (
                    Array.isArray(this.creative.fieldValues) &&
                    this.creative.fieldValues.every(item => !item.formatField.required || item.filled)
                )
            );
        },
        isApprovable() {
            return this.isRetailer
                && this.creative
                && this.creative.creativeState !== CreativeStates.Approved;
        },
        displayCreativeSasLink() {
            return this.isRetailer && this.campaignItem.sasInfo && this.campaignItem.sasInfo.creativeURL;
        }
    },
    watch: {
        async viewState(value) {
            if (value === 'new') {
                this.creativeEdit = await this.$store.dispatch('creatives/createCreative', {productId: this.campaignItem.product.id, campaignItemId: this.campaignItem.id});
            } else if (value !== 'edit') {
                this.creativeEdit = null;
            } else if (value === 'edit' && this.creative) {
                this.creativeEdit = this.creative;
            }
        },
    },
    methods: {
        async loadData(to) {
            this.campaign = null;
            this.campaignItemId = null;
            const campaignId = to.params.campaignId;
            this.campaignItemId = to.params.campaignItemId;
            this.campaign = await this.$store.dispatch('campaigns/setCurrentCampaign', {campaignId});
            this.viewState = 'search';
            if (this.campaignItem.creative !== null || this.campaignItem.zipUploaded) {
                this.viewState = 'preview';
            }
        },
        async saveCreative() {
            if (!this.creativeEdit) {
                return;
            }
            let creative = null;
            this.saving = true;
            if (this.creativeEdit.id) {
                creative = await this.$store.dispatch('creatives/updateCreative', this.creativeEdit);
            } else {
                this.creativeEdit.companyId = this.campaign.company.id;
                creative = await this.$store.dispatch(
                    'creatives/saveCreative',
                    {
                        productId: this.campaignItem.product.id,
                        creative: this.creativeEdit,
                    }
                );
            }
            this.saving = false;
            return await this.setCreativeToCampaignItem(creative);
        },
        async setCreativeToCampaignItem(creative) {
            await this.$store.dispatch('campaigns/setCreativeToCampaignItem', {
                campaignItemId: this.campaignItem.id,
                creativeId: creative.id,
            })
            this.viewState = 'preview';
        },
        async requestCreativeCheck() {
            this.requestingCreativeCheck = true;
            const creative = await this.$store.dispatch("creatives/requestCreativeCheck", {
                creativeId: this.creative.id,
            });
            Vue.set(this.campaignItem, 'creative', creative);
            this.requestingCreativeCheck = false;
            this.show = false;
        },
        async submitApprove() {
            if (this.creative && this.creative.id) {
                await this.$store.dispatch('campaigns/setCreativeToCampaignItem', {
                    campaignItemId: this.campaignItem.id,
                    creativeId: this.creative.id,
                })
            }
            await this.loadData(this.$route);
        },
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.loadData(to);
        });
    },
    beforeRouteUpdate(to, from, next) {
        this.loadData(to);
        next();
    },
}
</script>


<style scoped>

</style>
