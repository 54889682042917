<template>
    <div>
        <span class="inline-block mb-2 font-medium">{{
                $t('component_userSalesPersonList_selected_sales_persons')
            }}</span>
        <div class="w-full rounded-2xl bg-white border border-gray-300 p-4 pt-6">
            <sortable-multiple-select
                v-if="options"
                v-model="valueChange"
                :fetch-options="fetchOptions"
                :fetch-key-option="getOption"
                text-attribute="name"
                value-attribute="id"
            ></sortable-multiple-select>
            </div>
    </div>

</template>

<script>
import SortableMultipleSelect from "@/components/SortableMultipleSelect.vue";

export default {
    name: "UserSalesPersonList",
    components: {SortableMultipleSelect},
    props: {
        "value" : Array,
    },
    data: () => {
        return {
            options: []
        };
    },
    computed: {
        valueChange: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value)
            }
        }
    },
    methods: {
        async getOptions() {
            this.options = await this.$store.dispatch('users/getSalesPersonUsers');
        },
        fetchOptions(query) {
            let items = [];
            if(this.options) {
                items = this.options.filter((item) => {
                    return item.active === true && item.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
                });
            }
            return ({results: items});
        },
        async getOption(id) {
            return await this.$store.dispatch('users/getUser', {userId: id});
        },
    },
    // obsluha eventu pri create component
    created() {
        this.getOptions();
    },

};
</script>
