import axios from "@/plugins/axios";

export default {
    namespaced: true,
    state: {
    },
    getters: {
    },
    mutations: {
    },
    actions: {
        async getTargets() {
            const response = await axios.get('/targeting/target');
            return response.data;
        },
        async searchTargets(context, { name }) {
            const response = await axios.get('/targeting/target/search', {params: {name: name}});
            return response.data;
        },
        async searchTargetProducts(context, searchData) {
            let {targetId, name, advertiserCompanyId, advertiserCompanyContactId } = searchData;

            if(!advertiserCompanyId && !advertiserCompanyContactId) {
                const campaign = context.rootGetters['campaigns/currentCampaign'];
                if (campaign) {
                    advertiserCompanyId = campaign.company.id;
                    if (campaign.companyContact) {
                        advertiserCompanyContactId = campaign.companyContact.id;
                    }
                }
            }

            const response = await axios.get('/targeting/target-product/search', {
                params: {
                    targetId: targetId,
                    name: name,
                    advertiserCompanyId,
                    advertiserCompanyContactId
                }
            });

            return response.data;
        },
        async validateTargetProduct(context, {targetProductId, advertiserCompanyId, advertiserCompanyContactId}) {
            const response = await axios.get('/targeting/target-product/validate', {
                params: {
                    targetProductId,
                    advertiserCompanyId,
                    advertiserCompanyContactId
                }
            });
            return response.data;
        },
        async searchTargetProductByURL(context, {targetId, url}) {
            try {
                let data = {
                    targetId: targetId,
                    url: url
                };

                const response = await axios.get('/targeting/target-product/search-url', {params: data});
                return response.data;
            } catch (e) {
                if (e.response.status === 404) {
                    return null;
                }
            }
        },
        async searchTargetProductsBySku(context, {targetId, sku}) {
            try {
                let data = {
                    targetId: targetId,
                    sku: sku
                };

                const response = await axios.get('/targeting/target-product/search-sku', {params: data});
                return response.data;
            } catch (e) {
                if (e.response.status === 404) {
                    return null;
                }
            }
        }
    }
};
