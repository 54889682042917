<template>
    <t-modal v-model="show">
        <div class="md:p-16 py-8 px-4">
            <div class="mx-auto">
                <slot>

                </slot>
            </div>
        </div>
        <template v-slot:button>
            <div class="w-8 h-8 bg-blue-100 rounded-full flex">
                <svg-icon name="x" class="w-3 h-3 m-auto"></svg-icon>
            </div>
        </template>
    </t-modal>
</template>

<script>
import SvgIcon from "@/components/SvgIcon.vue";

export default {
    name: "SimpleDialog",
    components: {SvgIcon},
    props: {
        value: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        show: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        }
    },
    watch: {
        value(value) {
            this.$emit(value ? 'open' : 'close');
        }
    }
}
</script>

<style scoped>

</style>
