<template>
    <t-rich-select
        ref="select"
        class="w-full"
        v-model="url"
        :options="options"
        value-attribute="id"
        text-attribute="name"
    ></t-rich-select>
</template>

<script>

export default {
    name: "TargetInputPicker",
    props: {
        value: {
            type: Number,
            nullable: true,
            default: null
        },
    },
    data() {
        return {
            show: false,
            items: [],
            options: [],
            targetLinkSource: null
        }
    },
    computed: {
        placeholder() {
            return '';
        },
        error() {
            if (!this.value) {
                return this.$t('component_targetLinkSourceInputPicker_is_required');
            }

            return '';
        },
        url: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        }
    },
    watch: {
        error(value) {
            this.$emit('error', !!value);
        }
    },
    methods: {
        async fetchTargets(query) {
            const items = await this.$store.dispatch('targeting/searchTargets', {name: query});
            return ({results: items});
        }
    },
    async created() {
        this.options = await this.$store.dispatch('targeting/getTargets');
        this.$refs.select;
    },
}
</script>

<style scoped>

</style>
