<template>
    <div>
        <div class="bg-white rounded-2xl p-6 w-full mt-8 flex justify-start items-start">
            <div class="h2 mr-5">{{creative.name}}</div>
           <div class="ml-auto pt-2">
               <creative-state :state="creative.creativeState"></creative-state>
                <div v-if="isZipUploaded" class="text-right mt-2 text-gray-700 text-xs text-upp">
                    {{ $t('view_activityCreative_zipUploaded') }}
                </div>
           </div>
        </div>
        <div v-if="hasInfoMessages" class="bg-white rounded-2xl p-6 w-full mt-8 flex flex-col space-y-2">
            <info-message v-if="displayRejectReason" type="info" class="w-full">
                <strong>{{ $t('common_rejectReason') }}:</strong>
                <div class="ml-2">{{creative.notes}}</div>
            </info-message>
            <info-message v-if="missingFields" type="error" class="w-full">
                <strong>{{ $t('common_missingFields') }}:</strong>
                <span class="ml-2">{{ missingFields }}</span>
            </info-message>
        </div>
        <div class="grid xl:grid-cols-3 md:grid-cols-3 grid-cols-2 mt-8 gap-4">
            <creative-preview-value
                v-for="fieldValue in fieldsToHandle"
                :key="fieldValue.id"
                :field-value="fieldValue"
            ></creative-preview-value>
        </div>
    </div>
</template>

<script>
import CreativePreviewValue from "@/components/CreativePreviewValue.vue";
import CreativeState from "@/components/CreativeState.vue";
import InfoMessage from "@/components/base/InfoMessage.vue";
import {CreativeStates} from "@/utils/CreativeState";

export default {
    name: "CreativePreview",
    components: {
        InfoMessage,
        CreativeState,
        CreativePreviewValue
    },
    props: {
        creative: {
            type: Object,
            required: true
        }
    },
    computed: {
        isZipUploaded() {
            return this.creative.zipMediaExternalId;
        },
        displayRejectReason() {
            return this.creative.creativeState === CreativeStates.Returned;
        },
        hasInfoMessages() {
            return this.displayRejectReason || this.missingFields;
        },
        missingFields() {
            if (this.isZipUploaded) {
                return '';
            }
            return this.creative.fieldValues
                .filter(fv => fv.formatField.required && !fv.filled)
                .map(fv => fv.formatField.name)
                .join(', ');
        },
        fieldsToHandle() {
            return this.creative.fieldValues
                .filter(fv => !fv.autoConverted)
                .filter(fv => fv.formatField.mediaTypeCruxo !== 'product-shop-data')
        }
    }
}
</script>

<style scoped>

</style>
