<template>
        <div class="max-w-screen-2xl px-8 md:px-8 mx-auto">
            <footer class="text-center p-4 border-t-gray-200 border-t-[1px]">
                <p class="text-gray-400 text-xs">Copyright © CRUXO All rights reserved</p>
            </footer>
        </div>
</template>
<script>
export default {
    name: "MainFooter"
};
</script>
