import { render, staticRenderFns } from "./DeleteCampaignItemDialog.vue?vue&type=template&id=e8dc2ade&scoped=true&"
import script from "./DeleteCampaignItemDialog.vue?vue&type=script&lang=js&"
export * from "./DeleteCampaignItemDialog.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e8dc2ade",
  null
  
)

export default component.exports