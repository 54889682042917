<template>
    <div>
        <images-grid :images="images" @image="image"></images-grid>

        <div
            class="flex flex-col md:flex-row justify-between text-center"
            data-cy="pagination"
        >
            <page-items-filter
                :itemsPerPageProp="itemsPerPage"
                :onPageItem="onPageItem"
            ></page-items-filter>
            <page-picker
                v-model="page"
                :total-pages="pages"
                :className="customClass"
            ></page-picker>
        </div>
    </div>
</template>

<script>
import PagePicker from "@/components/PagePicker.vue";
import { mapGetters } from "vuex";
import ImagesGrid from "@/components/base/ImagesGrid.vue";
import PageItemsFilter from "@/components/PageItemsFilter.vue";

export default {
    name: "OneImageSelect",
    components: { PagePicker, ImagesGrid, PageItemsFilter },
    data: function () {
        return {
            itemsPerPage: ["10", "20", "50"],
            customClass: "onImageSelect__pagePicker",
        };
    },
    computed: {
        ...mapGetters("images", ["images", "pages"]),
        page: {
            get: function () {
                return this.$store.getters["images/page"];
            },
            set: async function (page) {
                await this.$store.dispatch("images/listImages", { page: page });
            },
        },
    },
    methods: {
        image(image) {
            this.$emit("image", image);
        },
    },
    created() {
        this.$store.dispatch("images/listImages", { page: this.page });
    },
};
</script>

<style scoped></style>
