<template>
    <div>
        <span class="inline-block mb-2 font-medium">
            {{ $t('component_userBrandsList_selected_brands') }}
        </span>
        <t-rich-select
            ref="select"
            multiple
            v-model="valueChange"
            :fetch-options="fetchOptions"
            text-attribute="name"
            value-attribute="id"
            :placeholder="$t('component_userBrandsList_placeholder')"
            :searchBoxPlaceholder="$t('component_userBrandsList_search_placeholder')"
            :noResultsText="$t('component_userSalesPersonList_not_found')"
            :searchingText="$t('common_searching')"
        ></t-rich-select>
    </div>
</template>

<script>

export default {
    name: "UserBrandsList",
    components: {},
    props: {
        "value" : Array,
        "user": Object
    },
    data: () => {
        return {
            allowedBrands: []
        };
    },
    computed: {
        valueChange: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value)

            }
        }
    },
    watch: {
        user(newValue) {
           this.setAllowedBrands(newValue);
        }
    },
    methods: {
        async fetchOptions(name) {
            const results = await this.$store.dispatch('brands/searchBrands', {name});
            return {
                results
            }
        },
        setAllowedBrands(user) {
            const _this = this;
            this.$nextTick(() => {
                if (user.allowedBrands.length > 0) {
                    const items = user.allowedBrands || [];

                    _this.allowedBrands = items.map(brand => brand.id);
                    _this.$refs.select.selectedOptions = _this.$refs.select.normalizeOptions(items);
                }
            });
        }
    },
    created() {
        this.setAllowedBrands(this.user);
    },
};
</script>
