<template>
    <div class="">
        <div class="mb-4 md:w-2/3">
            <p class="mb-1 text-gray-600">{{$t('common_name')}}</p>
            <t-input v-model="name" required></t-input>
        </div>

        <horizontal-tabs class="m-6">

            <horizontal-tab-content
                identifier="creativeParts"
                :tab-name="$t('component_campaignItemDialog_buttonFillCreative')"
            >

            <creative-fields-input
                v-model="fieldValues"
                class="grid xl:grid-cols-4 md:grid-cols-3 grid-cols-2 mt-8 gap-4"
                :target-id="campaignItem.product.productCategory.target?.id"
                :company-id="campaign.company.id"
            />

            </horizontal-tab-content>

            <horizontal-tab-content
                identifier="creativeZip"
                :tab-name="$t('component_campaignItemDialog_buttonUploadZip')"
                :hidden="!allowCreativeZips"
            >
                <creative-zip-input
                    :company-id="companyId"
                    v-model="zipMediaExternalId"
                ></creative-zip-input>
            </horizontal-tab-content>

        </horizontal-tabs>

        <info-message v-if="missingFields" type="error" class="w-full mb-6">
            <strong>{{ $t('common_missingFields') }}:</strong>
            <span class="ml-2">{{ missingFields }}</span>
        </info-message>
    </div>
</template>

<script>
import CreativeZipInput from "@/components/creative/CreativeZipInput.vue";
import HorizontalTabs from "@/components/HorizontalTabs";
import HorizontalTabContent from "@/components/HorizontalTabContent";
import CreativeFieldsInput from "@/components/creative/CreativeFieldsInput.vue";
import InfoMessage from "@/components/base/InfoMessage.vue";

export default {
name: 'CreativeEditForm',
    components: {
        InfoMessage,
        CreativeFieldsInput,
        CreativeZipInput,
        HorizontalTabContent,
        HorizontalTabs
    },
    props: {
        value: {
            type: Object,
            required: true
        },
        campaignItem: {
        },
        campaign: {
        },
        allowCreativeZips: {
            type: Boolean,
            default: true
        },
    },
    data() {
        return {
            viewState: 'creativeParts',
        };
    },
    computed: {
        name: {
            get() {
                return this.value.name;
            },
            set(name) {
                this.$emit('input', {...this.value, name});
            }
        },
        fieldValues: {
            get() {
                return this.value.fieldValues;
            },
            set(fieldValues) {
                this.$emit('input', {...this.value, fieldValues});
            }
        },
        zipMediaExternalId: {
            get() {
                return this.value.zipMediaExternalId;
            },
            set(zipMediaExternalId) {
                this.$emit('input', {...this.value, zipMediaExternalId: zipMediaExternalId});
            }
        },
        companyId() {
            return this.campaign.company.id;
        },
        missingFields() {
            if (this.zipMediaExternalId) {
                return '';
            }
            return this.value.fieldValues
                .filter(fv => fv.formatField.required && !fv.filled && !fv.autoConverted)
                .map(fv => fv.formatField.name)
                .join(', ');
        }
    },
    methods: {

    }

}
</script>


<style scoped>

</style>
