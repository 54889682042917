<template>
	<div class="max-w-screen-2xl pt-1 pb-8 px-4 md:px-8 mx-auto">
        <simple-breadcrumb :items="breadcrumbs"></simple-breadcrumb>

        <div>
            <header class="flex flex-wrap items-start justify-between mt-5 mb-3 space-y-2 lg:space-y-0">
                <div class="flex items-center mb-4 space-x-2 pr-3">
                    <h1 class="text-purple-100 h1">{{ $t('view_ProductCategoriesSettings_title') }}</h1>
                </div>

                <div class="flex flex-col items-end">
                    <c-button
                        size="big"
                        prepend-icon="plus"
                        :to="{name: 'administrationProductCategorySettingsDetail', params: {productCategoryId: 'new'}}"
                        >
                        {{ $t('view_productCategoriesSettingsView_buttonAdd') }}
                    </c-button>
                </div>
            </header>

            <dynamic-table :items="categories" :sortable-config="{handle: '.trow-handle'}" sortable @sort="sortData">
                <dynamic-table-column identifier="title">
                    {{ $t('view_productCategorySettings_name') }}
                </dynamic-table-column>
                <dynamic-table-column identifier="minDaysBeforeStartDate">
                    {{ $t('view_productCategorySettings_minDays') }}
                </dynamic-table-column>
                <dynamic-table-column identifier="active" class="text-center">
                    {{ $t('view_productCategorySettings_active') }}
                </dynamic-table-column>
                <dynamic-table-column identifier="internal" class="text-center">
                    {{ $t('view_productCategorySettings_internal') }}
                </dynamic-table-column>
                <dynamic-table-column identifier="actions" class="text-right">
                    {{ $t('common_actions') }}
                </dynamic-table-column>
                <template v-slot:title="item">
                    <span class="font-medium text-base leading-4">{{ item.title }}</span>
                </template>
                <template v-slot:active="item">
                    <boolean-column :value="item.active"></boolean-column>
                </template>
                <template v-slot:internal="item">
                    <boolean-column :value="item.internal"></boolean-column>
                </template>
                <template v-slot:actions="item">
                    <div class="flex justify-end">
                        <t-button
                            class="mr-2"
                            variant="editCircle"
                            tag-name="a"
                            :to="{name: 'administrationProductCategorySettingsDetail', params: {productCategoryId: item.id}}"
                            :title="$t('common_edit')"
                        >
                            <c-icon class="w-3.5 h-3.5" icon="edit-pencil"/>
                        </t-button>
                        <t-button
                            class="mr-2"
                            variant="editCircleRemove"
                            :title="$t('common_delete')"
                            @click="deleteCategoryId = item.id"
                        >
                            <c-icon class="w-3.5 h-3.5" icon="x-fat"/>
                        </t-button>
                        <t-button
                            class="trow-handle mr-2 cursor-move"
                            variant="editCircle"
                            :title="$t('common_sort')"
                        >
                            <c-icon class="w-3.5 h-3.5" icon="arrow-up-down"/>
                        </t-button>
                    </div>
                </template>
            </dynamic-table>
        </div>

        <product-category-card :productCategoryId="selectedProductCategoryId" @close="close">
        </product-category-card>

        <t-modal v-model="deletePopup">
            <div class="md:p-20 p-8">
                <div class="max-w-2xl text-center">
                    <h1 class="h1 mb-8">
                        {{ $t('component_ProductCategoryCard_deletePopupTitle') }}
                    </h1>
                    <div class="flex justify-center mt-8 flex-wrap">
                        <t-button @click="deletePopup = false" variant="inverse" class="md:mr-6 my-1 md:text-lg">
                            {{ $t('common_cancel') }}
                        </t-button>
                        <t-button @click="deleteCategory" variant="secondary" class="m-1">
                            {{ $t('common_delete') }}
                        </t-button>
                    </div>
                </div>
            </div>
            <template v-slot:button>
                <div class="w-8 h-8 bg-blue-100 rounded-full flex">
                    <svg-icon name="x" class="w-3 h-3 m-auto"></svg-icon>
                </div>
            </template>
        </t-modal>
    </div>
</template>

<script>

import SimpleBreadcrumb from "@/components/SimpleBreadcrumb";
import ProductCategoryCard from "@/components/ProductCategoryCard";
import SvgIcon from "@/components/SvgIcon";
import DynamicTable from "@/components/universal/dynamic-table/DynamicTable.vue";
import DynamicTableColumn from "@/components/universal/dynamic-table/DynamicTableColumn.vue";
import CIcon from "@/components/base/icons/CIcon.vue";
import BooleanColumn from "@/components/universal/dynamic-table/BooleanColumn.vue";
import CButton from "@/components/base/buttons/CButton.vue";

export default {
    name: "ProductCategoriesSettingsView",
    metaTags() {
        return {
            title: this.$t('view_ProductCategoriesSettings_metaTitle') + ' - ' + this.$t('common_meta_title'),
        }
    },
    components: {
        CButton,
        BooleanColumn,
        CIcon,
        DynamicTableColumn,
        DynamicTable,
        ProductCategoryCard,
        SvgIcon,
        SimpleBreadcrumb,
    },
    data: () => {
        return {
            showCreateDialog: false,
            selectedProductCategoryId: null,
            categories: [],
            deleteCategoryId: null,
        };
    },
    computed: {
        deletePopup: {
            get() {
                return this.deleteCategoryId !== null;
            },
            set(value) {
               if(!value) {
                   this.deleteCategoryId = null;
               }
            }
        },
        breadcrumbs() {
            const that = this;

            return [
                {
                    title: that.$t('common_settings'),
                    current: false,
                },
                {
                    title: this.campaignBreadcrumbName,
                    current: 'page',
                },
            ];
        },
    },
    methods: {
        async loadData(to) {
            const id = to.params.productCategoryId;
            this.selectedProductCategoryId = id || null;
            this.categories = await this.$store.dispatch('products/getCategories');
        },
        close() {
            this.$router.push({name: 'administrationProductCategorySettings'})
        },
        async sortData(items) {
            const that = this;

            try {
                await that.$store.dispatch("products/updatePositionCategories", {
                    productCategoriesOrder: items.map((item) => item.id),
                });
            } catch (e) {
                console.log(e);
            }
        },
        deleteCategory() {
            this.$store.dispatch('products/deleteCategory', { productCategoryId: this.deleteCategoryId}).then(() => {
                this.deleteCategoryId = null;
                this.loadData(this.$route);
            });
        }
    },
    async beforeRouteEnter(to, from, next) {
        await next(async (vm) => {
            await vm.loadData(to);
        });
    },
    async beforeRouteUpdate(to) {
        await this.loadData(to);
    }
};
</script>
