import axios from "@/plugins/axios";


export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        async searchCreatives(context, filter) {
            const response = await axios.post(`/creatives/search`, filter);
            return response.data;
        },
        async getCreative(context, {id}) {
            const response = await axios.get(`/creatives/${id}`);
            return response.data;
        },
        async createCreative(context, {productId, campaignItemId}) {
            const response = await axios.get(`/creatives/create/product/${productId}`, {params: {campaignItemId}});
            return response.data;
        },
        async updateCreative(context, creative) {
            const response = await axios.put(`/creatives/${creative.id}`, creative);
            return response.data;
        },
        async saveCreative(context, {productId, creative}) {
            const response = await axios.post(`/creatives/save/product/${productId}`, creative);
            return response.data;
        },
        uploadFile(context, {file, progressFunc, id, companyId, restrictions}) {
            if(!id) {
                id = '';
            }
            return new Promise((resolve, reject) => {
                let data = new FormData();
                data.append("file", file);
                if(restrictions && restrictions.size) {
                    data.append("size", restrictions.size);
                }
                if(restrictions && restrictions.width) {
                    data.append("width", restrictions.width);
                }
                if(restrictions && restrictions.height) {
                    data.append("height", restrictions.height);
                }
                axios
                    .post(`/creatives/upload/company/${companyId}/media/${id}`, data, {
                        onUploadProgress: progressFunc,
                    })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        uploadFormatFieldFile(context, {file, progressFunc, formatFieldId}) {
            return new Promise((resolve, reject) => {
                let data = new FormData();
                data.append("file", file);
                axios
                    .post(`/creatives/formats/fields/${formatFieldId}/upload`, data, {
                        onUploadProgress: progressFunc,
                    })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        async requestCreativeCheck(context, {creativeId}) {
            const response = await axios.post(`/creatives/${creativeId}/request-check`);
            return response.data;
        },
        async approveCreative(context, {creativeId}) {
            const response = await axios.post(`/creatives/${creativeId}/approve`);
            return response.data;
        },
        async rejectCreative(context, {creativeId, note}) {
            const response = await axios.post(`/creatives/${creativeId}/reject`, {note});
            return response.data;
        },
        async getMediaInfo(context, {mediaExternalId})  {
            const response = await axios.get(`/creatives/media/${mediaExternalId}`);
            return response.data;
        },
        async getTmpMediaInfo(context, {tmpMediaFileId})  {
            const response = await axios.get(`/creatives/tmp-media/${tmpMediaFileId}`);
            return response.data;
        },
        async getCreativeFormats() {
            const response = await axios.get(`/creatives/formats`);
            return response.data;
        }
    }
}
