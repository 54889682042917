<template>
    <t-modal v-model="show" v-if="campaignItem">
        <div class="md:p-20 p-8">
            <div class="max-w-2xl">
                <h1 class="text-blue-400 h1 pr-5">
                    {{ campaignItem.generatedName || campaignItem.product.name }}
                </h1>
                <h2 class="text-blue-400 h2 mt-2" v-if="campaignItem.targetProduct">
                    {{ campaignItem.targetProduct.name }}
                </h2>
                <p class="mt-5">
                    {{ campaignItem.product.description }}
                </p>
                <div class="flex mt-8">
                    <strong class="mr-2">
                        {{ $t('component_campaignItemDialog_itemState') }}
                    </strong>
                    <campaign-item-state :campaignItemState="campaignItem.itemState"></campaign-item-state>
                </div>
                <div class="flex" v-if="campaignItem.targetURL">
                    <strong class="mr-2">
                        {{ $t('component_campaignItemDialog_clickUrl') }}
                    </strong>
                    <a :href="campaignItem.targetURL" target="_blank">{{ campaignItem.targetURL }}</a>
                </div>
                <div class="flex" v-if="sasLineItemURL">
                    <strong class="mr-2">
                        {{ $t('component_campaignItemDialog_sasLineItemUrl') }}:
                    </strong>
                    <a :href="sasLineItemURL" target="_blank">{{ sasLineItemURL }}</a>
                </div>
                <div class="flex" v-if="sasFlightURL">
                    <strong class="mr-2">
                        {{ $t('component_campaignItemDialog_sasFlightUrl') }}:
                    </strong>
                    <a :href="sasFlightURL" target="_blank">{{ sasFlightURL }}</a>
                </div>
                <div class="flex" v-if="sasCreativeURL">
                    <strong class="mr-2">
                        {{ $t('component_campaignItemDialog_sasCreativeUrl') }}:
                    </strong>
                    <a :href="sasCreativeURL" target="_blank">{{ sasCreativeURL }}</a>
                </div>
                <div class="flex">
                    <strong class="mr-2">
                        {{ $t('common_currentPerformance') }}
                    </strong>
                    <span class="mr-4">
                    {{
                        $tc(
                            'common_pluralize_views',
                            campaignItem.actualViews,
                            {views: $n(campaignItem.actualViews)}
                        )
                        }}
                    </span><span class="mr-4">
                    {{
                        $tc(
                            'common_pluralize_clicks',
                            campaignItem.actualClicks,
                            {clicks: $n(campaignItem.actualClicks)}
                        )
                    }}
                    </span><span class="mr-4">
                    {{
                        $tc(
                            'common_pluralize_impressions',
                            campaignItem.actualImpressions,
                            {impressions: $n(campaignItem.actualImpressions)}
                        )
                    }}
                    </span>
                </div>
                <div class="flex items-center"
                     v-if="campaignItem.orderedClicksCount || campaignItem.orderedImpressionsCount">
                    <strong>
                        {{ $t('component_campaignItemDialog_ordered') }}
                    </strong>
                    <div class="flex items-center my-1 mx-2" v-if="campaignItem.orderedClicksCount">
                        <svg-icon name="ico-click" class="mr-2"></svg-icon>
                        <span>
                            {{
                                $tc(
                                    'common_pluralize_clicks',
                                    campaignItem.orderedClicksCount,
                                    {clicks: $n(campaignItem.orderedClicksCount)}
                                )
                            }}
                        </span>
                    </div>
                    <div class="flex items-center my-1 mx-2" v-if="campaignItem.orderedImpressionsCount">
                        <svg-icon name="square-rounded" class="mr-2 h-5"></svg-icon>
                        <span>
                            {{
                                $tc(
                                    'common_pluralize_impressions',
                                    campaignItem.orderedImpressionsCount,
                                    {impressions: $n(campaignItem.orderedImpressionsCount)}
                                )
                            }}
                        </span>
                    </div>
                </div>
                <div class="flex" v-if="campaignItem.message">
                    <strong class="mr-2">
                        {{ $t('component_campaignItemDialog_message') }}
                    </strong>
                    {{ campaignItem.message }}
                </div>
                <div class="flex" v-if="campaignItem.note">
                    <strong class="mr-2">
                        {{ $t('component_campaignItemDialog_note') }}
                    </strong>
                    {{ campaignItem.note }}
                </div>
                <p>
                    <strong>{{ $t('common_price') }}</strong>
                    <span class="inline-block ml-2">{{ $n(campaignItem.price, "currency") }}</span>
                </p>
                <p>
                    <strong>{{ $t('component_campaignItemDialog_fromTo') }}</strong>
                    <span class="inline-block ml-2">{{ startDate }} - {{ endDate }}</span>
                </p>
            </div>
        </div>
        <template v-slot:button>
            <div class="w-8 h-8 bg-blue-100 rounded-full flex">
                <svg-icon name="x" class="w-3 h-3 m-auto"></svg-icon>
            </div>
        </template>
    </t-modal>
</template>

<script>
import CampaignItemState from '@/components/CampaignItemState';
import SvgIcon from "@/components/SvgIcon";
import {parseISO} from "date-fns";

export default {
    name: "CampaignItemDetailDialog",
    components: {
        CampaignItemState,
        SvgIcon
    },
    props: {
        value: {},
        campaign: {},
        campaignItem: {},
    },
    data() {
        return {
            creativeAsZip: false,
            percents: 0,
            uploading: false,
            sending: false
        }
    },
    computed: {
        show: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        },
        startDate() {
            if (!this.campaignItem.startDate) {
                return '';
            }
            return this.$d(parseISO(this.campaignItem.startDate));
        },
        endDate() {
            if (!this.campaignItem.endDate) {
                return '';
            }
            return this.$d(parseISO(this.campaignItem.endDate));
        },
        zipState() {
            if (this.uploading) {
                return 'loading';
            }
            if (this.campaignItem.zipUploaded) {
                return 'approved';
            }
            return 'default';
        },
        enableSendForCheck() {
            return !this.sending
                && (this.campaignItem.zipUploaded || this.campaignItem.itemMedia.every(item => item.filled))
                && this.campaignItem.itemState.canSendForCheck
                ;
        },
        creativeEditAllowed() {
            return this.campaignItem.creativeEditAllowed;
        },
        sasLineItemURL() {
            if(this.campaignItem.sasInfo) {
                return this.campaignItem.sasInfo.lineItemURL;
            }
            return null;
        },
        sasFlightURL() {
            if(this.campaignItem.sasInfo) {
                return this.campaignItem.sasInfo.flightURL;
            }
            return null;
        },
        sasCreativeURL() {
            if(this.campaignItem.sasInfo) {
                return this.campaignItem.sasInfo.creativeURL;
            }
            return null;
        }
    },
    methods: {
        changed() {
            this.$emit('change');
        },
        async uploadFile(file) {
            this.uploading = true;
            const media = await this.$store.dispatch("campaigns/uploadZipFile", {
                file,
                progressFunc: this.progressFunction,
                id: this.campaignItem.id
            });
            this.uploading = false;
            this.$emit('change', media);
        },
        progressFunction(progressEvent) {
            this.percents = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        },
        async send() {
            this.sending = true;
            await this.$store.dispatch("campaigns/requestCampaignItemCheck", {
                campaignItemId: this.campaignItem.id
            });
            this.sending = false;
            this.show = false;
        }
    },
    created() {
        // this.loadData();
    },
}
</script>

<style scoped>

</style>
