<template>
    <t-modal v-model="show">
        <div class="md:p-20 p-8">
            <h1 class="h1 font-medium mb-5 pr-5">{{ variableName }}</h1>

            <loading-box theme="light" v-model="saving">
                <div class="flex items-center bg-gray-50 md:p-8 p-4 rounded-2xl flex-wrap space-y-4">

                    <div class="w-full">
                        <label v-if="variableType == 'bool'" class="cursor-pointer inline-flex items-center w-full">
                            <t-checkbox name="active" v-model="variableValue"/>
                            <checkbox-icon/>
                            <span class="leading-tight">{{ variableName }}</span>
                        </label>
                        <p v-else class="mb-3 text-red-400">
                            VARIABLE TYPE {{ variableType }} NOT IMPLEMENTED
                        </p>
                    </div>

                    <p class="mb-3 text-red-400" v-if="errorMessage">{{ errorMessage }}</p>
                </div>

                <div class="flex items-center justify-end mt-6">
                    <t-button
                        @click.prevent="close()"
                        variant="link"
                        class="border-gray-400 text-gray-400 mr-4"
                    >
                        {{ $t('common_cancel') }}
                    </t-button>
                    <t-button
                        class="ml-4 sm:my-0 my-3 flex items-center"
                        @click="save"
                        :variant="variableChanged ? 'bigGreen' : 'bigGray'"
                        :disabled="!variableChanged"
                    >

                        <div class="flex bg-green-200 rounded-full p-1.5 mr-3 text-black" :class="{'grayscale': !variableChanged}">
                            <c-icon class="w-3.5 h-3.5" icon="check"></c-icon>
                        </div>

                        {{ $t('common_save') }}
                    </t-button>
                </div>
            </loading-box>
        </div>

        <template v-slot:button>
            <div class="w-8 h-8 bg-blue-100 rounded-full flex">
                <svg-icon name="x" class="w-3 h-3 m-auto"></svg-icon>
            </div>
        </template>
    </t-modal>
</template>

<script>
import LoadingBox from "@/components/LoadingBox.vue";
import SvgIcon from "@/components/SvgIcon.vue";
import CIcon from "@/components/base/icons/CIcon.vue";
import CheckboxIcon from "@/components/icons/CheckboxIcon.vue";

export default {
	name: "SettingsVariableEditDialog",
    mixins: [],
    components: {
        LoadingBox,
        SvgIcon,
        CheckboxIcon,
        CIcon
    },
    props: {
        "settingsVariable": {
            required:  true
        }
    },
    data: () => {
        return {
            changedValue: null,
            saving: false,
        }
    },
	computed: {
        show: {
            get() {
                return this.settingsVariable != null;
            },
            set() {
                this.close();
            }
        },
        variableName() {
            return this.settingsVariable.name;
        },
        variableType() {
            return this.settingsVariable.type;
        },
        variableChanged() {
            return this.changedValue != null;
        },
        variableValue: {
            get() {
                return this.settingsVariable.value;
            },
            set(value) {
                this.changedValue = value;
            }
        },
        errorMessage() {
            return null;
        }
    },
	methods: {
        close() {
            this.$emit('close');
        },
        save() {
            this.saving = true;

            this.$store.dispatch(
                'settings/updateVariable',
                {
                    id: this.settingsVariable.id,
                    value: this.changedValue
                }
            );

            this.saving = false;

            this.$emit(
                'change',
                {
                    id: this.settingsVariable.id,
                    value: this.changedValue
                }
            );
        }
    },
};
</script>
