import i18n from "@/plugins/i18n";

export const CreativeStates = {
    New: 'new',
    WaitingForApproval: 'waiting-for-approval',
    Returned: 'returned',
    Approved: 'approved'
};

export const getCreativeStateName = (stateIdentifier) => {
    switch(stateIdentifier) {
        case CreativeStates.New:
            return i18n.t('common_CreativeState_new');
        case CreativeStates.WaitingForApproval:
            return i18n.t('common_CreativeState_waitingForApproval');
        case CreativeStates.Returned:
            return i18n.t('view_activityCreative_returned');
        case CreativeStates.Approved:
            return i18n.t('view_activityCreative_approved');
    }
}

export const getCreativeStateColor = (stateIdentifier) => {
    switch(stateIdentifier) {
        case CreativeStates.New:
            return 'bg-yellow-600';
        case CreativeStates.WaitingForApproval:
            return 'bg-orange-400';
        case CreativeStates.Returned:
            return 'bg-red-450';
        case CreativeStates.Approved:
            return 'bg-green-600';
    }
}
