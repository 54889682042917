<template>
	<div class="max-w-screen-2xl pt-1 pb-8 px-4 md:px-8 mx-auto">
        <simple-breadcrumb :items="breadcrumbs"></simple-breadcrumb>

        <div class="mt-5">
            <h1 class="mb-5 text-purple-100 h1">{{ $t('common_users_settings') }}</h1>

            <div class="flex flex-col mx-auto sm:mx-0 sm:items-center sm:flex-row w-3/4">
                <!-- Fulltext search -->
                <div class="relative">
                    <t-input v-model="search" :placeholder="$t('view_userList_searchUsers')"></t-input>
                    <svg-icon name="search" class="absolute right-6 top-3 pointer-events-none"></svg-icon>
                </div>

                <div class="min-w-min mt-5 sm:mt-0">
                    <t-select
                    v-model="filterUserRoles"
                    :options="userRoles"
                    placeholder="Select a role"
                    value-attribute="id"
                    variant="defaultBlueOutline"
                    class="py-3.5 mx-0 sm:mx-5 indent-2"
                    ></t-select>
                </div>
            </div>

            <!-- Table -->
            <dynamic-table :items="users" :loading="loadingData">
                <dynamic-table-column identifier="name" class="pb-2">
                    {{ $t('common_name') }}
                </dynamic-table-column>
                <dynamic-table-column identifier="email" class="pb-2">
                    {{ $t('common_email') }}
                </dynamic-table-column>
                <dynamic-table-column identifier="roles" class="pb-2">
                  {{ $t('common_userRole') }}
                </dynamic-table-column>
                <dynamic-table-column identifier="companyName" class="pb-2">
                    {{ $t('common_company') }}
                </dynamic-table-column>
                <dynamic-table-column identifier="active" class="pb-2">
                    {{ $t('view_userList_active') }}
                </dynamic-table-column>
                <dynamic-table-column identifier="actions" style-header="width: 12rem" class="pb-2">
                    {{ $t('common_actions') }}
                </dynamic-table-column>
                <template v-slot:name="item">
                    <span class="font-medium text-base leading-4">{{ item.name }}</span>
                </template>
                <template v-slot:roles="item">
                  {{ item.roles[0].name }}
                </template>
                <template v-slot:active="item">
                    <boolean-column :value="item.storefrontActive"></boolean-column>
                </template>
                <template v-slot:actions="item">
                    <div class="flex space-x-2">
                        <circle-icon-button
                            icon="edit-pencil"
                            :title="$t('common_edit')"
                            :to="{name: 'userDetail', params: {userId: item.id}}"
                        />
                        <circle-icon-button
                            icon="door-open"

                            :title="loginAsUserEnabledTitle(item)"
                            :disabled="!loginAsUserEnabled(item)"
                            @click="loginAsUser(item.id)"
                        />
                    </div>
                </template>
            </dynamic-table>
            <div
                class="flex flex-col md:flex-row justify-between text-center"
                data-cy="pagination"
            >
                <page-items-filter v-model="maxResults" />
                <page-picker
                    v-model="firstResult"
                    :total-count="totalCount"
                    :page-size="maxResults"
                    :className="customClass"
                ></page-picker>
            </div>
        </div>

    </div>
</template>

<script>
import _ from 'lodash';
import SimpleBreadcrumb from "@/components/SimpleBreadcrumb";
import PagePicker from "@/components/PagePicker.vue";
import SvgIcon from "@/components/SvgIcon.vue";
import DynamicTable from "@/components/universal/dynamic-table/DynamicTable.vue";
import DynamicTableColumn from "@/components/universal/dynamic-table/DynamicTableColumn.vue";
import BooleanColumn from "@/components/universal/dynamic-table/BooleanColumn";
import CircleIconButton from "@/components/base/buttons/CircleIconButton.vue";
import PageItemsFilter from "@/components/PageItemsFilter.vue";

export default {
    name: "UserListView",
    metaTags() {
        return {
            title: this.$t('common_users_settings_metaTitle') + ' - ' + this.$t('common_meta_title'),
        }
    },
    components: {
        CircleIconButton,
        BooleanColumn,
        DynamicTableColumn,
        DynamicTable,
        SvgIcon,
        PagePicker,
        SimpleBreadcrumb,
        PageItemsFilter,
    },
    data: () => {
        return {
            loadingData: false,
            totalCount: 0,
            users: [],
            customClass: 'main__pagePicker',
            userRoles: ['Agency', 'Vendor']
        };
    },
    computed: {
        breadcrumbs() {
            const that = this;

            return [
                {
                    title: that.$t('common_settings'),
                    current: false,
                },
                {
                    title: this.campaignBreadcrumbName,
                    current: 'page',
                },
            ];
        },
        firstResult: {
            get() {
                return this.$store.getters['users/listFilter'].firstResult;
            },
            set(value) {
                this.$store.dispatch('users/setFilterFirstResult', value);
            }
        },
        maxResultsStorageKey() {
            return `max-results-${this.$route.name}`;
        },
        maxResults: {
            get() {
                const initialMaxResults = this.$store.getters['users/listFilter'].maxResults;
                const storedMaxResultsValue = localStorage.getItem(this.maxResultsStorageKey);
                return storedMaxResultsValue
                    ? +storedMaxResultsValue
                    : initialMaxResults;
            },
            set(value) {
                localStorage.setItem(this.maxResultsStorageKey, value);
                this.$store.dispatch('users/setFilterMaxResults', value);
            },
        },
        search: {
            get() {
                return this.$store.getters['users/listFilter'].search;
            },
            set: _.debounce(function(value) {
                this.$store.dispatch('users/setFilterSearch', value);
            }, 500),
        },
        filterUserRoles: {
            get() {
                return this.$store.getters['users/listFilter'].userRole;
            },
            set(value) {
                this.$store.dispatch('users/setFilterUserRole', value);
            },
        },
    },
    watch: {
        firstResult() {
            this.loadData();
        },
        maxResults() {
            this.loadData();
        },
        search() {
            this.loadData();
        },
        filterUserRoles() {
            this.loadData();
        },
    },
    methods: {
        loginAsUserEnabled(user) {
            return (user.active && user.storefrontActive);
        },
        loginAsUserEnabledTitle(user){
            if(this.loginAsUserEnabled(user)){
                return this.$t('common_login_as_vendor')
            } else {
                return this.$t('common_login_as_vendor_not_active')
            }
        },
        async loadData() {
            this.loadingData = true;
            this.users = [];
            this.totalCount = 0;
            const currentFilter = this.$store.getters['users/listFilter'];

            const usersPage = await this.$store.dispatch('users/filterUsers', {
                ...currentFilter,
                maxResults: this.maxResults,
            });
            this.users = usersPage.items;
            this.totalCount = usersPage.totalCount;
            this.loadingData = false;
        },
        async loginAsUser(id) {
            if (id) {
                await this.$store.dispatch('users/loginAsUser', {id});
                await this.$router.push({name: "campaignList"});
            } else {
                await this.$router.push({name: 'userList'})
            }
        },
    },
    created() {
        this.loadData();
    },
    async beforeRouteUpdate() {
        await this.loadData();
    },
};
</script>
