<template>
	<div class="max-w-screen-2xl pt-1 pb-8 px-4 md:px-8 mx-auto">
        <simple-breadcrumb v-if="!loadingCampaign && campaignExists" :items="breadcrumbs"></simple-breadcrumb>

        <not-found-slate v-if="!loadingCampaign && !campaignExists" />

        <loading-box v-model="loadingCampaign" min-height="12rem" theme="light">
            <div v-if="!loadingCampaign && campaignExists">

                <header class="flex flex-wrap items-start justify-between mt-5 mb-3 space-y-2 lg:space-y-0">
                    <div class="pr-3">
                        <!-- Název kampaně -->
                        <div class="flex items-center mb-4 space-x-2">
                            <h1 class="text-purple-100 h1">{{ $t("view_campaignDetail_campaignName", {name}) }}</h1>
                            <circle-icon-button
                                v-if="campaignEditable"
                                class="ml-5 -mb-1"
                                icon="edit-pencil"
                                :title="$t('common_edit')"
                                @click="showUpdateCampaignDialog = true"
                            />
                        </div>
                        <!-- / Název kampaně -->

                        <div class="lg:flex lg:space-x-4 items-center">
                            <!-- Stav kampaně -->
                            <div class="flex items-center space-x-4">
                                <!--div class="text-gray-500">{{ $t("common_campaignState") }}</div-->
                                <campaign-state-edit
                                    v-if="campaign"
                                    :campaign="campaign"
                                    :loading="loadingState"
                                    @update="changeState"
                                    @open="updateStateErrorMessage = null"
                                    :disabled="!isRetailer"
                                />
                                <info-message
                                    v-if="updateStateErrorMessage"
                                    type="error"
                                    >
                                    {{ updateStateErrorMessage }}
                                </info-message>
                            </div>
                            <!-- / Stav kampaně -->

                            <!-- Obchodník -->
                            <div class="flex items-center mt-4 lg:mt-0">
                                    {{ $t("common_salesPerson") }}:
                                    <b class="ml-1">{{salesPersonName}}</b>

                                    <t-button v-if="campaignEditable && isRetailer" @click="showUpdateCampaignDialog = true" variant="link" class="ml-3" :title="$t('common_edit')">
                                        <c-icon class="w-3 h-3 text-gray-500" icon="edit-pencil"></c-icon>
                                    </t-button>
                            </div>
                            <!-- / Obchodník -->

                        </div>
                        <info-message class="mt-2" v-if="showCampaignRejectionReason && campaign.rejectReason">
                            <div>
                                <div class="font-semibold">{{ $t("common_rejectReason") }}:</div>
                                <div
                                    v-html="campaign.rejectReason.replace('\n', '<br>')"
                                ></div>
                            </div>
                        </info-message>
                    </div>

                    <div class="relative hidden ml-a shrink-0"> <!-- Tady nevím co to je - Ondra: sortování na mobilu - zatím skrývám jako nadbytečnou funkci na mobilu -->
                        <button
                        class="flex items-center justify-between bg-blue-50 hover:bg-blue-100 hover:border-blue-100 text-white text-lg rounded-full p-4 relative z-30"
                            @click="showSortingFilter = !showSortingFilter"
                        >
                            <!-- {{ $t("common_sort") }} -->
                            <svg-icon name="ico-sort" class="w-4 h-4"></svg-icon>
                        </button>
                        <div v-if="showSortingFilter"
                             class="absolute -top-4 lg:top-full pt-24 pb-5 lg:right-0 -right-4 px-8 lg:py-5 bg-white shadow-lg rounded-b-xl w-72 lg:w-full rounded-xl lg:rounded-none z-20">
                            <div>
                                <table-sort-heading
                                    :name="$t('common_product')"
                                    property-name="name"
                                    v-model="sort"
                                    sort-type="checkbox"
                                />
                            </div>
                            <div class="relative lg:hidden shrink-0">
                                <button
                                    class="flex items-center justify-between bg-black text-white font-bold rounded-full px-6 py-2 relative z-30 mr-0 ml-auto"
                                    @click="showSortingFilter = !showSortingFilter"
                                >
                                    {{ $t("common_sort") }}
                                    <svg-icon name="ico-sort" class="ml-6 w-10 -mr-1"></svg-icon>
                                </button>
                                <div v-if="showSortingFilter"
                                     class="absolute -top-4 lg:top-full pt-24 pb-5 lg:right-0 -right-4 px-8 lg:py-5 bg-white shadow-lg rounded-b-xl w-72 lg:w-full rounded-xl lg:rounded-none z-20">
                                    <div>
                                        <table-sort-heading
                                            :name="$t('common_product')"
                                            property-name="name"
                                            v-model="sort"
                                            sort-type="checkbox"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="flex flex-col items-end"> <!-- Tlačítka -->
                        <!-- Přidat aktivitu -->
                        <t-button
                            v-if="itemAdditionAllowed"
                            variant="bigGreen" class="hidden lg:flex lg:items-center relative group ml-auto pl-6"
                            @click="showSelectProductDialog = true"
                        >
                            <div class="flex bg-green-200 rounded-full p-1.5 mr-3 text-black">
                                <c-icon class="w-3.5 h-3.5" icon="plus"></c-icon>
                            </div>
                            {{ $t('view_campaignDetail_buttonAddItem') }}
                        </t-button>
                    </div>
                </header>
                <div v-if="displayOfPerformanceMetrics"> <!-- Performance kampaně -->
                    <div class="flex justify-end">
                        <!--h2 class="p-3 h3">{{ $t("common_currentPerformance") }}</h2-->
                        <t-button
                            variant="mediumBlue"
                            class="flex items-center relative group pl-4"
                            :class="{'rounded-b-none rounded-t-[18px] pr-3': showActualPerformance}"
                            @click="showActualPerformance = !showActualPerformance"
                        >
                            <c-icon class="w-4 h-4 mr-2" icon="graph-up"></c-icon>
                            {{ $t('common_performance') }} 
                        </t-button>
                    </div>
                    <div v-if="showActualPerformance" class="relative block rounded-xl bg-white rounded-tr-none">
                        <table class="text-center w-full">
                            <tr class="border-b borex-gray-100">
                                <th v-if="isDisplayImpressions" class="p-2">
                                    {{ $t("common_impressions") }}
                                </th>
                                <th v-if="isDisplayViews" class="p-2">
                                    {{ $t("common_views") }}
                                </th>
                                <th v-if="isDisplayVisibilityRatio" class="p-2">
                                    {{ $t("common_visibilityRatio") }}
                                </th>
                                <th v-if="isDisplayVisibilityClicks" class="p-2">
                                    {{ $t("common_clicks") }}
                                </th>
                                <th v-if="isDisplayCtrRatio" class="p-2">
                                    {{ $t("common_ctrRatio") }}
                                </th>
                            </tr>
                            <tr>
                                <td v-if="isDisplayImpressions" class="p-2">
                                    {{ $n(campaign.actualImpressions) }}
                                </td>
                                <td v-if="isDisplayViews" class="p-2">
                                    {{ $n(campaign.actualViews) }}
                                </td>
                                <td v-if="isDisplayVisibilityRatio" class="p-2">
                                    {{ $n(campaign.visibilityRatio / 100, { style: 'percent' }) }}
                                </td>
                                <td v-if="isDisplayVisibilityClicks" class="p-2">
                                    {{ $n(campaign.actualClicks) }}
                                </td>
                                <td v-if="isDisplayCtrRatio" class="p-2">
                                    {{ $n(campaign.ctrRatio / 100, { style: 'percent', minimumFractionDigits: 2, maximumSignificantDigits: 2 }) }}
                                </td>
                            </tr>
                        </table>
                        <hr class="mb-3">
                        <div class="flex items-center space-x-4 justify-end pr-4 pb-3">
                            <div class="text-gray-500">{{ $t("common_price") }}</div>
                            <div class="flex">
                                <span class="font-bold text-md">{{ $n(totalPrice, "currency") }}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="px-4 pb-2 lg:grid grid-cols-12 hidden gap-x-2 mt-8 text-gray-500">
                    <table-sort-heading
                        :name="$t('common_product')"
                        property-name="name"
                        v-model="sort"
                        class="col-span-3"
                    ></table-sort-heading>
                    <table-sort-heading
                        :name="$t('common_beginning')"
                        property-name="startDate"
                        v-model="sort"
                        class="col-span-1"
                    ></table-sort-heading>
                    <table-sort-heading
                        :name="$t('common_ending')"
                        property-name="endDate"
                        v-model="sort"
                        class="col-span-1"
                    ></table-sort-heading>
                    <table-sort-heading
                        :name="$t('common_ordered')"
                        property-name="price"
                        v-model="sort"
                        class="col-span-2"
                    ></table-sort-heading>
                    <table-sort-heading
                        :name="$t('common_creativeState')"
                        property-name="state"
                        v-model="sort"
                        class="col-span-2"
                        childClass="justify-start"
                    ></table-sort-heading>
                    <div class="col-span-1 text-right">
                        {{ $t('common_editing') }}
                    </div>
                </div>

                <campaign-item
                    v-for="campaignItem in campaignItems"
                    :key="campaignItem.id"
                    :campaign="campaign"
                    :campaignItem="campaignItem"
                    :campaignEditable="campaignEditable"
                >
                </campaign-item>

                <div v-if="campaignItems.length == 0" class="py-8 px-4 bg-white rounded-lg text-center text-base text-gray-400">
                    {{ $t('view_campaignDetail_emptyItems') }}
                    <!-- Přidat aktivitu -->

                    <t-button
                        v-if="itemAdditionAllowed"
                        variant="link"
                        class="inline ml-1 text-gray-400"
                        @click="showSelectProductDialog = true"
                    >

                        {{ $t('view_campaignDetail_buttonAddItem') }}
                    </t-button>

                </div>


                <!-- Odkazy do SAAS -->
                <div class="flex mt-3">
                    <t-button
                        v-if="sasProposalURL"
                        :href="sasProposalURL"
                        variant="smallBlue"
                        class="flex items-center relative group pr-2.5 mr-2"
                        target="_blank"
                    >
                        SAS Proposal
                        <c-icon class="ml-1 w-3 h-3" icon="paperclip"></c-icon>
                    </t-button>

                    <t-button
                        v-if="sasCampaignURL"
                        :href="sasCampaignURL"
                        variant="smallBlue"
                        class="flex items-center relative group pr-2.5"
                        target="_blank"
                    >
                        SAS Campaign
                        <c-icon class="ml-1 w-3 h-3" icon="paperclip"></c-icon>
                    </t-button>
                </div>

                <info-message type="error" class="mt-2" v-if="errorMessage">
                    {{ errorMessage }}
                </info-message>

                <div class="flex justify-between flex-wrap items-start mt-4">
                    <div class="text-2xl lg:w-auto w-full mt-6">
                        {{ $t('view_campaignDetail_campaignTotalPrice') }}
                        <b>{{ $n(totalPrice, "currency") }}</b>
                    </div>

                    <div class="flex w-full space-y-6 mt-6 items-center flex-col  lg:w-auto lg:space-x-6 lg:flex-row lg:space-y-0 lg:justify-end">

                        <text-button class="text-gray-400" @click="closeDetail">
                            {{ $t('view_campaignDetail_buttonBackToCampaigns') }}
                        </text-button>

                        <c-button
                            v-if="enableRejectToVendor"
                            size="big"
                            color="red"
                            :disabled="changeStateInProgress"
                            @click="showRejectPopup = true"
                            >
                            {{ $t('common_reject_to_vendor') }}
                        </c-button>

                        <c-button
                            v-if="enableSendForCheck"
                            size="big"
                            prepend-icon="check"
                            :loading="sendingForCheckInProgress"
                            @click="requestCampaignCheck"
                        >
                            {{ $t('view_campaignDetail_buttonSendForApproval') }}
                        </c-button>

                        <c-button
                            v-if="enableApprovalByRetailer"
                            size="big"
                            prepend-icon="check"
                            :loading="approvingInProgress"
                            @click="approveByRetailer"
                        >
                            {{ $t('common_approve') }}
                        </c-button>

                        <c-button
                            v-if="enableDelete"
                            size="big"
                            color="red"
                            prepend-icon="x"
                            @click="showDeleteCampaignDialog = true"
                        >
                            {{ $t("common_delete") }}
                        </c-button>

                        <c-button
                            v-if="enableApproval"
                            size="big"
                            prepend-icon="check"
                            :loading="approvingInProgress"
                            @click="approve"
                        >
                            {{ $t('common_approve') }}
                        </c-button>


                </div>



                </div>



            </div>
        </loading-box>

        <creative-popup v-if="campaignItem" v-model="showDetailDialog" :company="campaign.company" :campaign-item="campaignItem"></creative-popup>

        <delete-campaign-dialog
            :show-dialog="showDeleteCampaignDialog"
            :campaign="campaign"
            @close="showDeleteCampaignDialog = false"
        >
        </delete-campaign-dialog>

        <campaign-item-dialog

            :campaign="campaign"
            :campaign-item="campaignItem"
            @close="campaignItem = null"
            @change="loadDataSilently">
        </campaign-item-dialog>

        <campaign-item-media-detail-dialog
            v-model="showMediaDetailDialog"
            :campaignItem="campaignItem"
            @close="campaignItem = null"
        ></campaign-item-media-detail-dialog>

        <product-select-dialog
            v-if="campaign"
            v-model="showSelectProductDialog"
            :campaign="this.campaign"
            @selected="loadData"
        >
        </product-select-dialog>

        <update-campaign-dialog
            :show-dialog="showUpdateCampaignDialog"
            :campaign="this.campaign"
            @updated="loadData"
            @close="showUpdateCampaignDialog = false">
        </update-campaign-dialog>

        <t-dialog
            name="promptDialog"
            ref="promptDialog"
            type="prompt"
        >
            <template v-slot:title></template>
            <template v-slot:text></template>
            <template v-slot:icon>
                <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                     xmlns="http://www.w3.org/2000/svg">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                          d="M13 7a4 4 0 11-8 0 4 4 0 018 0zM9 14a6 6 0 00-6 6v1h12v-1a6 6 0 00-6-6zM21 12h-6"/>
                </svg>
            </template>
        </t-dialog>

        <!-- reject popup -->
        <simple-dialog v-model="showRejectPopup">
            <div class="h1">
                {{ $t('view_campaignDetail_rejectToVendor_title') }}
            </div>
            <form @submit.prevent.stop="rejectToVendor">
                <div class="text-left w-full mx-2 mt-4 mb-1 text-gray-600">
                    {{$t('view_campaignDetail_rejectToVendor_text')}}
                </div>
                <t-textarea class="rounded-3xl lg:h-36 mb-4" v-model="rejectReasonText" required>

                </t-textarea>
                <div class="flex space-x-2 justify-end mb-4 lg:mb-4">
                    <base-button class="hover:underline" @click="showRejectPopup = false">{{$t('common_cancel')}}</base-button>
                    <c-button color="green" type="submit">{{ $t('view_campaignDetail_rejectToVendor_btnOk') }}</c-button>
                </div>
            </form>
        </simple-dialog>
        <!-- end reject popup -->
    </div>
</template>

<script>
import CIcon from "@/components/base/icons/CIcon.vue";
import SvgIcon from "@/components/SvgIcon";
import ProductSelectDialog from "@/components/ProductSelectDialog";
import CampaignItem from "@/components/CampaignItem";
import CampaignItemDialog from "@/components/CampaignItemDialog";
import TableSortHeading from "@/components/TableSortHeading";
import {mapGetters} from "vuex";
import CampaignStateEnum from "@/utils/CampaignStateEnum";
import LoadingBox from "@/components/LoadingBox.vue";
import CampaignItemMediaDetailDialog from "@/components/CampaignItemMediaDetailDialog.vue";
import SimpleBreadcrumb from "@/components/SimpleBreadcrumb";
import UpdateCampaignDialog from "@/components/campaign/UpdateCampaignDialog.vue";
import DeleteCampaignDialog from "@/components/campaign/DeleteCampaignDialog.vue";
import CampaignStateEdit from "@/components/CampaignStateEdit.vue";
import CreativePopup from "@/components/CreativePopup.vue";
import SimpleDialog from "@/components/base/SimpleDialog.vue";
import InfoMessage from "@/components/base/InfoMessage.vue";
import CButton from "@/components/base/buttons/CButton.vue";
import BaseButton from "@/components/base/BaseButton.vue";
import TextButton from "@/components/base/buttons/TextButton.vue";
import {parseISO, endOfDay} from "date-fns";
import CircleIconButton from "@/components/base/buttons/CircleIconButton.vue";
import NotFoundSlate from "@/components/NotFoundSlate.vue";

function sortString(a, b) {
    if (a < b) {
        return -1;
    }
    if (a > b) {
        return 1;
    }
    // names must be equal
    return 0;
}

export default {
    name: "CampaignDetailView",
    metaTags() {
        return {
            title: this.$t('view_campaignDetail_metaTitle') + ' - ' + this.$t('common_meta_title'),
        }
    },
    components: {
        NotFoundSlate,
        CircleIconButton,
        TextButton,
        BaseButton,
        CButton,
        InfoMessage,
        SimpleDialog,
        CreativePopup,
        CampaignStateEdit,
        UpdateCampaignDialog,
        DeleteCampaignDialog,
        CampaignItemMediaDetailDialog,
        LoadingBox,
        CampaignItemDialog,
        SvgIcon,
        CampaignItem,
        ProductSelectDialog,
        TableSortHeading,
        SimpleBreadcrumb,
        CIcon,
    },
    data: () => {
        return {
            showSelectProductDialog: false,
            showSortingFilter: false,
            showUpdateCampaignStateSelect: false,
            showUpdateCampaignDialog: false,
            showDeleteCampaignDialog: false,
            sort: null,
            sendingForCheckInProgress: false,
            changeStateInProgress: false,
            showNameDialog: false,
            approvingInProgress: false,
            deleteInProgress: false,
            campaignName: '',
            campaignNameError: '',
            possibleStates: [],
            currentStateId: null,
            loadingState: false,
            showActualPerformance: true,
            loadingCampaign: false,
            showRejectPopup: false,
            rejectReasonText: '',
            salesPersons: [],
            errorMessage: '',
            updateStateErrorMessage: null,
            campaignExists: null,
        };
    },
    computed: {
        ...mapGetters('auth', ['isImpersonate', 'isRetailerOrImpersonated', 'locale', 'isVendor', 'isRetailer', 'isAgency']),
        breadcrumbs() {
            const that = this;

            return [
                {
                    title: that.$t('common_campaignsManagement'),
                    current: false,
                    to: {name: "campaignList"}
                },
                {
                    title: this.campaignBreadcrumbName,
                    current: 'page',
                },
            ];
        },
        campaign() {
            return this.$store.getters["campaigns/currentCampaign"];
        },
        name() {
            if (!this.campaign) {
                return '';
            }
            if (this.isVendor) {
                return this.campaign.name;
            }
            return this.campaign.company.name + ' - ' + this.campaign.name;
        },
        campaignBreadcrumbName() {
            let name = this.name;

            if ((this.isRetailer || this.isAgency) && this.campaign && this.campaign.company) {
                name = name + ' (' + this.campaign.company.name + ')';
            }

            return name;
        },
        campaignItemId() {
            return this.$route.params.campaignItemId;
        },
        totalPrice() {
            if (!this.campaign) {
                return 0;
            }
            return this.campaign.totalPrice;
        },
        campaignItem() {
            if (!this.campaign || !this.campaignItemId) {
                return null;
            }
            return this.campaignItems.find(item => item.id == this.campaignItemId);
        },
        campaignStateId() {
            if (!this.campaign) {
                return null;
            }
            return this.campaign.campaignState.id;
        },
        showDetailDialog: {
            get() {
                return this.$route.name === 'campaignDetailItem';
            },
            set(value) {
                if (!value) {
                    this.$router.push({
                        name: 'campaignDetail',
                        params: {
                            campaignId: this.$route.params.campaignId,
                        }
                    });
                }
            }
        },
        editableSalesPerson() {
            return this.salesPersons.length > 1;
        },
        salesPersonName() {
            if (!this.campaign) {
                return '';
            }
            if (!this.campaign.salesPerson) {
                return this.$t('common_notAssignedUser');
            }
            return this.campaign.salesPerson.name;
        },
        showMediaDetailDialog: {
            get() {
                return this.$route.name === 'campaignItemCreativePreview';
            },
            set(value) {
                if (!value) {
                    this.$router.push({
                        name: 'campaignDetail',
                        params: {
                            campaignId: this.$route.params.campaignId,
                        }
                    });
                }
            }
        },
        campaignItems: function () {
            if (!this.campaign) {
                return []
            }
            let items = this.campaign.campaignItems;
            if (this.sort) {
                items = items.sort((itemA, itemB) => {
                    let sort = 0;
                    if (this.sort.name === "name") {
                        sort = sortString(itemA.product.name, itemB.product.name)
                    } else if (this.sort.name === "startDate") {
                        sort = sortString(itemA.startDate, itemB.startDate)
                    } else if (this.sort.name === "endDate") {
                        sort = sortString(itemA.startDate, itemB.startDate)
                    } else if (this.sort.name === "state") {
                        sort = sortString(itemA.itemState.name, itemB.itemState.name)
                    } else if (this.sort.name === "price") {
                        sort = itemA.price - itemB.price;
                    }

                    if (this.sort.direction === 'DESC') {
                        return -sort;
                    }
                    return sort;
                });
            }
            return items;
        },
        campaignIsRunning() {
            if (this.campaign) {
                return [CampaignStateEnum.Online, CampaignStateEnum.Paused, CampaignStateEnum.Closed].indexOf(
                    this.campaign.campaignState.identifier
                ) >= 0
            }
            return false;
        },
        enableSendForCheck() {
            if (this.campaign) {
                return this.campaign.campaignItems.length > 0
                    && this.campaign.campaignState.canSendForCheck
                    && !this.isRetailerOrImpersonated;
            }
            return false;
        },
        enableApprovalByRetailer() {
            if (this.campaign) {
                return this.campaign.campaignItems.length > 0
                    && ['in-process', 'sas-check', 'user-check'].indexOf(this.campaign.campaignState.identifier) >= 0
                    && this.isRetailerOrImpersonated;
            }
            return false;
        },
        itemAdditionAllowed() {
            if (this.campaign?.itemAdditionAllowed) {
                if (this.isVendor && this.campaign.campaignState.identifier === CampaignStateEnum.Reserved) {
                    return false;
                }
                return true;
            }
            return false;
        },
        campaignEditable() {
            if(!this.campaign) {
                return false;
            }
            if (
                this.isRetailerOrImpersonated
                && [CampaignStateEnum.Online, CampaignStateEnum.Canceled, CampaignStateEnum.Paused]
                    .indexOf(this.campaign.campaignState.identifier) < 0
            ) {
                return true;
            }
            return this.campaign.campaignState.canEdit;
        },
        enableApproval() {
            if (this.campaign) {
                return this.campaign.campaignItems.length > 0
                    && this.campaign.campaignState.canApprove
                    && !this.isRetailerOrImpersonated;
            }
            return false;
        },
        enableDelete() {
            if (this.campaign) {
                return this.campaign.campaignItems.length === 0
                    || ['in-process'].indexOf(this.campaign.campaignState.identifier) === 0
                    || !this.isVendor;
            }

            return false;
        },
        enableRejectToVendor() {
            if (this.campaign) {
                return this.campaign.campaignItems.length > 0
                    && ['sas-check'].indexOf(this.campaign.campaignState.identifier) >= 0
                    && this.isRetailerOrImpersonated;
            }
            return false;
        },
        sasCampaignURL() {
            if (this.campaign && this.campaign.sasInfo) {
                return this.campaign.sasInfo.campaignURL;
            }
            return null;
        },
        sasProposalURL() {
            if (this.campaign && this.campaign.sasInfo) {
                return this.campaign.sasInfo.proposalURL;
            }
            return null;
        },
        displayOfPerformanceMetrics() { // dispay of performance metrics is allowed
            return this.campaignIsRunning
                && this.isDisplayImpressions
                || this.isDisplayViews
                || this.isDisplayVisibilityRatio
                || this.isDisplayVisibilityClicks
                || this.isDisplayCtrRatio;
        },
        displayOfPerformanceMetricsGroup() { // dispay of performance metrics is allowed
            return this.campaignIsRunning
                && this.$store.getters['appSettings/displayOfPerformanceMetricsGroup'];
        },
        showCampaignRejectionReason() {
            if (!this.campaign) {
                return false;
            }
            const campaignStateIdentifier = this.campaign.campaignState.identifier;
            return [
                CampaignStateEnum.InProcess,
                CampaignStateEnum.UserCheck,
            ].indexOf(campaignStateIdentifier) >= 0;
        },
        isDisplayImpressions() {
            return this.displayOfPerformanceMetricsGroup.displayImpressions && this.campaign.actualImpressions !== null;
        },
        isDisplayViews() {
            return this.displayOfPerformanceMetricsGroup.displayViews && this.campaign.actualViews !== null;
        },
        isDisplayVisibilityRatio() {
            return this.displayOfPerformanceMetricsGroup.displayVisibilityRatio && this.campaign.visibilityRatio !== null;
        },
        isDisplayVisibilityClicks() {
            return this.displayOfPerformanceMetricsGroup.displayVisibilityClicks && this.campaign.actualClicks !== null;
        },
        isDisplayCtrRatio() {
            return this.displayOfPerformanceMetricsGroup.displayCtrRatio && this.campaign.ctrRatio !== null;
        },
    },
    watch: {
        async locale() {
            await this.loadData();
        },
        async campaignStateId(stateId) {
            if (!this.campaign) { // currently no campaign set
                return;
            }
            if (stateId !== this.currentStateId) {
                this.currentStateId = stateId;
                this.possibleStates = await this.$store.dispatch('campaigns/possibleStates', {campaignId: this.campaign.id});
            }
        },
        campaign() {
            this.errorMessage = '';
        },
        showRejectPopup(value) {
            if (value) {
               return;
            }
            this.rejectReasonText = '';
        },
    },
    methods: {
        closeDetail() {
            this.$router.push({name: "campaignList"});
        },
        async loadDataSilently() {
            const id = this.$route.params.campaignId;
            try {
                await this.$store.dispatch('campaigns/setCurrentCampaign', {campaignId: id});
                this.campaignExists = true;
            } catch (error) {
                this.campaignExists = false;
            }

            this.currentStateId = this.campaign?.campaignState.id > 0
                ? this.campaign.campaignState.id
                : null;

            this.possibleStates = await this.$store.dispatch('campaigns/possibleStates', {campaignId: id});
            this.campaignNameError = '';
            this.showActualPerformance = this.campaignIsRunning;
            this.$metaTags({title: this.$t('view_campaignDetail_metaTitle') + ' „' + this.name + '“ - ' + this.$t('common_meta_title'),})
            if(this.isRetailer) {
                this.salesPersons = await this.getAllSalesPersons();
            }
            if(this.isVendor) {
                this.salesPersons = await this.getLoggedUserSalesPersons();
            }
        },
        async loadData() {
            this.loadingCampaign = true;
            await this.loadDataSilently();
            this.loadingCampaign = false;
            this.updateStateErrorMessage = null;
        },
        async requestCampaignCheck() {
            this.errorMessage = '';

            this.sendingForCheckInProgress = true;
            const id = this.$route.params.campaignId;
            await this.$store.dispatch('campaigns/requestCampaignCheck', {campaignId: id});
            this.sendingForCheckInProgress = false;
        },
        async approve() {
            this.errorMessage = '';

            this.approvingInProgress = true;
            this.changeStateInProgress = true;
            if(!this.validateCampaignItemsForApproval()) {
                this.approvingInProgress = false;
                this.changeStateInProgress = false;
                return;
            }
            const id = this.$route.params.campaignId;
            try {
                await this.$store.dispatch('campaigns/approve', {campaignId: id});
            } catch (e) {
                this.errorMessage = this.$t("common_unexpectedError");
                // if (e.response && e.response.data.message) {
                //     this.errorMessage = e.response.data.message;
                // }
            } finally {
                this.approvingInProgress = false;
                this.changeStateInProgress = false;
            }
            this.approvingInProgress = false;
            this.changeStateInProgress = false;
        },
        async rejectToVendor() {
            this.errorMessage = '';

            this.changeStateInProgress = true;
            const id = this.$route.params.campaignId;
            await this.$store.dispatch('campaigns/rejectToVendor', {campaignId: id, reason: this.rejectReasonText});
            this.changeStateInProgress = false;
            this.showRejectPopup = false;
        },
        async approveByRetailer() {
            this.errorMessage = '';

            this.approvingInProgress = true;
            this.changeStateInProgress = true;
            if(!this.validateCampaignItemsForApproval()) {
                this.approvingInProgress = false;
                this.changeStateInProgress = false;
                return;
            }
            const id = this.$route.params.campaignId;
            try {
                await this.$store.dispatch('campaigns/approveByRetailer', {campaignId: id});
            } catch (e) {
                this.errorMessage = this.$t("common_unexpectedError");
                // this.errorMessage = e;
                // if (e.response && e.response.data.message) {
                //     this.errorMessage = e.response.data.message;
                // }
            } finally {
                this.approvingInProgress = false;
                this.changeStateInProgress = false;
            }
        },
        validateCampaignItemsForApproval() {
            // all campaign items must end in future
            const invalidItems = [];
            const now = new Date();
            const campaignItems = this.campaign.campaignItems;
            for (let i = 0; i < campaignItems.length; i++) {
                const campaignItem = campaignItems[i];
                const endDate = endOfDay(parseISO(campaignItem.endDate));
                if (endDate < now) {
                    invalidItems.push({
                        'id': campaignItem.id,
                        'name': campaignItem.generatedName || campaignItem.product.name,
                    });
                }
                // invalidItems.push({
                //     'id': campaignItem.id,
                //     'name': campaignItem.generatedName || campaignItem.product.name,
                // });
            }
            // console.log(invalidItems);
            if (invalidItems.length > 0) {
                let itemsNames = invalidItems.map(item => item.name).join(', ');
                this.errorMessage = this.$t('view_campaignDetail_cantApproveWithPastItems', {itemsNames: itemsNames});
                return false;
            }

            return true;
        },
        async changeState(state) {
            const stateId = state.id;
            if (stateId === this.campaign.campaignState.id) {
                return;
            }
            this.loadingState = true;
            const id = this.$route.params.campaignId;
            try {
                const campaign = await this.$store.dispatch('campaigns/changeState', {campaignId: id, stateId});
                this.currentStateId = campaign.campaignState.id;
            } catch (error) {
                this.updateStateErrorMessage = error?.response?.data?.message || this.$t('common_unexpectedError');
            }

            this.possibleStates = await this.$store.dispatch('campaigns/possibleStates', {campaignId: id});
            this.loadingState = false;
            this.showUpdateCampaignStateSelect = false;
        },
        async getLoggedUserSalesPersons() {
            const user = await this.$store.dispatch('auth/getLoggedUser');
            return user.salesPersons;
        },
        async getAllSalesPersons() {
            return await this.$store.dispatch('users/getSalesPersonUsers');
        },
    },
    async created() {
        await this.loadData();
    },
    async beforeRouteEnter(to, from, next) {
        await next(async (vm) => {
            await vm.loadData();
            if(to.name === 'campaignItemCreativePreview' && vm.campaignItem.product.nativeFormat) {
                await vm.$router.push({name: 'campaignDetail', params: {campaignId: vm.campaign.id}});
            }
        });
    },
    async beforeRouteUpdate(to, from, next) {
        await next(async (vm) => {
            await vm.loadData();
            if(to.name === 'campaignItemCreativePreview' && vm.campaignItem.product.nativeFormat) {
                await vm.$router.push({name: 'campaignDetail', params: {campaignId: vm.campaign.id}});
            }
        });
    },
};
</script>
