<template>
    <div class="w-full overflow-x-auto scrollbar scrollbar-thumb-blue-400 scrollbar-track-gray-200 scrollbar-thin pb-2">
        <dynamic-table :items="productAvailability.weeks">
            <dynamic-table-column identifier="dateLabel">
                {{ $t('common_week') }}
            </dynamic-table-column>
            <dynamic-table-column
                v-for="value in productAvailability.weeks[0].values"
                :key="value.variableValue"
                :identifier="getSlotName(value)">
                 {{ value.variableValue ? value.variableValue.name : '' }}
            </dynamic-table-column>
            <template v-slot:dateLabel="item">
                <span class="whitespace-nowrap">{{ item.dateLabel }}</span>
            </template>
            <template
                v-for="(value, index) in productAvailability.weeks[0].values"
                v-slot:[getSlotName(value)]="item"
            >
                <span v-if="!item.values[index].campaignItem" :key="getSlotName(value)" class="text-green-400">
                    {{ $t('component_ProductAvailabilitiesTable_available') }}
                </span>
                <router-link
                    v-else
                    :key="getSlotName(value)"
                    class="underline cursor-pointer text-red-400 hover:no-underline"
                    :to="{name: 'campaignDetail', params: {campaignId: item.values[index].campaign.id}}"
                >
                    {{ item.values[index].label }}
                </router-link>
            </template>
        </dynamic-table>

    </div>
</template>
<script>


import DynamicTable from "@/components/universal/dynamic-table/DynamicTable.vue";
import DynamicTableColumn from "@/components/universal/dynamic-table/DynamicTableColumn.vue";

export default {
    name: "ProductAvailabilitiesTable",
    components: {DynamicTableColumn, DynamicTable},
    props: {
        productAvailability: {
            default: null
        }
    },
    methods: {
        getSlotName(value) {
            return value.variableValue ? ''+ value.variableValue.id : 'variableValue';
        }
    },
};
</script>
