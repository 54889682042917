import axios from "@/plugins/axios";

export default {
    namespaced: true,
    state: {
    },
    getters: {
    },
    mutations: {
    },
    actions: {
        async searchBrands(context, {name}) {
            const response = await axios.get('/brands/search', {params: {name}});
            return response.data;
        },
    }
};
