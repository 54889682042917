<template>
    <simple-dialog v-model="display">
        <p class="h1 lg:mb-8 mb-4">
            {{ $t("component_deleteCampaignItemDialog_description") }}
        </p>

        <h2 class="h1 text-blue-400">
            {{ campaignItem.product.name }}
        </h2>

        <div class="flex mt-8 flex-wrap justify-end">
            <text-button class="text-gray-400 md:mr-6 my-1" @click="cancel">
                {{ $t('common_back') }}
            </text-button>
            <c-button
                color="red"
                size="medium"
                @click="deleteCampaignItem"
                :loading="deleting"
            >
                {{ $t('common_delete') }}
            </c-button>
        </div>
    </simple-dialog>
</template>

<script>
import TextButton from "@/components/base/buttons/TextButton.vue";
import CButton from "@/components/base/buttons/CButton.vue";
import SimpleDialog from "@/components/base/SimpleDialog.vue";
export default {
    name: "DeleteCampaignItemDialog",
    components: {SimpleDialog, CButton, TextButton},
    props: ["campaignItem", "value"],
    data: () => {
        return {
            deleting: false
        };
    },
    computed: {
        display: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            }
        }
    },
    methods: {
        cancel() {
            this.display = false;
        },
        async deleteCampaignItem() {
            this.deleting = true;
            await this.$store.dispatch('campaigns/deleteCampaignItem', {id: this.campaignItem.id});
            this.deleting = false;
            this.display = false;
        },
    }
}
</script>

<style scoped>

</style>
