import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./plugins/vue-tailwind";
import "./assets/tailwind.css";
import "./plugins/vee-validate";
import auth from "@/store/auth";
import axios from "@/plugins/axios";
import i18n from "@/plugins/i18n";
import vueHeadful from "@/plugins/vue-headful";
import * as Sentry from "@sentry/vue";
import PortalVue from 'portal-vue'
import {mapGetters} from "vuex";

Vue.use(PortalVue)
Vue.config.productionTip = false;
Vue.config.ignoredElements = [
    /^gd-/,
];

store.registerModule("auth", auth(router));

axios.interceptors.request.use((config) => {
    const token = localStorage.getItem("authToken");
    if (!token) {
        return config;
    }
    config.headers.Authorization = `Bearer ${token}`;
    return config;
});

axios.interceptors.response.use((response) => {
    return response;
}, (error) => {
    const status = error.response?.status || 500;
    if (status === 401) {
        if (store.getters['auth/userId'] !== null) {
            store.dispatch('auth/logout');
        }
        return Promise.reject(error);
    } else if (status === 403) {
        router.push('/unauthorized');
    }

    return Promise.reject(error);
});


router.beforeEach(async (to, from, next) => {
    const userInfo = await store.dispatch("auth/authorization");

    /**
     * If logged
     */
    if (userInfo) {
        if (userInfo.locale) {
            sessionStorage.setItem("locale", userInfo.locale);
        }

        if (to.name === "login") {
            return next({name: store.getters['auth/defaultPage']});
        }
        /**
         * vendorList is accessible only for retailer users
         */
        if (to.name === "vendorList" && !store.getters['auth/isRetailer']) {
            return next({name: "campaignList"});
        }
        if (to.name === "vendorDetail" && !store.getters['auth/isRetailer']) {
            return next({name: "campaignList"});
        }
        if (
            ['productList'].indexOf(to.name) >= 0 // I want to go to productList
            && store.getters['auth/isRetailer'] // and I am retailer
        ) {
            return next({name: "campaignList"});
        }
        return next();
    }

    /**
     * Force redirect to login if not logged for authenticated routes
     */
    if (to.meta.authenticated) {
        return next({name: "login"});
    }

    next();
});

// router.afterEach((to) => {
//     // Use next tick to handle router history correctly
//     // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
//     Vue.nextTick(() => {
//         document.title = to.meta.title || i18n.t('common_meta_title');
//     });
// });
if (!window.location.hostname.endsWith('localhost')) {
    const appRelease = 'main';
    let appInstance = 'localhost';
    let appEnvironment = 'production';

    if (window.location.hostname.endsWith('.app.cruxo.io')) {
        appInstance = window.location.hostname.split('.')[0];
    } else if (window.location.hostname.endsWith('.dev.cruxo.io')) {
        appInstance = 'main';
        appEnvironment = 'dev';
    } else if (window.location.hostname.endsWith('.stage.cruxo.io')) {
        appInstance = 'main';
        appEnvironment = 'stage';
    }
    Sentry.init({
        Vue,
        dsn: "https://95d3bf05000bd0115cd8595370bd4b75@o4506342193364992.ingest.sentry.io/4506342497910784",
        integrations: [
            new Sentry.BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
                tracingOrigins: [window.location.hostname + '/api'],
            }),
            new Sentry.Replay(),
        ],
        environment: appEnvironment,
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
        // This sets the sample rate at 10%. You may want
        // to change it to 100% while in development
        // and then sample at a lower rate in production.
        replaysSessionSampleRate: 0.1,
        // If you're not already sampling the entire session,
        // change the sample rate to 100% when sampling
        // sessions where errors occur.
        replaysOnErrorSampleRate: 1.0,
    });
    Sentry.setTags({
        instance: appInstance,
        release: appRelease,
    });
}


new Vue({
    router,
    store,
    i18n,
    vueHeadful,
    render: (h) => h(App),
    computed:{
        ...mapGetters('auth', ['userId']),
    },
    watch: {
        userId: function (newVal) {
            if (newVal !== null) {
                this.$store.dispatch('appSettings/initAppSettings');
                this.$store.dispatch('dashboards/loadData');
            }
        }
    },
}).$mount("#app");
