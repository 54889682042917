import axios from "@/plugins/axios";
import {DeactivatedUserError, NotValidResetHashError} from "@/errors/login";

export default {
    namespaced: true,
    state: {
        recovery: null,
        notValidRecovery: false,
    },
    getters: {
        notValidRecovery(state) {
            return state.notValidRecovery;
        },
    },
    mutations: {
        setRecovery(state, recovery) {
            state.recovery = recovery;
        },
        SET_NOT_VALID_RECOVERY(state, notValidRecovery) {
            state.notValidRecovery = notValidRecovery;
        }
    },
    actions: {
        async resetPassword(context, {login, password, hash}) {
            try {
                await axios.post(`/account/password-update`, {
                    username: login,
                    password,
                    hash
                });
                await context.dispatch('auth/login', {login, password},  {root:true});
                return true;
            } catch (e) {
                return e.response.data;
            }
        },
        async createPasswordReset(context, {login}) {
            try {
                const response = await axios.post(`/account/password-recovery`, {
                    username: login
                });
                context.commit('setRecovery', response.data);
                return response.data;
            } catch (e) {
                if ('deactivated' in e.response.data && e.response.data.deactivated) {
                    throw new DeactivatedUserError(e.response.data.message);
                }
                return null;
            }
        },
        async getPasswordReset(context, {hash}) {
            try {
                const response = await axios.get(`/account/password-recovery/${hash}`);
                return response.data;
            } catch (e) {
                if(e.response.status === 410) {
                    context.commit('SET_NOT_VALID_RECOVERY', true);
                    throw new NotValidResetHashError();
                }
                if ('deactivated' in e.response.data && e.response.data.deactivated) {
                    throw new DeactivatedUserError(e.response.data.message);
                }
                return null;
            }
        },
        handleNotValidRecovery(context) {
            context.commit("SET_NOT_VALID_RECOVERY", false);
        }
    },
};
