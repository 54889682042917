<template>
    <span 
        class="text-center"
        :class="{
            'text-green-600': value,
            'text-red-600': !value,
        }"
    >
        <c-icon v-if="value" class="mx-auto w-3 h-3 text-green" icon="check"/>
        <c-icon v-else class="mx-auto w-3 h-3 text-red" icon="x"/>
    </span>
</template>

<script>
import CIcon from "@/components/base/icons/CIcon.vue";

export default {
    name: "BooleanColumn",
    props: {
        value: {
            type: Boolean,
            required: true,
        },
    },
    components: {CIcon},
    computed: {
        text() {
            return this.value ? "ano" : "ne";
        },
    },
}
</script>



<style scoped>

</style>
