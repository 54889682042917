import axios from "@/plugins/axios";
import {LoginFailError} from "@/errors/login";

export default {
    namespaced: true,
    state: {
        currentUser: null,
        listFilter: { // filter for user list view
            maxResults: 10,
            firstResult: 0,
            search: '',
            userRole: '',
        },
    },
    getters: {
        currentUser: (state) => state.currentUser,
        listFilter(state) {
            return state.listFilter;
        }
    },
    mutations: {
        SET_CURRENT_USER(state, user) {
            state.currentUser = user;
        },
        CLEAR_LIST_FILTER(state) {
            state.filter = {
                maxResults: 10,
                firstResult: 0,
                search: '',
                userRole: '',
            };
        },
        SET_LIST_FILTER(state, filter) {
            state.filter = filter;
        },
    },
    actions: {
        async filterUsers(context, {maxResults, firstResult, search, userRole}) {
            const filterData = new URLSearchParams();
            filterData.append('maxResults', maxResults);
            filterData.append('firstResult', firstResult);
            if (search) {
                filterData.append('search', search);
            }

            if (userRole) {
                filterData.append('userRole', userRole);
            }

            const response = await axios.get('/users/filter', { params: filterData });
            return response.data;
        },
        setFilterFirstResult(context, firstResult) {
            let filter = context.state.listFilter;
            filter.firstResult = firstResult;
            context.commit('SET_LIST_FILTER', filter);
        },
        setFilterMaxResults(context, maxResults) {
            let filter = context.state.listFilter;
            filter.maxResults = maxResults;
            filter.firstResult = 0;
            context.commit('SET_LIST_FILTER', filter);
        },
        setFilterSearch(context, search) {
            let filter = context.state.listFilter;
            filter.search = search;
            context.commit('SET_LIST_FILTER', filter);
        },
        setFilterUserRole(context, userRole) {
            let filter = context.state.listFilter;
            filter.userRole = userRole;
            context.commit('SET_LIST_FILTER', filter);
        },
        clearFilter(context, {firstResult}) {
            let filter = context.state.listFilter;
            filter.firstResult = firstResult;
            context.commit('CLEAR_LIST_FILTER', filter);
        },
        async getUser(context, {userId}) {
            const response = await axios.get('/users/'+ userId);
            return response.data;
        },
        async updateUser(context, { user, userEdit}) {
            await axios.put(`/users/${user.id}`, userEdit);
        },
        async updatePreferredLocale(context, {userId, locale}) {
            const data = {
                locale
            };
            await axios.put(`/users/update-preferred-locale/${userId}`, data);
        },
        async getVendors() {
            const response = await axios.get('/users/vendors');
            return response.data;
        },
        async getSalesPersonUsers() {
            const response = await axios.get('/users/by-role/SalesPerson');
            return response.data;
        },
        async getAgencyUsers() {
            const response = await axios.get('/users/by-role/Agency');
            return response.data;
        },
        async getAgencies() {
            const response = await axios.get('/agencies');
            return response.data;
        },
        async getAgencyContacts(context, {agencyId}) {
            const response = await axios.get(`/agencies/${agencyId}/contacts`);
            return response.data;
        },
        async getCompanies() {
            const response = await axios.get('/companies');
            return response.data;
        },
        async getVendorCompanies(context, {userId}) {
            const response = await axios.get(`/users/${userId}/vendor-companies`);
            return response.data;
        },
        async getCompanyContacts(context, {companyId}) {
            const response = await axios.get(`/companies/${companyId}/contacts`);
            return response.data;
        },
        async getUserEdit(context, {userId}) {
            const response = await axios.get(`/users/${userId}/edit`);
            return response.data;
        },
        async loginAsUser(context, { id }) {
            try {
                const response = await axios.post("/auth/login-as-user", {
                    id,
                });
                if (response) {
                    localStorage.setItem("authToken", response.data.token);
                }
                return response.data;
            } catch (e) {
                localStorage.setItem("authToken", null);
                throw new LoginFailError(e.response.data.message);
            }

        },
        loginBackAsRetailer(context) {
            localStorage.setItem("authToken", context.rootState.auth.userInfo.originalTokenOfRetailer);
        }
    }
};
