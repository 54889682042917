<template>

<div class="max-w-2xl m-auto px-3">
    <div class="max-w-lg loginModal">
        <div class="text-center mb-12">
            <h1 class="text-4xl mb-4">
                {{ $t('view_resetPassword_title') }}
            </h1>
            <p></p>
        </div>
        <form ref="form" @submit.prevent="submit">
            <t-input
                v-model="password"
                :placeholder="$t('view_resetPassword_inputPassword')"
                type="password"
                required
                class="mb-4 rounded-2xl"
            ></t-input>
            <t-input
                v-model="passwordVerify"
                :placeholder="$t('view_resetPassword_inputPasswordVerify')"
                type="password"
                required
                class="mb-4 rounded-2xl"
            ></t-input>
            <p class="text-red-600 -mt-2 pl-4">{{ error }}</p>
            <div class="flex w-full space-y-6 mt-6 items-center flex-col  lg:w-auto lg:space-x-6 lg:flex-row lg:space-y-0 lg:justify-end">
                <t-button :to="{ name: 'login' }" class="text-gray-400" variant="link">
                    {{ $t('view_resetPassword_buttonBackToLogin') }}
                </t-button>
                <c-button size="big" :disabled="disableButton || submitting">
                    {{ $t('common_send') }}
                </c-button>
            </div>
        </form>
    </div>
</div>
</template>

<script>
import {NotValidResetHashError} from "@/errors/login";
import CButton from "@/components/base/buttons/CButton.vue";


export default {
    components: {CButton},
    name: "ForgottenPasswordView",
    metaTags() {
        return {
            title:  this.$t('view_forgottenPassword_metaTitle') + ' - ' + this.$t('common_meta_title'),
        }
    },
    data: () => {
        return {
            userData: null,
            passwordVerify: "",
            password: "",
            submitting: false,
        };
    },
    computed: {
        error() {
            if(
                this.password !== this.passwordVerify
                && this.password !== ''
                && this.passwordVerify !== ''
            ) {
                return this.$t('view_resetPassword_errorPasswordDifference');
            }
            return "";
        },
        disableButton() {
            return this.password !== this.passwordVerify
            || this.password === ''
            || this.passwordVerify === '';
        }
    },
    methods: {
        async submit() {
            this.submitting = true;
            await this.$store.dispatch('account/resetPassword', {
                login: this.userData.username,
                password: this.password,
                hash: this.$route.params.hash
            });
            this.submitting = false;
        },
        async checkHash(hash) {
            try {
                this.userData = await this.$store.dispatch('account/getPasswordReset',{hash});
                if(!this.userData) {
                    await this.$router.push({
                        name: 'notFound',
                        // preserve current path and remove the first char to avoid the target URL starting with `//`
                        params: { pathMatch: this.$route.path.substring(1).split('/') },
                        // preserve existing query and hash if any
                        query: this.$route.query,
                        hash: this.$route.hash,
                    })
                }
            } catch (e) {
                if(e instanceof NotValidResetHashError) {
                    await this.$router.push({
                        name: 'forgottenPassword',
                    })
                }
            }

        }
    },
    created() {
        this.checkHash(this.$route.params.hash);
    },
    async beforeRouteUpdate(to) {
        await this.checkHash( to.params.hash);
    },
};
</script>

<style scoped></style>
