<template>
    <div class="flex justify-start items-center">
        <span class="w-4 h-4 rounded-full mr-4 flex-shrink-0" :class="stateColor"></span>
        <span class="uppercase text-xs">{{ stateName }}</span>
    </div>
</template>

<script>
import {computed, toRefs} from "vue";
import campaignStateColor from "@/mixins/campaign-state-color";

export default {
    name: "CampaignState",
    components: {},
    props: {
        campaignState: {
            type: Object,
            required: true,
        },
        isChangable: Boolean
    },
    setup(props) {
        const {campaignState} = toRefs(props);

        const stateName = computed(() => {
            return campaignState.value.name;
        });

        const stateIdentifier = computed(() => {
            return campaignState.value.identifier;
        });

        const {stateColor} = campaignStateColor(stateIdentifier);

        return {
            stateName,
            stateIdentifier,
            stateColor,
        }
    }
}
</script>
