<template>
    <div>
        <file-state-button
            @file="uploadFile"
            :percents="percents"
            :item-type="zipState"
            :accept="['application/zip']"
        />
    </div>
</template>

<script>
import FileStateButton from "@/components/FileStateButton.vue";

export default {
name: 'CreativeZipInput',
    components: {FileStateButton},
    props: {
        value: {
            required: true
        },
        companyId: {
            type: Number,
            required: true
        },
    },
    data() {
        return {
            uploading: false,
            percents: 0,

            error: '',
            fileError: '',
        }
    },
    computed: {
        zipMediaExternalId: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        },
        zipState() {
            if (this.uploading) {
                return 'loading';
            }
            if (this.value) {
                return 'approved';
            }
            return 'default';
        },
    },
    methods: {
        async uploadFile(file) {
            this.uploading = true;
            try {
                const media = await this.$store.dispatch("creatives/uploadFile", {
                    file,
                    progressFunc: this.progressFunction,
                    id: this.zipMediaExternalId || null,
                    companyId: this.companyId,
                    restrictions: {}
                });
                this.zipMediaExternalId = media.id;
            } catch (error) {
                if (error.response && error.response.data && error.response.data.message) {
                    this.fileError = error.response.data.message;
                } else {
                    console.error(error);
                    this.fileError = this.$t("common_error_message");
                }
            }
            this.uploading = false;
        },
    }

}
</script>


<style scoped>

</style>
