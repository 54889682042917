import Vue from "vue";
import VueTailwind from "vue-tailwind";
import Czech from 'vue-tailwind/dist/l10n/cs'
import English from 'vue-tailwind/dist/l10n/index'
import {
    TButton,
    TCard,
    TDropdown,
    TInput,
    TCheckbox,
    TModal,
    TDatepicker,
    TTextarea,
    TRichSelect,
    TSelect,
    TCheckboxGroup,
    TDialog
} from "vue-tailwind/dist/components";

const components = {
    "t-input": {
        component: TInput,
        props: {
            fixedClasses: "block w-full py-3.5 transition duration-100 ease-in-out disabled:opacity-40 disabled:cursor-not-allowed required:bg-input-required required:bg-input-required required:bg-no-repeat",
            classes: "rounded-full px-5 bg-white border-gray-500 border focus:outline focus:outline-2 focus:-outline-offset-1 focus:outline-blue-500",
            variants: {
                error: 'rounded-full px-5 bg-white border-red-300 border focus:outline focus:outline-2 focus:-outline-offset-1 focus:outline-blue-500',
                bottomBorder: 'border-bt border-gray-900',
                noBorder: 'px-0 focus:ring-0',
                borderY: 'border-y px-0 rounded-none focus:ring-0'
            }
        },
    },
    't-select': {
        component: TSelect,
        props: {
            fixedClasses: '',
            classes: '',
                variants: {
                    default: 'focus:outline-yellow-500 min-w-fit p-2  mx-5 block w-full transition duration-100 ease-in-out disabled:opacity-40 disabled:cursor-not-allowed required:bg-input-required required:bg-input-required required:bg-no-repeat rounded-full  bg-white border-gray-500 border focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-blue-500',
                    selectButtonItemsPerPage: 'min-w-fit p-2 mx-5 block w-full transition duration-100 ease-in-out disabled:opacity-40 disabled:cursor-not-allowed required:bg-input-required required:bg-input-required required:bg-no-repeat rounded-full bg-white border-gray-500 border focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-blue-500',
                    defaultBlueOutline: 'min-w-fit p-2 mx-5 block w-full transition duration-100 ease-in-out disabled:opacity-40 disabled:cursor-not-allowed required:bg-input-required required:bg-input-required required:bg-no-repeat rounded-full  bg-white border-gray-500 border focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-blue-500',
            }
        }
    },
    't-rich-select': {
        component: TRichSelect,
        props: {
            fixedClasses: {
                wrapper: 'relative w-full ',
                buttonWrapper: 'inline-block relative w-full',
                selectButton: 'w-full flex text-left justify-between rounded-[1.5rem] items-center px-3 py-3.5 text-black transition duration-100 ease-in-out border border-gray-500 focus:outline focus:outline-2 focus:-outline-offset-1 focus:outline-blue-500 disabled:opacity-50 disabled:cursor-not-allowed',
                selectButtonLabel: 'block truncate',
                selectButtonPlaceholder: 'block truncate',
                selectButtonIcon: 'fill-current flex-shrink-0 ml-1 h-4 w-4',
                selectButtonClearButton: 'rounded-full flex flex-shrink-0 items-center justify-center absolute right-0 top-0 m-2 h-8 w-8 transition duration-100 ease-in-out',
                selectButtonClearIcon: 'fill-current h-3 w-3',
                selectButtonTagWrapper: 'flex flex-wrap overflow-hidden',
                selectButtonTag: 'rounded rounded-full bg-green-400 block disabled:cursor-not-allowed disabled:opacity-50 duration-100 ease-in-out focus:border-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 rounded rounded-full shadow-sm text-sm text-white transition white-space-no m-0.5 max-w-full overflow-hidden min-h-8 flex items-center',
                selectButtonTagText: 'px-3 py-1',
                selectButtonTagDeleteButton: '-ml-1.5 h-full hover:bg-blue-600 hover:shadow-sm inline-flex items-center px-2 transition',
                selectButtonTagDeleteButtonIcon: 'w-3 h-3',
                dropdown: 'absolute w-full mt-1 absolute border rounded-lg shadow-sm z-40',
                dropdownFeedback: '',
                loadingMoreResults: '',
                optionsList: 'overflow-auto',
                searchWrapper: 'inline-block w-full',
                searchBox: 'inline-block w-full',
                optgroup: '',
                option: 'cursor-pointer',
                disabledOption: 'opacity-50 cursor-not-allowed',
                highlightedOption: 'cursor-pointer',
                selectedOption: 'cursor-pointer',
                selectedHighlightedOption: 'cursor-pointer',
                optionContent: '',
                optionLabel: 'truncate block',
                selectedIcon: 'fill-current h-4 w-4',
                enterClass: '',
                enterActiveClass: '',
                enterToClass: '',
                leaveClass: '',
                leaveActiveClass: '',
                leaveToClass: ''
            },
            classes: {
                wrapper: '',
                buttonWrapper: '',
                selectButton: 'bg-white',
                selectButtonLabel: '',
                selectButtonPlaceholder: 'text-gray-400',
                selectButtonIcon: 'text-gray-600',
                selectButtonClearButton: 'hover:bg-blue-100 text-gray-600',
                selectButtonClearIcon: '',
                dropdown: 'bg-white border-gray-300',
                dropdownFeedback: 'pb-2 px-3 text-gray-400 text-sm',
                loadingMoreResults: 'pb-2 px-3 text-gray-400 text-sm',
                optionsList: '',
                searchWrapper: 'p-2 placeholder-gray-400',
                searchBox: 'px-3 py-2 bg-gray-50 text-sm rounded-full border focus:outline-none focus:shadow-outline border-gray-300',
                optgroup: 'text-gray-400 uppercase text-xs py-1 px-2 font-medium',
                option: '',
                disabledOption: '',
                highlightedOption: 'bg-blue-100',
                selectedOption: 'font-medium bg-blue-500 font-medium text-white',
                selectedHighlightedOption: 'font-medium bg-blue-600 font-medium text-white',
                optionContent: 'flex justify-between items-center px-3 py-2',
                optionLabel: '',
                selectedIcon: '',
                enterClass: 'opacity-0',
                enterActiveClass: 'transition ease-out duration-100',
                enterToClass: 'opacity-100',
                leaveClass: 'opacity-100',
                leaveActiveClass: 'transition ease-in duration-75',
                leaveToClass: 'opacity-0'
            },
            variants: {
                danger: {
                    selectButton: 'border-red-300 bg-red-50 text-red-900',
                    selectButtonPlaceholder: 'text-red-200',
                    selectButtonIcon: 'text-red-500',
                    selectButtonClearButton: 'hover:bg-red-200 text-red-500',
                    dropdown: 'bg-red-50 border-red-300'
                },
                success: {
                    selectButton: 'border-green-300 bg-green-50 text-green-900',
                    selectButtonIcon: 'text-green-500',
                    selectButtonClearButton: 'hover:bg-green-200 text-green-500',
                    dropdown: 'bg-green-50 border-green-300'
                }
            }
        }
    },
    't-checkbox': {
        component: TCheckbox,
        props: {
            fixedClasses: '',
            classes: 'opacity-0 absolute h-6 w-6 cursor-pointer',
            variants: {
                basic: {
                    wrapper: '',
                    input: 'h-4 w-4 cursor-pointer',
                    label: 'inline-block text-gray-600 pl-2'
                }
            }
        }
    },
    "t-button": {
        component: TButton,
        props: {
            fixedClasses: 'transition duration-200 ease-out rounded-full disabled:cursor-not-allowed',
            //classes: 'px-6 py-2 text-blue-700 bg-green-400 border-2 border-full border-transparent hover:bg-blue-400 hover:text-green-400',
            variants: {
                smallGreen: 'border-none block block tracking-wide px-4 py-1 text-blue-700 bg-green-400 border-2 border-full border-transparent hover:bg-blue-400 hover:text-green-400 active:focus:outline active:focus:outline-2 active:focus:outline-offset-1 active:focus:outline-blue-500',
                mediumGreen: 'border-none block text-sm tracking-wide px-6 py-2 text-blue-700 bg-green-400 border-2 border-full border-transparent hover:bg-blue-400 hover:text-green-400 active:focus:outline active:focus:outline-2 active:focus:outline-offset-1 active:focus:outline-blue-500',
                bigGreen: 'border-none block text-base tracking-wide px-8 py-3 text-blue-700 bg-green-400 border-2 border-full border-transparent hover:bg-blue-400 hover:text-green-400 active:focus:outline active:focus:outline-2 active:focus:outline-offset-1 active:focus:outline-blue-500',
                mediumGray: 'border-none block text-base tracking-wide px-6 py-2 text-gray-500 bg-gray-300 border-2 border-full border-transparent',
                bigGray: 'border-none block text-base tracking-wide px-8 py-3 text-gray-500 bg-gray-300 border-2 border-full border-transparent',
                smallBlue: 'border-none block text-[12px] tracking-wide px-4 py-1 text-blue-700 bg-blue-50 border-2 border-full border-transparent hover:bg-blue-100 hover:border-blue-100 active:focus:outline active:focus:outline-2 active:focus:outline-offset-1 active:focus:outline-blue-500',
                mediumBlue: 'border-none block text-sm tracking-wide px-6 py-2 text-blue-700 bg-blue-50 border-2 border-full border-transparent hover:bg-blue-100 hover:border-blue-100 active:focus:outline active:focus:outline-2 active:focus:outline-offset-1 active:focus:outline-blue-500',
                bigBlue: 'border-none block text-base tracking-wide px-8 py-3 text-blue-700 bg-blue-50 border-2 border-full border-transparent',
                //mediumHollow: 'block text-sm tracking-wide px-6 py-2 text-blue-700 bg-green-400 border-2 border-full border-transparent hover:bg-blue-400 hover:text-green-400 active:focus:outline active:focus:outline-2 active:focus:outline-offset-1 active:focus:outline-blue-500',
                mediumCircleBlue: 'border-none flex justify-center items-center text-sm text-blue-400 tracking-wide p-2 w-[50px] h-[50px] bg-blue-50 hover:bg-blue-100 hover:border-blue-100 border-2 active:focus:outline active:focus:outline-2 active:focus:outline-offset-1 active:focus:outline-blue-500',
                editCircle: 'border-none flex justify-center items-center text-sm text-blue-400 tracking-wide p-1 w-[30px] h-[30px] shrink-0 bg-lime-100 hover:bg-lime-120 active:focus:outline active:focus:outline-2 active:focus:outline-offset-1 active:focus:outline-blue-500',
                editCircleRemove: 'border-none flex justify-center items-center text-sm text-red-400 tracking-wide p-1 w-[30px] h-[30px] shrink-0 bg-lime-100 hover:bg-lime-120 active:focus:outline active:focus:outline-2 active:focus:outline-offset-1 active:focus:outline-blue-500',
                link: 'text-black underline border-transparent hover:no-underline',
                subMenuLink: 'text-black no-underline border-transparent hover:underline',
                delete: 'text-white border-red-400 bg-red-500 hover:bg-red-600 hover:text-white hover:border-red-500 hover:bg-red-600 py-2 px-4',
                //depracted >>>>>>>>>>>
                secondary: 'px-6 py-2 text-gray-800 bg-transparent border border-gray-300 hover:text-gray-600 hover:bg-gray-100 md:text-lg',
                border: ' px-6 py-2 bg-white border border-black text-black hover:bg-black hover:text-white hover:border-black',
                purpleInverse: 'px-6 py-2 border-blue-400 hover:bg-blue-400 active:bg-blue-400 hover:text-white active:text-white text-blue-400 font-medium bg-transparent',
                blueLight: 'px-6 py-2 text-lg bg-blue-50 hover:bg-blue-100 hover:border-blue-100 text-blue-700 border-2',
                gray: ' px-6 py-2 bg-gray-200 text-gray-700 font-normal hover:bg-gray-300 hover:border-gray-300 text-xs px-4 py-1.5 text-blue-700',
                grayPrimary: ' px-6 py-2 bg-gray-300 text-gray-500 font-medium border-gray-300',
                //smallBlue: 'border-1 h-9 rounded-full bg-cyan-100 flex border border-cyan-600 hover:bg-cyan-600 text-sm px-3',
                inverse: ' px-6 py-2 border-green-400 font-bold text-black bg-white hover:bg-green-400 hover:text-white',
                small: 'px-5 py-1 text-sm',
                list: 'block border-transparent hover:no-underline w-full hover:bg-gray-300 text-left rounded-none px-4 py-2',
            }
        },
    },
    't-textarea': {
        component: TTextarea,
        props: {
            fixedClasses: 'block w-full transition duration-100 ease-in-out border disabled:opacity-50 disabled:cursor-not-allowed',
            classes: 'block w-full py-3.5 transition duration-100 ease-in-out disabled:opacity-40 disabled:cursor-not-allowed required:bg-input-required required:bg-input-required required:bg-no-repeat rounded-2xl px-5 bg-white border-gray-500 border focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-blue-500',
            variants: {
                danger: 'border-red-300 bg-red-50 placeholder-red-200 text-red-900',
                success: 'border-green-300 bg-green-50 placeholder-gray-400 text-green-900'
            }
        }
    },
    "t-card": {
        component: TCard,
        props: {
            fixedClasses: {
                wrapper: 'block rounded-xl transition duration-100 ',
            },
            classes: {
                wrapper: "p-4 bg-white",
            },
            variants: {
                product: {
                    wrapper: 'p-6 md:p-8 bg-gray-50 rounded-3xl cursor-pointer hover:bg-blue-100 relative',
                    body: 'mb-0 mt-auto h-full flex flex-col justify-between'
                },
                user: {
                    wrapper: 'p-6 md:p-8 bg-white rounded-x1 relative',
                    body: 'h-full flex flex-col justify-between'
                },
                campaign: {
                    wrapper: 'px-4 bg-white lg:py-2 py-5 cursor-pointer hover:bg-blue-50 rounded-lg',
                },
                campaignItem: {
                    wrapper: 'px-4 bg-white lg:py-2 py-5 rounded-lg',
                    body: 'mb-0 mt-auto'
                },
            }
        },
    },
    "t-dropdown": {
        component: TDropdown,
        props: {
            fixedClasses: {
                button: "mr-0 ml-auto",
                wrapper: "inline-flex flex-col relative",
                dropdownWrapper: "absolute table h-full z-50 w-full min-w-[180px] text-center rounded-2xl bg-white shadow-xl",
                dropdown: "origin-top-right right-0 w-full rounded-2xl py-3",
                enterClass: "opacity-0 translate-y-[-10px]",
                enterActiveClass: "transition transform ease-out duration-100",
                enterToClass: "opacity-100 scale-100",
                leaveClass: "opacity-100 translate-y-[10px]",
                leaveActiveClass: "transition transform ease-in duration-75",
                leaveToClass: "opacity-0 scale-95",
            },
            classes: {
                button: "bg-blue-500 hover:bg-blue-600",
                dropdown: "bg-white pb-2",
                dropdownWrapper:"left-1/2 top-0 shadow-xl"
            },
            variants: {
                danger: {
                    button: "bg-red-500 hover:bg-red-600",
                    dropdown: "bg-red-50 mt-1 pb-2",
                },
                submenu: {
                    dropdownWrapper: "absolute z-10 top-full z-10 w-full h-auto text-right bg-transparent left-1/2 top-[44px] left-1/2 translate-x-[-50%] border-t-blue-400 border-t-2 ring-offset-0",
                    dropdown: "bg-white py-4 space-y-1",
                },
                picker: {
                    dropdownWrapper: "posahanypicker w-full h-auto text-right top-[55px]",
                    dropdown: "bg-white shadow-xl border border-gray-300 focus:border-gray-400 py-4 space-y-1",
                }
            },
        },
    },
    't-modal': {
        component: TModal,
        props: {
            fixedClasses: {
                overlay: 'z-100 overflow-auto scrolling-touch left-0 top-0 bottom-0 right-0 w-full h-full fixed bg-opacity-50 flex customScrollbar !m-0',
                wrapper: 'relative m-auto z-50 px-3 py-12',
                modal: 'overflow-visible relative rounded-4xl',
                body: '',
                header: 'border-b p-3 rounded-t',
                footer: ' p-3 rounded-b',
                close: 'flex items-center justify-center absolute right-4 top-4 md:right-14 md:top-10 transition hover:opacity-80 focus:outline-none'
            },
            classes: {
                overlay: 'bg-black',
                wrapper: 'w-full max-w-4xl',
                modal: 'bg-white shadow',
                body: '',
                header: 'border-gray-100',
                footer: 'bg-gray-100',
                close: 'text-gray-600',
                closeIcon: 'fill-current h-12 w-12',
                overlayEnterClass: 'opacity-0',
                overlayEnterActiveClass: 'transition ease-out duration-100',
                overlayEnterToClass: 'opacity-100',
                overlayLeaveClass: 'opacity-100',
                overlayLeaveActiveClass: 'transition ease-in duration-75',
                overlayLeaveToClass: 'opacity-0',
                enterClass: '',
                enterActiveClass: '',
                enterToClass: '',
                leaveClass: '',
                leaveActiveClass: '',
                leaveToClass: ''
            },
            variants: {
                danger: {
                    overlay: 'bg-red-100',
                    header: 'border-red-50 text-red-700',
                    close: 'bg-red-50 text-red-700 hover:bg-red-200 border-red-100 border',
                    modal: 'bg-white border border-red-100 shadow-lg',
                    footer: 'bg-red-50'
                },
                wide: {
                    wrapper: 'w-full max-w-7xl',
                }
            }
        }
    },
    't-dialog': {
        name: 'TDialog',
        component: TDialog,
        props: {
            fixedClasses: {
                overlay: 'overflow-auto scrolling-touch left-0 top-0 bottom-0 right-0 w-full h-full fixed z-40 bg-opacity-50',
                wrapper: 'relative m-auto z-50 px-3 py-12 w-full max-w-4xl',
                modal: 'overflow-visible relative ',
                close: 'flex items-center justify-center  rounded-full absolute right-0 top-0 -m-3 h-8 w-8 transition duration-100 ease-in-out focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50',
                dialog: 'overflow-visible relative',
                iconWrapper: 'flex flex-shrink-0 h-12 items-center justify-center rounded-full w-12 mx-auto',
                icon: 'w-6 h-6',
                okButton: 'mr-2 mb-2 transition duration-200 ease-out rounded-full disabled:cursor-not-allowed border-none block text-sm tracking-wide px-6 py-2 text-blue-700 bg-green-400 border-2 border-full border-transparent hover:bg-blue-400 hover:text-green-400 active:focus:outline active:focus:outline-2 active:focus:outline-offset-1 active:focus:outline-blue-500',
                buttons: 'p-3 mt-5 flex space-x-4 justify-end'
            },
            classes: {
                close: 'bg-gray-100 text-gray-600 hover:bg-gray-200',
                closeIcon: 'fill-current h-4 w-4',
                overlay: 'bg-black',
                wrapper: '',
                dialog: 'md:p-20 p-8 bg-white shadow rounded-2xl text-left',
                body: 'space-y-3',
                buttons: '',
                iconWrapper: 'bg-gray-100',
                icon: 'text-gray-500',
                content: 'w-full flex justify-center flex-col',
                titleWrapper: '',
                title: 'h1 lg:mb-8 mb-4',
                textWrapper: 'text-left w-full mx-2 mt-4',
                text: '',
                cancelButton: 'text-gray-400 md:mr-6 my-1 transition duration-200 ease-out rounded-full disabled:cursor-not-allowed text-black underline border-transparent hover:no-underline',
                okButton: 'text-blue-700 bg-green-400 border-transparent hover:bg-blue-400 hover:text-green-400',
                inputWrapper: 'mt-1 flex items-center space-x-3',
                input: 'block w-full px-3 py-2 text-black placeholder-gray-400 transition duration-100 ease-in-out bg-white border border-gray-300 rounded-full shadow-sm focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed w-full',
                select: 'block w-full px-3 py-2 text-black placeholder-gray-400 transition duration-100 ease-in-out bg-white border border-gray-300 rounded shadow-sm focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50  disabled:opacity-50 disabled:cursor-not-allowed w-full',
                radioWrapper: 'flex items-center space-x-2',
                radio: 'text-blue-500 transition duration-100 ease-in-out border-gray-300 shadow-sm focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50 focus:ring-offset-0  disabled:opacity-50 disabled:cursor-not-allowed',
                radioText: '',
                checkboxWrapper: 'flex items-center space-x-2',
                checkbox: 'text-blue-500 transition duration-100 ease-in-out border-gray-300 rounded shadow-sm focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50 focus:ring-offset-0  disabled:opacity-50 disabled:cursor-not-allowed',
                checkboxText: '',
                errorMessage: 'text-red-500 block text-sm',
                busyWrapper: 'absolute bg-opacity-50 bg-white flex h-full items-center justify-center left-0 top-0 w-full',
                busyIcon: 'animate-spin h-6 w-6 fill-current text-gray-500',
                overlayEnterClass: 'opacity-0',
                overlayEnterActiveClass: 'transition ease-out duration-100',
                overlayEnterToClass: 'opacity-100',
                overlayLeaveClass: 'opacity-100',
                overlayLeaveActiveClass: 'transition ease-in duration-75',
                overlayLeaveToClass: 'opacity-0',
                enterClass: '',
                enterActiveClass: '',
                enterToClass: '',
                leaveClass: '',
                leaveActiveClass: '',
                leaveToClass: ''
            },
            variants: {
                danger: {
                    overlay: 'bg-red-100',
                    okButton: 'bg-red-500 hover:bg-red-600',
                    iconWrapper: 'bg-red-50',
                    buttons: 'bg-red-50',
                    icon: 'text-red-500'
                },
                horizontal: {
                    body: 'p-3 flex space-x-3',
                    title: 'text-lg font-semibold'
                }
            }
        }
    },
    't-datepicker': {
        component: TDatepicker,
        props: {
            locales: {
                cs:  Czech,
                en:  English,
            },
            // locale: Czech,
            userFormat: "d.m.Y",
            fixedClasses: {
                navigator: 'flex',
                navigatorViewButton: 'flex items-center',
                navigatorViewButtonIcon: 'flex-shrink-0 h-5 w-5',
                navigatorViewButtonBackIcon: 'flex-shrink-0 h-5 w-5',
                navigatorLabel: 'flex items-center py-1',
                navigatorPrevButtonIcon: 'h-6 w-6 inline-flex',
                navigatorNextButtonIcon: 'h-6 w-6 inline-flex',
                inputWrapper: 'relative',
                viewGroup: 'inline-flex flex-wrap',
                view: 'w-64',
                calendarDaysWrapper: 'grid grid-cols-7',
                calendarHeaderWrapper: 'grid grid-cols-7',
                monthWrapper: 'grid grid-cols-4',
                yearWrapper: 'grid grid-cols-4',
                input: 'block w-full transition duration-100 ease-in-out disabled:opacity-50 disabled:cursor-not-allowed',
                clearButton: 'flex flex-shrink-0 items-center justify-center absolute right-0 top-0 m-2 !rounded-full',
                clearButtonIcon: 'fill-current h-3 w-3'
            },
            classes: {
                wrapper: 'flex flex-col',
                dropdownWrapper: 'relative z-10',
                dropdown: 'origin-top-left absolute rounded shadow bg-white overflow-hidden mt-1',
                enterClass: 'opacity-0 scale-95',
                enterActiveClass: 'transition transform ease-out duration-100',
                enterToClass: 'opacity-100 scale-100',
                leaveClass: 'opacity-100 scale-100',
                leaveActiveClass: 'transition transform ease-in duration-75',
                leaveToClass: 'opacity-0 scale-95',
                inlineWrapper: '',
                inlineViews: 'rounded bg-white border mt-1 inline-flex',
                inputWrapper: '',
                input: 'border rounded-full bg-white border-gray-500 focus:outline focus:outline-2 focus:-outline-offset-1 focus:outline-blue-500 pl-4 pr-6 py-3',
                clearButton: 'hover:bg-gray-100 rounded transition duration-100 ease-in-out text-gray-600 h-8 w-8',
                clearButtonIcon: '',
                viewGroup: '',
                view: '',
                navigator: 'pt-2 px-3',
                navigatorViewButton: 'transition ease-in-out duration-100 inline-flex cursor-pointer rounded-full px-2 py-1 -ml-1 hover:bg-gray-100',
                navigatorViewButtonIcon: 'fill-current text-gray-400',
                navigatorViewButtonBackIcon: 'fill-current text-gray-400',
                navigatorViewButtonMonth: 'text-gray-700 font-medium',
                navigatorViewButtonYear: 'text-gray-500 ml-1',
                navigatorViewButtonYearRange: 'text-gray-500 ml-1',
                navigatorLabel: 'py-1',
                navigatorLabelMonth: 'text-gray-700 font-medium',
                navigatorLabelYear: 'text-gray-500 ml-1',
                navigatorPrevButton: 'transition ease-in-out duration-100 inline-flex cursor-pointer hover:bg-gray-100 rounded-full p-1 ml-2 ml-auto disabled:opacity-50 disabled:cursor-not-allowed',
                navigatorNextButton: 'transition ease-in-out duration-100 inline-flex cursor-pointer hover:bg-gray-100 rounded-full p-1 -mr-1 disabled:opacity-50 disabled:cursor-not-allowed',
                navigatorPrevButtonIcon: 'text-gray-400',
                navigatorNextButtonIcon: 'text-gray-400',
                calendarWrapper: 'px-3 pt-2',
                calendarHeaderWrapper: '',
                calendarHeaderWeekDay: 'uppercase text-xs text-gray-500 w-8 h-8 flex items-center justify-center',
                calendarDaysWrapper: '',
                calendarDaysDayWrapper: 'w-full h-8 flex flex-shrink-0 items-center',
                otherMonthDay: 'text-sm rounded-full w-8 h-8 mx-auto hover:bg-blue-100 text-gray-400 disabled:opacity-50 disabled:cursor-not-allowed',
                emptyDay: '',
                inRangeFirstDay: 'text-sm bg-blue-500 text-white w-full h-8 rounded-l-full',
                inRangeLastDay: 'text-sm bg-blue-500 text-white w-full h-8 rounded-r-full',
                inRangeDay: 'text-sm bg-blue-200 w-full h-8 disabled:opacity-50 disabled:cursor-not-allowed',
                selectedDay: 'text-sm rounded-full w-8 h-8 mx-auto bg-blue-500 text-white disabled:opacity-50 disabled:cursor-not-allowed',
                activeDay: 'text-sm rounded-full bg-blue-100 w-8 h-8 mx-auto disabled:opacity-50 disabled:cursor-not-allowed',
                highlightedDay: 'text-sm rounded-full bg-blue-200 w-8 h-8 mx-auto disabled:opacity-50 disabled:cursor-not-allowed',
                day: 'text-sm rounded-full w-8 h-8 mx-auto hover:bg-blue-100 disabled:opacity-50 disabled:cursor-not-allowed',
                today: 'text-sm rounded-full w-8 h-8 mx-auto hover:bg-blue-100 disabled:opacity-50 disabled:cursor-not-allowed border border-blue-500',
                monthWrapper: 'px-3 pt-2',
                selectedMonth: 'text-sm rounded w-full h-12 mx-auto bg-blue-500 text-white',
                activeMonth: 'text-sm rounded w-full h-12 mx-auto bg-blue-100',
                month: 'text-sm rounded w-full h-12 mx-auto hover:bg-blue-100',
                yearWrapper: 'px-3 pt-2',
                year: 'text-sm rounded w-full h-12 mx-auto hover:bg-blue-100',
                selectedYear: 'text-sm rounded w-full h-12 mx-auto bg-blue-500 text-white',
                activeYear: 'text-sm rounded w-full h-12 mx-auto bg-blue-100'
            },
            variants: {
                danger: {
                    input: 'border-red-300 bg-red-50 placeholder-red-200 text-red-900',
                    clearButton: 'hover:bg-red-200 text-red-500'
                },
                bottomLine: {
                    input: 'border-b-2 border-zinc-900 focus:outline-none py-2 px-2',
                    clearButton: 'h-5 w-3'
                }
            }
        }
    },
    't-checkbox-group': {
        component: TCheckboxGroup,
        props: {
            classes: {
                groupWrapper: 'flex flex-col',
                label: 'ml-2 text-gray-700 uppercase text-sm',
                input: 'transition duration-100 ease-in-out rounded shadow-sm focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50 focus:ring-offset-0  disabled:opacity-50 disabled:cursor-not-allowed text-blue-500 border-gray-300 focus:border-gray-400'
            },
            variants: {
                danger: {
                    groupWrapper: 'flex flex-col',
                    label: 'ml-2 text-red-500 uppercase text-sm',
                    input: 'transition duration-100 ease-in-out rounded shadow-sm focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50 focus:ring-offset-0  disabled:opacity-50 disabled:cursor-not-allowed text-red-500 border-red-500'
                },
                buttons: {
                    groupWrapper: 'flex',
                    label: '',
                    labelChecked: '',
                    wrapper: 'mx-1 bg-white border border-gray-300 flex items-center px-4 py-2 rounded shadow-sm cursor-pointer focus:shadow-outline text-sm text-gray-700 hover:text-gray-500 leading-5 uppercase',
                    wrapperChecked: 'mx-1 bg-gray-100 border border-gray-300 flex items-center px-4 py-2 rounded shadow-inner cursor-pointer focus:shadow-outline text-sm text-gray-700 hover:text-gray-500 leading-5 uppercase',
                    inputWrapper: '',
                    inputWrapperChecked: '',
                    input: 'absolute invisible'
                }
            }
        }
    }
};
Vue.use(VueTailwind, components);
