<template>
    <nav class="flex py-2" aria-label="Breadcrumb">
        <ol class="inline-flex lg:items-center flex-wrap">
            <li v-for="(item, index) in items" :key="item.title" :aria-current="item.current">
                <base-button
                    class="flex lg:items-center text-left"
                    :class="(item.onClick || item.to) ? '' : 'nolink cursor-default'"
                    :to="item.to"
                    @click="item.onClick ? item.onClick() : null"
                >
                    <svg-icon v-if="item.icon" :name="item.icon" class="mr-2 w-3 -rotate-90" aria-hidden="true"></svg-icon>
                    <svg v-if="index !== 0" aria-hidden="true" class="w-4 h-4 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                    <span class="mr-1 text-xs font-medium" :class="item.onClick ? ' text-gray-500 hover:text-blue-400' : 'text-gray-500'">{{ item.title }}</span>
                </base-button>
            </li>
        </ol>
    </nav>
</template>

<script>
import SvgIcon from "@/components/SvgIcon";
import BaseButton from "@/components/base/BaseButton.vue";

export default {
    name: "SimpleBreadcrumb",
    components: {BaseButton, SvgIcon},
    props: {
        items: {
            default: () => {
                return {};
            },
        },
    }
}
</script>
