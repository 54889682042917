<script>
import {computed, ref, toRefs, watch} from "vue";
import {onClickOutside} from "@vueuse/core";

export default {
    name: "BaseDropdown",
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        noRender: {
            type: Boolean,
            default: false,
        },
        disableClickOutside: {
            type: Boolean,
            default: false,
        }
    },
    emits: ['input'],
    setup(props, {emit, attrs}) {
        const {value, noRender, disableClickOutside} = toRefs(props);
        const open = ref(value.value);
        const target = ref(null);

        const doOpen = () => {
            open.value = true;
            emit('input', true);
        }

        const doClose = () => {
            open.value = false;
            emit('input', false);
        }

        watch(value, () => {
            open.value = value.value;
        });

        onClickOutside(target, (event) => {


            if(disableClickOutside.value) {
                return;
            }
            if(open.value) {
                doClose();
                event.stopPropagation();
            }

        })

        const render = computed(() => {
            return !noRender.value || open.value;
        });

        return {
            open,
            target,
            doOpen,
            doClose,
            render,
            attrs
        }
    }
}
</script>

<template>
    <div v-if="render" ref="target" v-bind="attrs" :class="{'hidden':!open}">
        <slot></slot>
    </div>
</template>

<style scoped>

</style>
