<template>
    <horizontal-tabs>
        <horizontal-tab-content
            tab-name="Nahrát"
            identifier="upload"
        >
            <one-image-upload
                @image="image"
                :default-names="defaultNames"
            >
            </one-image-upload>
        </horizontal-tab-content>
        <horizontal-tab-content
            tab-name="Vybrat"
            identifier="select"
        >
            <one-image-select
                @image="image"
            >
            </one-image-select>
        </horizontal-tab-content>
    </horizontal-tabs>
</template>

<script>
import HorizontalTabs from "@/components/HorizontalTabs.vue";
import HorizontalTabContent from "@/components/HorizontalTabContent.vue";
import OneImageUpload from "@/components/base/OneImageUpload.vue";
import OneImageSelect from "@/components/base/OneImageSelect.vue";

export default {
    name: "OneImageSelection",
    components: {OneImageSelect, OneImageUpload, HorizontalTabContent, HorizontalTabs},
    props: {
        value: {
            type: Number,
            default: null,
        },
        defaultNames: {
            // default localized names for new image
            // format: {"cs-CZ": "Český název", "en-GB": ...}
            type: Object,
            default: () => {},
        }
    },
    methods:{
        image(data) {
            this.$emit("image", data);
        },
        cancel() {
            this.$emit("cancel");
        },
    }

}
</script>

<style scoped>

</style>
