<template>
    <div v-if="landingPage" class="max-w-screen-2xl pt-1 pb-8 px-8 md:px-8 mx-auto">
        <simple-breadcrumb :items="breadcrumbs"></simple-breadcrumb>

        <loading-box theme="light" v-model="working">
            <div v-if="landingPage">

                <header class="flex flex-wrap items-center justify-start mt-5 mb-3 space-y-2 lg:space-y-0">
                    <div class="flex items-center mb-4 space-x-2 pr-3 w-full">
                        <h1 class="text-purple-100 h1">{{ landingPage.name }}</h1>
                    </div>
                    <creative-state :state="landingPage.creativeState"></creative-state>
                    <div class="flex items-center ml-3">
                        <c-icon
                            class="w-3 h-3 mr-2"
                            :icon="landingPage.active ? 'check': 'x'"
                            :class="landingPage.active ? 'text-green-500' : 'text-red-500'"
                            >
                        </c-icon>
                        {{ landingPage.active ? $t('common_is_active') : $t('common_is_not_active') }}
                    </div>
                </header>


                <div class="my-4"><strong>{{ $t('common_target_web') }}</strong>: {{ landingPage.target.name }}</div>
                <div class="my-4"><strong>{{ $t('common_vendor') }}</strong>: {{ landingPage.company.name }}</div>


            </div>
            <div v-if="viewState === 'edit' && landingPageEdit">
                <form class="" @submit.prevent.stop="saveLandingPage">
                    <div class="grid grid-cols-1 mt-8 gap-4">
                        <div
                            class="whiteBlock rounded-2xl p-6"
                        >
                            <label for="" class="text-gray-600 mb-1 block">
                                {{ $t('common_name') }}
                            </label>
                            <t-input
                                variant=""
                                class=""
                                v-model="landingPageEdit.name"
                                required
                            >
                            </t-input>
                        </div>

                        <div
                            class="whiteBlock rounded-2xl p-6"
                        >
                            <label class="cursor-pointer inline-flex items-center w-full">
                                <t-checkbox name="active" v-model="landingPageEdit.active"/>
                                <checkbox-icon/>
                                <span class="leading-tight">Aktivní</span>
                            </label>
                        </div>
                    </div>
                    <creative-fields-input
                        v-model="editFieldValues"
                        class="grid grid-cols-1 mt-4 gap-4"
                        :target-id="landingPageEdit.target?.id"
                        :company-id="landingPageEdit.company.id"
                    />
                    <t-button variant="bigGreen" @click.prevent.stop="submitLandingPageForm">
                        {{ $t('common_save') }}
                    </t-button>
                    <button ref="submitButton" class="hidden" type="submit"></button>
                </form>
            </div>
            <div v-if="viewState === 'preview'">

                <div v-if="displayRejectReason" class="bg-white rounded-2xl p-6 w-full mt-8">
                    <info-message type="info" class="w-full">
                        <strong>{{ $t('common_rejectReason') }}:</strong>
                        <div class="ml-2">{{landingPage.notes}}</div>
                    </info-message>
                </div>

                <div class="grid xl:grid-cols-3 md:grid-cols-3 grid-cols-2 mt-8 gap-4">
                    <creative-preview-value
                        v-for="fieldValue in previewFieldValues"
                        :key="fieldValue.id"
                        :field-value="fieldValue"
                    ></creative-preview-value>
                </div>

                <info-message v-if="missingFields" type="error" class="w-full mt-6">
                    <strong>{{ $t('common_missingFields') }}:</strong>
                    <span class="ml-2">{{ missingFields }}</span>
                </info-message>

                <div class="grid xl:grid-cols-3 md:grid-cols-3 grid-cols-2 gap-4">
                <landing-page-approval-form
                    v-if="isApprovable"
                    class="rounded-2xl bg-white p-6 mt-4 lg:col-span-2"
                    :landing-page="landingPage"
                    @submit="submitApprove"
                >
                </landing-page-approval-form>
                </div>

                <div class="flex w-full space-y-6 mt-6 items-center flex-col  lg:w-auto lg:space-x-6 lg:flex-row lg:space-y-0 lg:justify-end">

                    <c-button v-if="canEditCreative"
                        @click="viewState = 'edit'"
                        size="big"
                        prepend-icon="edit-pencil"
                        >

                        {{ $t('common_edit') }}
                    </c-button>
                    <c-button
                        v-if="displaySendForCheck"
                        class="sm:my-0 my-3"
                        size="big"
                        :disabled="!enableSendForCheck"
                        :loading="requestingCreativeCheck"
                        prepend-icon="check"
                        @click="requestCheck"
                    >
                        {{ $t('component_campaignItemDialog_buttonSendForApproval') }}
                    </c-button>
                </div>

            </div>

        </loading-box>

    </div>
</template>

<script>
import {mapGetters} from "vuex";
import SimpleBreadcrumb from "@/components/SimpleBreadcrumb";
import LoadingBox from "@/components/LoadingBox.vue";
import CheckboxIcon from "@/components/icons/CheckboxIcon.vue";
import CIcon from "@/components/base/icons/CIcon.vue";
import {CreativeStates} from "@/utils/CreativeState";
import CreativeState from "@/components/CreativeState.vue"
import CreativeFieldsInput from "@/components/creative/CreativeFieldsInput.vue";
import InfoMessage from "@/components/base/InfoMessage.vue";
import CreativePreviewValue from "@/components/CreativePreviewValue.vue";
import LandingPageApprovalForm from "@/components/landing-page/LandingPageApprovalForm.vue";
import CButton from "@/components/base/buttons/CButton.vue";

export default {
    name: "LandingPageDetailView",
    metaTags() {
        return {
            title: this.$t('view_landingPageDetailView_metaTitle') + ' - ' + this.$t('common_meta_title'),
        }
    },
    components: {
        CButton,
        LandingPageApprovalForm,
        CreativePreviewValue, InfoMessage,
        CreativeFieldsInput,
        CreativeState,
        CIcon,
        CheckboxIcon,
        LoadingBox,
        SimpleBreadcrumb,
    },
    props: {},
    data() {
        return {
            landingPage: null,
            errorMessage: null,
            working: false,
            requestingCreativeCheck: false,
            viewState: 'preview',
            landingPageEdit: null,
        }
    },
    computed: {
        ...mapGetters("auth", ["isRetailer", 'isVendor', 'isAgency']),
        breadcrumbs() {
            const that = this;

            const breadcrumbs = [
                {
                    title: that.$t('common_landingPages'),
                    current: false,
                    to: {name: "landingPagesList"},
                },
            ];

            if (that.landingPage !== null) {
                breadcrumbs.push({
                    title: that.landingPage.name,
                    current: 'page',
                });
            }

            return breadcrumbs;
        },
        creative() {
            if (!this.landingPage) {
                return null;
            }
            return {
                creativeState: this.landingPage.creativeState,
                fieldValues: this.landingPage.fieldValues,
                notes: '',
                name: this.landingPage.name,
                zipMediaExternalId: null
            };
        },
        editFieldValues: {
            get() {
                return this.landingPageEdit ? this.landingPageEdit.fieldValues : [];
            },
            set(value) {
                this.landingPageEdit.fieldValues = value;
            }
        },
        targetId() {
            return this.landingPage.target.id;
        },
        companyId() {
            return this.landingPage.company.id;
        },
        fieldValues() {
            if (!this.landingPage) {
                return [];
            }
            return this.landingPage.fieldValues;
        },
        enableSendForCheck() {
            return !this.sending && this.isCreativeFilled;
        },
        isCreativeFilled() {
            return this.fieldValues
                .filter(fv => fv.formatField.mediaTypeCruxo !== 'product-shop-data')
                .filter(fv => fv.formatField.required)
                .every(item => item.filled);
        },
        displaySendForCheck() {
            return !this.isRetailer
                && this.landingPage.creativeState !== CreativeStates.Approved
                && this.landingPage.creativeState !== CreativeStates.WaitingForApproval;
        },
        canEditCreative() {
            return (
                this.creative.creativeState != CreativeStates.Approved
                && this.creative.creativeState != CreativeStates.WaitingForApproval
            ) || this.isRetailer;
        },
        displayRejectReason() {
            return this.creative.creativeState === CreativeStates.Returned;
        },
        isApprovable() {
            return this.isRetailer && this.creative.creativeState !== CreativeStates.Approved;
        },
        previewFieldValues() {
            if (!this.landingPage) {
                return [];
            }
            return this.landingPage.fieldValues
                .filter(fv => !fv.autoConverted)
                .filter(fv => fv.formatField.mediaTypeCruxo !== 'product-shop-data');
        },
        missingFields() {
            return this.fieldValues
                .filter(fv => fv.formatField.mediaTypeCruxo !== 'product-shop-data')
                .filter(fv => fv.formatField.required && !fv.filled)
                .map(fv => fv.formatField.name)
                .join(', ');
        },
    },
    watch: {
        async viewState(value) {
            if (value !== 'edit') {
                this.$nextTick(() => {
                    this.landingPageEdit = null;
                });
            } else if (value === 'edit' && this.landingPage) {
                this.landingPageEdit =  await this.$store.dispatch('landingPages/getLandingPage', {landingPageId: this.landingPage.id});
            }
        },
    },
    methods: {
        async loadData(route) {
            const landingPageId = route.params.landingPageId;
            if(this.landingPage && landingPageId === this.landingPage.id) {
                return;
            }

            this.working = true;

            this.landingPage = await this.$store.dispatch('landingPages/getLandingPage', {landingPageId: landingPageId});
            if(route.params.viewState && route.params.viewState !== this.viewState) {
                this.viewState = route.params.viewState;
            }
            this.working = false;
            // this.$metaTags({title: this.$t('view_campaignItemDetail_metaTitle') + ' „' + this.campaignItem.generatedName + '“ - ' + this.$t('common_meta_title'),})
        },
        submitLandingPageForm() {
            for (const fieldValue of this.landingPageEdit.fieldValues) {
                if(fieldValue.text && fieldValue.text.length > 0) {
                    fieldValue.text = fieldValue.text.trim();
                    fieldValue.filled = fieldValue.text.length > 0;
                }
            }
            this.$nextTick(() => {
                this.$refs.submitButton.click();
            });
        },
        async saveLandingPage() {
            this.working = true;
            try {
                this.landingPage = await this.$store.dispatch('landingPages/updateLandingPage', this.landingPageEdit);
                this.viewState = 'preview';
            } catch (e) {
                this.errorMessage = e;
            }
            this.working = false;
        },
        submitApprove(landingPage) {
            this.landingPage = landingPage;
        },
        async requestCheck() {
            this.requestingCreativeCheck = true;
            this.landingPage = await this.$store.dispatch('landingPages/requestLandingPageCheck', {
                landingPageId: this.landingPage.id
            })
            this.requestingCreativeCheck = false;
        },
    },
    async beforeRouteEnter(to, from, next) {
        await next(async (vm) => {
            await vm.loadData(to);
        });
    },
    async beforeRouteUpdate(to, from, next) {
        await next(async (vm) => {
            await vm.loadData(to);
        });
    }
}
</script>

<style scoped>

</style>
