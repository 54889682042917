<template>
	<div class="max-w-screen-2xl pt-1 pb-8 px-8 md:px-8 mx-auto">
        <simple-breadcrumb :items="breadcrumbs"></simple-breadcrumb>

        <div class="mt-5">
            <h1 class="mb-5 text-purple-100 h1">{{ $t('common_creative_format_settings') }}</h1>

            <!-- Fulltext search -->
            <div class="mb-3 relative max-w-xl">
                <t-input v-model="search" :placeholder="$t('view_creativeFormatList_searchFormat')"></t-input>
                <svg-icon name="search" class="absolute right-6 top-3 pointer-events-none"></svg-icon>
            </div>

            <!-- Table -->
            <dynamic-table :items="creativeFormats" :loading="loadingData">
                <dynamic-table-column identifier="name" class="pb-2">
                    {{ $t('common_title') }}
                </dynamic-table-column>
                <dynamic-table-column identifier="actions" style-header="width: 12rem" class="pb-2">
                    {{ $t('common_actions') }}
                </dynamic-table-column>
                <template v-slot:name="item">
                    <span class="font-medium text-base leading-4">{{ item.name }}</span>
                </template>
                <template v-slot:actions="item">
                    <div class="flex">
                    <t-button
                        class="mr-2"
                        variant="editCircle"
                        tag-name="a"
                        :to="{name: 'creativeFormatDetail', params: {creativeFormatId: item.id}}"
                        :title="$t('common_edit')"
                    >
                        <c-icon class="w-3.5 h-3.5" icon="pen-full"/>
                    </t-button>
                    </div>
                </template>
            </dynamic-table>

            <page-picker v-model="firstResult" :total-count="totalCount" :page-size="pageSize"></page-picker>
        </div>

    </div>
</template>

<script>
import SimpleBreadcrumb from "@/components/SimpleBreadcrumb";
import PagePicker from "@/components/PagePicker.vue";
import SvgIcon from "@/components/SvgIcon.vue";
import DynamicTable from "@/components/universal/dynamic-table/DynamicTable.vue";
import DynamicTableColumn from "@/components/universal/dynamic-table/DynamicTableColumn.vue";
import CIcon from "@/components/base/icons/CIcon.vue";

export default {
    name: "CreativeFormatListView",
    metaTags() {
        return {
            title: this.$t('common_creative_format_settings_metaTitle') + ' - ' + this.$t('common_meta_title'),
        }
    },
    components: {
        CIcon,
        DynamicTableColumn,
        DynamicTable,
        SvgIcon,
        PagePicker,
        SimpleBreadcrumb,
    },
    data: () => {
        return {
            loadingData: false,
            totalCount: 0,
            creativeFormats: [],
        };
    },
    computed: {
        breadcrumbs() {
            const that = this;

            return [
                {
                    title: that.$t('common_settings'),
                    current: false,
                },
                {
                    title: this.campaignBreadcrumbName,
                    current: 'page',
                },
            ];
        },
        pageSize() {
            return this.$store.getters['creativeFormats/listFilter'].maxResults;
        },
        firstResult: {
            get() {
                return this.$store.getters['creativeFormats/listFilter'].firstResult;
            },
            set(value) {
                this.$store.dispatch('creativeFormats/setFilterFirstResult', value);
            }
        },
        search: {
            get() {
                return this.$store.getters['creativeFormats/listFilter'].search;
            },
            set(value) {
                this.$store.dispatch('creativeFormats/setFilterSearch', value);
            }
        },
    },
    watch: {
        firstResult() {
            this.loadData();
        },
        search() {
            this.loadData();
        },
    },
    methods: {
        async loadData() {
            this.loadingData = true;

            this.creativeFormats = [];
            this.totalCount = 0;
            const currentFilter = this.$store.getters['creativeFormats/listFilter'];

            const page = await this.$store.dispatch('creativeFormats/filterCreativeFormats', currentFilter);
            this.creativeFormats = page.items;
            this.totalCount = page.totalCount;
            this.loadingData = false;
        },
    },
    created() {
        this.loadData();
    },
    async beforeRouteUpdate() {
        await this.loadData();
    },
};
</script>
