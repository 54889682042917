<template>
	<span class="group w-8 h-8 rounded-full bg-red-50 flex border border-red-600 hover:bg-red-600 transition-colors duration-200">
		<svg width="20" height="20" viewBox="0 0 20 20" fill="none" class="m-auto text-red-600 group-hover:text-white transition-colors duration-200" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.0008 9.99997L14.3696 14.3688M5.6319 14.3688L10.0008 9.99997L5.6319 14.3688ZM14.3696 5.6311L10.0008 9.99997L14.3696 5.6311ZM10.0008 9.99997L5.6319 5.6311L10.0008 9.99997Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
	</span>
</template>

<script>

export default ({
	name: "RemoveIcon",
})
</script>
