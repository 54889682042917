import axios from "axios";

const location = window.location;
const baseURL = `${location.protocol}//${location.host}/api`;

const client = axios.create({
    baseURL,
    headers: { "X-Requested-With": "XMLHttpRequest" },
    withCredentials: true,
});

export default client;
