<template>
    <div class="relative">
        <div
            v-if="value" class="flex absolute inset-0"
            :class="classes"
            :style="styles"
            v-bind="$attrs"
        >
            <svg
                class="animate-spin m-auto h-16 w-16"
                :class="{
                    'text-green-400': this.theme === 'dark',
                    'text-blue-400': this.theme === 'light'
                }"
                xmlns="http://www.w3.org/2000/svg" fill="none"
                viewBox="0 0 24 24"
            >
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
        </div>
        <slot></slot>
    </div>
</template>
<script>

export default {
    name: "LoadingBox",
    components: {},
    props: {
        value: {
            type: Boolean
        },
        rounded: {
            type: Boolean,
            default: false
        },
        theme: {
            default: 'dark'
        },
        minHeight: {
            type: String,
            default: undefined
        }
    },
    data: () => {
        return {};
    },
    computed: {
        classes() {
            const classes = {
                'rounded-xl': this.rounded,
                'bg-black/60': this.theme === 'dark',
                'bg-white/60': this.theme === 'light'
            }

            return classes;
        },
        styles() {
            const styles = {};
            if (this.minHeight) {
                styles.minHeight = this.minHeight;
            }
            return styles;
        }
    }
}
</script>
