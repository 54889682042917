<template>
    <drop
        @dragover="handleDragover(...arguments)"
        @drop="handleDrop"
        @dragleave="onDragleave"
    >
        <div class="cursor-pointer" @click="openInput">
            <div v-if="isOver">
                Drop file here
            </div>
            <input
                type="file"
                style="
                    width: 0.1px;
                    height: 0.1px;
                    opacity: 0.1;
                    overflow: hidden;
                    position: absolute;
                    z-index: -1;
                "
                ref="input"
                @change="selectedFiles"
                :multiple="multiple"
            />
            <slot></slot>
        </div>
    </drop>
</template>

<script>
import _ from "lodash";
import Drop from "@/components/vue-drag-drop/src/Drop.vue";


export default {
    name: "BaseDropzone",
    components: { Drop },
    data() {
        return {
            selected: [],
            isOver: false,
        };
    },
    props: {
        multiple: {
            default: false,
            type: Boolean,
        },
        dropText: {
            default: "",
            type: String,
        },
    },
    computed: {
        text() {
            if (this.dropText !== "") {
                return this.dropText;
            }
            if (this.multiple) {
                return "Drop files here";
            }
            return "Drop file here";
        },
    },
    methods: {
        openInput: function () {
            this.$refs.input.focus();
            this.$refs.input.click();
        },
        selectedFiles: function (event) {
            const files = event.target.files;
            if (this.multiple) {
                this.$emit("files", files);
            } else {
                this.$emit("file", files[0]);
            }
            //trick to trigger change after uploading the same file
            this.$refs.input.value = "";
        },
        handleDragover(data, event) {
            if (!this.canDrop(event)) {
                event.dataTransfer.dropEffect = "none";
                this.setIsOver(false);
            } else {
                this.setIsOver(true);
            }
        },
        setIsOver: _.throttle(function (status) {
            this.isOver = status;
        }, 200),
        canDrop(event) {
            const count = Math.max(
                event.dataTransfer.files.length,
                [...event.dataTransfer.items].filter(
                    (item) => item.kind === "file"
                ).length
            );
            return (
                (this.multiple && count > 0) || (!this.multiple && count === 1)
            );
        },
        onDragleave() {
            this.setIsOver(false);
        },
        handleDrop(data, event) {
            if (!this.canDrop(event)) {
                this.setIsOver(false);
                return;
            }
            let files = [];
            if (event.dataTransfer.files.length > 0) {
                files = [...event.dataTransfer.files];
            } else {
                files = [...event.dataTransfer.items]
                    .filter((item) => item.kind === "file")
                    .map((item) => item.getAsFile());
            }
            this.setIsOver(false);
            if (this.multiple) {
                this.$emit("files", files);
            } else {
                this.$emit("file", files[0]);
            }
        },
    },
};
</script>
