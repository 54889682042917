import axios from "@/plugins/axios";

export default {
    namespaced: true,
    state: {
        prefill: null
    },
    getters: {
        prefill(state) {
            return state.prefill;
        },
    },
    mutations: {
        SET_PRODUCT_PREFILL(state, prefill) {
            state.prefill = prefill;
        }
    },
    actions: {
        async getProducts() {
            const response = await axios.get('/products');
            return response.data;
        },
        async searchProducts(context, {phrase}) {
            const response = await axios.get('/products/search', { params: {phrase} });
            return response.data;
        },
        async getFlatRateProductsWithAvailabilities() {
            const response = await axios.get('/products/flat-rate-with-availabilities');
            return response.data;
        },
        async getProduct(context, {productId}) {
            const productPromise = axios.get(`/products/${productId}`);
            const sasInfoPromise = axios.get(`/sas-info/products/${productId}`);
            const [product, sasInfo] = await Promise.all([
                productPromise,
                sasInfoPromise,
            ]);

            product.data.sasInfo = sasInfo.data;
            return product.data;
        },
        async getProductEdit(context, {productId}) {
            const response = await axios.get(`/products/${productId}/edit`);
            return response.data;
        },
        async getProductAvailabilities(context, {productId}) {
            const response = await axios.get('/products/'+ productId +'/availability');
            return response.data;
        },
        async getProductVariableValueAvailabilities(context, {productId, variableValueId, excludedCampaignItems}) {
            if(!variableValueId) {
                variableValueId = '';
            }
            const response = await axios.get(
                '/products/'+ productId +'/variable-value-availability/'+ variableValueId,
                { params: {'excludedCampaignItems': excludedCampaignItems} }
            );
            return response.data;
        },
        async getCategories() {
            const response = await axios.get('/categories');
            return response.data;
        },
        async getActiveCategories() {
            const response = await axios.get('/categories/active');
            return response.data;
        },
        async getUserCategoriesWithProducts() { // only returns the categories that contains at least one product
            try {
                const response = await axios.get('/user-categories');
                let categories = null;
                
                if (Array.isArray(response.data)) {
                    categories = response.data.filter(category => category.products.length > 0);
                }

                return categories;
            } catch(error) {
                throw Error(error.response.data.message);
            }
        },
        async getCategory(context, {productCategoryId}) {
            const response = await axios.get('/categories/'+ productCategoryId);
            return response.data;
        },
        async updateCategory(context, { productCategoryId, active, internal, title, shortDescription, description, autoPauseFlight, minDaysBeforeStartDate, domainUrl, target, productIds  }) {
            const data = {
                active,
                internal,
                title,
                shortDescription,
                description,
                autoPauseFlight,
                minDaysBeforeStartDate,
                domainUrl,
                target,
                productIds
            };
            await axios.put(`/categories/${productCategoryId}`, data);
        },
        async createCategory(context, { active, internal, title, shortDescription, description, autoPauseFlight, minDaysBeforeStartDate, domainUrl, target, productIds }) {
            const data = {
                active,
                internal,
                title,
                shortDescription,
                description,
                autoPauseFlight,
                minDaysBeforeStartDate,
                domainUrl,
                target,
                productIds
            };
            await axios.put(`/categories/create`, data);
        },
        async updateProduct(
            context, {productId, productEdit}
        ) {
            return await axios.put(`/products/${productId}`, productEdit);
        },
        async deleteCategory(context, { productCategoryId } ) {
            await axios.delete(`/categories/${productCategoryId}`);
        },
        async updatePositionCategories(context, { productCategoriesOrder }) {
            await axios.put(`/categories-position`, { productCategoriesOrder });
        },
        setPrefill(context, prefill) {
            context.commit("SET_PRODUCT_PREFILL", prefill);
        }
    }
};
