import Vue from "vue";
import VueRouter from "vue-router";
import LoginView from "@/views/LoginView";
import AuthLayout from "@/layouts/AuthLayout";
import BaseLayout from "@/layouts/BaseLayout";
import ForgottenPasswordView from "@/views/ForgottenPasswordView";
import ResetPasswordView from "@/views/ResetPasswordView";
import NotFoundView from "@/views/NotFoundView";
import ProductListView from "@/views/ProductListView";
import ProductAvailabilityView from "@/views/ProductAvailabilityView";
import CampaignListView from "@/views/CampaignListView";
import CampaignItemsListView from "@/views/CampaignItemsListView";
import CampaignDetailView from "@/views/CampaignDetailView";
import UserListView from "@/views/UserListView.vue";
import UserDetailView from "@/views/UserDetailView";
import ProductCategoriesSettingsView from "@/views/ProductCategoriesSettingsView";
import CampaignItemDetailView from "@/views/CampaignItemDetailView.vue";
import EditActivityView from "@/views/activities/EditActivityView.vue";
import ProductsSettingsView from "@/views/ProductsSettingsView";
import SettingsVariablesView from "@/views/SettingsVariablesView";
import AdToolDetailView from "@/views/ad-tools/AdToolDetailView.vue";
import StyleguideView from "@/views/StyleguideView.vue";
import PageView from "@/views/PageView.vue";
import ActivityCreativeView from "@/views/ActivityCreativeView.vue";
import CreativeFormatListView from "@/views/CreativeFormatListView";
import creativeFormatDetailView from "@/views/CreativeFormatDetailView";
import LandingPagesListView from "@/views/LandingPagesListView.vue";
import LandingPageDetailView from "@/views/LandingPageDetailView.vue";
import LandingPageTypeSettingsView from "@/views/settings/LandingPageTypeSettingsView.vue";
import ProductsSettingsDetailView from "@/views/ProductsSettingsDetailView";
import IsForbiddenView from "@/views/IsForbiddenView.vue";
import DashboardsView from "@/views/DashboardsView.vue";
import store from "@/store";

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        redirect: '/tools',
    },
    {
        path: '',
        redirect: 'login',
        component: AuthLayout,
        children: [
            {
                path: '/login',
                name: 'login',
                component: LoginView,
            },
            {
                path: '/forgot-password',
                name: 'forgottenPassword',
                component: ForgottenPasswordView,
            },
            {
                path: '/reset-password/:hash',
                name: 'resetPassword',
                component: ResetPasswordView,
            }
        ],
    },
    {
        path: '',
        redirect: 'productList',
        component: BaseLayout,
        children: [
            {
                path: '/tools',
                name: 'productList',
                component: ProductListView,
                meta: {
                    authenticated: true,
                    mainHeaderItemName: 'products',
                },
            },
            {
                path: '/tools/:selectedProductId',
                name: 'productDetail',
                component: AdToolDetailView,
                meta: {
                    authenticated: true,
                    mainHeaderItemName: 'products',
                },
            },
            {
                path: '/availabilities',
                name: 'availabilities',
                component: ProductAvailabilityView,
                meta: {
                    authenticated: true,
                    mainHeaderItemName: 'availabilities',
                },
            },
            {
                path: '/campaigns',
                name: 'campaignList',
                component: CampaignListView,
                meta: {
                    authenticated: true,
                    mainHeaderItemName: 'campaigns',
                },
            },
            {
                path: '/campaigns/:campaignId',
                name: 'campaignDetail',
                component: CampaignDetailView,
                meta: {
                  authenticated: true,
                  mainHeaderItemName: 'campaigns',
                },
              },
            {
                path: '/campaigns/:campaignId/items/:campaignItemId',
                name: 'campaignDetailItem',
                redirect: to => {
                    return {
                        name: 'campaignItemDetail',
                        params: {
                            campaignId: to.params.campaignId,
                            campaignItemId: to.params.campaignItemId,
                        },
                    }
                },
            },
            {
                path: '/campaigns/:campaignId/tools/:selectedProductId',
                name: 'addAdDoolDetail',
                component: AdToolDetailView,
                meta: {
                  authenticated: true,
                  mainHeaderItemName: 'campaigns',
                },
            },
            {
                path: '/campaigns/:campaignId/items/:campaignItemId/edit',
                name: 'editCampaignActivity',
                component: EditActivityView,
                meta: {
                  authenticated: true,
                  mainHeaderItemName: 'campaigns',
                },
            },
            {
                path: '/campaigns/:campaignId/items/:campaignItemId/detail',
                name: 'campaignItemDetail',
                component: CampaignItemDetailView,
                meta: {
                  authenticated: true,
                  mainHeaderItemName: 'campaigns',
                },
            },
            {
                path: '/campaigns/:campaignId/items/:campaignItemId/creative',
                name: 'campaignItemCreative',
                component: ActivityCreativeView,
                meta: {
                  authenticated: true,
                  mainHeaderItemName: 'campaigns',
                },
            },
            {
                path: '/campaigns/:campaignId/items/:campaignItemId/media',
                name: 'campaignItemCreativePreview',
                component: CampaignDetailView,
                meta: {
                  authenticated: true,
                  mainHeaderItemName: 'campaigns',
                },
            },
            {
                path: '/activities',
                name: 'CampaignItemsListView',
                component: CampaignItemsListView,
                meta: {
                  authenticated: true,
                  mainHeaderItemName: 'campaignItems',
                },
              },

        ],
    },
    {
        path: '',
        redirect: 'landingPagesList',
        component: BaseLayout,
        children: [
            {
                path: '/landing-pages',
                name: 'landingPagesList',
                component: LandingPagesListView,
                meta: {
                    authenticated: true,
                    mainHeaderItemName: 'landingPages',
                },
            },
            {
                path: '/landing-pages/:landingPageId',
                name: 'landingPageDetail',
                component: LandingPageDetailView,
                meta: {
                    authenticated: true,
                    mainHeaderItemName: 'landingPages',
                },
            },
        ]
    },
    {
        path: '',
        redirect: 'dashboards',
        component: BaseLayout,
        children: [
            {
                path: '/dashboards',
                name: 'dashboards',
                component: DashboardsView,
                meta: {
                    authenticated: true,
                    mainHeaderItemName: 'dashboards',
                },
            },
        ],
    },
    {
        path: '',
        redirect: 'userList',
        component: BaseLayout,
        children: [
            {
                path: '/settings/users',
                name: 'userList',
                component: UserListView,
                meta: {
                    authenticated: true,
                    mainHeaderItemName: 'administration',
                },
            },
            {
                path: '/settings/users/:userId',
                name: 'userDetail',
                component: UserDetailView,
                meta: {
                    authenticated: true,
                    mainHeaderItemName: 'administration',
                },
            },
        ],
        beforeEnter: (to,from,next) => {
            const isRetailer = store.getters['auth/isRetailer'];
            const isSalesPerson = store.getters['auth/isSalesPerson'];
            const isAdministrator = store.getters['auth/isAdministrator'];
            if (!isRetailer && !isSalesPerson && !isAdministrator) {
                next({ name: 'isForbidden' });
                return;
            }
            next(true);
        },
    },
    {
        path: '',
        redirect: 'creativeFormatList',
        component: BaseLayout,
        children: [
            {
                path: '/settings/templates',
                name: 'creativeFormatList',
                component: CreativeFormatListView,
                meta: {
                    authenticated: true,
                    mainHeaderItemName: 'administration',
                },
            },
            {
                path: '/settings/templates/:creativeFormatId',
                name: 'creativeFormatDetail',
                component: creativeFormatDetailView,
                meta: {
                    authenticated: true,
                    mainHeaderItemName: 'administration',
                },
            },
        ],
        beforeEnter: (to,from,next) => {
            const isAdministrator = store.getters['auth/isAdministrator'];
            if (!isAdministrator) {
                next({ name: 'isForbidden' });
                return;
            }
            next(true);
        },
    },
    {
        path: '',
        redirect: 'administrationProductCategorySettings',
        component: BaseLayout,
        children: [
            {
                path: '/settings/categories',
                name: 'administrationProductCategorySettings',
                component: ProductCategoriesSettingsView,
                meta: {
                    authenticated: true,
                    mainHeaderItemName: 'administration',
                },
            },
            {
                path: '/settings/categories/:productCategoryId',
                name: 'administrationProductCategorySettingsDetail',
                component: ProductCategoriesSettingsView,
                meta: {
                    authenticated: true,
                    mainHeaderItemName: 'administration',
                },
            },
            {
                path: '/settings/tools',
                name: 'administrationProductSettings',
                component: ProductsSettingsView,
                meta: {
                    authenticated: true,
                    mainHeaderItemName: 'administration',
                },
            },
            {
                path: '/settings/tools/:productId',
                name: 'administrationProductSettingsDetail',
                component: ProductsSettingsDetailView,
                meta: {
                    authenticated: true,
                    mainHeaderItemName: 'administration',
                },
            },
            {
                path: '/settings/landing-page-types',
                name: 'administrationLandingPageTypeSettings',
                component: LandingPageTypeSettingsView,
                meta: {
                    authenticated: true,
                    mainHeaderItemName: 'administration',
                },
            },
            {
                path: '/settings/landing-page-types/:landingPageId',
                name: 'administrationLandingPageTypeSettingsDetail',
                component: LandingPageTypeSettingsView,
                meta: {
                    authenticated: true,
                    mainHeaderItemName: 'administration',
                },
            },

            {
                path: '/settings/others',
                name: 'settingsVariables',
                component: SettingsVariablesView,
                meta: {
                    authenticated: true,
                    mainHeaderItemName: 'administration',
                },
            },
        ],
        beforeEnter: (to,from,next) => {
            const isAdministrator = store.getters['auth/isAdministrator'];
            if (!isAdministrator) {
                next({ name: 'isForbidden' });
                return;
            }
            next(true);
        },
    },
    {
        path: '',
        redirect: 'styleguide',
        component: BaseLayout,
        children: [
            {
                path: '/styleguide',
                name: 'styleguide',
                component: StyleguideView,
                meta: {
                    authenticated: true,
                },
            },
        ],
        beforeEnter: (to,from,next) => {
            const isAdministrator = store.getters['auth/isAdministrator'];
            if (!isAdministrator) {
                next({ name: 'isForbidden' });
                return;
            }
            next(true);
        },
    },
    {
        path: '',
        redirect: 'helpPage',
        component: BaseLayout,
        children: [
            {
                path: '/help',
                name: 'helpPage',
                component: PageView,
                meta: {
                    authenticated: true,
                    pageCode: 'help',
                    mainHeaderItemName: 'help',
                },
            },
        ],
    },
    {
        path: '',
        redirect: 'isForbidden',
        component: BaseLayout,
        children: [
            {
                path: '/unauthorized',
                name: 'isForbidden',
                component: IsForbiddenView,
                meta: {
                    authenticated: true,
                    pageCode: 'isForbidden',
                    mainHeaderItemName: 'isForbidden',
                },
            },
        ],
    },
    {
        path: '/zapomenute-heslo', redirect: '/forgot-password',
    },
    {
        path: '/reset-hesla/:hash', redirect: '/reset-password/:hash', 
    },
    {
        path: '/nastroje', redirect: '/tools',
    },
    {
        path: '/nastroje/:selectedProductId', redirect: '/tools/:selectedProductId',
    },
    {
        path: '/dostupnost', redirect: '/availabilities',
    },
    {
        path: '/kampane', redirect: '/campaigns',
    },
    {
        path: '/kampane/:campaignId', redirect: '/campaigns/:campaignId',
    },
    {
        path: '/kampane/:campaignId/polozka/:campaignItemId', redirect: '/campaigns/:campaignId/items/:campaignItemId',
    },
    {
        path: '/kampane/:campaignId/nastroje/:selectedProductId', redirect: '/campaigns/:campaignId/tools/:selectedProductId',
    },
    {
        path: '/kampane/:campaignId/polozka/:campaignItemId/edit', redirect: '/campaigns/:campaignId/items/:campaignItemId/edit',
    },
    {
        path: '/kampane/:campaignId/polozka/:campaignItemId/detail', redirect: '/campaigns/:campaignId/items/:campaignItemId/detail',
    },
    {
        path: '/kampane/:campaignId/polozka/:campaignItemId/kreativa', redirect: '/campaigns/:campaignId/items/:campaignItemId/creative', 
    },
    {
        path: '/kampane/:campaignId/polozka/:campaignItemId/media', redirect: '/campaigns/:campaignId/items/:campaignItemId/media',
    },
    {
        path: '/aktivity', redirect: '/activities',
    },
    {
        path: '/nastaveni/uzivatele', redirect: '/settings/users',
    },
    {
        path: '/nastaveni/uzivatele/:userId', redirect: '/settings/users/:userId',
    },
    {
        path: '/nastaveni/sablony', redirect: '/settings/templates',
    },
    {
        path: '/nastaveni/sablony/:creativeFormatId', redirect: '/settings/templates/:creativeFormatId',
    },
    {
        path: '/nastaveni/kategorie', redirect: '/settings/categories',
    },
    {
        path: '/nastaveni/kategorie/:productCategoryId', redirect: '/settings/categories/:productCategoryId', 
    },
    {
        path: '/nastaveni/nastroje', redirect: '/settings/tools', 
    },
    {
        path: '/nastaveni/nastroje/:productId', redirect: '/settings/tools/:productId', 
    },
    {
        path: '/nastaveni/typy-landing-pages', redirect: '/settings/landing-page-types',
    },
    {
        path: '/nastaveni/typy-landing-pages/:landingPageId', redirect: '/settings/landing-page-types/:landingPageId', 
    },
    {
        path: '/nastaveni/ostatni', redirect: '/settings/others', 
    },
    // 404 - musí zůstat poslední
    {
        path: '',
        redirect: 'notFound',
        component: AuthLayout,
        children: [
            {
                path: '/:pathMatch(.*)*',
                name: 'notFound', 
                component: NotFoundView,
            },
        ],
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

export default router;
