export class LoginFailError extends Error {

}

export class DeactivatedUserError extends Error {

}


export class NotValidResetHashError extends Error {

}
