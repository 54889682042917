<template>
  <div class="relative flex flex-wrap flex-col items-center min-h-screen bg-login bg-no-repeat bg-cover bg-center">
    <router-view></router-view>
    
    <div class="pt-10 flex items-center justify-between">
      <svg-icon name="logo-cruxo" alt="Logo cruxo" width="140" class="-ml-4"></svg-icon>
    </div>
  </div>
</template>

<script>
import SvgIcon from "@/components/SvgIcon";

export default {
  name: "AuthLayout",
  components: {SvgIcon}
};
</script>

<style scoped></style>
