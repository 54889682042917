<template>
    <base-button
        v-bind="$attrs"
        class="block text-black no-underline border-transparent hover:underline"
        :tag-name="tagName"
        :to="to"
        :disabled="disabled"
        @click="$emit('click', $event)"
    >
        <slot></slot>
    </base-button>
</template>

<script>
import BaseButton from "@/components/base/BaseButton.vue";

export default {
    name: "MainHeaderSubItem",
    components: {BaseButton},
    props: {
        tagName: {
            type: String,
            default: ''
        },
        to: {
            type: Object,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },

}
</script>

<style scoped>

</style>
