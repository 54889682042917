<template>
    <div>
        <span class="inline-block mb-2 font-medium">
            {{ $t('component_userAgencyList_selected_agency') }}
        </span>
        <t-rich-select
            ref="select"
            v-model="valueChange"
            :clearable="true"
            :options="options"
            value-attribute="id"
            text-attribute="label"
            multiple
            :placeholder="$t('component_userAgencyList_placeholder')"
            :searchBoxPlaceholder="$t('component_userAgencyList_search_placeholder')"
            :noResultsText="$t('component_userAgencyList_not_found')"
            :searchingText="$t('common_searching')"
        >
        </t-rich-select>
    </div>

</template>

<script>


// import axios from "@/plugins/axios";

export default {
    name: "UserAgencyList",
    components: {},
    props: {
        value: {
            type: Array,
            required: true,
        },
        user: {
            type: Object,
            required: true,
        }
    },
    data: () => {
        return {
            options: [],
        };
    },
    computed: {
        valueChange: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value)
            }
        }
    },
    methods: {
        async getOptions() {
            const options = await this.$store.dispatch('users/getAgencyUsers');
            this.options = options.map((option) => {
                    let label = option.label;
                    if (option.agencyName) {
                        label = option.agencyName + ' - ' + label
                    }
                    return {
                        id: option.id,
                        label:  label,
                    };
                });
            this.$refs.select.selectedOptions = this.$refs.select.normalizeOptions(options.filter(option => this.value.includes(option.id)));
        },
    },
    // obsluha eventu pri create component
    created() {
        this.getOptions();
    },

};
</script>
