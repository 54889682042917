<template>
    <div v-if="page" class="max-w-screen-2xl py-8 px:4 md:p-16 mx-auto space-y-6">
        <div v-if="!editMode">
            <div class="flex items-center justify-between mb-4">
                <h1 class="h1 text-purple-100">
                    {{ page.title }}
                </h1>
                <base-button v-if="isAdministrator" @click="editMode = true">
                    <edit-icon/>
                </base-button>
            </div>
            <t-card>
                <div class="w-full simpleHtmlStyledContainer" v-html="page.content"></div>
            </t-card>
        </div>
        <div v-else class="flex flex-wrap space-y-2">
            <div class="w-full">
                <p class="font-bold">{{ $t('common_title') }}</p>
                <t-input type="text" min="0" v-model="title"></t-input>
            </div>
            <div
                class="w-full"
                :class="{ 'fixed inset-0 z-50 bg-white p-2': showFullEditMode }"
            >
                <p class="font-bold">
                    {{ $t('common_content') }}
                    <span
                        @click="showFullEditMode = !showFullEditMode"
                        class="float-right cursor-pointer text-blue-400 hover:underline"
                    >
                        <c-icon class="w-3 h-3" icon="columns-two"></c-icon>
                    </span>
                </p>
                <div class="grid grid-cols-2 gap-x-1">
                    <div :class="{'col-span-1': showFullEditMode, 'col-span-2': !showFullEditMode}">
                        <t-textarea
                            v-model="content"
                            class="h-[95vh]"
                            @keypress.enter="updateHtmlContent()"
                        ></t-textarea>
                        <p class="mt-4">
                            <strong>Script</strong>
                        </p>
                        <t-textarea
                            v-model="script"
                            @blur="evalScript"
                            class="mt-1 h-[20vh]"
                        ></t-textarea>
                    </div>
                    <div
                        v-if="showFullEditMode"
                        class="col-span-1 simpleHtmlStyledContainer px-6 h-[95vh] overflow-auto"
                        v-html="htmlContent"
                    >
                    </div>
                </div>
            </div>
            <div class="w-full flex space-x-2 justify-end">
                <t-button @click="editMode = false" variant="gray">{{ $t('common_cancel') }}</t-button>
                <t-button @click="save">{{ $t('common_save') }}</t-button>
            </div>
        </div>
    </div>
</template>

<script>

import CIcon from "@/components/base/icons/CIcon.vue";
import {mapGetters} from "vuex";
import EditIcon from "@/components/icons/EditIcon.vue";
import BaseButton from "@/components/base/BaseButton.vue";

export default {
    name: "PageView",
    components: {BaseButton, EditIcon, CIcon},
    data() {
        return {
            page: null,
            editMode: false,
            title: '',
            content: '',
            script: '',
            showFullEditMode: false
        }
    },

    computed: {
        ...mapGetters('auth', ['isAdministrator']),
        locale() {
            return this.$i18n.locale;
        },
        htmlContent: function () {
            return this.content;
        }
    },
    watch: {
        locale() {
            this.loadData(this.$route);
        },
        editMode(newValue) {
            if(!newValue) {
                this.evalScript();
            }
        }
    },
    methods: {
        async loadData(to) {
            const page = await this.$store.dispatch('pages/getPage', {code: to.meta.pageCode});
            this.setPage(page);
        },
        setPage(page) {
            this.page = page;
            this.$metaTags({title: this.page.title + ' - ' + this.$t('common_meta_title'),})
            this.title = this.page.title;
            this.content = this.page.content;
            this.script = this.page.script;
        },
        async save() {
            const page = await this.$store.dispatch('pages/updatePage', {
                code: this.page.code,
                title: this.title,
                content: this.content,
                script: this.script
            });
            this.setPage(page);
            this.editMode = false;
        },
        updateHtmlContent(e) {
            this.content = e.target.value
        },
        evalScript() {
            eval(this.script);
        }
    },

    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.loadData(to);
        })
    },
    updated() {
        this.evalScript();
    },
    beforeRouteUpdate(to, from, next) {
        this.loadData(to);
        next();
    }
}
;
</script>

<style lang="scss">
    @import '../assets/simpleHtmlStyledContainer.css';
</style>

<style scoped>

</style>
