<template>
    <div class="max-w-2xl m-auto px-3">
        <div class="loginModal">
            <div class="text-center">
                <h1 class="login__title">
                    {{$t('view_login_title')}}
                </h1>
                <i18n path="view_login_description" tag="p">
                    <template v-slot:link>
                        <router-link class="underline hover:no-underline break-words" :to="{ name: 'forgottenPassword' }">
                            {{ $t('view_login_description_link') }}
                        </router-link>
                    </template>
                </i18n>
            </div>
            <form ref="form" @submit.prevent="submit" class="lg:px-14">
                <div class="mt-9 mb-5">
                    <t-input v-model="login" :placeholder="$t('common_email')" required
                        class="mb-4 rounded-full py-5 text-purple">
                    </t-input>
                    <t-input
                        v-model="password"
                        :placeholder="$t('common_password')"
                        type="password"
                        required
                        class="mb-4 rounded-full py-5 bg-gray-100"
                    ></t-input>
                    <p v-if="error.length" class="rounded-full px-3 py-1 bg-red-50 text-red-500">{{ error }}</p>
                </div>
                <div class="flex w-full space-y-6 mt-6 items-center flex-col  lg:w-auto lg:space-x-6 lg:flex-row lg:space-y-0 lg:justify-end">
                    <text-button :to="{ name: 'forgottenPassword' }" class="text-gray-400">
                        {{ $t('view_login_buttonForgottenPassword') }}
                    </text-button>
                    <c-button type="submit" size="big" append-icon="arrow-right" :loading="loading">
                        {{ $t('view_login_buttonLogin') }}
                    </c-button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import {DeactivatedUserError, LoginFailError} from "@/errors/login";
import CButton from "@/components/base/buttons/CButton.vue";
import TextButton from "@/components/base/buttons/TextButton.vue";

export default {
    name: "LoginView",
    components: {TextButton, CButton},
    metaTags() {
        return {
            title:  this.$t('view_login_metaTitle') + ' - ' + this.$t('common_meta_title'),
        }
    },
    data: () => {
        return {
            login: "",
            password: "",
            error: "",
            loading: false,
        };
    },
    methods: {
        submit: async function () {
            this.error = "";
            this.loading = true;
            try {
                await this.$store.dispatch("auth/login", {
                    login: this.login,
                    password: this.password,
                });
            } catch (err) {
                if (err instanceof LoginFailError) {
                    this.error = this.$t('view_login_error');
                } else if (err instanceof DeactivatedUserError) {
                    this.error = this.$t('view_login_error_deactivated');
                } else {
                    this.error = err.message;
                }
            }
            this.loading = false;
        },
    },
};
</script>

<style scoped></style>
