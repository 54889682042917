<template>
    <div>
        <div v-if="sortType === 'table'" class="group flex items-center cursor-pointer" @click="change" :class="$props.childClass">
            <p class="font-medium">{{ name }}</p>
            <c-icon v-if="stateIco" :icon="stateIco" class="ml-[2px] w-6 h-6 text-blue-100 group-hover:text-blue-400"></c-icon>
        </div>
        <div v-if="sortType === 'mobile'" class=" group flex items-center justify-between cursor-pointer w-full" @click="change" :class="$props.childClass">
            <p class="font-semibold">{{ name }}</p>
            <c-icon v-if="stateIco" :icon="stateIco" class="ml-[2px] w-6 h-6 text-blue-100 group-hover:text-blue-400"></c-icon>
        </div>
        <div v-if="sortType === 'checkbox'" class="group flex items-center cursor-pointer" @click="change" :class="$props.childClass">
            <label class="cursor-pointer inline-flex items-center w-full mb-6 opacity-40">
                <t-checkbox name="options" :checked="checked"/>
                <checkbox-icon />
                <strong>{{ name }}</strong>
                <c-icon v-if="stateIco && stateIco !== 'ico-order'" :icon="stateIco" class="ml-[2px] w-6 h-6 text-blue-100 group-hover:text-blue-400"></c-icon>
            </label>
        </div>
        <div v-if="sortType === 'none'" class="group flex items-center h-6" :class="$props.childClass">
            <p class="font-medium">{{ name }}</p>
        </div>
    </div>
</template>

<script>
import CheckboxIcon from "@/components/icons/CheckboxIcon";
import CIcon from "@/components/base/icons/CIcon.vue";

export default {
    name: "TableSortHeading",
    components: {CheckboxIcon, CIcon},
    props: {
        name: {
            type: String
        },
        propertyName: {
            type: String
        },
        value: {
            type: Object
        },
        sortType: {
            type: String,
            default: 'table'
        },
        childClass: {
            type: String
        }
    },
    computed: {
        stateIco() {
            if (!this.propertyName) {
                return null;
            }
            if (!this.value) {
                return 'arrow-up-down';
            }
            if (this.value.name !== this.propertyName) {
                return 'arrow-up-down';
            }
            if (this.value.direction === 'ASC') {
                return 'sorted-down';
            }
            if (this.value.direction === 'DESC') {
                return 'sorted-up';
            }
            return null;
        },
        checked()  {
            return this.propertyName && this.value && this.propertyName === this.value.name;
        },
        newValue() {
            if (!this.value) {
                return {
                    name: this.propertyName,
                    direction: 'ASC'
                };
            }
            if (this.value.name !== this.propertyName) {
                return {
                    name: this.propertyName,
                    direction: 'ASC'
                };
            }
            if (this.value.direction === 'ASC') {
                return {
                    name: this.propertyName,
                    direction: 'DESC'
                };
            }
            return null;
        }
    },
    methods: {
        change() {
            this.$emit('input', this.newValue);
        }
    }
}
</script>

<style scoped>

</style>
