<template>
    <component
        :is="component"
        :to="to"
        variant="link"
        :class="{
            'is-active': active,
            'menu__btn': windowWidth > 1023,
            'menu__mobileBtn ': windowWidth < 1024
        }"
        @click="click"
        @mousedown="mouseDown"
        @focus="focus"
        @blur="blur"
        @keydown="keydown"
    >
        <slot></slot>
    </component>
</template>
<script>
export default {
    name: 'main-header-item',
    props: {
        name: {
            type: String,
            required: true
        },
        to: {},
        activePrefix: {
            type: String,
            default: ''
        },
        campaignsDisplayed: {},
        hasVendorRole: {},
        window: {}
    },
    data() {
        return {
            windowWidth: 0
        };
    },
    computed: {
        component() {
            if(this.to) {
                return 'router-link';
            }
            return 'button';
        },
        active() {
            if(
                !Object.hasOwn(this.$route, 'meta')
                || !Object.hasOwn(this.$route.meta, 'mainHeaderItemName')
            ) {
                return false;
            }
            const routeMainHeaderItemName = this.$route.meta.mainHeaderItemName;
            return routeMainHeaderItemName == this.name;
        }
    },
    created() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
        handleResize() {
            this.windowWidth = window.innerWidth;
        },
        click(e) {
            this.$emit('click', e);
            this.$el.focus();  // fix for Safari which is not focusing the button after click
        },
        mouseDown(e) {
            this.$emit('mousedown', e);
        },
        focus(e) {
            this.$emit('focus', e);
        },
        blur(e) {
            this.$emit('blur', e);
        },
        keydown(e) {
            this.$emit('keydown', e);
        }
    }
}
</script>
