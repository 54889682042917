<template>
    <div v-bind="$attrs">
        <div class="flex items-center">
            <button
                v-for="button in buttons"
                :key="button.key"
                class="menu__btn menu__btn--gray"
                :class=" {'is-active' : current === button.key}"
                @click.prevent="current = button.key"
            >
                {{ button.name }}
            </button>
        </div>
        <slot>

        </slot>
    </div>
</template>

<script>
import Vue from "vue";

export default {
    name: "HorizontalTabs",
    props: {
        value: {
            type: String,
            default: null,
        }
    },
    data() {
        return {
            current: null
        };
    },
    watch: {
        current(newVal) {
            const _this = this
            this.$nextTick(() => {
                _this.$slots.default.forEach(item => {
                    Vue.set(item.componentInstance, 'value', newVal)
                });
            })

            if(this.value !== newVal) {
                this.$emit('input', newVal)
            }
        },
        value(newVal) {
            this.current = newVal;
        }
    },
    computed: {
        buttons() {
            return this.$slots.default
                .filter(item => !item.componentOptions.propsData.hidden)
                .map(item => {
                    return {
                        key: item.componentOptions.propsData.identifier,
                        name: item.componentOptions.propsData.tabName
                    }
                });
        }
    },
    created() {
        if(!this.value && this.buttons.length > 0) {
            this.current = this.buttons[0].key;
        } else {
            this.current = this.value;
        }
    }
}
</script>

<style scoped>

</style>
