import axios from "@/plugins/axios";
import {DeactivatedUserError, LoginFailError} from "@/errors/login";
import Vue from "vue";

export default (router) => {
    return {
        namespaced: true,
        state: {
            locale: null,
            userInfo: null,
        },
        getters: {
            userId(state) {
                if (!state.userInfo) {
                    return null;
                }
                return state.userInfo.userId;
            },
            userName(state) {
                if (!state.userInfo) {
                    return "";
                }
                return state.userInfo.userName;
            },
            companyName(state) {
                if (!state.userInfo) {
                    return "";
                }
                return state.userInfo.companyName;
            },
            userRoles(state) {
                if (!state.userInfo) {
                    return [];
                }
                if (!Array.isArray(state.userInfo.userRoles)) {
                    return [];
                }
                return state.userInfo.userRoles;
            },
            agencyId(state) {
                if (!state.userInfo) {
                    return null;
                }
                return state.userInfo.agencyId;
            },
            agencyContactId(state) {
                if (!state.userInfo) {
                    return null;
                }
                return state.userInfo.agencyContactId;
            },
            originalTokenOfRetailer(state) {
                if (!state.userInfo) {
                    return "";
                }
                return state.userInfo.originalTokenOfRetailer;
            },
            locale(state) {
                if (!state.locale) {
                    return "";
                }
                sessionStorage.setItem("locale", state.locale);
                return state.locale;
            },
            isImpersonate(state)  {
                if (!state.userInfo) {
                    return false;
                }
                return state.userInfo.originalTokenOfRetailer !== null;
            },
            isRetailerOrImpersonated(state) {
                if (!state.userInfo) {
                    return false;
                }
                return state.userInfo.originalTokenOfRetailer !== null || state.userInfo.userRoles.indexOf('Retailer') >= 0;
            },
            isRetailer(state) {
                if (!state.userInfo) {
                    return false;
                }
                if (!Array.isArray(state.userInfo.userRoles)) {
                    return false;
                }
                return state.userInfo.userRoles.indexOf('Retailer') >= 0;
            },
            isAgency(state) {
                if (!state.userInfo) {
                    return false;
                }
                return state.userInfo.userRoles.indexOf('Agency') >= 0;
            },
            isVendor(state) {
                if (!state.userInfo) {
                    return false;
                }
                return state.userInfo.userRoles.indexOf('Vendor') >= 0;
            },
            isAdministrator(state) {
                if (!state.userInfo) {
                    return false;
                }
                return state.userInfo.userRoles.indexOf('Administrator') >= 0;
            },
            isSalesPerson(state) {
                if (!state.userInfo) {
                    return false;
                }
                return state.userInfo.userRoles.indexOf('SalesPerson') >= 0;
            },
            defaultPage(state) {
                if(!state.userInfo) {
                    return "login"
                }
                if(state.userInfo.userRoles.indexOf('Retailer') >= 0) {
                    return "campaignList"
                }
                return "campaignList";
            },
            userProfile(state) {
                return state.userProfile;
            },
        },
        mutations: {
            setUserInfo(state, userInfo) {
                Vue.set(state, "userInfo", userInfo);
                if(userInfo) {
                    state.locale = userInfo.locale;
                }
            },
            setLocale(state, locale) {
                state.locale = locale;
            },
            SET_USER_PROFILE(state, userProfile) {
                state.userProfile = userProfile;
            }
        },
        actions: {
            async login(context, { login, password }) {
                try {
                    const response = await axios.post("/auth/login", {
                        login,
                        password,
                    });
                    if (response) {
                        localStorage.setItem("authToken", response.data.token);
                        await context.commit("setUserInfo", response.data);
                        const page = context.getters.defaultPage;
                        await context.dispatch('appSettings/initAppSettings', null, {root: true});
                        router.push({name: page })
                    }
                    return response.data;
                } catch (e) {
                    if ('deactivated' in e.response.data && e.response.data.deactivated) {
                        throw new DeactivatedUserError(e.response.data.message);
                    }
                    localStorage.setItem("authToken", null);
                    context.commit("setUserInfo", null);
                    throw new LoginFailError(e.response.data.message);
                }
            },
            async setLocale(context, {locale}) {
                context.commit("setLocale", locale);
            },
            async logout({ commit }) {
                try {
                    await axios.post("/auth/logout");
                    localStorage.setItem("authToken", null);
                    commit("setUserInfo", null);
                    router.push({ name: "login" });
                } catch (e) {
                    localStorage.setItem("authToken", null);
                    commit("setUserInfo", null);
                    router.push({ name: "login" });
                }
            },
            async authorization({ commit }) {
                try {
                    const response = await axios.get("/auth/authorization");
                    if (response) {
                        localStorage.setItem("authToken", response.data.token);
                        commit("setUserInfo", response.data);
                    }
                    return response.data;
                } catch (e) {
                    localStorage.setItem("authToken", null);
                    commit("setUserInfo", null);
                }
            },
            async getLoggedUser() {
                const response = await axios.get("/user-profile/logged-in-user");
                return response.data;
            },
        },
    };
};
