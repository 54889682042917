<template>
    <simple-dialog v-model="showDialog">
        <h1 class="text-black text-3xl mb-5 pr-5">
            {{ $t('component_createLandingPageDialog_title') }}
        </h1>
        <validation-observer v-slot="{ handleSubmit }">
            <form class="mt-8 w-full p-8 bg-gray-50 rounded-2xl" @submit.prevent="handleSubmit(submit)">
                <c-select
                    v-model="landingPageTypeId"
                    :options="landingPageTypes"
                    :label="$t('common_landing_page_type')"
                    label-attribute="name"
                    value-attribute="id"
                    required
                />

                <c-select
                    v-model="targetId"
                    :options="allowedTargets"
                    :label="$t('common_target_web')"
                    label-attribute="name"
                    value-attribute="id"
                    required
                    :disabled="disableTargetSelect"
                />

                <c-select
                    v-if="isRetailerOrAgency"
                    v-model="companyId"
                    :options="companies"
                    :label="$t('common_advertiserCompany')"
                    label-attribute="name"
                    value-attribute="id"
                    required
                />

                <c-input
                    v-model="name"
                    :label="$t('common_name')"
                    required
                />

                <div class="flex flex-wrap items-center justify-end mt-8">
                    <text-button
                        class="mr-4"
                        @click.prevent="showDialog = false"
                        type="button"
                    >
                        {{ $t('common_cancel') }}
                    </text-button>
                    <c-button
                        size="big"
                        color="green"
                        :loading="submitting"
                        prepend-icon="plus"
                        type="submit"
                    >
                        {{ $t('common_save') }}
                    </c-button>
                </div>
            </form>
        </validation-observer>
    </simple-dialog>
</template>
<script>
import {mapGetters} from "vuex";
import SimpleDialog from "@/components/base/SimpleDialog.vue";
import TextButton from "@/components/base/buttons/TextButton.vue";
import CButton from "@/components/base/buttons/CButton.vue";
import CSelect from "@/components/base/form/CSelect.vue";
import CInput from "@/components/base/form/CInput.vue";
import {ValidationObserver} from "vee-validate";

export default {
	name: "CreateLandingPageDialog",
	components: {CInput, CSelect, CButton, TextButton, SimpleDialog, ValidationObserver },
    props: {
        open: {
            default: false,
        },
    },
    data: () => {
        return {
            name: '',
            landingPageTypeId: null,
            targetId: null,
            companyId: null,

            landingPageTypes: [],
            targets: [],
            companies: [],
            submitting: false,
        };
    },
	computed: {
        ...mapGetters('auth', ['isRetailer', 'isAgency', 'isVendor', 'isSalesPerson']),
		showDialog: {
			get() {
				return this.open;
			},
			set(value) {
                if(!value) {
                    this.name = '';
                    this.landingPageTypeId = null;
                    this.targetId = null;
                    this.companyId = null;
                    this.$emit("close", value);
                }
			},
		},
        isRetailerOrAgency() {
            return this.isRetailer || this.isAgency;
        },
        disableTargetSelect() {
            return !this.landingPageTypeId;
        },
        allowedTargets() {
            if (!this.landingPageTypeId) {
                return [];
            }
            const landingPageType = this.landingPageTypes.find(type => type.id === this.landingPageTypeId);
            if (landingPageType.allowedTargets.length == 0) {
                return this.targets;
            }
            return this.targets.filter(target => {
                return landingPageType.allowedTargets.includes(target.id);
            });
        },
	},
    watch: {
        async showDialog(value) {
            if (!value) {
                return;
            }

            [this.landingPageTypes, this.targets] = await Promise.all([
                this.$store.dispatch('landingPages/getActiveLandingPageTypes'),
                this.$store.dispatch('targeting/getTargets')
            ]);

            if (this.isRetailer) {
                this.companies = await this.$store.dispatch('users/getCompanies');
            } else if (this.isAgency) {
                const userId = this.$store.getters['auth/userId'];
                this.companies = await this.$store.dispatch('users/getVendorCompanies', {userId: userId});
            } else {
                this.companies = [];
            }
        },
    },
	methods: {
        async submit() {
            this.submitting = true;
            const landingPage = await this.$store.dispatch("landingPages/createLandingPage", {
                landingPageTypeId: this.landingPageTypeId,
                targetId: this.targetId,
                companyId: this.companyId,
                name: this.name,
            });
            this.submitting = false;
            this.name = '';
            this.$emit('created', landingPage);
        },
    },
	filters: {},
};
</script>
