<template>
    <div>
        <div v-if="restriction.type === 'text_max_len'">
            <c-input
                v-model="restrictionValue"
                :label="$t('component_creativeFormatDetailView_restriction_textMaxLen_name')"
                type="number"
                min="1"
            />
        </div>
        <div v-else-if="restriction.type === 'image_format'">
            <c-select
                v-model="restrictionValue"
                :label="$t('component_creativeFormatDetailView_restriction_imageFormat_name')"
                :options="['JPEG', 'PNG', 'WEBP']"
                multiple
            />
        </div>
        <div v-else-if="restriction.type === 'image_width'">
            <c-input
                v-model="restrictionValue"
                :label="$t('component_creativeFormatDetailView_restriction_imageWidth_name')"
                type="number"
                min="1"
            />
        </div>
        <div v-else-if="restriction.type === 'image_height'">
            <c-input
                v-model="restrictionValue"
                :label="$t('component_creativeFormatDetailView_restriction_imageHeight_name')"
                type="number"
                min="1"
            />
        </div>
        <div v-else-if="restriction.type === 'image_byte_size'">
            <c-input
                v-model="restrictionSizeValue"
                :label="$t('component_creativeFormatDetailView_restriction_imageBiteSize_name')"
                type="number"
                min="1"
            />
        </div>
        <div v-else-if="restriction.type === 'number_type'">
            <c-select
                v-model="restrictionValue"
                :label="$t('component_creativeFormatDetailView_restriction_numberType_name')"
                :options="numberOptions"
                label-attribute="label"
                value-attribute="value"
                required
            ></c-select>
        </div>
        <div v-else-if="restriction.type === 'number_min'">
            <c-number-input
                v-model="restrictionValue"
                :label="$t('component_creativeFormatDetailView_restriction_numberMin_name')"
            />
        </div>
        <div v-else-if="restriction.type === 'number_max'">
            <c-number-input
                v-model="restrictionValue"
                :label="$t('component_creativeFormatDetailView_restriction_numberMax_name')"
            />
        </div>
        <div v-else-if="restriction.type === 'number_step'">
            <c-number-input
                v-model="restrictionValue"
                :label="$t('component_creativeFormatDetailView_restriction_numberStep_name')"
                min="0"
            />
        </div>
        <div v-else-if="restriction.type === 'sku_count'">
            <c-input
                v-model="restrictionValue"
                :label="$t('component_creativeFormatDetailView_restriction_skuCount_name')"
                type="number"
                min="0"
                step="1"
            />
        </div>
        <div v-else-if="restriction.type === 'sku_count_max'">
            <c-input
                v-model="restrictionValue"
                :label="$t('component_creativeFormatDetailView_restriction_skuCountMax_name')"
                type="number"
                min="1"
                step="1"
            />
        </div>
        <!--
        <div v-else-if="restriction.type === 'image_conversation_format' && restriction.possible_values && restriction.possible_values.length > 0">
            <c-select
                v-model="restrictionValue"
                :label="$t('component_creativeFormatDetailView_restriction_imageConversationFormat_name')"
                :options="restriction.possible_values"
                label-attribute="value"
                value-attribute="key"
            />
        </div>
        <div v-else-if="restriction.type === 'image_conversation_source' && restriction.possible_values && restriction.possible_values.length > 0">
            <c-select
                v-model="restrictionValue"
                :label="$t('component_creativeFormatDetailView_restriction_imageConversationSource_name')"
                :options="restriction.possible_values"
                label-attribute="value"
                value-attribute="key"
            />
        </div>
        -->
    </div>
</template>
<script>
import CSelect from "@/components/base/form/CSelect.vue";
import CInput from "@/components/base/form/CInput.vue";
import CNumberInput from "@/components/base/form/CNumberInput.vue";

export default {
    name: 'CreativeFormatFieldRestrictions',
    components: {CNumberInput, CInput, CSelect},
    props: {
        value: {}
    },
    computed: {
        restriction() {
            return this.value
        },
        numberOptions() {
            return [
                {value: 'Int', label: this.$t('component_creativeFormatDetailView_restriction_numberType_int')},
                {value: 'Float', label: this.$t('component_creativeFormatDetailView_restriction_numberType_float')},
            ]
        },
        restrictionSizeValue: {
            get() {
                return (this.restrictionValue / 1024);
            },
            set(value) {
                this.restrictionValue = (value * 1024);
            },
        },
        restrictionValue: {
            get() {
                return this.restriction.value
            },
            set(value) {
                this.$emit('input', {...this.restriction, value})
            }
        }
    }
}
</script>
