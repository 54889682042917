<template>
    <div>
        <base-button @click="open = true">
            <img
                class="w-25 aspect-square object-cover rounded-2xl"
                v-if="value"
                :src="src"
            >
        </base-button>
        <div class="flex mt-4">
            <t-button
                @click="open = true"
                variant="mediumBlue"
            >
                Vyberte obrázek
            </t-button>
            <t-button
                v-if="value"
                class="ml-2"
                variant="delete"
                @click="$emit('input', null)"
            >
                Odebrat obrázek
            </t-button>
        </div>
        <simple-dialog v-model="open">
            <one-image-selection
                @image="image"
                :default-names="defaultNames"
            ></one-image-selection>
        </simple-dialog>
    </div>
</template>

<script>
import ImageService from "@/services/ImageService";
import OneImageSelection from "@/components/base/OneImageSelection.vue";
import SimpleDialog from "@/components/base/SimpleDialog.vue";
import BaseButton from "@/components/base/BaseButton.vue";

export default {
    name: "OneImageInput",
    components: {BaseButton, SimpleDialog, OneImageSelection},
    props: {
        value: {
            type: Number,
            default: null,
        },
        defaultNames: {
            // default localized names for new image
            // format: {"cs-CZ": "Český název", "en-GB": ...}
            type: Object,
            default: () => {},
        }
    },
    data: () => ({
        defaultImageSize: {
            width: 300,
            height: 300,
        },
        open: false
    }),
    computed: {
        src() {
            return this.value ? this.imageSrc(this.value) : null;
        },
    },
    methods: {
        image(data) {
            this.$emit("input", data.id);
            this.open = false;
        },
        imageSrc(id) {
            return ImageService.imageURL(id, this.defaultImageSize);
        },
    },
}
</script>

<style scoped>

</style>
