<template>
    <t-dropdown
        ref="dropdown"
        class="lg:mt-0 mt-3 mr-0 ml-auto"
        variant="submenu"
    >
        <template
            v-slot:trigger="{
                mousedownHandler,
                focusHandler,
                blurHandler,
                keydownHandler,
            }"
        >
            <main-header-item
                :name="name"
                @mousedown="mousedownHandler"
                @focus="focusHandler"
                @blur="blurHandler"
                @keydown="keydownHandler"
            >
                <slot></slot>
            </main-header-item>
        </template>
        <template v-slot="">
            <div class="px-3 py-2 space-y-3 text-center" @click="clickInDropdown">
                <slot name="dropdown">

                </slot>
            </div>
        </template>
    </t-dropdown>
</template>

<script>
import MainHeaderItem from "@/components/header/MainHeaderItem.vue";

export default {
    name: "MainHeaderItemDropdown",
    components: {MainHeaderItem},
    props: {
        name: {
            type: String,
            required: true
        },
    },
    methods: {
        clickInDropdown() {
            const _this = this;
            this.$nextTick(() => {
                _this.$refs.dropdown.doHide();
            })
        }
    }
}
</script>

<style scoped>

</style>
