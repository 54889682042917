<template>
    <div v-if="product && productEdit" class="max-w-screen-2xl pt-1 pb-8 px-4 md:px-8 mx-auto">
        <simple-breadcrumb :items="breadcrumbs"></simple-breadcrumb>

        <h1 class="my-5 text-purple-100 h1">{{ productEdit.name }}</h1>

        <info-message v-for="message in productErrors" :key="message" type="error" class="mb-4">
            {{ message }}
        </info-message>

        <div class="w-full mb-4">
            <p class="text-gray-600 mb-1 block">
                {{ $t('view_ProductSettingsDetailView_storefrontName') }}
            </p>
            <t-input v-model="productEdit.title"></t-input>
        </div>

        <div class="w-full mb-4" v-if="isCpm || isCpc">
            <t-checkbox v-model="productEdit.active"/>
            <checkbox-icon style="display: inline-block"/>
            <span class="leading-tight">{{ $t('view_ProductSettingsDetailView_active') }}</span>
        </div>

        <div class="w-full mb-4">
            <p class="text-gray-600 mb-1 block">
                {{ $t('view_ProductSettingsDetailView_descriptionFieldLabel') }}</p>
            <t-textarea v-model="productEdit.description"></t-textarea>
        </div>

        <div class="w-full mb-4">
            <p class="text-gray-600 mb-1 block">
                {{ $t('view_ProductSettingsDetailView_storefrontProductCategory') }}</p>
            <t-select
                v-model="productEdit.productCategoryId"
                :options="productCategoryOptions"
                text-attribute="optionName"
                value-attribute="id"
            >
            </t-select>
        </div>

        <div class="w-full mb-4">
            <p class="text-gray-600 mb-1 block">{{ $t('view_ProductSettingsDetailView_storefrontProductTemplate') }}</p>
            <t-rich-select
                v-model="productEdit.creativeFormatId"
                :options="creativeFormatOptions"
                text-attribute="optionName"
                value-attribute="id"
                required
            >
            </t-rich-select>
        </div>

        <div class="w-full mb-4" v-if="isCpm || isCpc">
            <p class="text-gray-600 mb-1 block">{{ $t('common_budget') }}</p>
            <t-input type="number" v-model="productEdit.minAmount"></t-input>
        </div>

        <div class="w-full mb-4 flex items-center" v-if="isCpm || isCpc">
            <t-checkbox v-model="productEdit.checkAvails"/>
            <checkbox-icon style="display: inline-block"/>
            <span class="leading-tight">{{
                    $t('view_ProductSettingsDetailView_checkAvailability')
                }}</span>
        </div>

        <div class="w-full mb-4 flex items-center" v-if="hasSelectableVariableValues">
            <t-checkbox v-model="productEdit.multipleVariableValues"/>
            <checkbox-icon style="display: inline-block"/>
            <span class="leading-tight">{{
                    $t('view_ProductSettingsDetailView_multipleVariableValues')
                }}</span>
        </div>

        <div class="w-full mb-4 flex items-center" v-if="!isFlatRate">
            <t-checkbox name="typeSearch" v-model="productEdit.auction"/>
            <checkbox-icon style="display: inline-block"/>
            <span class="leading-tight">{{ $t('view_ProductSettingsDetailView_enableAuction') }}</span>
        </div>

        <div class="w-full mb-4">
            <p class="text-gray-600 mb-1 block">
                {{ $t('view_ProductSettingsDetailView_addToolType') }}
            </p>
            <c-select
                v-model="nativeFormat"
                :options="basicTypeOptions"
                value-attribute="value"
                label-attribute="label"
            >
            </c-select>
        </div>

        <div class="w-full mb-4" v-if="showNativeFormatSelect">
            <p class="text-gray-600 mb-1 block">
                {{ $t('view_ProductSettingsDetailView_nativeToolType') }}
            </p>
            <c-select
                v-model="productEdit.nativeType"
                :options="nativeTypeOptions"
                value-attribute="value"
                label-attribute="label"
            >
            </c-select>
        </div>

        <div class="w-full mb-4">
            <one-image-input v-model="productEdit.imageId" :default-names="defaultImageNames">
            </one-image-input>
        </div>

        <div class="w-full mb-4">
            <p class="text-gray-600 mb-1 block">{{ 'Icon' }}</p>
            <icon-select-input v-model="productEdit.icon"
                :options="[
                    'banner-carousel-hp-green',
                    'banner-category-green',
                    'banner-sponsored-category-green',
                    'banner-under-carousel',
                    'sponsored-product-cart-plus',
                    'sponsored-product-cart',
                    'sponsored-product-category-position-1-green',
                    'sponsored-product-category-position-1-purple',
                    'sponsored-product-category-position-3-green',
                    'sponsored-product-category-position-3-purple',
                    'sponsored-product-category-position-4-green',
                    'sponsored-product-category-position-4-purple',
                    'sponsored-product-hp-green',
                    'sponsored-product-hp-purple',
                    'sponsored-product-product-detail-green',
                    'sponsored-product-product-detail-purple',
                    'sponsored-product-search',
                ]"
            >
            </icon-select-input>
        </div>

        <div class="w-full mb-4">
            <p class="text-gray-600 mb-1 block">{{
                    $t('view_ProductSettingsDetailView_moreInfoUrlName')
                }}</p>
            <t-input v-model="productEdit.moreInfoUrl"></t-input>
        </div>
        <div class="w-full mb-4">
            <p class="text-gray-600 mb-1 block">
                {{ $t('view_ProductSettingsDetailView_moreInfoUrlTitleName') }}</p>
            <t-input v-model="productEdit.moreInfoUrlTitle"></t-input>
        </div>


        <p class="mb-3 text-red-400" v-if="errorMessage">{{ errorMessage }}</p>

        <div class="w-full mb-2 flex flex-col md:flex-row">
            <div class="flex mt-3">
                <t-button
                    v-if="sasProductURL"
                    variant="smallBlue"
                    class="flex items-center relative group h-fit"
                    :href="sasProductURL"
                    target="_blank"
                >
                    {{ $t("common_edit_tool") }}
                    <c-icon class="ml-1 w-3 h-3" icon="external-link"></c-icon>
                </t-button>
            </div>
            <div class="md:ml-auto flex">
                <t-button
                    @click="$router.push({name: 'administrationProductSettings'})"
                    variant="link"
                    class="border-gray-400 text-gray-400 mr-4"
                >
                    {{ $t('common_back') }}
                </t-button>

                <t-button
                    v-if="product && productEdit"
                    @click="saveProduct"
                    :disabled="!isSendEnabled"
                    :variant="!isSendEnabled ? 'bigGray': 'bigGreen'"
                    class="flex items-center relative group ml-4 my-4 pl-6"
                >
                    <loading-icon v-if="isWorking" class="inline-block"></loading-icon>
                    <div v-if="isSendEnabled" class="flex bg-green-200 rounded-full p-1.5 mr-3 text-black">
                        <c-icon class="w-3.5 h-3.5" icon="check"></c-icon>
                    </div>
                    {{ $t('common_save') }}
                </t-button>
            </div>
        </div>

    </div>
</template>

<script>

import SimpleBreadcrumb from "@/components/SimpleBreadcrumb";
import CIcon from "@/components/base/icons/CIcon";
import CheckboxIcon from "@/components/icons/CheckboxIcon";
import InfoMessage from "@/components/base/InfoMessage";
import LoadingIcon from "@/components/LoadingIcon";
import IconSelectInput from "@/components/base/IconSelectInput";
import OneImageInput from "@/components/base/OneImageInput";
import CSelect from "@/components/base/form/CSelect.vue";

export default {
    name: "ProductsSettingsDetailView",
    metaTags() {
        return {
            title: this.$t('view_productSettingDetail_title') + ' - ' + this.$t('common_meta_title'),
        }
    },
    components: {
        CSelect,
        SimpleBreadcrumb,
        IconSelectInput,
        OneImageInput,
        LoadingIcon,
        CIcon,
        CheckboxIcon,
        InfoMessage,
    },
    data: () => {
        return {
            product: null,
            productEdit: null,
            isWorking: false,
            formErrors: {},
            errorMessage: '',
            productCategoryOptions: [],
            creativeFormatOptions: []
        };
    },
    computed: {
        breadcrumbs() {
            const that = this;

            return [
                {
                    title: that.$t('common_products_settings'),
                    current: false,
                    onClick: () => {
                        that.$router.push({ name: 'administrationProductSettings' });
                    },
                },
                {
                    title: this.product.name,
                    current: 'page',
                },
            ];
        },
        productErrors() {
            if (!this.product) {
                return [];
            }
            const errors = [];
            if (!this.product.sizeExternalId) {
                errors.push(this.$t('view_ProductSettingsDetailView_missingSizeExternalId'));
            }
            if (!this.productEdit.creativeFormatId) {
                errors.push(this.$t('view_ProductSettingsDetailView_missingCreativeFormat'));
            }
            return errors;
        },
        nativeFormat: {
            get() {
                return this.productEdit.nativeFormat;
            },
            set(value) {
                this.productEdit.nativeFormat = value;
            }
        },
        basicTypeOptions() {
            return [
                {
                    value: false,
                    label: this.$t('view_ProductSettingsDetailView_bannerFormat'),
                },
                {
                    value: true,
                    label: this.$t('view_ProductSettingsDetailView_nativeFormat'),
                },
            ]
        },
        nativeTypeOptions() {
            return [
                {value: null, label: '--'},
                {value: 'topInCategory', label: this.$t("view_ProductSettingsDetailView_nativeType_topInCategory")},
                {value: 'topInSearch', label: this.$t("view_ProductSettingsDetailView_nativeType_topInSearch")},
                {value: 'other', label: this.$t("view_ProductSettingsDetailView_nativeType_other")},
            ];
        },
        showNativeFormatSelect() {
            return this.nativeFormat;
        },
        isFlatRate() {
            return this.product.rateType === 'Flat Rate';
        },
        isCpc() {
            return this.product.rateType === 'CPC';
        },
        isCpm() {
            return this.product.rateType === 'CPM';
        },
        hasSelectableVariableValues() {
            return this.product.variables.length > 0
                && this.product.variables[0].values.length > 0
                && !this.isFlatRate;
        },
        defaultImageNames() {
            return {
                'cs-CZ': this.$t("view_ProductSettingsDetailView_previewImageName", 'cs-CZ', {activityName: this.product.title}),
                'cs-CZ-publisher': this.$t("view_ProductSettingsDetailView_previewImageName", 'cs-CZ-publisher', {activityName: this.product.title}),
                'en-GB': this.$t("view_ProductSettingsDetailView_previewImageName", 'en-GB', {activityName: this.product.title}),
            }
        },
        sasProductURL() {
            if (this.product && this.product?.sasInfo) {
                return this.product.sasInfo.productURL;
            }
            return null;
        },
        isSendEnabled() {
            return (
                this.productEdit.title
                && this.productEdit.productCategoryId
            );
        },
    },
    methods: {

        async loadData() {
            const id = this.$route.params.productId;

            const [
                productCategories,
                creativeFormats,
                product,
                productEdit
            ] = await Promise.all([
                this.$store.dispatch('products/getCategories'),
                this.$store.dispatch('creatives/getCreativeFormats'),
                this.$store.dispatch("products/getProduct", {productId: id}),
                this.$store.dispatch("products/getProductEdit", {productId: id})
            ]);

            const productCategoryOptions = [
                {id: null, title: '--', name: null},
                ...productCategories
            ];
            this.productCategoryOptions = productCategoryOptions.map((category) => {
                category.optionName = category.title;
                return category
            });
            const creativeFormatOptions = [
                {id: null, title: '--', name: null},
                ...creativeFormats
            ];
            this.creativeFormatOptions = creativeFormatOptions.map((format) => {
                format.optionName = format.name;
                return format
            });

            this.isWorking = true;
            this.product = product;
            this.productEdit = productEdit;
            this.isWorking = false;
        },
        async saveProduct() {
            this.isWorking = true;

            try {
                await this.$store.dispatch("products/updateProduct", {
                    productId: this.product.id,
                    productEdit: this.productEdit
                });
                this.isWorking = false
                await this.$router.push({name: "administrationProductSettings"})
            } catch (e) {
                this.errorMessage = e;
                this.isWorking = false
                if (e.response && e.response.data.message) {
                    this.errorMessage = e.response.data.message;
                }
                if (e.response.data.formErrors) { // there are some form errors
                    // DOTO: throw errors
                    this.errorMessage = Object.values(e.response.data.formErrors).join(' ');
                    this.formErrors = e.response.data.formErrors;
                }
            }
        },
    },
    beforeRouteEnter(to, from, next) {
        next(async (vm) => {
            await vm.loadData();
        });
    },
    async beforeRouteUpdate() {
        await this.loadData();
    },
}
</script>
