<template>
	<div class="max-w-screen-2xl pt-1 pb-8 px-4 md:px-8 mx-auto">
        <simple-breadcrumb :items="breadcrumbs"></simple-breadcrumb>

        <div>
            <header class="flex flex-wrap items-start justify-between mt-5 mb-3 space-y-2 lg:space-y-0">
                <div class="flex items-center mb-4 space-x-2 pr-3">
                    <h1 class="text-purple-100 h1">{{ $t('view_LandingPageTypeSetting_title') }}</h1>
                </div>

                <div class="flex flex-col items-end">
                    <t-button
                        :to="{name: 'administrationLandingPageTypeSettingsDetail', params:{landingPageId: 'new'}}"
                        tag-name="a"
                        class="flex items-center relative group ml-auto mt-2 pl-6"
                        variant="bigGreen">
                        <div class="flex bg-green-200 rounded-full p-1.5 mr-3 text-black">
                            <c-icon class="w-3.5 h-3.5" icon="plus"></c-icon>
                        </div>
                        {{ $t('view_LandingPageTypeSetting_buttonAdd') }}
                    </t-button>
                </div>
            </header>

            <dynamic-table :items="items">
                <dynamic-table-column identifier="name">
                    {{ $t('common_name') }}
                </dynamic-table-column>
                <dynamic-table-column identifier="active" class="text-center">
                    {{ $t('view_productCategorySettings_active') }}
                </dynamic-table-column>
                <dynamic-table-column identifier="actions" class="text-right">
                    {{ $t('common_actions') }}
                </dynamic-table-column>
                <template v-slot:title="item">
                    <span class="font-medium text-base leading-4">{{ item.name }}</span>
                </template>
                <template v-slot:active="item">
                    <boolean-column :value="item.active"></boolean-column>
                </template>
                <template v-slot:actions="item">
                    <div class="flex justify-end">
                        <t-button
                            class="mr-2"
                            variant="editCircle"
                            tag-name="a"
                            :to="{name: 'administrationLandingPageTypeSettingsDetail', params:{landingPageId: item.id}}"
                            :title="$t('common_edit')"
                        >
                            <c-icon class="w-3.5 h-3.5" icon="edit-pencil"/>
                        </t-button>
                    </div>
                </template>
            </dynamic-table>
        </div>

        <landing-page-type-card :landing-page-type-id="selectedLandingPageId" @close="close">
        </landing-page-type-card>

    </div>
</template>

<script>

import SimpleBreadcrumb from "@/components/SimpleBreadcrumb";
import DynamicTable from "@/components/universal/dynamic-table/DynamicTable.vue";
import DynamicTableColumn from "@/components/universal/dynamic-table/DynamicTableColumn.vue";
import CIcon from "@/components/base/icons/CIcon.vue";
import BooleanColumn from "@/components/universal/dynamic-table/BooleanColumn.vue";
import LandingPageTypeCard from "@/components/landing-page/LandingPageTypeCard.vue";

export default {
    name: "LandingPageTypeSettingsView",
    metaTags() {
        return {
            title: this.$t('view_LandingPageTypeSetting_metaTitle') + ' - ' + this.$t('common_meta_title'),
        }
    },
    components: {
        LandingPageTypeCard,
        BooleanColumn,
        CIcon,
        DynamicTableColumn,
        DynamicTable,
        SimpleBreadcrumb,
    },
    data: () => {
        return {
            showCreateDialog: false,
            selectedLandingPageId: null,

            items: [],
        };
    },
    computed: {
        deletePopup: {
            get() {
                return this.deleteCategoryId !== null;
            },
            set(value) {
               if(!value) {
                   this.deleteCategoryId = null;
               }
            }
        },
        breadcrumbs() {
            const that = this;

            return [
                {
                    title: that.$t('common_settings'),
                    current: false,
                },
                {
                    title: this.campaignBreadcrumbName,
                    current: 'page',
                },
            ];
        },
    },
    methods: {
        async loadData(to) {
            const id = to.params.landingPageId;
            this.selectedLandingPageId = id || null;
            this.items = await this.$store.dispatch('landingPages/getLandingPageTypes');
        },
        close() {
            this.$router.push({name: 'administrationLandingPageTypeSettings'})
        },
    },
    async beforeRouteEnter(to, from, next) {
        await next(async (vm) => {
            await vm.loadData(to);
        });
    },
    async beforeRouteUpdate(to) {
        await this.loadData(to);
    }
};
</script>
