<template>
    <div id="app" class="font-sans bg-gray-50 min-h-screen text-sm">
        <c-icon-sprite />
        <router-view />
    </div>
</template>

<script>


import CIconSprite from "@/components/base/icons/CIconSprite.vue";

export default {
    components: {CIconSprite},
    metaTags: {
        title: 'Cruxo - Retail Media Platform'
    }
};
</script>
