<template>
    <div class="max-w-screen-2xl p-8 md:p-16 mx-auto">

        <h1 class="h1 text-purple-100">{{ $t('view_forbidden_title') }}</h1>

        <div class="block rounded-xl bg-white mt-4 p-8">
            {{ $t('view_forbidden_description') }}
        </div>
    </div>
</template>

<script>

export default {
    name: "IsForbiddenView",
    metaTags() {
        return {
            title: this.$t('view_forbidden_description') + ' - ' + this.$t('view_forbidden_metaTitle'),
        };
    },
    data: () => {
        return {};
    },
}
</script>
