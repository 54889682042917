<template>
    <div class="space-y-3 bg-gray-50 rounded-2xl p-6 -mx-6">
        <div class="sm:flex flex-wrap items-center justify-between mb-2 w-full">
            <div class="mt-4 mb-2 w-full flex items-center">
                <div>
                    <p class="font-bold">{{ formatName }}</p>
                    <p>{{ formatDescription }}</p>
                </div>
                <p v-if="error" class="text-red-500">{{ error }}</p>
            </div>
        </div>

        <div v-if="mediaType === 'file'" class="items-center " :class="{'': !last}">
            <p class="text-gray-600 py-2 mr-4">
                {{ $t('common_file') }}
            </p>
            <div v-if="mediaLoading">
                <loading-box class="h-32" v-model="mediaLoading" theme="light"></loading-box>
            </div>
            <div v-else-if="imgSrc">
                <img
                    v-if="imgSrc"
                    class="rounded-2xl"
                    :src="imgSrc"
                    :alt="mediaInfo.name"
                >
            </div>
            <div class="w-full mt-4 mb-2">
                <div class="w-full flex items-center justify-between">
                    <file-state-button @file="uploadFile" :item-type="state" :percents="percents"/>
                    <c-button
                        v-if="imgSrc"
                        size="medium"
                        append-icon="eye"
                        append-icon-class="w-5 h-5"
                        color="blue"
                        @click.prevent.stop="showFileDetailPopup = true"
                    >
                        {{ $t('component_creativeInput_view') }}
                    </c-button>
                </div>

                <info-message class="mt-2" v-if="fileError" v-text="fileError" type="error"></info-message>
                <info-message v-for="validationError in translatedValidationErrors" :key="validationError.error" class="mt-2" type="error">
                    {{ validationError }}
                </info-message>

            </div>
        </div>

        <div v-if="cruxoMediaType === 'sku'" class="w-full">
            <sku-input-picker
                label="Products"
                v-model="skus"
                :targetId="targetId"
                :multiple="true"
                :required="required"
                :rules="rules"
            />
        </div>

        <c-textarea
            v-if="cruxoMediaType === 'text'"
            v-model="text"
            label="Text"
            :required="required"
            :rules="rules"
        />
        <c-number-input
            v-if="cruxoMediaType === 'number'"
            v-model="text"
            label="Number"
            type="number"
            :min="minNumber"
            :max="maxNumber"
            :step="step" return restriction.value;
            :required="required"
            :rules="rules"
        ></c-number-input>
        <!-- file detail popup -->
        <simple-dialog v-if="mediaType === 'file'" v-model="showFileDetailPopup">
            <div>
                <div v-if="mediaLoading">
                    <loading-box class="h-32" v-model="mediaLoading" theme="light"></loading-box>
                </div>
                <div v-else>
                    <img v-if="imgSrc" :src="imgSrc" :alt="mediaInfo.name">
                </div>
            </div>
        </simple-dialog>
        <!-- file detail reject popup -->
    </div>
</template>

<script>
import SkuInputPicker from "@/components/SkuInputPicker";
import FileStateButton from "@/components/FileStateButton";
import LoadingBox from "@/components/LoadingBox";
import SimpleDialog from "@/components/base/SimpleDialog";
import InfoMessage from "@/components/base/InfoMessage.vue";
import CTextarea from "@/components/base/form/CTextarea.vue";
import CNumberInput from "@/components/base/form/CNumberInput.vue";

export default {
    name: "CreativeInput",
    components: {
        CNumberInput,
        CTextarea,
        InfoMessage,
        FileStateButton,
        SkuInputPicker,
        LoadingBox,
        SimpleDialog,
    },
    props: {
        value: {},
        companyId: {
            type: Number,
            required: true
        },
        targetId: {
            type: Number,
            required: true
        },
        last: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            mediaLoading: false,
            mediaInfo: null,
            tmpMediaFileId: null,
            showFileDetailPopup: false,
            uploading: false,
            percents: 0,
            error: '',
            fileError: '',
            fileValidationErrors: [],
            innerText: '',
            textTimeout: null,
        };
    },
    computed: {
        formatName() {
            return (
                this.value.formatField.displayName ?? this.value.formatField.name
            );
        },
        formatDescription() {
            return (
                this.value.formatField.displayDescription ?? this.value.formatField.description
            );
        },
        required() {
            return this.value.formatField.required;
        },
        mediaType() {
            return this.value.formatField.mediaType;
        },
        cruxoMediaType() {
            return this.value.formatField.mediaTypeCruxo;
        },
        imgSrc() {
            if (!this.mediaInfo) {
                return null;
            }
            return "data:" + this.mediaInfo.contentType + ";base64," + this.mediaInfo.content;
        },
        maxLength() {
            const restriction = this.value.formatField.restrictionDetails.find(r => r.type === 'text_max_len');
            if (restriction) {
                return restriction.value;
            }
            return 0;
        },
        numberType() {
            const restriction = this.value.formatField.restrictionDetails.find(r => r.type === 'number_type');
            if (restriction) {
                return restriction.value;
            }
            return [];
        },
        step() {
            if(this.numberType === 'Int') {
                return 1;
            }
            return 0.01;
        },
        minNumber() {
            const restriction = this.value.formatField.restrictionDetails.find(r => r.type === 'number_min');
            if (restriction) {
                if(this.numberType === 'Int') {
                    return Number.parseInt(restriction.value);
                }
                return Number.parseFloat(restriction.value);
            }
            return undefined
        },
        maxNumber() {
            const restriction = this.value.formatField.restrictionDetails.find(r => r.type === 'number_max');
            if (restriction) {
                if(this.numberType === 'Int') {
                    return Number.parseInt(restriction.value);
                }
                return Number.parseFloat(restriction.value);
            }
            return undefined;
        },
        skuCountMin() {
            const restriction = this.value.formatField.restrictionDetails.find(r => r.type === 'sku_count');
            if (restriction) {
                return restriction.value;
            }
            return 0;
        },
        skuCountMax() {
            const restriction = this.value.formatField.restrictionDetails.find(r => r.type === 'sku_count_max');
            if (restriction) {
                return restriction.value;
            }
            return 0;
        },
        rules() {
            const rules = [];
            if (this.required) {
                rules.push('required');
            }
            if (this.maxLength > 0) {
                rules.push('max:' + this.maxLength);
            }
            if (this.minNumber) {
                rules.push('min_value:' + this.minNumber);
            }
            if (this.maxNumber) {
                rules.push('max_value:' + this.maxNumber);
            }
            if (this.skuCountMin) {
                rules.push('sku_count:' + this.skuCountMin);
            }
            if (this.skuCountMax) {
                rules.push('sku_count_max:' + this.skuCountMax);
            }
            return rules.join('|');
        },
        state() {
            if (this.uploading) {
                return 'loading';
            }
            if (this.value.filled) {
                return 'approved';
            }
            return 'default';
        },
        text: {
            get() {
                return this.value.text || '';
            },
            set(value) {
                this.innerText = value;
                this.$emit('input', {...this.value, text: value, filled: value.length > 0});
            }
        },
        skus: {
            get() {
                return this.text.split(',').filter(word => word.length > 0);
            },
            async set(value) {
                this.text = value.join(',');
            }
        },
        translatedValidationErrors() {
            const _this = this;
            const translatedErrors = [];
            this.fileValidationErrors.forEach(function(error) {
                switch(error.error) {
                    case 'fileTooBig':
                        translatedErrors.push(
                            _this.$t(
                                'component_creativeInput_error_fileTooBig',
                                {
                                    currentFileSize: _this.formatBytes(parseInt(error.currentFileSize)),
                                    maxSize: _this.formatBytes(parseInt(error.maxFileSize))
                                }
                            )
                        );
                        break;
                    case 'invalidImageWidth':
                        translatedErrors.push(
                            _this.$t('component_creativeInput_error_InvalidImageWidth', {requiredImageWidth: error.requiredImageWidth})
                        );
                        break;
                    case 'invalidImageHeight':
                        translatedErrors.push(
                            _this.$t('component_creativeInput_error_InvalidImageHeight', {requiredImageHeight: error.requiredImageHeight})
                        );
                        break;
                    case 'invalidImageFormat':
                        translatedErrors.push(
                            _this.$t('component_creativeInput_error_InvalidImageFormat', {requiredImageFormat: error.requiredImageFormat})
                        )
                }
            });
            return translatedErrors;
        }
    },
    methods: {
        async uploadFile(file) {
            this.fileError = '';
            this.fileValidationErrors = [];
            this.uploading = true;
            let media = null;
            try {
                media = await this.$store.dispatch("creatives/uploadFormatFieldFile", {
                    file,
                    progressFunc: this.progressFunction,
                    formatFieldId: this.value.formatField.id,
                });
            } catch (error) {
                if (error.response && error.response.data && error.response.data.error == 'validationErrors') {
                    this.fileValidationErrors = error.response.data.validationErrors;
                } else if (error.response && error.response.data && error.response.data.error) {
                    if( error.response.data.error == 'notSupportedImageFormat') {
                        this.fileError = this.$t('component_creativeInput_error_notSupportedImageFormat');
                    } else {
                        this.fileError = error.response.data.error;
                    }
                } else {
                    this.fileError = this.$t("common_error_message");
                }
            }
            this.uploading = false;
            if (media) {
                this.tmpMediaFileId = media.tmpMediaFileId;
                this.$emit('input', {...this.value, tmpMediaFileId: media.tmpMediaFileId, filled: true});
                await this.getMediaInfo();
            }
        },
        saveText() {
            if (this.textTimeout) {
                clearTimeout(this.textTimeout);
                this.textTimeout = null;
            }
            this.$emit('input', {...this.value, text: this.text, filled: this.text.trim().length > 0});
        },
        async getMediaInfo() {
            if (this.mediaType !== 'file') {
                this.mediaInfo = null;
                return;
            }
            if (!this.value.mediaExternalId && !this.tmpMediaFileId) {
                this.mediaInfo = null;
                return;
            }
            var medialExternalId = this.value.mediaExternalId;
            if (this.tmpMediaFileId) {
                this.mediaLoading = true;
                this.mediaInfo = await this.$store.dispatch('creatives/getTmpMediaInfo', {tmpMediaFileId: this.tmpMediaFileId});
                this.mediaLoading = false;
            } else {
                this.mediaLoading = true;
                this.mediaInfo = await this.$store.dispatch('creatives/getMediaInfo', {mediaExternalId: medialExternalId});
                this.mediaLoading = false;
            }
        },
        formatBytes(bytes, decimals) {
            if (bytes == 0) return '0 B';
            var k = 1024,
                dm = decimals || 2,
                sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
                i = Math.floor(Math.log(bytes) / Math.log(k));
            return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
        }
    },
    created() {
        this.innerText = this.value.text || '';
        this.getMediaInfo();
    },
    beforeDestroy() {
        this.saveText();
    }
}
</script>

<style scoped>

</style>
