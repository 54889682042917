<template>
	<div class="max-w-screen-2xl pt-1 pb-8 px-4 md:px-8 mx-auto">
        <simple-breadcrumb :items="breadcrumbs"></simple-breadcrumb>

        <div>
            <h1 class="text-purple-100 h1 mt-5 mb-4">{{ $t('view_ProductsSettings_title') }}</h1>

            <dynamic-table :items="products">
                <dynamic-table-column identifier="name">
                    {{ $t('view_productSettings_sasName') }}
                </dynamic-table-column>
                <dynamic-table-column identifier="title">
                    {{ $t('view_productSettings_name') }}
                </dynamic-table-column>
                <dynamic-table-column identifier="category">
                    {{ $t('view_productSettings_category') }}
                </dynamic-table-column>
                <dynamic-table-column identifier="rateType">
                    {{ $t('view_productSettings_rateType') }}
                </dynamic-table-column>
                <dynamic-table-column identifier="price" class="text-right">
                    {{ $t('view_productSettings_price') }}
                </dynamic-table-column>
                <dynamic-table-column identifier="active" class="text-center">
                    {{ $t('view_productSettings_active') }}
                </dynamic-table-column>
                <dynamic-table-column identifier="actions" style-header="width: 12rem" class="text-right">
                    {{ $t('common_actions') }}
                </dynamic-table-column>

                <template v-slot:name="item">
                    <span class="font-medium text-base leading-4">{{ item.name }}</span>
                </template>
                <template v-slot:title="item">
                    <span class="font-medium text-base leading-4">{{ item.title }}</span>
                </template>
                <template v-slot:category="item">
                    <span class="font-medium text-base leading-4">{{ item.productCategory ? item.productCategory.title : "-" }}</span>
                </template>
                <template v-slot:rateType="item">
                   {{ getRateTypeName(item.rateType) }}
                </template>
                <template v-slot:price="item">
                    <div class="text-right">
                        {{ $n(item.price, 'currency') }}
                    </div>
                </template>
                <template v-slot:active="item">
                    <boolean-column :value="item.active"></boolean-column>
                </template>
                <template v-slot:actions="item">
                    <div class="flex justify-end">
                        <t-button
                            class="mr-2"
                            variant="editCircle"
                            tag-name="a"
                            :to="{name: 'administrationProductSettingsDetail', params: {productId: item.id}}"
                            :title="$t('common_edit')"
                        >
                            <c-icon class="w-3.5 h-3.5" icon="edit-pencil"/>
                        </t-button>
                    </div>
                </template>
            </dynamic-table>
        </div>

    </div>
</template>

<script>
import SimpleBreadcrumb from "@/components/SimpleBreadcrumb";
import DynamicTable from "@/components/universal/dynamic-table/DynamicTable.vue";
import DynamicTableColumn from "@/components/universal/dynamic-table/DynamicTableColumn.vue";
import BooleanColumn from "@/components/universal/dynamic-table/BooleanColumn.vue";
import CIcon from "@/components/base/icons/CIcon.vue";
import {getRateTypeName} from "@/utils/RateType";

export default {
    name: "ProductsSettingsView",
    metaTags() {
        return {
            title: this.$t('view_ProductsSettings_title') + ' - ' + this.$t('common_meta_title'),
        }
    },
    components: {
        CIcon,
        BooleanColumn,
        DynamicTableColumn,
        DynamicTable,
        SimpleBreadcrumb,
    },
    data: () => {
        return {
            products: [],
            selectedProductId: null,
        };
    },
    computed: {
        breadcrumbs() {
            const that = this;

            return [
                {
                    title: that.$t('common_settings'),
                    current: false,
                },
                {
                    title: this.campaignBreadcrumbName,
                    current: 'page',
                },
            ];
        }
    },
    methods: {
        getRateTypeName,
        async loadData(to) {
            const id = to.params.productId;
            this.selectedProductId = id || null;

            this.products = await this.$store.dispatch('products/getProducts');
        },
        close() {
            this.$router.push({name: 'administrationProductSettings'})
        },
    },
    async beforeRouteEnter(to, from, next) {
        await next(async (vm) => {
            await vm.loadData(to);
        });
    },
    async beforeRouteUpdate(to) {
        await this.loadData(to);
    }
};
</script>
