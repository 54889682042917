import Vue from 'vue'
import VueI18n from 'vue-i18n'
import base from '@/i18n/base.json'
import csCZ from '@/i18n/locales/cs-CZ.json'
import csCZPublisher from '@/i18n/locales/cs-CZ-publisher.json'
import enGB from '@/i18n/locales/en-GB.json'
import plPL from '@/i18n/locales/pl-PL.json'
function isObject(obj) {
    return obj !== null && typeof obj === 'object'
}

function isString(val) {
    return typeof val === 'string'
}


Vue.use(VueI18n);

const numberFormats = {
    'cs-CZ': {
        currency: {
            style: 'currency',
            currency: 'CZK',
            minimumFractionDigits: 0
        }
    },
    'en-GB': {
        currency: {
            style: 'currency',
            currency: 'CZK',
            minimumFractionDigits: 0
        }
    },
    'pl-PL': {
        currency: {
            style: 'currency',
            currency: 'CZK',
            minimumFractionDigits: 0
        }
    },
};

const dateTimeFormats = {
    "cs-CZ": {
        "day": {
            day: "numeric"
        },
        "dayMonth": {
            day: "numeric",
            month: "numeric",
        },
        "fullDate": {
            day: "numeric",
            month: "numeric",
            year: "numeric",
        },
        "long": {
            day: "numeric",
            month: "numeric",
            year: "numeric",
            hour: 'numeric',
            minute: 'numeric',
        }
    },
    "en-GB": {
        "day": {
            day: "numeric"
        },
        "dayMonth": {
            day: "numeric",
            month: "numeric",
        },
        "fullDate": {
            day: "numeric",
            month: "numeric",
            year: "numeric",
        },
        "long": {
            day: "numeric",
            month: "numeric",
            year: "numeric",
            hour: 'numeric',
            minute: 'numeric',
        }
    },
    "pl-PL": {
        "day": {
            day: "numeric"
        },
        "dayMonth": {
            day: "numeric",
            month: "numeric",
        },
        "fullDate": {
            day: "numeric",
            month: "numeric",
            year: "numeric",
        },
        "long": {
            day: "numeric",
            month: "numeric",
            year: "numeric",
            hour: 'numeric',
            minute: 'numeric',
        }
    }
}

function czSKPluralization(choice) {
    if(choice === 1) {
        return 0;
    }
    if([2,3,4].indexOf(choice) >= 0) {
        return 1;
    }
    return 2;
}

function plPLPluralization(choice) {
    if(choice === 1) {
        return 0;
    }
    if([2,3,4].indexOf(choice % 10) >= 0 && [12,13,14].indexOf(choice % 100) < 0) {
        return 1;
    }
    if (choice % 10 < 2 || choice % 10 > 4 || [12,13,14].indexOf(choice % 100) >= 0) {
        return 2;
    }
    return 3;
}

const pluralizationRules = {
    "cs": czSKPluralization,
    "cs-CZ": czSKPluralization,
    "cs-CZ-publisher": czSKPluralization,
    'pl-PL': plPLPluralization,
}

const i18n = new VueI18n({
    locale: 'cs-CZ',
    fallbackLocale: 'cs-CZ',
    formatFallbackMessages: true,
    numberFormats,
    dateTimeFormats,
    messages: {
        "cs": base,
        "cs-CZ": csCZ,
        "cs-CZ-publisher": csCZPublisher,
        "en-GB": enGB,
        "pl-PL": plPL,
    },
    pluralizationRules
});

// Fix Intl not supported variant locales

Vue.prototype.$d = function (value, ...args) {
    let locale = this.$i18n.locale;
    if (args.length === 1) {
         if (isObject(args[0])) {
            if (args[0].locale) {
                locale = args[0].locale;
            }
        }
    } else if (args.length === 2) {
        if (isString(args[1])) {
            locale = args[1];
        }
    }
    if(locale === 'cs-CZ-publisher') {
        locale = 'cs-CZ';
    }
    if (args.length === 1) {
        if (isObject(args[0])) {
            args[0].locale = locale;
        } else {
            args[1] = locale;
        }
    } else if (args.length === 2) {
        if (isString(args[1])) {
            args[1] = locale;
        }
    } else {
        args[0] = {locale};
    }
    return i18n.d(value, ...args);
}

Vue.prototype.$n = function (value, ...args) {
    let locale = this.$i18n.locale;
    if (args.length === 1) {
        if (isObject(args[0])) {
            if (args[0].locale) {
                locale = args[0].locale;
            }
        }
    } else if (args.length === 2) {
        if (isString(args[1])) {
            locale = args[1];
        }
    }
    if(locale === 'cs-CZ-publisher') {
        locale = 'cs-CZ';
    }
    if (args.length === 1) {
        if (isObject(args[0])) {
            args[0].locale = locale;
        } else {
            args[1] = locale;
        }
    } else if (args.length === 2) {
        if (isString(args[1])) {
            args[1] = locale;
        }
    } else {
        args[0] = {locale};
    }
    return i18n.n(value, ...args);
}

export default i18n;
