<template>
	<simple-dialog v-model="show">
        <h1 class="text-black text-3xl mb-5 pr-5">
            {{ $t('component_campaignUpdateDialog_header') }}
        </h1>
        <form action class="mt-8 w-full" @submit.prevent="updateCampaign">
            <div
                v-if="isRetailer && !disableSalesPersonSelect"
                class="p-8 pt-8 bg-gray-50 rounded-2xl"
            >
                <label for="" class="text-gray-600 mb-3 block">
                    {{ $t('common_salesPerson') }}
                </label>
                <t-rich-select
                    v-model="salesPersonId"
                    :options="salesPersons"
                    value-attribute="id"
                    text-attribute="name"
                    :disabled="disableSalesPersonSelect"
                    clearable
                ></t-rich-select>
            </div>
            <div class="p-8 bg-gray-50 rounded-2xl" :class="isRetailer ? 'pt-0' : 'pt-8'">
                <label for="" class="text-gray-600 mb-3 block">
                    {{ $t('view_campaignDetail_campaignNameInput') }}
                </label>
                <t-input
                    v-model="campaignName"
                    class="text-left border-gray-400 w-full"
                    required
                ></t-input>
                <p class="mt-3 w-full text-red-500" v-text="campaignNameError"></p>
            </div>
            <div class="flex flex-wrap items-center justify-end mt-8">
                <t-button
                    @click.prevent="show = false"
                    variant="link"
                    class="border-gray-400 text-gray-400 mr-6"
                >
                    {{ $t('common_cancel') }}
                </t-button>
                <t-button
                    :disabled="working"
                    variant="bigGreen"
                    class="ml-4 sm:my-0 my-3 flex items-center"
                >
                    <div class="flex bg-green-200 rounded-full p-1 mr-2 text-black">
                        <c-icon class="w-3 h-3" icon="check" ></c-icon>
                    </div>
                    {{ $t('common_save') }}
                </t-button>
            </div>
        </form>
    </simple-dialog>
</template>
<script>
import {mapGetters} from "vuex";
import SimpleDialog from "@/components/base/SimpleDialog.vue";
import CIcon from "@/components/base/icons/CIcon.vue";

export default {
	name: "UpdateCampaignDialog",
    components: {
        SimpleDialog,
        CIcon
    },
	props: {
        showDialog: {
            type: Boolean,
            default: false
        },
        campaign: {
            type: Object
        }
    },
    data: () => {
        return {
            campaignName: '',
            salesPersonId: null,

            campaignNameError: '',
            salesPersons: [],

            working: false
        };
    },
	computed: {
        ...mapGetters('auth', ['isRetailer']),
		show: {
			get() {
				return this.showDialog;
			},
			set(value) {
                if(!value) {
                    this.campaignName = this.campaign.name;
                    this.salesPersonId = this.campaign.salesPerson ? this.campaign.salesPerson.id : null;
                    this.$emit("close", value);
                }
			},
		},
        disableSalesPersonSelect() {
            return this.salesPersons.length <= 1;
        }
	},
    watch: {
        showDialog(value) {
            if (value) {
                if(this.isRetailer) {
                    this.loadAllSalesPersons();
                }
            }
        },
        campaign(value) {
            if (value) {
                this.campaignName = value.name;
                this.salesPersonId = value.salesPerson ? value.salesPerson.id : null;
            }
        },
    },
	methods: {
        async updateCampaign() {
            try {
                this.working = true;
                await this.$store.dispatch("campaigns/updateCampaign", {
                    campaignId: this.campaign.id,
                    salesPersonId: this.salesPersonId,
                    name: this.campaignName
                });
                this.working = false;
                this.show = false;
            } catch (error) {
                this.campaignNameError = error.message;
            }
        },
        async loadAllSalesPersons() {
            this.salesPersons = await this.$store.dispatch('users/getSalesPersonUsers');
        }
    },
	filters: {

    },
};
</script>
