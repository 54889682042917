<template>
    <div class="">
        <div class="xs:flex flex-wrap items-center justify-between mb-6 space-y-3">
            <div class="w-full my-4">
                <p class="font-bold">{{ creativeTemplate.name }}</p>
                <p>{{ creativeTemplate.description }}</p>
                <p v-if="error" class="text-red-500">{{ error }}</p>
            </div>

            <div v-if="creativeTemplate.mediaType !== 'file'" class="w-full">
                {{ creativeTemplate.text }}
            </div>

            <div class="flex flex-wrap space-x-2 w-full" v-if="fileInfo">
                <div class="flex my-4 space-x-2">
                    <p class="font-bold">{{ $t('common_size') }}</p>
                    <p>{{ fileInfo.fileSize }}</p>
                </div>
                <div class="flex my-4 space-x-2">
                    <p class="font-bold">{{ $t('common_mimeType') }}</p>
                    <p>{{ fileInfo.mimeType }}</p>
                </div>
                <div class="flex  my-4 space-x-2">
                    <p class="font-bold">{{ $t('common_width') }}</p>
                    <p>{{ fileInfo.width }}</p>
                </div>
                <div class="flex my-4 space-x-2">
                    <p class="font-bold">{{ $t('common_height') }}</p>
                    <p>{{ fileInfo.height }}</p>
                </div>
            </div>

            <div v-if="fileInfo" class="w-full my-4">
                <img :src="filePreview" :width="fileInfo.width" :height="fileInfo.height">
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "CampaignItemMediaPreview",
    components: {},
    props: {
        creativeTemplate: {},
        last: {
            type: Boolean,
            default: false
        },
        campaignItem: {}
    },
    data() {
        return {
            error: '',
            fileError: '',
            fileInfo: null,
            filePreview: null,
        }
    },
    computed: {
    },
    methods: {
        async loadData() {
            if (this.creativeTemplate.mediaType === "file" && this.creativeTemplate.mediaExternalId && this.creativeTemplate.mediaExternalId.length > 0) {
                this.fileInfo = await this.$store.dispatch("campaigns/fileInfo", {
                    creativeTemplateId: this.creativeTemplate.id,
                });
                var filePreview = await this.$store.dispatch("campaigns/filePreview", {
                    creativeTemplateId: this.creativeTemplate.id,
                });
                this.filePreview = 'data:' + this.fileInfo.mimeType + ';base64,' + filePreview;
            }
        },
    },
    async created() {
        await this.loadData();
    },
}
</script>

<style scoped>

</style>
