export default {
    props: {
        label: {
            type: String,
            default: ''
        },
        required: {
            type: Boolean,
            default: false
        },
        readonly: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        rules: {
            type: String,
            default: ''
        },
    },
    computed: {
        computedRules() {
            let rules = this.rules.split('|').filter(rule => rule.length > 0);
            if (this.required && !rules.includes('required')) {
                rules.push('required');
            }
            return rules.join('|');
        },
    }
};
