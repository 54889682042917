<template>
    <t-modal v-model="show" v-if="campaignItem">
        <div class="md:p-20 p-8">
            <div class="max-w-2xl">
                <h1 class="h1 mb-5 pr-5">
                    {{ campaignItem.generatedName || campaignItem.product.name }}
                </h1>
                <p>
                    {{ campaignItem.product.description }}
                </p>
                <div class="flex mt-8 gap-4 flex-wrap">
                    <div class="flex">
                        <strong class="mr-2">
                            {{ $t('component_campaignItemDialog_itemState') }}
                        </strong>
                        <campaign-item-state :campaignItemState="campaignItem.itemState"></campaign-item-state>
                    </div>
                    <div class="flex items-center"
                         v-if="campaignItem.orderedClicksCount || campaignItem.orderedImpressionsCount">
                        <strong>
                            {{ $t('component_campaignItemDialog_ordered') }}
                        </strong>
                        <div class="flex items-center my-1 mx-2" v-if="campaignItem.orderedClicksCount">
                            <svg-icon name="ico-click" class="mr-2"></svg-icon>
                            <span>
                                {{
                                    $tc(
                                        'common_pluralize_clicks',
                                        campaignItem.orderedClicksCount,
                                        {clicks: $n(campaignItem.orderedClicksCount)}
                                    )
                                }}
                            </span>
                        </div>
                        <div class="flex items-center my-1 mx-2" v-if="campaignItem.orderedImpressionsCount">
                            <svg-icon name="square-rounded" class="mr-2 h-5"></svg-icon>
                            <span>
                                {{
                                    $tc(
                                        'common_pluralize_impressions',
                                        campaignItem.orderedImpressionsCount,
                                        {impressions: $n(campaignItem.orderedImpressionsCount)}
                                    )
                                }}
                            </span>
                        </div>
                    </div>
                    <p>
                        <strong>{{ $t('common_price') }}</strong>
                        <span class="inline-block ml-2">{{ $n(campaignItem.price, "currency") }}</span>
                    </p>
                    <p>
                        <strong>{{ $t('component_campaignItemDialog_fromTo') }}</strong>
                        <span class="inline-block ml-2">{{ startDate }} - {{ endDate }}</span>
                    </p>
                    <p>
                        <strong>URL</strong>
                        <span class="inline-block ml-2">{{ campaignItem.targetURL }}</span>
                    </p>

                    <p v-if="sasLineItemURL">
                        <strong class="mr-2">
                            {{ $t('component_campaignItemDialog_sasLineItemUrl') }}:
                        </strong>
                        <a :href="sasLineItemURL" target="_blank">{{ sasLineItemURL }}</a>
                    </p>
                    <p v-if="sasFlightURL">
                        <strong class="mr-2">
                            {{ $t('component_campaignItemDialog_sasFlightUrl') }}:
                        </strong>
                        <a :href="sasFlightURL" target="_blank">{{ sasFlightURL }}</a>
                    </p>
                    <p v-if="sasCreativeURL">
                        <strong class="mr-2">
                            {{ $t('component_campaignItemDialog_sasCreativeUrl') }}:
                        </strong>
                        <a :href="sasCreativeURL" target="_blank">{{ sasCreativeURL }}</a>
                    </p>
                </div>



                <div v-if="creativeEditAllowed" class="mt-8">
                    <p class="text-red-500 font-bold" v-text="campaignItem.message">
                    </p>
                    <div>
                        <div class="flex items-center">
                            <button
                                class="menu__btn menu__btn--gray"
                                :class="creativeAsZip ? '' : 'is-active'"
                                @click="creativeAsZip = false"
                            >
                                {{ $t('component_campaignItemDialog_buttonFillCreative') }}
                            </button>
                            <div v-if="allowCreativeZips" class="text-gray-500">{{ $t('common_or') }}</div>
                            <button
                                v-if="allowCreativeZips"
                                class="menu__btn menu__btn--gray"
                                :class="!creativeAsZip? '': 'is-active'"
                                @click="creativeAsZip = true"
                            >
                                {{ $t('component_campaignItemDialog_buttonUploadZip') }}
                            </button>
                        </div>
                        <div v-if="!creativeAsZip" class="space-y-4">
                            <creative-input
                                v-for="(creativeTemplate, index) in campaignItem.itemMedia"
                                :key="creativeTemplate.id"
                                :last="index == campaignItem.itemMedia.length - 1"
                                :creative-template="creativeTemplate"
                                :campaign-item="campaignItem"
                                @change="changed"
                            ></creative-input>
                        </div>
                        <div v-if="allowCreativeZips && creativeAsZip" class="bg-gray-50 rounded-xl p-6 -mx-6">
                            <div class="sm:flex items-center justify-between sm:pt-10 pt-4">
                                <p class="font-bold py-2">
                                    {{ $t('common_file') }}
                                </p>
                                <file-state-button
                                    @file="uploadFile"
                                    :percents="percents"
                                    :item-type="zipState"
                                    :accept="['application/zip']"
                                />
                                <t-input
                                    class="!border-gray-300 sm:w-60 w-full my-2"
                                    :placeholder="$t('common_note')"
                                ></t-input>
                            </div>
                        </div>
                    </div>

                    <div class="flex flex-wrap items-center justify-end md:mt-14 mt-10 space-x-4">
                        <t-button
                            @click.prevent="show = false"
                            variant="link"
                            class="border-gray-400 text-gray-400 mr-6"
                        >
                            {{ $t('common_back') }}
                        </t-button>
                        <t-button
                            v-if="isRetailer && campaignItem.product.nativeFormat === false"
                            variant="mediumBlue"
                            class="ml-auto"
                            :to="{name:'campaignItemCreativePreview', params: { campaignId: this.campaign.id, campaignItemId: this.campaignItem.id}}"
                        >
                            {{ $t('component_campaignItemDialog_buttonShowCreativeInputPreview') }}
                        </t-button>
                        <t-button
                            :disabled="!enableSendForCheck"
                            @click="send"
                            variant="bigGreen"
                            v-if="!enableApprovalByRetailer"
                        >
                            <div v-if="!sending" class="flex bg-green-200 rounded-full p-1.5 mr-3 text-black" :class="{'grayscale': !variableChanged}">
                                <c-icon class="w-3.5 h-3.5" icon="check"></c-icon>
                            </div>
                            <loading-icon v-if="sending" class="inline-block"></loading-icon>
                            {{ $t('component_campaignItemDialog_buttonSendForApproval') }}
                        </t-button>

                        <t-button
                            v-if="enableApprovalByRetailer"
                            @click="approve"
                            variant="bigGreen"
                        >
                            <div v-if="!sending" class="flex bg-green-200 rounded-full p-1.5 mr-3 text-black" :class="{'grayscale': !variableChanged}">
                                <c-icon class="w-3.5 h-3.5" icon="check"></c-icon>
                            </div>
                            <loading-icon v-if="approvingInProgress" class="inline-block"></loading-icon>
                            {{ $t('common_approve') }}
                        </t-button>
                    </div>
                </div>
            </div>
        </div>
        <template v-slot:button>
            <div class="w-8 h-8 bg-blue-100 rounded-full flex">
                <svg-icon name="x" class="w-3 h-3 m-auto"></svg-icon>
            </div>
        </template>
    </t-modal>
</template>

<script>
import CampaignItemState from '@/components/CampaignItemState';
import FileStateButton from "@/components/FileStateButton";
import CreativeInput from "@/components/creative/CreativeInput";
import SvgIcon from "@/components/SvgIcon";
import CIcon from "@/components/base/icons/CIcon.vue";
import LoadingIcon from "@/components/LoadingIcon";
import {parseISO} from "date-fns";
import {mapGetters} from "vuex";

export default {
    name: "CampaignItemDialog",
    components: {
        CampaignItemState,
        LoadingIcon,
        SvgIcon,
        CIcon,
        CreativeInput,
        FileStateButton
    },
    props: {
        value: {},
        campaign: {},
        campaignItem: {},
    },
    data() {
        return {
            creativeAsZip: false,
            percents: 0,
            uploading: false,
            sending: false,
            approvingInProgress: false
        }
    },
    computed: {
        ...mapGetters('auth', ['isImpersonate', 'isRetailerOrImpersonated', 'isRetailer']),
        show: {
            get() {
                return this.value;
            },
            set(value) {
                if (this.value !== value) {
                    this.$emit('input', value);
                }
            }
        },
        startDate() {
            if (!this.campaignItem.startDate) {
                return '';
            }
            return this.$d(parseISO(this.campaignItem.startDate));
        },
        endDate() {
            if (!this.campaignItem.endDate) {
                return '';
            }
            return this.$d(parseISO(this.campaignItem.endDate));
        },
        zipState() {
            if (this.uploading) {
                return 'loading';
            }
            if (this.campaignItem.zipUploaded) {
                return 'approved';
            }
            return 'default';
        },
        enableSendForCheck() {
            return !this.sending && this.isCreativeFilled && this.campaignItem.itemState.canSendForCheck;
        },
        enableApprovalByRetailer() {
            return this.isCreativeFilled && this.isRetailerOrImpersonated;
        },
        isCreativeFilled() {
            return !!this.campaignItem.creative;
        },
        creativeEditAllowed() {
            return this.campaignItem.creativeEditAllowed || (this.isRetailer && this.campaignItem.itemState.identifier === 'approved');
        },
        sasLineItemURL() {
            if(this.campaignItem.sasInfo) {
                return this.campaignItem.sasInfo.lineItemURL;
            }
            return null;
        },
        sasFlightURL() {
            if(this.campaignItem.sasInfo) {
                return this.campaignItem.sasInfo.flightURL;
            }
            return null;
        },
        sasCreativeURL() {
            if(this.campaignItem.sasInfo) {
                return this.campaignItem.sasInfo.creativeURL;
            }
            return null;
        },
        allowCreativeZips() {
            return this.$store.getters['appSettings/allowCreativeZips'];
        }
    },
    methods: {
        changed() {
            this.$emit('change');
        },
        async uploadFile(file) {
            this.uploading = true;
            const media = await this.$store.dispatch("campaigns/uploadZipFile", {
                file,
                progressFunc: this.progressFunction,
                id: this.campaignItem.id
            });
            this.uploading = false;
            this.$emit('change', media);
        },
        progressFunction(progressEvent) {
            this.percents = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        },
        async send() {
            this.sending = true;
            await this.$store.dispatch("campaigns/requestCampaignItemCheck", {
                campaignItemId: this.campaignItem.id
            });
            this.sending = false;
            this.show = false;
        },
        async approve() {
            this.approvingInProgress = true;
            await this.$store.dispatch('campaigns/campaignItemApprove', {
                campaignItemId: this.campaignItem.id
            });
            this.approvingInProgress = false;
            this.show = false;
        }
    }
}
</script>

<style scoped>

</style>
